import { Div, FormRenderer } from '@/components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Text } from '@punto-ui/react'
import useToast from '@/hooks/useToast'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { z } from 'zod'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { useEffect } from 'react'
import { useCompanyAddress } from '@/libs/react-query/hooks/company/useCompanyAddress'
import { useHandleUpdateCompanyAddress } from '@/libs/react-query/mutations/company/useHandleUpdateCompanyAddress'
import {
  countries,
  states,
} from '@/pages/colaboradores/create/forms/useAddressData'

const CompanyAddressSchema = z.object({
  street: z.string().optional(),
  number: z.string().optional(),
  complement: z.string().optional(),
  neighborhood: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  country: z.string().optional(),
})

type CompanyAddressSchemaType = z.infer<typeof CompanyAddressSchema>

const CompanyAddress = () => {
  const { data: companyAddress, isFetching: isLoadingAddress } =
    useCompanyAddress()
  const { mutateAsync: handleUpdateCompanyAddress, isLoading } =
    useHandleUpdateCompanyAddress()
  const toast = useToast()

  const form = useForm<CompanyAddressSchemaType>({
    resolver: zodResolver(CompanyAddressSchema),
    defaultValues: {
      street: companyAddress?.addresses[0]?.street || '',
      number: companyAddress?.addresses[0]?.number || '',
      complement: companyAddress?.addresses[0]?.complement || '',
      neighborhood: companyAddress?.addresses[0]?.neighboor || '',
      city: companyAddress?.addresses[0]?.city || '',
      state: companyAddress?.addresses[0]?.state || '',
      country: companyAddress?.addresses[0]?.country || '',
    },
  })

  useEffect(() => {
    const values = form.getValues()
    const hasAnyValues = Object.values(values).some((value) => value !== '')

    if (companyAddress?.addresses?.length && !hasAnyValues) {
      form.setValue('street', companyAddress?.addresses[0]?.street || '')
      form.setValue('number', companyAddress?.addresses[0]?.number || '')
      form.setValue(
        'complement',
        companyAddress?.addresses[0]?.complement || '',
      )
      form.setValue(
        'neighborhood',
        companyAddress?.addresses[0]?.neighboor || '',
      )
      form.setValue('city', companyAddress?.addresses[0]?.city || '')
      form.setValue('state', companyAddress?.addresses[0]?.state || '')
      form.setValue('country', companyAddress?.addresses[0]?.country || '')
    }
  }, [companyAddress])

  const inputForm: ControlledFieldProps<CompanyAddressSchemaType>[] = [
    {
      _type: 'dropdown',
      label: 'País de domicilio',
      name: 'country',
      disabled: isLoadingAddress,
      options: countries.map((country) => ({
        label: country,
        value: country,
      })),
      defaultOption: {
        label: '',
        value: '',
      },
    },
    {
      _type: 'dropdown',
      disabled: isLoadingAddress,
      label: 'Departamento de domicilio',
      options: states.map((state) => ({ label: state, value: state })),
      name: 'state',
      defaultOption: {
        label: '',
        value: '',
      },
    },
    {
      _type: 'input',
      disabled: isLoadingAddress,
      label: 'Calle',
      name: 'street',
    },
    {
      _type: 'input',
      disabled: isLoadingAddress,
      label: 'Ciudad de domicilio',
      name: 'city',
    },
    {
      _type: 'input',
      label: 'Dirección',
      disabled: isLoadingAddress,
      name: 'neighborhood',
    },
    {
      _type: 'input',
      label: 'Complemento',
      disabled: isLoadingAddress,
      name: 'complement',
    },
    {
      _type: 'input',
      disabled: isLoadingAddress,
      label: 'Número',
      name: 'number',
    },
  ]

  const submitAddressUpdate = async (data: CompanyAddressSchemaType) => {
    try {
      await handleUpdateCompanyAddress({
        id: companyAddress?.addresses[0]?.id,
        street: data.street,
        number: data.number,
        complement: data.complement,
        neighboor: data.neighborhood,
        city: data.city,
        state: data.state,
        country: data.country,
      })

      toast.addToast({
        title: 'Dirección actualizada con éxito',
        type: 'positive',
        description:
          'Los datos de la dirección han sido actualizados con éxito',
        id: Date.now(),
      })
    } catch (err) {
      toast.addToast({
        title: 'Error al actualizar dirección',
        type: 'negative',
        description:
          'Ha ocurrido un error al actualizar los datos de la dirección',
        id: Date.now(),
      })
    }
  }

  return (
    <FormProvider {...form}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            background: '$interface_light_pure',
            minWidth: 360,
            padding: '$4',
            margin: '$4',
            borderRadius: '$md',
            gap: '$4',
          }}
        >
          <Text
            variant="subtitle2"
            css={{
              color: '$brand_primary_pure',
            }}
          >
            Dirección
          </Text>
          <FormRenderer items={inputForm} />
          <Button
            isLoading={isLoading}
            disabled={isLoadingAddress || isLoading}
            onClick={() => {
              form.handleSubmit(submitAddressUpdate)()
            }}
            icon={<CheckCircleIcon />}
          >
            Actualizar dirección
          </Button>
        </Div>
      </Div>
    </FormProvider>
  )
}

export default CompanyAddress
