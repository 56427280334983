import { Div } from '@/components'
import { styled, Text } from '@punto-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { AddShiftData } from '../form'
import { ShiftRow } from './ShiftRow'

export const ShiftCalendarDefinition = () => {
  const methods = useFormContext<AddShiftData>()

  const shifts = useWatch({
    control: methods.control,
    name: 'shifts',
  })

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',

        backgroundColor: '$interface_light_pure',
        borderRadius: '$lg',
        border: '1px solid',
        borderColor: '$interface_light_down',

        minWidth: 1500,
      }}
    >
      <Table>
        <TableHead>
          <ShiftTableHeader />
        </TableHead>
        <TableBody>
          {shifts.map((shift, index) => (
            <ShiftRow shift={shift} index={index} key={shift.dayOfWeek} />
          ))}
        </TableBody>
      </Table>
    </Div>
  )
}

const ShiftTableHeader = () => {
  return (
    <TableRow>
      <TableCell
        // checkbox
        css={{
          paddingLeft: '$2',
          width: 40,
        }}
      />
      <TableCell
        // copy
        css={{
          width: 30,
        }}
      />
      <TableCell>
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
          }}
        >
          Dia
        </Text>
      </TableCell>
      <TableCell />
      <TableCell>
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
            textAlign: 'center',
          }}
        >
          Entrada
        </Text>
      </TableCell>
      <TableCell>
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
          }}
        >
          Intervalos
        </Text>
      </TableCell>
      <TableCell>
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
            textAlign: 'center',
          }}
        >
          Salida
        </Text>
      </TableCell>
      <TableCell>
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
            textAlign: 'center',
          }}
        >
          Cambio de Jornada
        </Text>
      </TableCell>
      <TableCell
        css={{
          borderLeft: 'solid 1px',
          borderColor: '$interface_light_down',
        }}
      >
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
            textAlign: 'center',
          }}
        >
          Total
        </Text>
      </TableCell>
      <TableCell>
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
            textAlign: 'center',
          }}
        >
          Diurnas
        </Text>
      </TableCell>
      <TableCell>
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
            textAlign: 'center',
          }}
        >
          Nocturnas
        </Text>
      </TableCell>
      <TableCell>
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
            textAlign: 'center',
          }}
        >
          Intervalos
        </Text>
      </TableCell>
    </TableRow>
  )
}

const Table = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',
})
const TableHead = styled('thead')
const TableBody = styled('tbody')
const TableRow = styled('tr', {
  height: 40,
})
const TableCell = styled('td', {
  height: 40,
})
