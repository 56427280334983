import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../../useMe'
import { INotificationsSchedules } from '../../../types/notifications'

const fetchNotificationsSchedules = async () => {
  const response = await apiV2.get<INotificationsSchedules[]>(
    '/notifications/schedules',
  )

  return response.data
}

export const useNotificationsSchedules = () => {
  const { data: me } = useMe()

  const defaultEnabled = !!me && !!me.company_id

  const query = useQuery(
    ['notifications-schedules', me?.company_id],
    async () => {
      const notificationsSchedules = await fetchNotificationsSchedules()

      return notificationsSchedules
    },
    {
      keepPreviousData: true,
      enabled: defaultEnabled,
    },
  )

  return query
}
