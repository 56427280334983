import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { setCookie } from 'nookies'

const handleChangeMultiEnterpriseCompany = async (companyId: string) => {
  const response = await apiV2.post<{ token: string }>(
    '/multi-enterprise-account/change-company',
    {
      company_id: companyId,
    },
  )
  apiV2.defaults.headers.Authorization = `Bearer ${response.data.token}`

  setCookie(undefined, '@PuntoOk:token', response.data.token, {
    maxAge: 60 * 60 * 24 * 30 * 12, // 1 year
    path: '/',
  })

  // Notify other tabs about the token change using BroadcastChannel
  const broadcast = new BroadcastChannel('token-change')
  broadcast.postMessage({ token: response.data.token })

  // Cleanup by closing the BroadcastChannel
  broadcast.close()

  queryClient.resetQueries()
  queryClient.invalidateQueries()

  return response.data
}

export const useHandleChangeMultiEnterpriseCompany = () => {
  const mutation = useCustomMutation(
    ['change-multi-enterprise-company'],
    handleChangeMultiEnterpriseCompany,
  )

  return mutation
}
