import { ICompanyV2 } from '@/libs/react-query/types/company'
import {
  AguinaldoPlaygroundContentType,
  AguinaldoPlaygroundTableType,
} from './types'
import { AguinaldoDetailed } from '@/libs/react-query/types/aguinaldo'

export const fromAguinaldoDetailedToPlayground = (
  aguinaldo: AguinaldoDetailed,
  nameFilterPreviousValue: string,
  cellsIdsPreviousValue: string[],
  company: ICompanyV2,
): AguinaldoPlaygroundTableType => {
  const aguinaldoData: AguinaldoPlaygroundContentType[] = aguinaldo.data.map(
    (data) => {
      return {
        userId: data.worker.id,
        checked: {
          value: false,
          status: '',
        },
        name: {
          value: data.worker.firstName,
          status: '',
        },
        avatar: {
          url: data.worker.photo_url || '',
          alt: data.worker.name || '',
        },
        surname: {
          value: data.worker.surname,
          status: '',
        },
        ci: {
          value: data.worker.document || '',
          status: '',
        },

        monthsPayrolls: data.months_payrolls
          .sort((a, b) => a.month - b.month)
          .map((payroll) => ({
            value: payroll.payroll_id,
            status: '',
            original: '',
            metadata: {
              month: payroll.month,
            },
          })),

        monthsValues: data.months_values
          .sort((a, b) => a.month - b.month)
          .map((value, index) => {
            const doesNotHavePayroll = !data.months_payrolls[index]?.payroll_id

            return {
              value: value.value,
              status: doesNotHavePayroll ? 'warning' : '',
              original: '',
              metadata: {
                month: value.month,
              },
            }
          }),

        aguinaldoAntecipate: {
          value: data.aguinaldoAntecipate,
          status: '',
        },

        subtotal: {
          value: data.subtotal,
          status: '',
        },

        total: {
          value: data.total,
          status: '',
        },

        // data.days.map((day) => {
        //   return [
        //     {
        //       id: day.date,
        //       name: fromDayStatusToDayLabel[day.status]?.label || 'F',
        //       variant:
        //         fromDayStatusToDayLabel[day.status]?.variant || 'negative',
        //     },
        //   ]
        // }),
      } as AguinaldoPlaygroundContentType
    },
  )

  const concepts: boolean[] = []

  const orderedCompanyConcepts = [...(company?.concepts || [])]
  orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

  orderedCompanyConcepts.forEach((conceptName, index) => {
    if (
      aguinaldo.aguinaldo.aguinaldoConfig.concepts.find(
        (c) => c.name === conceptName,
      )
    ) {
      concepts[index] = true
    } else {
      concepts[index] = false
    }
  })

  return {
    data: aguinaldoData,
    id: aguinaldo.aguinaldo.id,
    year: aguinaldo.aguinaldo.year,

    name: aguinaldo.aguinaldo.name || '',
    status: aguinaldo.aguinaldo.status,
    nameFilter: nameFilterPreviousValue,
    description: aguinaldo.aguinaldo.description || '',
    cellsIds: cellsIdsPreviousValue,
    isFavorite: aguinaldo.aguinaldo.is_favorite || false,
    users_ids: aguinaldo.aguinaldo.users.map((user) => user.user_id),
    pagination: {
      page: 1,
      perPage: 10,
      total: aguinaldoData.length,
      totalPages: aguinaldo.totalPages,
    },
    configuration: {
      nightOrdinaryHours:
        aguinaldo.aguinaldo.aguinaldoConfig.ordinaryNightHours,
      morningOrdinaryHours:
        aguinaldo.aguinaldo.aguinaldoConfig.ordinaryMorningHours,
      morningExtraHours: aguinaldo.aguinaldo.aguinaldoConfig.extraMorningHours,
      nightExtraHours: aguinaldo.aguinaldo.aguinaldoConfig.extraNightHours,
      morningHolidayHours:
        aguinaldo.aguinaldo.aguinaldoConfig.holidayMorningHours,
      nightHolidayHours: aguinaldo.aguinaldo.aguinaldoConfig.holidayNightHours,
      morningDiscountHours:
        aguinaldo.aguinaldo.aguinaldoConfig.discountMorningHours,
      nightDiscountHours:
        aguinaldo.aguinaldo.aguinaldoConfig.discountNightHours,

      payments: aguinaldo.aguinaldo.aguinaldoConfig.payments,
      familyBonus: aguinaldo.aguinaldo.aguinaldoConfig.familiarBonus,
      mobility: aguinaldo.aguinaldo.aguinaldoConfig.mobility,
      bonus: aguinaldo.aguinaldo.aguinaldoConfig.bonus,
      commission: aguinaldo.aguinaldo.aguinaldoConfig.commission,
      gift: aguinaldo.aguinaldo.aguinaldoConfig.gift,
      otherPayments: aguinaldo.aguinaldo.aguinaldoConfig.otherEarns,

      ips9: aguinaldo.aguinaldo.aguinaldoConfig.ips9,
      ips16: aguinaldo.aguinaldo.aguinaldoConfig.ips16,

      antecipate: aguinaldo.aguinaldo.aguinaldoConfig.antecipate,
      loan: aguinaldo.aguinaldo.aguinaldoConfig.loan,
      lost: aguinaldo.aguinaldo.aguinaldoConfig.lost,
      purchase: aguinaldo.aguinaldo.aguinaldoConfig.purchase,
      judicial: aguinaldo.aguinaldo.aguinaldoConfig.judicial,
      excess: aguinaldo.aguinaldo.aguinaldoConfig.excess,
      otherDiscount: aguinaldo.aguinaldo.aguinaldoConfig.otherDiscount,

      vacation_value: aguinaldo.aguinaldo.aguinaldoConfig.vacation_value,
      concepts,
    },
  }
}
