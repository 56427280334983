import { useMemo } from 'react'
import { AddWorkerFields } from '.'
import { useIsWorkerFormAvailable } from '../hooks/useIsWorkerFormAvailable'

export const states = [
  'Alto Paraguay',
  'Alto Parana',
  'Amambay',
  'Asuncion',
  'Caaguazu',
  'Caazapa',
  'Canindeyu',
  'Central',
  'Concepcion',
  'Cordillera',
  'Boqueron',
  'Guaira',
  'Itapua',
  'Misiones',
  'Neembucu',
  'Paraguari',
  'Presidente Hayes',
  'San Pedro',
]

export const countries = ['Brasil', 'Paraguay']

export const useAddressData = () => {
  const isWorkerCreationAvailable = useIsWorkerFormAvailable()

  const optionalDataForm: AddWorkerFields = useMemo(
    () => [
      {
        _type: 'dropdown',
        label: 'País de domicilio',
        name: 'country',
        disabled: !isWorkerCreationAvailable,
        options: countries.map((country) => ({
          label: country,
          value: country,
        })),
        defaultOption: {
          label: 'Paraguay',
          value: 'Paraguay',
        },
      },
      {
        _type: 'dropdown',
        disabled: !isWorkerCreationAvailable,
        label: 'Departamento de domicilio',
        options: states.map((state) => ({ label: state, value: state })),
        name: 'state',
        defaultOption: {
          label: 'Alto Parana',
          value: 'Alto Parana',
        },
      },
      {
        _type: 'input',
        disabled: !isWorkerCreationAvailable,
        label: 'Calle',
        name: 'street',
      },
      {
        _type: 'input',
        disabled: !isWorkerCreationAvailable,
        label: 'Ciudad de domicilio',
        name: 'city',
      },
      {
        _type: 'input',
        label: 'Dirección',
        disabled: !isWorkerCreationAvailable,
        name: 'neighborhood',
      },
      {
        _type: 'input',
        label: 'Complemento',
        disabled: !isWorkerCreationAvailable,
        name: 'complement',
      },
      {
        _type: 'input',
        disabled: !isWorkerCreationAvailable,
        label: 'Número',
        name: 'number',
      },
    ],
    [isWorkerCreationAvailable],
  )

  return {
    data: optionalDataForm,
  }
}
