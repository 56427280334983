import React from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { keyframes } from '@stitches/react'
import {
  ArrowPathRoundedSquareIcon,
  ArrowsRightLeftIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ChevronRightIcon,
  SunIcon,
} from '@heroicons/react/24/outline'
import { Checkbox, Tag, Text, styled } from '@punto-ui/react'
import { SmartShiftWithScheduleInfos } from '@/pages/turnos/components/SmartShiftManagement/types'
import { useSmartShift } from '@/pages/turnos/components/SmartShiftManagement/context'
import dayjs from 'dayjs'
import { Div, Touchable } from '@/components'
import { InputDatePicker } from '@/components/InputDatePicker'

interface ShiftCellPopoverProps {
  currentShift: SmartShiftWithScheduleInfos
  userId: string
  isRest?: boolean
}

export const ShiftCellPopover = ({
  currentShift,
  userId,
  isRest,
}: ShiftCellPopoverProps) => {
  const {
    shifts,
    handleUpdateShifts,
    handleUpdateScheduledShifts,
    handleDeleteByScheduleIds,
  } = useSmartShift()

  const weekday = dayjs(currentShift?.date).day()
  const currentShiftDay = currentShift?.days?.find(
    (day) => day.weekDay === weekday,
  )
  const shiftDayIndex = currentShift?.days?.findIndex(
    (day) => day.weekDay === weekday,
  )

  return (
    <DropdownMenu.Portal>
      <PopoverContent sideOffset={5}>
        <Flex
          css={{
            paddingBottom: '$4',
            borderBottom: 'solid 1px',
            borderBottomColor: '$interface_light_down',
            zIndex: 9999,
          }}
        >
          <Checkbox checked />
          <Flex css={{ flexDirection: 'column', marginLeft: '$4' }}>
            <Text variant="subtitle2">
              {isRest ? 'Descanso' : currentShift?.shiftName}
            </Text>
            {currentShiftDay?.intervals && (
              <Text variant="description" css={{ marginTop: '$2' }}>
                {currentShiftDay?.intervals.map((interval, index) => (
                  <Flex css={{ alignItems: 'center' }} key={index}>
                    <Text variant="caption">{interval.startTime}</Text>
                    <Text variant="caption" css={{ margin: '0 $1' }}>
                      -
                    </Text>
                    <Text variant="caption">{interval.endTime}</Text>
                  </Flex>
                ))}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex css={{ flexDirection: 'column', paddingTop: '$4' }}>
          <DropdownMenu.Sub>
            {!isRest && (
              <DropdownMenuSubTrigger style={{ zIndex: 9999 }}>
                <Flex>
                  <ArrowPathRoundedSquareIcon />
                  <Text>Cambiar el turno de esta fecha</Text>
                </Flex>
                <ChevronRightIcon />
                <DropdownMenu.Portal>
                  <DropdownMenuSubContent>
                    {shifts?.map((shift) => (
                      <Touchable
                        key={shift.shiftId}
                        onClick={() =>
                          handleUpdateShifts(
                            [
                              {
                                dates: [currentShift?.date],
                                userId,
                              },
                            ],
                            shift.shiftId,
                          )
                        }
                      >
                        <Flex
                          css={{
                            flexDirection: 'column',
                            background: shift.color,
                            padding: '$4',
                            borderRadius: '$md',
                            marginBottom: '$2',

                            '&:hover': {
                              opacity: 0.8,
                              cursor: 'pointer',
                            },
                          }}
                        >
                          <Text
                            variant="paragraph"
                            css={{ marginBottom: '$2' }}
                          >
                            {shift.shiftName}
                          </Text>
                          <Flex css={{ alignItems: 'center' }}>
                            <Text variant="caption">
                              {
                                shift.days[shiftDayIndex]?.intervals[0]
                                  .startTime
                              }
                            </Text>
                            <Text variant="caption" css={{ margin: '0 $1' }}>
                              -
                            </Text>
                            <Text variant="caption">
                              {
                                shift.days[shiftDayIndex]?.intervals[
                                  shift.days[shiftDayIndex]?.intervals.length -
                                    1
                                ].endTime
                              }
                            </Text>
                          </Flex>
                        </Flex>
                      </Touchable>
                    ))}
                  </DropdownMenuSubContent>
                </DropdownMenu.Portal>
              </DropdownMenuSubTrigger>
            )}
            {isRest && (
              <DropdownMenuSubTrigger
                css={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleDeleteByScheduleIds({
                    ids: [currentShift.schedule.id],
                  })
                }}
              >
                <Flex>
                  <ArrowPathRoundedSquareIcon />
                  <Text>Remover Descanso</Text>
                </Flex>
              </DropdownMenuSubTrigger>
            )}
          </DropdownMenu.Sub>
          <DropdownMenu.Sub>
            <DropdownMenuSubTrigger>
              <Flex>
                <CalendarIcon />
                <Text>
                  Definir fecha de inicio del {isRest ? 'descanso' : 'turno'}
                </Text>
              </Flex>
              <ChevronRightIcon />
              <DropdownMenu.Portal>
                <DropdownMenuSubContent>
                  <Div css={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      defaultChecked={
                        !!(currentShift?.schedule.startDate === null)
                      }
                      onCheckedChange={async function (checked) {
                        if (checked) {
                          const payload = {
                            scheduled_shift_id:
                              currentShift?.schedule.scheduled_shift_id,
                            endDate: currentShift?.schedule.endDate,
                            startDate: null,
                            isRestDay: isRest,
                          }

                          await handleUpdateScheduledShifts(payload)
                        }
                      }}
                    />
                    <Text
                      css={{ marginLeft: '$2', color: '$interface_dark_deep' }}
                    >
                      Sin inicio
                    </Text>
                  </Div>
                  <InputDatePicker
                    maxDates={60}
                    isDisabled={(date: Date) => {
                      const d = dayjs(date)

                      return currentShift?.schedule.endDate === null
                        ? false
                        : d.isAfter(
                            dayjs(currentShift?.schedule.endDate),
                            'day',
                          )
                    }}
                    hideInput
                    stayOpen
                    firstDate={currentShift?.schedule.startDate || null}
                    secondDate={null}
                    type={'single'}
                    onChange={async function (dates: (Date | null)[]) {
                      if (
                        !dayjs(dates[0]).isSame(
                          currentShift?.schedule.startDate,
                          'day',
                        ) &&
                        dates.length
                      ) {
                        const payload = {
                          scheduled_shift_id:
                            currentShift?.schedule.scheduled_shift_id,
                          startDate: dates[0],
                          endDate: currentShift?.schedule.endDate,
                          isRestDay: isRest,
                        }

                        await handleUpdateScheduledShifts(payload)
                      }
                    }}
                  />
                </DropdownMenuSubContent>
              </DropdownMenu.Portal>
            </DropdownMenuSubTrigger>
          </DropdownMenu.Sub>
          <DropdownMenu.Sub>
            <DropdownMenuSubTrigger>
              <Flex>
                <CalendarDaysIcon />
                <Text>
                  Definir fecha de fim del {isRest ? 'descanso' : 'turno'}
                </Text>
              </Flex>
              <ChevronRightIcon />
              <DropdownMenu.Portal>
                <DropdownMenuSubContent>
                  <Div css={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      defaultChecked={
                        !!(currentShift?.schedule.endDate === null)
                      }
                      onCheckedChange={async function (checked) {
                        if (checked) {
                          const payload = {
                            scheduled_shift_id:
                              currentShift?.schedule.scheduled_shift_id,
                            startDate: currentShift?.schedule.startDate,
                            endDate: null,
                            isRestDay: isRest,
                          }

                          await handleUpdateScheduledShifts(payload)
                        }
                      }}
                    />
                    <Text
                      css={{ marginLeft: '$2', color: '$interface_dark_deep' }}
                    >
                      Sin termino
                    </Text>
                  </Div>
                  <InputDatePicker
                    maxDates={60}
                    hideInput
                    isDisabled={(date: Date) => {
                      const d = dayjs(date)

                      return currentShift?.schedule.startDate === null
                        ? false
                        : d.isBefore(
                            dayjs(currentShift?.schedule.startDate),
                            'day',
                          )
                    }}
                    stayOpen
                    firstDate={currentShift?.schedule.endDate || null}
                    secondDate={null}
                    type={'single'}
                    onChange={async function (dates: (Date | null)[]) {
                      if (
                        !dayjs(dates[0]).isSame(
                          currentShift?.schedule.endDate,
                          'day',
                        ) &&
                        dates.length
                      ) {
                        const payload = {
                          scheduled_shift_id:
                            currentShift?.schedule.scheduled_shift_id,
                          startDate: currentShift?.schedule.startDate,
                          endDate: dates[0],
                          isRestDay: isRest,
                        }

                        await handleUpdateScheduledShifts(payload)
                      }
                    }}
                  />
                </DropdownMenuSubContent>
              </DropdownMenu.Portal>
            </DropdownMenuSubTrigger>
          </DropdownMenu.Sub>

          <ShiftItemSvgStyles disabled>
            <Flex>
              <ArrowsRightLeftIcon />
              <Text>Agregar movimiento en esta fecha</Text>
            </Flex>
            <Flex>
              <Tag css={{ marginRight: '$2' }}>Breve</Tag>
              <ChevronRightIcon />
            </Flex>
          </ShiftItemSvgStyles>
          <ShiftItemSvgStyles disabled>
            <Flex>
              <SunIcon />
              <Text>Agregar vacaciones en esta fecha</Text>
            </Flex>
            <Flex>
              <Tag css={{ marginRight: '$2' }}>Breve</Tag>
              <ChevronRightIcon />
            </Flex>
          </ShiftItemSvgStyles>
        </Flex>
      </PopoverContent>
    </DropdownMenu.Portal>
  )
}

const Flex = styled('div', { display: 'flex' })
const itemsStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$2',

  [`${Flex}`]: {
    alignItems: 'center',
    color: '$interface_dark_deep',

    [`${Text}`]: {
      color: '$interface_dark_deep',
    },

    '& svg': {
      color: '$interface_dark_deep',
    },
  },
}

export const ShiftItem = styled(DropdownMenu.Item, {
  ...itemsStyle,
})

const DropdownMenuSubTrigger = styled(DropdownMenu.SubTrigger, {
  ...itemsStyle,

  zIndex: 9999,

  '& svg': {
    height: '$5',
    color: '$interface_dark_deep',
    width: '$5',
    marginRight: '$2',
  },

  '&[data-state="open"]': {
    backgroundColor: '$interface_light_up',
  },
})

export const ShiftItemSvgStyles = styled('div', {
  ...itemsStyle,

  '& svg': {
    height: '$5',
    color: '$interface_dark_deep',
    width: '$5',
    marginRight: '$2',
  },

  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
  },
})

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(12px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-12px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-12px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(12px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const PopoverContent = styled(DropdownMenu.Content, {
  borderRadius: '$lg',
  zIndex: 9999,
  padding: '$5',
  minWidth: 500,
  backgroundColor: '$interface_light_pure',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
})

const DropdownMenuSubContent = styled(DropdownMenu.SubContent, {
  borderRadius: '$lg',
  marginBottom: '$2',
  zIndex: 9999,
  padding: '$4',
  minWidth: 200,
  maxHeight: 400,
  overflow: 'scroll',
  backgroundColor: '$interface_light_pure',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
})
