import { IReportBuilderColumnType } from '../../type'
import { generateRandomNumberUnderBigMoney } from '@/utils/random'

export const dailyPayrollColumns: IReportBuilderColumnType[] = [
  {
    type: 'payrolls',
    value: 'total_ordinary_morning_value',
    label: 'T. Ord. Diur.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_ordinary_night_value',
    label: 'T. Adicional Nocturno',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_morning_extra_value',
    label: 'T. Ext. Diur.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_night_extra_value',
    label: 'T. Ext. Noc.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_morning_holiday_value',
    label: 'T. Fer. Diur.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_night_holiday_value',
    label: 'T. Fer. Noc.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_morning_discount_value',
    label: 'T. Desc. Diur.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_night_discount_value',
    label: 'T. Desc. Noc.',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Horas (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_ordinary_value',
    label: 'Total Valor Ordinarias',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Generales (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_extra_value',
    label: 'T. Extras',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Generales (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_holiday_value',
    label: 'T. Feriado',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Generales (G$)',
  },
  {
    type: 'payrolls',
    value: 'total_discount_value',
    label: 'T. Descuentos',
    getRandomPlaceholder: generateRandomNumberUnderBigMoney,
    valueType: 'number' as const,
    subgroup: 'Totales Generales (G$)',
  },
]
