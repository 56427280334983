import { apiV2 } from '@/services/api'
import { IPayment } from '@/libs/react-query/types'
import { queryClient, useCustomMutation } from '@/libs/react-query'

const handleDeleteNotification = async (id: string) => {
  const response = await apiV2.delete<IPayment>(`/notifications/${id}`)
  queryClient.invalidateQueries()
  return response.data
}

export const useHandleDeleteNotifications = (id?: string) => {
  const mutation = useCustomMutation(
    ['delete-notification', id],
    handleDeleteNotification,
  )

  return mutation
}
