import { apiV2 } from '@/services/api'
import { IPayment } from '@/libs/react-query/types'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { ICreateNotificationScheduleDTO } from '@/libs/react-query/types/notifications'

const handleCreateNotification = async (
  props: ICreateNotificationScheduleDTO,
) => {
  const response = await apiV2.post<IPayment>('/notifications', props)
  queryClient.invalidateQueries()
  return response.data
}

export const useHandleCreateNotifications = () => {
  const mutation = useCustomMutation(
    ['create-notification'],
    handleCreateNotification,
  )

  return mutation
}
