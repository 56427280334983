import { AddShiftFields } from '../form'

export const useToleranceForm = () => {
  const data: AddShiftFields = [
    {
      _type: 'input',
      label: 'Tolerancia Llegada Anticipada',
      name: 'config.entrance_tolerance_before',
      inputType: 'hour',
      placeholder: '00:00',
    },
    {
      _type: 'input',
      label: 'Tolerancia Llegada Tardia',
      name: 'config.entrance_tolerance_after',
      inputType: 'hour',
      placeholder: '00:00',
    },
    {
      _type: 'input',
      label: 'Tolerancia Retiro Anticipado',
      name: 'config.leave_tolerance_before',
      inputType: 'hour',
      placeholder: '00:00',
    },
    {
      _type: 'input',
      label: 'Tolerancia Retiro Tardio',
      name: 'config.leave_tolerance_after',
      inputType: 'hour',
      placeholder: '00:00',
    },
  ]

  return {
    data,
  }
}
