import { z } from 'zod'

export const reportsStateSchema = z.object({
  filterFavorites: z.boolean(),
})

export type IReportsStateSchema = z.infer<typeof reportsStateSchema>

export const INITIAL_REPORTS_STATE: IReportsStateSchema = {
  filterFavorites: false,
}
