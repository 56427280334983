import { AddShiftFields } from '../../../forms'

export const useDiscountOptions = () => {
  const data: AddShiftFields = [
    {
      _type: 'checkbox',
      label: 'Considerar tolerancia',
      name: 'config.consider_tolerance',
    },
    {
      _type: 'checkbox',
      label: 'Descontar tiempo a menos',
      name: 'config.discount_time',
    },
    {
      _type: 'checkbox',
      label: 'Autocompletar intervalos',
      name: 'config.should_autocomplete_intervals',
    },
  ]

  return {
    data,
  }
}
