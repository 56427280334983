import { useEffect } from 'react'
import { queryClient } from '@/libs/react-query'
import { setCookie } from 'nookies'
import { apiV2 } from '@/services/api'

export const useTokenChangeListener = () => {
  useEffect(() => {
    const broadcast = new BroadcastChannel('token-change')

    // Listen for messages
    const handleBroadcastMessage = (event: MessageEvent) => {
      const { token } = event.data

      // Update the authorization token and cookie
      apiV2.defaults.headers.Authorization = `Bearer ${token}`
      setCookie(undefined, '@PuntoOk:token', token, {
        maxAge: 60 * 60 * 24 * 30 * 12, // 1 year
        path: '/',
      })

      // Optionally reload or refetch data
      queryClient.resetQueries()
      location.reload() // This reloads the page to apply changes
    }

    broadcast.addEventListener('message', handleBroadcastMessage)

    // Cleanup on component unmount
    return () => {
      broadcast.removeEventListener('message', handleBroadcastMessage)
      broadcast.close()
    }
  }, [])
}
