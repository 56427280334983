import { Div } from '@/components'
import { useSmartShift } from '../../context'
import { Button, Text } from '@punto-ui/react'
import { SmartShift } from '../../types'
import {
  ArrowTurnRightUpIcon,
  CloudIcon,
  MoonIcon,
  SunIcon,
} from '@heroicons/react/24/outline'
import { useEffect, useMemo, useRef, useState } from 'react'
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { useHorizontalScroll } from '@/hooks/useHorizontalScroll'
import { GrabIcon } from '@/assets/icons/GrabIcon'
import { useCan } from '@/hooks'

export const ShiftsTopBar = () => {
  const { shifts } = useSmartShift()

  const [isContained, setIsContained] = useState(false)
  const { ref } = useHorizontalScroll<HTMLDivElement>(isContained)

  return (
    <Div
      css={{
        position: 'relative',
      }}
    >
      <Div
        ref={ref}
        css={{
          display: 'flex',
          flexWrap: isContained ? 'nowrap' : 'wrap',
          maxWidth: '100vw',
          maxHeight: isContained ? 100 : 200,
          gap: '$2',
          overflowX: isContained ? 'auto' : 'hidden',
          overflowY: isContained ? 'hidden' : 'auto',
          paddingLeft: 16,
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        {shifts?.map((shift) => (
          <Shift
            key={shift.shiftId}
            shift={shift}
            label={shift.shiftName}
            id={shift.shiftId}
            color={shift.color}
            primaryColor={shift.primaryColor}
            type={shift.daily_type}
          />
        ))}
      </Div>
      <Div
        css={{
          position: 'absolute',
          right: 8,
          bottom: 8,
          svg: {
            transform: isContained ? 'rotate(180deg)' : 'rotate(0deg)',
          },
        }}
      >
        <Button variant="primary" onClick={() => setIsContained(!isContained)}>
          <ArrowTurnRightUpIcon />
        </Button>
      </Div>
    </Div>
  )
}

interface IShiftProps {
  id: string
  label: string
  color: string
  primaryColor: string
  type: string
  shift: SmartShift
}

const Shift = ({
  shift,
  id,
  label,
  color,
  primaryColor,
  type,
}: IShiftProps) => {
  const canManageShifts = useCan(['shifts.gestionar'])
  const draggableRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!draggableRef.current || !canManageShifts) return

    return draggable({
      element: draggableRef.current,
      getInitialData: () => ({
        shift,
        type: 'shift-management',
      }),
    })
  }, [shift, canManageShifts])

  const firstAndLastInterval = useMemo(() => {
    const firstDayWithStartTime = shift?.days?.find(
      (day) =>
        day.intervals[0].startTime &&
        day.intervals[0].startTime !== day.intervals[0].endTime,
    )

    const firstStartTime = firstDayWithStartTime?.intervals[0].startTime
    const lastEndTime =
      firstDayWithStartTime?.intervals[
        firstDayWithStartTime?.intervals.length - 1
      ].endTime

    return { firstStartTime, lastEndTime }
  }, [shift])

  return (
    <Div
      ref={draggableRef}
      css={{
        display: 'flex',
        flexDirection: 'row',
        gap: '$2',
        alignItems: 'center',

        overflow: 'hidden',

        // width: 250,
        minWidth: 250,

        // padding: '$2',
        paddingLeft: '$2',
        paddingRight: '$2',
        paddingTop: '$1',
        paddingBottom: '$1',

        background: color,
        borderRadius: 12,
        cursor: 'grab',

        opacity: 0.6,
        boxShadow: '0px 0px 8px 0px #343A4014',

        border: `1px solid ${primaryColor}`,

        '&:hover': {
          opacity: 1,
          boxShadow: '0px 0px 16px 0px #343A4014',
          zIndex: 2,
        },
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
          svg: {
            color: primaryColor,
          },
        }}
      >
        <GrabIcon fill={primaryColor} />
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          height: 25,
          maxHeight: 25,
          svg: {
            marginRight: '$2',
            width: 16,
            height: 16,
            color: primaryColor,
            strokeWidth: 2.5,
          },
        }}
      >
        {type === 'morning' ? (
          <SunIcon />
        ) : type === 'night' ? (
          <MoonIcon />
        ) : type === 'mixed' ? (
          <CloudIcon />
        ) : (
          <SunIcon />
        )}
        <Text
          variant="description"
          css={{
            color: primaryColor,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '25px',
          }}
        >
          {label}
        </Text>
      </Div>
      <Div
        css={{
          pointerEvents: 'none',
        }}
      >
        {firstAndLastInterval.firstStartTime &&
          firstAndLastInterval.lastEndTime && (
            <Text
              variant="caption"
              css={{
                fontWeight: '$bold',
                color: '$interface_dark_down',
                whiteSpace: 'nowrap',
              }}
            >
              {firstAndLastInterval.firstStartTime} -{' '}
              {firstAndLastInterval.lastEndTime}
            </Text>
          )}
      </Div>
    </Div>
  )
}
