import { useMemo } from 'react'
import { PayrollPlaygroundFields } from '../types'

export const usePayrollConfiguration = (isClosed?: boolean) => {
  const configuration: PayrollPlaygroundFields = useMemo(() => {
    const form: PayrollPlaygroundFields = [
      {
        _type: 'row',
        numberOfColumns: 2,
        fields: [
          {
            _type: 'input',
            label: 'Nombre de la planilla',
            name: 'name',
            disabled: isClosed,
            placeholder: 'Planilha Agosto 2023.',
            inputType: 'name',
          },
        ],
      },
      {
        _type: 'input',
        label: 'Descripción',
        name: 'description',
        disabled: isClosed,
        placeholder: 'Planilla nominal 01/08/2023 hasta 31/08/2023.',
        inputType: 'name',
      },
      {
        _type: 'row',
        numberOfColumns: 0,
        fields: [
          {
            _type: 'calendar',
            label: 'Fecha de firma',
            name: 'signatureDate',
            disabled: isClosed,
            calendarType: 'single',
            maxDates: 999,
          },
          {
            _type: 'calendar',
            label: 'Fecha de Pago',
            name: 'paymentDate',
            disabled: isClosed,
            calendarType: 'single',
            maxDates: 999,
          },
        ],
      },
      {
        _type: 'checkbox',
        disabled: isClosed,
        name: 'isFavorite',
        label: 'Favorita',
      },
    ]

    return form
  }, [isClosed])

  return {
    data: configuration,
  }
}
