import { Div } from '@/components/Div'
import { useAvailableOrganogram } from '@/libs/react-query/hooks/useAvailableOrganogram'
import { ICell, IOrganogram } from '@/libs/react-query/types/organogram'
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import { Progress, Text, styled } from '@punto-ui/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  findAndBuildMatchingOrganograms,
  getAllCellsUnderCellId,
  getMaxWidth,
} from './organogram'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { ControlledCheckbox } from '@/components/Forms'
import { useAvailableCells } from '@/libs/react-query/hooks'
import * as Popover from '@radix-ui/react-popover'
import { keyframes } from '@/styles'

interface CellFilterProps {
  callback: (cell: ICell[]) => void
  policiesIds: string[]
  direction?: 'right' | 'left'
  defaultValues?: boolean
  selectedIds?: string[]
  withoutTotalButtons?: boolean
  label?: string
  isWhite?: boolean
}

type SelectedCellsForm = Record<string, boolean>

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translateY(0%) scale(1)' },
})

export const OrganogramFilter = ({
  direction = 'left',
  callback,
  defaultValues,
  policiesIds,
  selectedIds,
  withoutTotalButtons,
  label,
  isWhite = false,
}: CellFilterProps) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)

  const { data: cells } = useAvailableCells(policiesIds)
  const { data: organogram } = useAvailableOrganogram(policiesIds)
  const [inputValue, setInputValue] = useState('')

  const [amountSelected, setAmountSelected] = useState(0)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const timeoutRef = useRef<any>(null)
  // const chipRef = useRef<any>(null)

  // useOutsideAlerter(chipRef, () => {
  //   setIsOptionsOpen(false)
  // })

  const methods = useForm<SelectedCellsForm>({
    defaultValues: {},
  })

  const handleSelectCell = useCallback(
    (cellId: string, isSelecting: boolean) => {
      if (!organogram) return []

      const selectedCellsIds = getAllCellsUnderCellId(
        organogram.organogram,
        cellId,
      )

      const selected = methods.getValues()

      const allCellsChecks: SelectedCellsForm =
        cells?.reduce(
          (acc, cell) => ({
            ...acc,
            [cell.id]: false,
          }),
          {} as SelectedCellsForm,
        ) || {}

      const newSelectedCells: SelectedCellsForm = {
        ...allCellsChecks,
        ...selected,
      }
      selectedCellsIds.forEach((id) => {
        newSelectedCells[id] = isSelecting
      })
      const allSelectedCells = Object.keys(newSelectedCells).filter(
        (key) => newSelectedCells[key],
      )
      const allCells = organogram.cells.filter((cell) =>
        allSelectedCells.includes(cell.id),
      )

      methods.reset(newSelectedCells)
      callback?.(allCells)
      setAmountSelected(allSelectedCells.length)
    },
    [cells, methods, organogram],
  )

  useEffect(() => {
    if (organogram?.organogram && selectedIds) {
      const allSelectedIds: SelectedCellsForm =
        cells?.reduce(
          (acc, cell) => ({
            ...acc,
            [cell.id]: false,
          }),
          {} as SelectedCellsForm,
        ) || {}
      selectedIds.forEach((id) => {
        allSelectedIds[id] = true
      })
      methods.reset(allSelectedIds)
      setAmountSelected(selectedIds.length)
    }
  }, [selectedIds, cells, organogram?.organogram, methods])

  const dropdownWidth = useMemo(() => {
    if (!organogram?.organogram) {
      return 0
    }

    const maxWidth = getMaxWidth(organogram?.organogram)

    const finalMaxWidth =
      maxWidth > 1000 ? 1000 : maxWidth < 300 ? 300 : maxWidth

    return finalMaxWidth
  }, [organogram])

  return (
    <Popover.Root
      open={isOptionsOpen}
      onOpenChange={(isOpen) => {
        setIsOptionsOpen(isOpen)
        if (isOpen) {
          timeoutRef.current = setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.focus()
            }
          }, 15)
        }
      }}
    >
      <FormProvider {...methods}>
        <Div
          css={{
            position: 'relative',
          }}
        >
          <Popover.Trigger
            style={{
              all: 'unset',
            }}
          >
            <FilterButton
              onClick={() => {
                setIsOptionsOpen(!isOptionsOpen)
                timeoutRef.current = setTimeout(() => {
                  if (inputRef.current) {
                    inputRef.current.focus()
                  }
                }, 15)
              }}
            >
              <Div
                css={{
                  display: 'flex',
                  alignItems: 'center',

                  minWidth: 100,
                  backgroundColor: isWhite
                    ? '$interface_light_pure'
                    : '$interface_light_up',
                  height: 32,
                  border: '1px solid',
                  borderColor: '$brand_primary_pure',
                  borderRadius: '$md',

                  '&:hover': {
                    backgroundColor: '$interface_light_down',
                  },

                  transition: 'all 0.3s ease-in-out',
                }}
              >
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    variant="description"
                    css={{
                      color: '$brand_primary_pure',
                      marginLeft: 12,
                      marginRight: 8,
                      lineHeight: '15px',
                    }}
                  >
                    {label || 'Sector'}
                  </Text>
                </Div>

                <Div
                  css={{
                    height: 20,
                    width: 20,
                    backgroundColor: !amountSelected
                      ? '$interface_light_down'
                      : '$brand_primary_pure',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '$full',
                    marginRight: 8,
                    border: !amountSelected ? '1px solid' : 'none',
                    borderColor: '$brand_primary_pure',
                  }}
                >
                  <Text
                    variant={'caption'}
                    css={{
                      color: !amountSelected
                        ? '$brand_primary_pure'
                        : '$interface_light_pure',
                      fontWeight: 'bold',
                    }}
                  >
                    {amountSelected}
                  </Text>
                </Div>
                <Div
                  css={{
                    height: 16,
                    width: 16,
                    marginRight: 12,
                    color: '$brand_primary_pure',
                  }}
                >
                  <ChevronDownIcon />
                </Div>
              </Div>
            </FilterButton>
          </Popover.Trigger>
          {/* {isOptionsOpen && ( */}
          <Popover.Portal>
            <Popover.Content sideOffset={5} asChild>
              <Div
                css={{
                  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
                  transition: 'grid-template-rows 500ms',
                  gridTemplateRows: isOptionsOpen ? '1fr' : '0fr',
                  display: 'grid',

                  zIndex: 9999999,

                  maxHeight: 400,
                  minWidth: dropdownWidth,
                  maxWidth: dropdownWidth,
                  width: '100%',

                  backgroundColor: '$interface_light_pure',
                  borderRadius: '$lg',
                  overflowY: 'hidden',

                  boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
                  border: 'solid 1px $interface_light_down',
                }}
              >
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '$interface_light_up',
                    padding: '$4',
                    svg: {
                      height: 12,
                      width: 12,
                      color: '$brand_primary_pure',
                      marginRight: '$2',
                    },
                  }}
                >
                  <MagnifyingGlassIcon />
                  <ChipInput
                    ref={inputRef}
                    placeholder={label || 'Sector'}
                    onChange={(e) => {
                      const name = e.target.value
                      setIsOptionsOpen(true)
                      setInputValue(name)
                    }}
                    value={inputValue}
                  />
                </Div>
                {organogram && !inputValue && (
                  <Div
                    css={{
                      overflowY: 'auto',
                      overflowX: 'scroll',
                      padding: '$2',

                      '&::-webkit-scrollbar': {
                        height: '2px',
                      },
                    }}
                  >
                    <OrganogramCell
                      handleSelectCell={handleSelectCell}
                      organogram={organogram.organogram}
                    />
                  </Div>
                )}
                {organogram && inputValue && cells && (
                  <Div
                    css={{
                      overflowY: 'auto',
                      overflowX: 'scroll',
                      padding: '$2',

                      '&::-webkit-scrollbar': {
                        height: '2px',
                      },
                    }}
                  >
                    {findAndBuildMatchingOrganograms(
                      organogram.organogram,
                      cells,
                      inputValue,
                    ).map((organogram) => (
                      <OrganogramCell
                        key={organogram.id}
                        handleSelectCell={handleSelectCell}
                        organogram={organogram}
                      />
                    ))}
                  </Div>
                )}
                {(!organogram && !inputValue) ||
                  (inputValue && !cells && <Progress />)}
              </Div>
            </Popover.Content>
          </Popover.Portal>
          {/* )} */}
        </Div>
        {/* </Div> */}
      </FormProvider>
    </Popover.Root>
  )
}

interface IOrganogramCell {
  organogram: IOrganogram
  handleSelectCell: (cellId: string, isSelecting: boolean) => void
}

const OrganogramCell = ({ handleSelectCell, organogram }: IOrganogramCell) => {
  const [isOpen, setIsOpen] = useState(true)
  const currentValue = useRef<any>(false)

  const { getValues } = useFormContext()

  return (
    <Div
      css={{
        position: 'relative',
      }}
    >
      <FilterButtonLabel>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Div
            css={{
              height: 16,
              width: 16,
              marginRight: '$1',
            }}
          >
            <ControlledCheckbox
              onClick={(e) => e.stopPropagation()}
              onValueChange={(e) => {
                currentValue.current = e
                handleSelectCell(organogram.id, e)
              }}
              name={organogram.id}
            />
          </Div>
          {organogram.children.length ? (
            <FilterButton
              // type="button"
              onClick={(e) => {
                setIsOpen(!isOpen)
                e.stopPropagation()
              }}
            >
              <Div
                css={{
                  height: 20,
                  width: 20,
                  color: '$interface_dark_up',
                  marginRight: '$2',
                  transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                  transition: 'transform 0.3s ease-in-out',

                  ':hover': {
                    color: '$interface_dark_down',
                    transition: 'color 0.3s ease-in-out',
                  },
                }}
              >
                <ChevronDownIcon />
              </Div>
            </FilterButton>
          ) : (
            <Div css={{ marginRight: '$2' }} />
          )}
          <Div
            onClick={() => {
              const value = getValues(organogram.id)
              const newValue = !value
              handleSelectCell(organogram.id, newValue)
            }}
          >
            <Text
              css={{
                color: '$interface_dark_deep',
                whiteSpace: 'nowrap',
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
                // maxWidth: '100%',
              }}
            >
              {organogram.name}
            </Text>
          </Div>
          {!!organogram.users?.length && (
            <Div
              css={{
                marginLeft: '$2',
              }}
            >
              <Text
                css={{
                  color: '$interface_dark_deep',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                  border: '1px solid',
                  borderColor: '$interface_dark_deep',
                  borderRadius: '$full',
                  width: organogram.users?.length > 99 ? 24 : 20,
                  height: organogram.users?.length > 99 ? 24 : 20,

                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                variant={'caption'}
              >
                {organogram.users?.length}
              </Text>
            </Div>
          )}
        </Div>
      </FilterButtonLabel>
      {/* {!!organogram.children.length && isOpen ? ( */}
      <Div
        css={{
          paddingLeft: 22,
          paddingTop: '$2',

          // overflow: 'scroll',

          transition: 'grid-template-rows 500ms',
          gridTemplateRows:
            isOpen && !!organogram.children.length ? '1fr' : '0fr',
          display: 'grid',
        }}
      >
        <Div
          css={{
            overflow: 'hidden',
          }}
        >
          {organogram.children.map((child, index) => (
            <Div key={child.id}>
              <OrganogramCell
                handleSelectCell={handleSelectCell}
                organogram={child}
              />
            </Div>
          ))}
        </Div>
      </Div>
    </Div>
  )
}

const FilterButton = styled('div', {
  all: 'unset',
  cursor: 'pointer',
})

const FilterButtonLabel = styled('div', {
  all: 'unset',
  cursor: 'pointer',
})

export const ChipInput = styled('input', {
  all: 'unset',
  textAlign: 'left',
  width: '100%',

  backgroundColor: 'transparent',
  fontFamily: '$default',
  fontSize: '$sm',
  fontWeight: 'regular',
  border: 0,
  // width: '100%',
  // maxWidth: 75,

  '&::placeholder': {
    color: '$brand_primary_pure',
  },
})
