import { apiV2 } from '@/services/api'
import { IUpdateCompanyAddressDTO } from '@/libs/react-query/mutations/company/types'
import { queryClient, useCustomMutation } from '@/libs/react-query'

export const useHandleUpdateCompanyAddress = () => {
  const mutation = useCustomMutation(
    ['useHandleUpdateCompanyAddress'],
    handleUpdateCompanyAddress,
  )

  return mutation
}

const handleUpdateCompanyAddress = async (props: IUpdateCompanyAddressDTO) => {
  try {
    await apiV2.post<void>('/company/update-company-address', props)

    queryClient.invalidateQueries()
  } catch (error) {
    console.log(error)
  }
}
