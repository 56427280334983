import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../../useMe'
import { ICompanyV2 } from '@/libs/react-query/types/company'

const fetchMultiEnterpriseCompanies = async () => {
  const response = await apiV2.get<ICompanyV2[]>(
    '/multi-enterprise-account/companies',
  )

  return response.data
}

export const useGetMultiEnterpriseCompanies = () => {
  const { data: me } = useMe()

  const defaultEnabled = !!me && !!me.company_id

  const query = useQuery(
    ['multi-enterprise-companies', me?.company_id],
    async () => {
      const multiEnterpriseCompanies = await fetchMultiEnterpriseCompanies()

      return multiEnterpriseCompanies
    },
    {
      keepPreviousData: true,
      enabled: defaultEnabled,
    },
  )

  return query
}
