import { queryClient } from '@/libs/react-query'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleToggleFavoritePdf = async (templateId: string) => {
  await apiV2.post<any>(`/report-builder/toggle-favorite-pdf-template`, {
    template_id: templateId,
  })
  queryClient.invalidateQueries('pdfs')
}

export const useHandleToggleFavoritePdf = () => {
  const mutation = useCustomMutation(
    ['useHandleToggleFavoritePdf'],
    handleToggleFavoritePdf,
  )

  return mutation
}
