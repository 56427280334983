import { apiV2 } from '@/services/api'
import { IPayment } from '@/libs/react-query/types'
import { queryClient, useCustomMutation } from '@/libs/react-query'
import { ICreateNotificationScheduleDTO } from '@/libs/react-query/types/notifications'

const handleUpdateNotification = async (
  props: ICreateNotificationScheduleDTO & {
    id: string
  },
) => {
  const response = await apiV2.put<IPayment>(
    `/notifications/${props.id}`,
    props,
  )
  queryClient.invalidateQueries()
  return response.data
}

export const useHandleUpdateNotifications = () => {
  const mutation = useCustomMutation(
    ['update-notification'],
    handleUpdateNotification,
  )

  return mutation
}
