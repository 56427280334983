import { Div } from '@/components/Div'
import { ControlledWorkerSearchBar, FormRenderer } from '@/components/Forms'
import { Text } from '@punto-ui/react'
import { useExportTemplateConfiguration } from './useExportTemplateConfiguration'
import { useReportTemplate } from '@/libs/react-query/hooks/useReportTemplate'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useFormContext, useWatch } from 'react-hook-form'
import { ExportTemplateSchemaType } from './form'
import { useEffect, useMemo } from 'react'
import { usePayrolls } from '@/libs/react-query/hooks'
import dayjs from 'dayjs'

export const ExportTemplateBasicConcepts = (props: {
  templateId: string
  prefix?: string
  shouldNotAskUsers?: boolean
  noPadding?: boolean
  height?: string
}) => {
  const name = props.prefix ? `${props.prefix}.` : ''
  const methods = useFormContext<ExportTemplateSchemaType>()
  const { data: template, isLoading: isLoadingTemplate } = useReportTemplate(
    props.templateId,
  )
  const { data: payrolls } = usePayrolls()
  const [month, year] = useWatch({
    control: methods.control,
    name: [`${name}month`, `${name}year`] as ['month', 'year'],
  })

  const {
    data: exportTemplateConfig,
    payrollForm,
    aguinaldoForm,
  } = useExportTemplateConfiguration(template, props.prefix)

  useEffect(() => {
    if (payrollForm.length === 1) {
      const payrollInMonthAndYear = (payrolls || []).findIndex((p) => {
        const payrollMonth = dayjs(
          dayjs(p.start_date).utc().format('YYYY-MM-DD'),
          'YYYY-MM-DD',
        ).month()
        const payrollYear = dayjs(
          dayjs(p.start_date).utc().format('YYYY-MM-DD'),
          'YYYY-MM-DD',
        ).year()

        return payrollMonth === +month && payrollYear === +year
      })

      if (payrollInMonthAndYear !== -1) {
        methods.setValue(`${name}payrollIds` as any, [
          payrolls?.[payrollInMonthAndYear].id || '',
        ])
      } else {
        methods.setValue(`${name}payrollIds` as any, [])
      }
    }

    if (payrollForm.length > 1) {
      const months = Array.from({ length: 12 }).map((_, i) => i)
      const payrollIds = months.map((m) => {
        const payrollInMonthAndYear =
          payrolls?.findIndex((p) => {
            const payrollMonth = dayjs(p.start_date).month()
            const payrollYear = dayjs(p.start_date).year()

            return payrollMonth === m && payrollYear === +year
          }) || -1

        return payrollInMonthAndYear !== -1 &&
          payrolls?.[payrollInMonthAndYear].id
          ? payrolls?.[payrollInMonthAndYear].id
          : ''
      })
      methods.setValue(`${name}payrollIds` as any, payrollIds)
    }
  }, [template, month, year])

  const usersIds = useWatch({
    control: methods.control,
    name: `${name}usersIds` as 'usersIds',
  })

  const shouldAksUsers = useMemo(() => {
    return (
      !props.shouldNotAskUsers &&
      template?.columns.every((item) => item.type !== 'sueldos') &&
      !template?.id.includes('bank-payroll')
    )
  }, [template, props.shouldNotAskUsers])

  return (
    <Div
      css={{
        minWidth: '40vw',
        padding: props.noPadding ? 0 : '$8',
        flex: 1,
        height: props.height || 'calc(80vh - 36px - 50px - 50px)',
        // height: '95vh',
        overflow: 'scroll',
      }}
    >
      {!isLoadingTemplate && (
        <>
          <Text
            variant={'subtitle2'}
            css={{
              color: '$brand_primary_pure',
              marginBottom: '$2',
            }}
          >
            Datos basicos del reporte: {template?.name || ''}
          </Text>
          <FormRenderer
            items={exportTemplateConfig}
            inline
            cols={exportTemplateConfig.length > 1 ? 3 : 2}
          />
          {shouldAksUsers && (
            <Div
              css={{
                //   height: 400,
                marginTop: '$4',
                marginBottom: '$4',
                //   overflow: 'hidden',
              }}
            >
              <ControlledWorkerSearchBar
                activeDateFilter
                activeSelection
                name={'usersIds'}
                policiesIds={permissionsArray.filter((p) =>
                  p.includes('reports'),
                )}
                maxHeight={200}
                usersIds={usersIds}
              />
            </Div>
          )}
          {!!payrollForm.length && (
            <>
              <Text
                variant={'subtitle2'}
                css={{
                  color: '$brand_primary_pure',
                  marginTop: '$6',
                  marginBottom: '$2',
                }}
              >
                Datos de Planilla
              </Text>
              <FormRenderer items={payrollForm} cols={4} inline />
            </>
          )}
          {!!aguinaldoForm.length && (
            <>
              <Text
                variant={'subtitle2'}
                css={{
                  color: '$brand_primary_pure',
                  marginTop: '$6',
                  marginBottom: '$2',
                }}
              >
                Datos de Aguinaldo
              </Text>
              <FormRenderer items={aguinaldoForm} cols={2} />
            </>
          )}
        </>
      )}
    </Div>
  )
}
