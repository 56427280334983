import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { useMemo } from 'react'
import { AguinaldoPlaygroundFields } from '../types'

export const useAguinaldoConcepts = (isClosed?: boolean) => {
  const { data: company } = useMyCompany()

  const orderedVacationConcepts = useMemo(() => {
    const orderedCompanyConcepts = [...(company?.concepts || [])]
    orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

    const groupInfoForm: AguinaldoPlaygroundFields = orderedCompanyConcepts.map(
      (c, index) => {
        return {
          _type: 'switch',
          label: c,
          disabled: isClosed,
          name: `configuration.concepts.${index}`,
        }
      },
    )

    return groupInfoForm
  }, [company, isClosed])

  return {
    data: orderedVacationConcepts,
  }
}
