import {
  IUserDocumentEntity,
  IUserDocumentStructure,
} from '@/libs/react-query/types/documents'
import { useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { IDocumentsStateSchema } from '../../documents-state'
import { Avatar, Div } from '@/components'
import {
  ChevronDownIcon,
  FolderIcon,
  FolderOpenIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { Checkbox, Progress, Text, Tooltip } from '@punto-ui/react'
import { useUserDocumentsStructure } from '@/libs/react-query/hooks/useUserDocumentsStructure'
import { capitalizeFirstLetters } from '@/utils/workers/name'
import { findBreadcrumb, getFileTypeIcon } from '../../documents-utils'
import { OpenDrawerIcon } from '@/assets/icons/OpenDrawerIcon'
import dayjs from 'dayjs'

const MIN_COLUMNS_WIDTH = 200

export const DocumentManagementStructure = () => {
  const methods = useFormContext<IDocumentsStateSchema>()

  const userId = useWatch({
    control: methods.control,
    name: 'management.userId',
  })

  const { data: structure, isLoading: isLoadingUserDocuments } =
    useUserDocumentsStructure(userId)

  if (isLoadingUserDocuments) {
    return (
      <Div>
        <Progress />
      </Div>
    )
  }

  if (!structure) {
    return (
      <Div>
        <Text>Seleccione un colaborador</Text>
      </Div>
    )
  }

  // console.log(structure)

  return (
    <Div
      css={{
        background: '$interface_light_pure',
        borderRadius: '$lg',

        maxHeight: 'calc(100vh - 160px)',
        overflow: 'scroll',

        position: 'relative',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',

          background: '$interface_light_pure',

          zIndex: 2,
          position: 'sticky',
          top: 0,

          height: 44,
        }}
      >
        <Div
          css={{
            flex: 1,
            minWidth: 1.2 * MIN_COLUMNS_WIDTH,
            paddingLeft: 36,
          }}
        >
          <Text variant="caption">Estrutura de Carpetas</Text>
        </Div>
        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
          }}
        >
          <Text
            variant="caption"
            css={{
              textAlign: 'center',
            }}
          >
            Fecha
          </Text>
        </Div>
        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
          }}
        >
          <Text
            variant="caption"
            css={{
              textAlign: 'center',
            }}
          >
            Documentos Anexados
          </Text>
        </Div>
        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
          }}
        >
          <Text
            variant="caption"
            css={{
              textAlign: 'center',
            }}
          >
            Documento Generado
          </Text>
        </Div>
        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
          }}
        >
          <Text
            css={{
              textAlign: 'right',
              paddingRight: 36,
            }}
            variant="caption"
          >
            Opciones
          </Text>
        </Div>
      </Div>
      <UserFoldersComponent
        structure={structure}
        index={1}
        userId={structure.user.id}
      />
    </Div>
  )
}

const UserFoldersComponent = ({
  structure,
  index,
  userId,
}: {
  structure: IUserDocumentStructure
  index: number
  userId: string
}) => {
  const isExpandable =
    !!structure.children.length || !!structure.entities.length

  const [isOpen, setIsOpen] = useState<boolean>(isExpandable)

  const methods = useFormContext<IDocumentsStateSchema>()
  const checked = useWatch({
    control: methods.control,
    name: `management.checked.${structure.id}`,
  })

  const isRoot = index === 1

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',

          '&:hover': {
            '> div': {
              background: '$interface_light_up',
            },
          },
        }}
      >
        <Div
          onClick={() => {
            if (isExpandable) {
              setIsOpen(!isOpen)
            }
          }}
          css={{
            cursor: 'pointer',

            display: 'flex',
            alignItems: 'center',
            gap: '$2',

            flex: 1,
            minWidth: 1.2 * MIN_COLUMNS_WIDTH,

            paddingTop: 6,
            paddingBottom: 6,

            paddingLeft: index * 24,
          }}
        >
          {isExpandable ? (
            <Div
              css={{
                height: 20,
                width: 20,

                svg: {
                  height: 16,
                  width: 16,

                  rotate: isOpen ? '0deg' : '-90deg',
                },
              }}
            >
              <ChevronDownIcon />
            </Div>
          ) : (
            <Div
              css={{
                height: 20,
                width: 20,
              }}
            />
          )}
          <Div
            css={{
              svg: {
                height: 20,
                width: 20,
              },
            }}
          >
            {isRoot && (
              <Avatar
                alt=""
                src={structure.user.photo_url || ''}
                height={20}
                width={20}
              />
            )}
            {!isRoot && <>{isOpen ? <FolderOpenIcon /> : <FolderIcon />}</>}
          </Div>
          <Text variant="caption">
            {isRoot
              ? capitalizeFirstLetters(structure.user.name)
              : structure.name}
          </Text>
        </Div>

        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
            height: 32,
          }}
        ></Div>
        <Div
          css={{
            height: 32,
            minWidth: MIN_COLUMNS_WIDTH,
          }}
        ></Div>
        <Div
          css={{
            height: 32,
            minWidth: MIN_COLUMNS_WIDTH,
          }}
        ></Div>
        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
            display: 'flex',
            height: 32,
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: 34,
            gap: '$2',
          }}
        >
          <Div
            css={{
              width: 1,
              height: 10,
              // background: '$interface_light_up',
            }}
          />
          <Div
            onClick={() => {
              methods.setValue('addDocument.isAddDocumentDrawerOpen', true)
              methods.setValue('addDocument.folderId', structure.id)
              methods.setValue('addDocument.userId', userId)
            }}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              cursor: 'pointer',

              svg: {
                height: 16,
                width: 16,
                color: '$interface_dark_down',
              },
            }}
          >
            <Tooltip message={`Agregar documento en "${structure.name}"`} arrow>
              <PlusIcon />
            </Tooltip>
          </Div>
          <Div
            css={{
              width: 1,
              height: 10,
              // background: '$interface_light_up',
            }}
          />
          <Checkbox
            checked={checked}
            onCheckedChange={(v) => {
              methods.setValue(`management.checked.${structure.id}`, Boolean(v))
            }}
          />
        </Div>
      </Div>

      {isOpen &&
        structure.entities.map((entity) => (
          <UserDocumentEntityComponent
            documentEntity={entity}
            key={entity.id}
            index={index}
            folderId={entity.folder_id}
            userId={userId}
          />
        ))}

      {isOpen && (
        <Div>
          {structure.children.map((c) => (
            <UserFoldersComponent
              userId={userId}
              structure={c}
              key={`${c.id}${userId}${c.children.length}${c.entities.length}`}
              index={index + 1}
            />
          ))}
        </Div>
      )}
    </Div>
  )
}

const UserDocumentEntityComponent = ({
  documentEntity,
  index,
  userId,
  folderId,
}: {
  documentEntity: IUserDocumentEntity
  index: number
  userId: string
  folderId: string
}) => {
  const { data: completeStructure } = useUserDocumentsStructure(userId)

  const methods = useFormContext<IDocumentsStateSchema>()

  const checked = useWatch({
    control: methods.control,
    name: `management.checkedDocuments.${documentEntity.id}`,
  })

  return (
    <Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',

          '&:hover': {
            '> div': {
              background: '$interface_light_up',
            },
          },
        }}
      >
        <Div
          css={{
            cursor: 'pointer',

            display: 'flex',
            alignItems: 'center',
            gap: '$2',

            flex: 1,
            minWidth: 1.2 * MIN_COLUMNS_WIDTH,

            paddingTop: 6,
            paddingBottom: 6,

            paddingLeft: index * 24,
          }}
        >
          <Div
            css={{
              height: 20,
              width: 20,
            }}
          />
          <Div
            css={{
              svg: {
                height: 20,
                width: 20,
              },
            }}
          >
            {getFileTypeIcon(
              documentEntity.attachedDocuments?.[0]?.document_type,
            )}
          </Div>
          <Text variant="caption">
            {capitalizeFirstLetters(documentEntity.name)}
          </Text>
        </Div>

        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
            height: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            variant="caption"
            css={{
              color: '$interface_dark_down',
            }}
          >
            {dayjs(documentEntity.created_at).format('DD/MM/YYYY')}
          </Text>
        </Div>

        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: MIN_COLUMNS_WIDTH,
            maxWidth: MIN_COLUMNS_WIDTH,
            width: MIN_COLUMNS_WIDTH,
            minHeight: 32,
            cursor: 'pointer',
          }}
        >
          {documentEntity.attachedDocuments?.map((attachedDocument, index) => (
            <Div
              onClick={() => {
                const breadcrump = findBreadcrumb(
                  completeStructure ? [completeStructure] : [],
                  folderId,
                )

                breadcrump.push(attachedDocument.name)

                methods.setValue('management.documentView', {
                  isViewDocumentOpen: true,
                  documentId: attachedDocument.document_id,
                  breadcrumbPath: breadcrump,
                  documentType: attachedDocument.document_type,
                  isFullscreen: false,
                  name: attachedDocument.name,
                })
              }}
              css={{
                cursor: 'pointer',
                marginLeft: index ? '$1' : 0,
              }}
              key={attachedDocument.id}
            >
              <Tooltip message={attachedDocument.name} arrow>
                {getFileTypeIcon(attachedDocument?.document_type)}
              </Tooltip>
            </Div>
          ))}
        </Div>

        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
            minHeight: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        ></Div>

        <Div
          css={{
            minWidth: MIN_COLUMNS_WIDTH,
            display: 'flex',
            minHeight: 32,
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: 34,
            gap: '$2',
          }}
        >
          <Div
            css={{
              width: 1,
              height: 10,
              // background: '$interface_light_up',
            }}
          />
          <Div
            onClick={() => {
              methods.setValue('editDocumentEntity', {
                name: documentEntity.name,
                newFiles: [],
                documentFiles: documentEntity.attachedDocuments.map((d) => {
                  return {
                    id: d.id,
                    name: d.name,
                    document_type: d.document_type,
                  }
                }),
                folderId: documentEntity.folder_id,
                isEditDocumentOpen: true,
                userId,
                id: documentEntity.id,
              })
              // methods.setValue('addDocument.folderId', structure.id)
              // methods.setValue('addDocument.userId', userId)
            }}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              cursor: 'pointer',

              svg: {
                height: 16,
                width: 16,
                color: '$interface_dark_down',
              },
            }}
          >
            <Tooltip
              message={`Agregar documento en "${documentEntity.name}"`}
              arrow
            >
              <OpenDrawerIcon />
            </Tooltip>
          </Div>
          <Div
            css={{
              width: 1,
              height: 10,
            }}
          />
          <Checkbox
            checked={checked}
            onCheckedChange={(v) => {
              methods.setValue(
                `management.checkedDocuments.${documentEntity.id}`,
                Boolean(v),
              )
            }}
          />
        </Div>
      </Div>
    </Div>
  )
}
