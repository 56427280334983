import { useCan } from '@/hooks'
import { useTabStore } from '@/store'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { AddShiftData } from './form'
import { useHandleCreateShifts } from '@/libs/react-query/mutations'
import { useHandleUpdateShifts } from '@/libs/react-query/mutations/shifts/useHandleUpdate'
import { convertAddShiftDataToUpdateShiftData } from './parser'

export const useHandleCreateShiftActions = ({
  isUpdatingShift,
  methods,
  shiftId,
}: {
  isUpdatingShift: boolean
  methods: UseFormReturn<AddShiftData>
  shiftId?: string
}) => {
  const { activeTab, setActions, removeTab, setTab } = useTabStore((state) => ({
    setActions: state.setActionsTab,
    activeTab: state.shifts.activeTab,
    setTab: state.setActiveTab,
    removeTab: state.removeTab,
  }))

  const { mutateAsync: createShift, isLoading: isLoadingShiftCreation } =
    useHandleCreateShifts()
  const { mutateAsync: updateShift, isLoading: isLoadingShiftUpdate } =
    useHandleUpdateShifts()

  const canCreate = useCan(['shifts.create', 'shifts.all'])

  useEffect(() => {
    const buttonLabel = isUpdatingShift ? 'Actualizar' : 'Guardar'
    setActions('shifts', [
      {
        action: async () => {
          const data = methods.getValues()
          const isValid = await methods.trigger()

          if (!isValid) {
            console.log('formHasErrors', methods.formState.errors)
            return
          }

          const dto = convertAddShiftDataToUpdateShiftData(data)

          if (isUpdatingShift && shiftId) {
            await updateShift({ shift_id: shiftId, ...dto })
            removeTab('shifts', shiftId)
          } else {
            await createShift(dto)
            removeTab('shifts', 'create')
          }

          setTab('shifts', 'shifts')
        },
        icon: <CheckCircleIcon />,
        label: buttonLabel,
        variant: 'primary',
        disabled: !canCreate,
        isLoading: isLoadingShiftCreation || isLoadingShiftUpdate,
        width: 100,
      },
    ])
  }, [
    activeTab,
    setActions,
    canCreate,
    isUpdatingShift,
    isLoadingShiftCreation,
    isLoadingShiftUpdate,
  ])
}
