import {
  IWorkedHourEnvelope,
  fromWorkedDayStatusToLabel,
  fromWorkedDayStatusToTagVariant,
} from '@/libs/react-query/types'
import { SmartTableFormItemValueType } from '@/components/SmartTable/types'
import { fromDateToFormattedHour, minuteToHour } from '@/utils/date'
import dayjs from 'dayjs'
import { PunchesInfosContentType, PunchesInfosTableType } from './types'
import { getNumberWith3Decimals } from '@/utils/math/number'
import { getPunchStatus, isPunchAutomatic } from '../../parser'

export interface ITotalWorkedHours {
  morningHours: number
  nightHours: number
  morningExtraHours: number
  nightExtraHours: number
  morningDiscountHours: number
  nightDiscountHours: number
  morningHolidayHours: number
  nightHolidayHours: number
  intervalHours: number
  totalMorningHours: number
  totalNightHours: number
}

export const fromPunchesToPunchTable = (
  workedHoursEnvelopes: IWorkedHourEnvelope[],
  savedSelectedDates: string[],
  previousName: string,
  previousDate: string[],
): PunchesInfosTableType => {
  const data: PunchesInfosContentType[] = []

  const greaterArrayLengthPair = workedHoursEnvelopes?.reduce((acc, curr) => {
    const arrayLength = curr.info.intervals.length || 0
    return arrayLength > acc ? arrayLength : acc
  }, 0)

  const totals = calculateTotalWorkedHours(workedHoursEnvelopes)

  workedHoursEnvelopes?.forEach((workedHoursEnvelope) => {
    const intervals: SmartTableFormItemValueType[] = []
    workedHoursEnvelope.info.intervals.forEach((interval) => {
      intervals.push({
        value: fromDateToFormattedHour(interval.entrance?.date) || '',
        original: fromDateToFormattedHour(interval.entrance?.date) || '',
        status: getPunchStatus(interval.entrance),
        metadata: {
          date: dayjs(
            interval.entrance?.date || workedHoursEnvelope.info.date,
          ).toDate(),
          isAutomatic: isPunchAutomatic(interval.entrance),
        },
      })
      intervals.push({
        value: fromDateToFormattedHour(interval.exit?.date) || '',
        original: fromDateToFormattedHour(interval.exit?.date) || '',
        status: getPunchStatus(interval.exit),
        metadata: {
          date: dayjs(
            interval.exit?.date || workedHoursEnvelope.info.date,
          ).toDate(),
          isAutomatic: isPunchAutomatic(interval.exit),
        },
      })
    })

    const filledIntervals = fullFillIntervals(
      intervals,
      greaterArrayLengthPair,
      {
        date: dayjs(workedHoursEnvelope.info.date).toDate(),
      },
    )

    data.push({
      rawStatus: workedHoursEnvelope.info.status,
      scheduled_shift_id: workedHoursEnvelope.info.scheduled_shift_id,
      id: workedHoursEnvelope.info.date,
      userId: workedHoursEnvelope.user.id,
      details: workedHoursEnvelope.info.details,
      isRest: workedHoursEnvelope.shift.isRestDay,

      // Table props
      date: {
        value: dayjs(workedHoursEnvelope.info.date).format('DD/MM/YYYY'),
        status: '',
        original: dayjs(workedHoursEnvelope.info.date).format('DD/MM/YYYY'),
        metadata: {
          date: dayjs(workedHoursEnvelope.info.date).toDate(),
        },
      },
      dayOfWeek: {
        value: dayjs(workedHoursEnvelope.info.date).format('dddd'),
        original: dayjs(workedHoursEnvelope.info.date).format('dddd'),
        status: '',
      },
      checked: {
        value: !!savedSelectedDates?.includes(workedHoursEnvelope.info.date),
        status: '',
      },
      avatar: {
        url: workedHoursEnvelope.user.photo_url || '',
        alt: workedHoursEnvelope.user.name || '',
      },
      shiftId: {
        value: workedHoursEnvelope.shift.shiftId || '',
        status: !workedHoursEnvelope.shift.shiftId ? 'warning' : '',
      },
      name: {
        value: workedHoursEnvelope.user.firstName,
        status: '',
      },
      surname: {
        value: workedHoursEnvelope.user.surname,
        status: '',
      },
      document: {
        value: workedHoursEnvelope.user.document || '',
        status: '',
      },
      status: [
        {
          id: workedHoursEnvelope.user.document || `${Date.now()}`,
          name: fromWorkedDayStatusToLabel(
            workedHoursEnvelope.info.status,
            true,
          ),
          variant:
            (fromWorkedDayStatusToTagVariant(
              workedHoursEnvelope.info.status,
            ) as string) || 'neutral',
        },
      ],
      entrance: {
        value: fromDateToFormattedHour(
          workedHoursEnvelope.info.firstPunch?.date,
        ),
        original: fromDateToFormattedHour(
          workedHoursEnvelope.info.firstPunch?.date,
        ),
        status: getPunchStatus(workedHoursEnvelope.info.firstPunch),
        metadata: {
          date: dayjs(
            workedHoursEnvelope.info.firstPunch?.date ||
              workedHoursEnvelope.info.date,
          ).toDate(),
          isAutomatic: isPunchAutomatic(workedHoursEnvelope.info.firstPunch),
        },
      },
      exit: {
        value: fromDateToFormattedHour(
          workedHoursEnvelope.info.lastPunch?.date,
        ),
        original: fromDateToFormattedHour(
          workedHoursEnvelope.info.lastPunch?.date,
        ),
        status: getPunchStatus(workedHoursEnvelope.info.lastPunch),
        metadata: {
          date: dayjs(
            workedHoursEnvelope.info.lastPunch?.date ||
              workedHoursEnvelope.info.date,
          ).toDate(),
          isAutomatic: isPunchAutomatic(workedHoursEnvelope.info.lastPunch),
        },
      },
      movements: workedHoursEnvelope.movements.map((movement) => ({
        id: movement.id,
        name: movement.title,
        variant: 'neutral',
        data: movement,
      })),
      intervals: filledIntervals,
      add: {
        value: '',
        status: '',
        metadata: {
          date: dayjs(workedHoursEnvelope.info.date).toDate(),
        },
      },
      morningDiscountHours: {
        value: minuteToHour(workedHoursEnvelope.info.morningDiscountHours * 60),
        original: minuteToHour(
          workedHoursEnvelope.info.morningDiscountHours * 60,
        ),
        status:
          workedHoursEnvelope.info.authorizedHours
            .authorizedDiscountMorningHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedDiscountMorningHours,
              ) ===
              getNumberWith3Decimals(
                workedHoursEnvelope.info.morningDiscountHours,
              )
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedDiscountMorningHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      morningExtraHours: {
        value: minuteToHour(workedHoursEnvelope.info.morningExtraHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.morningExtraHours * 60),
        status:
          workedHoursEnvelope.info.authorizedHours
            .authorizedExtraMorningHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedExtraMorningHours,
              ) ===
              getNumberWith3Decimals(workedHoursEnvelope.info.morningExtraHours)
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedExtraMorningHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      morningHolidayHours: {
        value: minuteToHour(workedHoursEnvelope.info.morningHolidayHours * 60),
        original: minuteToHour(
          workedHoursEnvelope.info.morningHolidayHours * 60,
        ),
        status:
          workedHoursEnvelope.info.authorizedHours
            .authorizedHolidayMorningHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedHolidayMorningHours,
              ) ===
              getNumberWith3Decimals(
                workedHoursEnvelope.info.morningHolidayHours,
              )
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedHolidayMorningHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      morningHours: {
        value: minuteToHour(workedHoursEnvelope.info.morningHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.morningHours * 60),
        status: '',
      },
      nightDiscountHours: {
        value: minuteToHour(workedHoursEnvelope.info.nightDiscountHours * 60),
        original: minuteToHour(
          workedHoursEnvelope.info.nightDiscountHours * 60,
        ),
        status:
          workedHoursEnvelope.info.authorizedHours
            .authorizedDiscountNightHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedDiscountNightHours,
              ) ===
              getNumberWith3Decimals(
                workedHoursEnvelope.info.nightDiscountHours,
              )
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedDiscountNightHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      nightExtraHours: {
        value: minuteToHour(workedHoursEnvelope.info.nightExtraHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.nightExtraHours * 60),
        status:
          workedHoursEnvelope.info.authorizedHours.authorizedExtraNightHours ===
          null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedExtraNightHours,
              ) ===
              getNumberWith3Decimals(workedHoursEnvelope.info.nightExtraHours)
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedExtraNightHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      nightHolidayHours: {
        value: minuteToHour(workedHoursEnvelope.info.nightHolidayHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.nightHolidayHours * 60),
        status:
          workedHoursEnvelope.info.authorizedHours
            .authorizedHolidayNightHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours
                  .authorizedHolidayNightHours,
              ) ===
              getNumberWith3Decimals(workedHoursEnvelope.info.nightHolidayHours)
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours
                .authorizedHolidayNightHours === 0
            ? 'soft-error'
            : 'soft-warning',
      },
      nightHours: {
        value: minuteToHour(workedHoursEnvelope.info.nightHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.nightHours * 60),
        status:
          workedHoursEnvelope.info.authorizedHours.authorizedNightHours === null
            ? ''
            : getNumberWith3Decimals(
                workedHoursEnvelope.info.authorizedHours.authorizedNightHours,
              ) === getNumberWith3Decimals(workedHoursEnvelope.info.nightHours)
            ? 'soft-success'
            : workedHoursEnvelope.info.authorizedHours.authorizedNightHours ===
              0
            ? 'soft-error'
            : 'soft-warning',
      },
      totalIntervalHours: {
        value: minuteToHour(workedHoursEnvelope.info.intervalHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.intervalHours * 60),
        status: '',
      },
      totalMorningHours: {
        value: minuteToHour(workedHoursEnvelope.info.totalMorningHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.totalMorningHours * 60),
        status: '',
      },
      totalNightHours: {
        value: minuteToHour(workedHoursEnvelope.info.totalNightHours * 60),
        original: minuteToHour(workedHoursEnvelope.info.totalNightHours * 60),
        status: '',
      },
      savedHours: {
        morningHours:
          workedHoursEnvelope.info.savedTotalHours?.morningHours !== null &&
          workedHoursEnvelope.info.savedTotalHours?.morningHours !== undefined
            ? workedHoursEnvelope.info.savedTotalHours?.morningHours
            : null,
        nightHours:
          workedHoursEnvelope.info.savedTotalHours?.nightHours !== null &&
          workedHoursEnvelope.info.savedTotalHours?.nightHours !== undefined
            ? workedHoursEnvelope.info.savedTotalHours?.nightHours
            : null,
        morningExtraHours:
          workedHoursEnvelope.info.savedTotalHours?.morningExtraHours !==
            null &&
          workedHoursEnvelope.info.savedTotalHours?.morningExtraHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.morningExtraHours
            : null,
        nightExtraHours:
          workedHoursEnvelope.info.savedTotalHours?.nightExtraHours !== null &&
          workedHoursEnvelope.info.savedTotalHours?.nightExtraHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.nightExtraHours
            : null,
        morningDiscountHours:
          workedHoursEnvelope.info.savedTotalHours?.morningDiscountHours !==
            null &&
          workedHoursEnvelope.info.savedTotalHours?.morningDiscountHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.morningDiscountHours
            : null,
        nightDiscountHours:
          workedHoursEnvelope.info.savedTotalHours?.nightDiscountHours !==
            null &&
          workedHoursEnvelope.info.savedTotalHours?.nightDiscountHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.nightDiscountHours
            : null,
        morningHolidayHours:
          workedHoursEnvelope.info.savedTotalHours?.morningHolidayHours !==
            null &&
          workedHoursEnvelope.info.savedTotalHours?.morningHolidayHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.morningHolidayHours
            : null,
        nightHolidayHours:
          workedHoursEnvelope.info.savedTotalHours?.nightHolidayHours !==
            null &&
          workedHoursEnvelope.info.savedTotalHours?.nightHolidayHours !==
            undefined
            ? workedHoursEnvelope.info.savedTotalHours?.nightHolidayHours
            : null,
        intervalHours:
          workedHoursEnvelope.info.savedTotalHours?.intervalHours !== null &&
          workedHoursEnvelope.info.savedTotalHours?.intervalHours !== undefined
            ? workedHoursEnvelope.info.savedTotalHours?.intervalHours
            : null,
      },
      authorizedHours: {
        morningDiscountHours:
          workedHoursEnvelope.info.authorizedHours
            ?.authorizedDiscountMorningHours,
        morningExtraHours:
          workedHoursEnvelope.info.authorizedHours.authorizedExtraMorningHours,
        morningHolidayHours:
          workedHoursEnvelope.info.authorizedHours
            .authorizedHolidayMorningHours,
        nightDiscountHours:
          workedHoursEnvelope.info.authorizedHours.authorizedDiscountNightHours,
        nightExtraHours:
          workedHoursEnvelope.info.authorizedHours.authorizedExtraNightHours,
        nightHolidayHours:
          workedHoursEnvelope.info.authorizedHours.authorizedHolidayNightHours,
        nightHours:
          workedHoursEnvelope.info.authorizedHours.authorizedNightHours,
      },
    })
  })

  const filledTotalIntervals = fullFillIntervals([], greaterArrayLengthPair, {
    disabled: true,
    draggableDisabled: true,
  })

  data.push({
    rawStatus: '',
    scheduled_shift_id: '',
    id: 'total',
    userId: 'total',
    details: {
      discountHours: [],
      extraHours: [],
      holidayHours: [],
      intervalHours: [],
      movementsHours: [],
      ordinaryHours: [],
    },
    isRest: false,

    // Table props
    date: {
      value: 'Totales',
      status: '',
      original: '',
    },
    dayOfWeek: {
      value: '',
      original: '',
      status: '',
    },
    checked: {
      value: false,
      status: '',
      metadata: {
        disabled: true,
      },
    },
    avatar: {
      url: '',
      alt: '',
    },
    shiftId: {
      value: '',
      status: '',
      metadata: {
        disabled: true,
        draggableDisabled: true,
      },
    },
    name: {
      value: '',
      status: '',
    },
    surname: {
      value: '',
      status: '',
    },
    document: {
      value: '',
      status: '',
    },
    status: [],
    entrance: {
      value: '',
      original: '',
      status: '',
      metadata: {
        disabled: true,
        draggableDisabled: true,
      },
    },
    exit: {
      value: '',
      original: '',
      status: '',
      metadata: {
        disabled: true,
        draggableDisabled: true,
      },
    },
    movements: [],
    intervals: filledTotalIntervals,
    add: {
      value: '',
      status: '',
      metadata: {
        disabled: true,
        draggableDisabled: true,
      },
    },
    morningDiscountHours: {
      value: minuteToHour(totals.morningDiscountHours * 60),
      original: minuteToHour(totals.morningDiscountHours * 60),
      status: '',
      metadata: {
        draggableDisabled: true,
        inputType: 'long-hour',
      },
    },
    morningExtraHours: {
      value: minuteToHour(totals.morningExtraHours * 60),
      original: minuteToHour(totals.morningExtraHours * 60),
      status: '',
      metadata: {
        draggableDisabled: true,
        inputType: 'long-hour',
      },
    },
    morningHolidayHours: {
      value: minuteToHour(totals.morningHolidayHours * 60),
      original: minuteToHour(totals.morningHolidayHours * 60),
      status: '',
      metadata: {
        draggableDisabled: true,
        inputType: 'long-hour',
      },
    },
    morningHours: {
      value: minuteToHour(totals.morningHours * 60),
      original: minuteToHour(totals.morningHours * 60),
      status: '',
      metadata: {
        draggableDisabled: true,
        inputType: 'long-hour',
      },
    },
    nightDiscountHours: {
      value: minuteToHour(totals.nightDiscountHours * 60),
      original: minuteToHour(totals.nightDiscountHours * 60),
      status: '',
      metadata: {
        draggableDisabled: true,
        inputType: 'long-hour',
      },
    },
    nightExtraHours: {
      value: minuteToHour(totals.nightExtraHours * 60),
      original: minuteToHour(totals.nightExtraHours * 60),
      status: '',
      metadata: {
        draggableDisabled: true,
        inputType: 'long-hour',
      },
    },
    nightHolidayHours: {
      value: minuteToHour(totals.nightHolidayHours * 60),
      original: minuteToHour(totals.nightHolidayHours * 60),
      status: '',
      metadata: {
        draggableDisabled: true,
        inputType: 'long-hour',
      },
    },
    nightHours: {
      value: minuteToHour(totals.nightHours * 60),
      original: minuteToHour(totals.nightHours * 60),
      status: '',
      metadata: {
        draggableDisabled: true,
        inputType: 'long-hour',
      },
    },
    totalIntervalHours: {
      value: minuteToHour(totals.intervalHours * 60),
      original: minuteToHour(totals.intervalHours * 60),
      status: '',
      metadata: {
        draggableDisabled: true,
        inputType: 'long-hour',
      },
    },
    totalMorningHours: {
      value: minuteToHour(totals.totalMorningHours * 60),
      original: minuteToHour(totals.totalMorningHours * 60),
      status: '',
      metadata: {
        draggableDisabled: true,
        inputType: 'long-hour',
      },
    },
    totalNightHours: {
      value: minuteToHour(totals.totalNightHours * 60),
      original: minuteToHour(totals.totalNightHours * 60),
      status: '',
      metadata: {
        draggableDisabled: true,
        inputType: 'long-hour',
      },
    },
    savedHours: {
      morningHours: null,
      nightHours: null,
      morningExtraHours: null,
      nightExtraHours: null,
      morningDiscountHours: null,
      nightDiscountHours: null,
      morningHolidayHours: null,
      nightHolidayHours: null,
      intervalHours: null,
    },
    authorizedHours: {
      morningDiscountHours: null,
      morningExtraHours: null,
      morningHolidayHours: null,
      nightDiscountHours: null,
      nightExtraHours: null,
      nightHolidayHours: null,
      nightHours: null,
    },
  })

  return {
    data,
    userId: workedHoursEnvelopes[0].user.id,
    numberOfIntervals: greaterArrayLengthPair,
    date: previousDate,
    name: previousName,
  }
}

export const fullFillIntervals = (
  intervals: any,
  greaterArrayLengthPair: number,
  metadata: any,
) => {
  const newIntervals = [...intervals]

  if (newIntervals.length === greaterArrayLengthPair * 2) {
    return newIntervals
  }

  const difference = greaterArrayLengthPair * 2 - newIntervals.length

  for (let i = 0; i < difference; i++) {
    newIntervals.push({
      value: '',
      original: '',
      status: '',
      metadata,
    })
  }

  return newIntervals
}

const calculateTotalWorkedHours = (
  data: IWorkedHourEnvelope[],
): ITotalWorkedHours => {
  return data.reduce(
    (acc: ITotalWorkedHours, curr: IWorkedHourEnvelope): ITotalWorkedHours => {
      acc.morningHours += curr.info.morningHours
      acc.nightHours += curr.info.nightHours
      acc.morningExtraHours += curr.info.morningExtraHours
      acc.nightExtraHours += curr.info.nightExtraHours
      if (curr.info.status !== 'ABSENCE') {
        acc.morningDiscountHours += curr.info.morningDiscountHours
        acc.nightDiscountHours += curr.info.nightDiscountHours
      }
      acc.morningHolidayHours += curr.info.morningHolidayHours
      acc.nightHolidayHours += curr.info.nightHolidayHours
      acc.intervalHours += curr.info.intervalHours
      acc.totalMorningHours += curr.info.totalMorningHours
      acc.totalNightHours += curr.info.totalNightHours

      return acc
    },
    {
      morningHours: 0,
      nightHours: 0,
      morningExtraHours: 0,
      nightExtraHours: 0,
      morningDiscountHours: 0,
      nightDiscountHours: 0,
      morningHolidayHours: 0,
      nightHolidayHours: 0,
      intervalHours: 0,
      totalMorningHours: 0,
      totalNightHours: 0,
    },
  )
}
