import { Div } from '@/components/Div'
import { ControlledWorkerSearchBar } from '@/components/Forms'
import { ExportPdfSchemaType } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { ExportPdfStepVariables } from '../steps'
import { useMemo } from 'react'
import { Tag } from '@punto-ui/react'

export const WorkerStep = (props: { variables: ExportPdfStepVariables }) => {
  const { control } = useFormContext<ExportPdfSchemaType>()

  const usersIds = useWatch({
    control,
    name: 'usersIds',
  })

  const policiesIds = permissionsArray.filter((p) => p.includes('workers'))

  const automaticWorkerSelectionLabel = useMemo(() => {
    if (props.variables.hasMovementVariable) {
      return 'Colaboradores seleccionados automáticamente por movimiento'
    }

    if (props.variables.hasPaymentVariable) {
      return 'Colaboradores seleccionados automáticamente por pago'
    }

    if (props.variables.hasPayrollVariable) {
      return 'Colaboradores seleccionados automáticamente por nomina'
    }

    if (props.variables.hasVacationVariable) {
      return 'Colaboradores seleccionados automáticamente por vacaciones'
    }

    if (props.variables.hasAguinaldoVariable) {
      return 'Colaboradores seleccionados automáticamente por aguinaldo'
    }

    return ''
  }, [props.variables])

  return (
    <Div
      css={
        {
          // overflow: 'hidden',
          // marginTop: '$2',
          // marginBottom: '$10',
          // maxHeight: 10000,
          // height: 512,
        }
      }
    >
      {!!automaticWorkerSelectionLabel && (
        <Div
          css={{
            marginBottom: '$4',
            display: 'flex',
          }}
        >
          <Tag variant={'positive'}>{automaticWorkerSelectionLabel}</Tag>
        </Div>
      )}
      <ControlledWorkerSearchBar<ExportPdfSchemaType>
        activeDateFilter
        activeSelection
        name={'usersIds'}
        usersIds={usersIds}
        maxHeight={automaticWorkerSelectionLabel ? 300 : 330}
        policiesIds={policiesIds}
        // css={{
        //   height: 'calc(100vh - 250px)',
        // }}
      />
    </Div>
  )
}
