import React, { useEffect, useRef, useState } from 'react'
import {
  FieldValues,
  useController,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { ControlledWorkerSearchBarP } from './types'
import { useAvailableCells, useWorkers } from '@/libs/react-query/hooks'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  CompleteSearchInputBar,
  Container,
  FiltersContainer,
  ListContainer,
  ListItemContainer,
  SearchArea,
  SearchInput,
} from './styles'
import { Button, Progress, Tag, Text } from '@punto-ui/react'
import { useDebounce } from '@/hooks'
import { Div } from '@/components/Div'
import { OrganogramFilter } from '@/components/Filters/OrganogramFilter'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Avatar } from '@/components/Avatar'
import { InputDatePicker } from '@/components/InputDatePicker'
import { Chip } from '@/components/Chip'

export const ControlledWorkerSearchBar = <TFieldValues extends FieldValues>(
  props: ControlledWorkerSearchBarP<TFieldValues>,
) => {
  const [activeDateSelection, setActiveDateSelection] = useState<
    Array<Date | null>
  >([])
  const [activeOptionsSelection, setActiveOptionsSelection] = useState<
    {
      label: string | React.ReactNode
      value: string
      selected: boolean
    }[]
  >([
    {
      label: 'Activos',
      value: 'active',
      selected: true,
    },
    {
      label: 'Inactivos',
      value: 'inactive',
      selected: false,
    },
  ])
  const [shouldShowSelected, setShouldShowSelected] = useState(false)

  const alreadySetCellsIds = useRef<boolean>(false)
  const [cellsIds, setCellsIds] = useState<string[]>([])
  const { data: cells } = useAvailableCells(props.policiesIds || [])

  const { control, register, getValues } = useFormContext()

  const [filterValue, setFilterValue] = useState('')
  const debouncedValue = useDebounce<string>(filterValue, 500)

  const {
    field: { onChange, value },
  } = useController({
    name: props.name,
    control,
    defaultValue: getValues(props.name),
  })

  const updatedValue = useWatch({
    control,
    name: props.name,
  })

  const shouldGetActive = activeOptionsSelection[0]?.selected
  const shouldGetInactive = activeOptionsSelection[1]?.selected

  const shouldGetActiveAndInactive = shouldGetActive && shouldGetInactive
  const shouldGetNone = !shouldGetActive && !shouldGetInactive
  const shouldOnlyGetActive = shouldGetActive && !shouldGetInactive
  const shouldOnlyGetInactive = shouldGetInactive && !shouldGetActive

  const policiesIds =
    !props.activeSelection || shouldGetNone
      ? props.policiesIds
      : shouldOnlyGetInactive
      ? props.activeDateFilter &&
        activeDateSelection[0] &&
        activeDateSelection[1]
        ? ['deleted']
        : ['only-deleted']
      : shouldGetActiveAndInactive
      ? [...(props.policiesIds || []), 'deleted']
      : shouldOnlyGetActive && activeDateSelection[0] && activeDateSelection[1]
      ? [...(props.policiesIds || []), 'deleted']
      : shouldOnlyGetActive
      ? [...(props.policiesIds || [])]
      : props.policiesIds

  const debouncedCellsIds = useDebounce<string[]>(cellsIds, 500)
  const { data: workers, isFetching: isFetchingWorker } = useWorkers({
    page: 0,
    pageSize: 10000,
    name: debouncedValue,
    cellsIds: debouncedCellsIds,
    policiesIds,
    // usersIds: props.usersIds,
    usersIdsFilter: props.usersIdsFilter,

    status: shouldOnlyGetInactive
      ? 'inactive'
      : shouldOnlyGetActive
      ? 'active'
      : undefined,
    activeEndDate:
      !shouldGetActiveAndInactive && activeDateSelection[1]
        ? activeDateSelection[1].toISOString()
        : undefined,
    activeStartDate:
      !shouldGetActiveAndInactive && activeDateSelection[0]
        ? activeDateSelection[0].toISOString()
        : undefined,
  })

  const debouncedSelectedIds = useDebounce<string[]>(value, 500)

  const { data: selectedUserWorkers, isFetching: isFetchingSelectedWorkers } =
    useWorkers({
      policiesIds: [...(props.policiesIds || []), 'deleted'],
      usersIds: debouncedSelectedIds,
      page: -1,
      allUsers: true,
    })

  useEffect(() => {
    if (alreadySetCellsIds.current) return

    if (cells?.length) {
      setCellsIds(cells.map((cell) => cell.id))
      alreadySetCellsIds.current = true
    }
  }, [cells])

  const handleWorkerClick = (workerId: string) => {
    if (areActionsDisabled) return

    if (value?.includes(workerId)) {
      const newArray = value.filter((id: string) => id !== workerId)

      onChange(newArray)
      props.onValueChange?.(filterValue, newArray)
    } else {
      const newArray = [workerId]

      if (value) {
        newArray.push(...value)
      }

      onChange(newArray)
      props.onValueChange?.(filterValue, newArray)
    }
  }

  const isAllSelected = workers?.data?.every((w) => value?.includes(w.id))

  const handleSelectAll = () => {
    if (areActionsDisabled) return

    if (!isAllSelected) {
      const newSelectedToInclude = workers?.data?.map((w) => w.id) ?? []
      const allSelectedSet = new Set([
        ...((value || []) as string[]),
        ...((newSelectedToInclude || []) as string[]),
      ])
      const allSelected: string[] = []
      allSelectedSet.forEach((id) => allSelected.push(id))

      onChange(allSelected)
      props.onValueChange?.(filterValue, allSelected)
    }
  }

  const handleUnselectAll = () => {
    if (areActionsDisabled) return

    onChange([])
    props.onValueChange?.(filterValue, [])
  }

  const handleUnselectWorker = (workerId: string) => {
    if (areActionsDisabled) return

    const newArray = value.filter((id: string) => id !== workerId)

    onChange(newArray)
    props.onValueChange?.(filterValue, newArray)
  }

  const selectedWorkers = selectedUserWorkers?.data?.filter((w) =>
    value?.includes(w.id),
  )

  const reference = useRef<HTMLDivElement>(null)
  register(props.name)

  const rowVirtualizer = useVirtualizer({
    count: workers?.data?.length || 0,
    getScrollElement: () => reference.current,
    estimateSize: React.useCallback(() => 36, []),
    overscan: 15,
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
  })

  const areActionsDisabled = props.disabled || isFetchingWorker

  return (
    <Container
      css={{
        transition: 'grid-template-columns 500ms',
        gridTemplateColumns: shouldShowSelected ? '1.5fr 1fr' : '1fr 0fr',
        display: 'grid',
        boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
        ...props.css,
      }}
    >
      <Div
        css={{
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text
            variant="caption"
            as="label"
            css={{ color: '$interface_dark_deep' }}
          >
            {props.label || 'Seleccione los colaboradores'}
          </Text>
          {!shouldShowSelected ? (
            <Button
              type="button"
              variant={'tertiary'}
              onClick={() => setShouldShowSelected(!shouldShowSelected)}
              size="sm"
              icon={
                shouldShowSelected ? (
                  <ChevronDoubleRightIcon />
                ) : (
                  <ChevronDoubleLeftIcon />
                )
              }
              css={{
                fontSize: '$sm',
                color: '$interface_dark_down',
              }}
            >
              Seleccionados
            </Button>
          ) : (
            <Div css={{ height: 32 }} />
          )}
        </Div>
        <SearchArea>
          <CompleteSearchInputBar>
            <MagnifyingGlassIcon />
            <SearchInput
              placeholder="Buscar..."
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
            {isFetchingWorker ||
            (isFetchingSelectedWorkers && !shouldShowSelected) ? (
              <Div css={{ width: 20 }}>
                <Progress size={'sm'} />
              </Div>
            ) : (
              <Div css={{ width: 20 }} />
            )}
            <Div
              css={{
                marginLeft: '$2',

                height: (workers?.data?.length || 0) > 100 ? 32 : 26,
                width: (workers?.data?.length || 0) > 100 ? 32 : 26,
                background: '$interface_light_down',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '$full',

                border: 'solid 1px $interface_light_down',

                transition: 'background 250ms',
              }}
            >
              <Text
                variant="caption"
                css={{
                  fontWeight: '$bold',
                  color: '$brand_primary_deep',
                }}
              >
                {workers?.data?.length || 0}
              </Text>
            </Div>
            <Div
              css={{
                marginLeft: '$2',

                height: (selectedWorkers?.length || 0) > 100 ? 32 : 26,
                width: (selectedWorkers?.length || 0) > 100 ? 32 : 26,
                background: !selectedWorkers?.length
                  ? '$interface_light_down'
                  : '$brand_primary_pure',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '$full',

                border: 'solid 1px $interface_light_down',

                transition: 'background 250ms',
              }}
            >
              <Text
                variant="caption"
                css={{
                  fontWeight: '$bold',
                  color: !selectedWorkers?.length
                    ? '$brand_primary_deep'
                    : '$interface_light_pure',
                }}
              >
                {value?.length || 0}
              </Text>
            </Div>
          </CompleteSearchInputBar>
        </SearchArea>
        <FiltersContainer>
          <Div
            css={{
              minWidth: 120,
              height: 32,
            }}
          >
            <OrganogramFilter
              isWhite
              direction="right"
              selectedIds={cellsIds}
              defaultValues={true}
              policiesIds={props.policiesIds || []}
              callback={(options) => {
                const cellsIds = options.map((cell) => cell.id)

                setCellsIds(cellsIds)
              }}
            />
          </Div>
          {props.activeSelection && (
            <Div>
              <Chip
                withoutActivatedNum
                inputStyle={{
                  width: 120,
                  height: 32,
                }}
                withoutTotalButtons
                dropdownSide={'right'}
                label={
                  shouldGetNone
                    ? '-'
                    : shouldOnlyGetActive
                    ? 'Activos'
                    : shouldOnlyGetInactive
                    ? 'Inactivos'
                    : 'Todos'
                }
                options={activeOptionsSelection}
                onChangeValue={(newOptions) => {
                  setActiveOptionsSelection(newOptions)
                }}
              />
            </Div>
          )}
          {props.activeDateFilter &&
            !shouldGetActiveAndInactive &&
            !(!shouldGetInactive && !shouldGetActive) && (
              <Div>
                <InputDatePicker
                  priorityPlaceholder={
                    shouldOnlyGetActive
                      ? 'Activos en'
                      : shouldOnlyGetInactive
                      ? 'Inactivos en'
                      : 'Todos en'
                  }
                  inputStyles={{
                    minWidth: 100,
                    height: 32,
                  }}
                  modern
                  maxDates={9999}
                  type={'range'}
                  onChange={(dates) => {
                    setActiveDateSelection(dates)
                  }}
                  firstDate={activeDateSelection[0]}
                  secondDate={activeDateSelection[1]}
                />
              </Div>
            )}
          <Button
            css={{
              marginLeft: 'auto',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            type="button"
            variant={'tertiary'}
            onClick={handleSelectAll}
            disabled={areActionsDisabled}
          >
            Todos
          </Button>
        </FiltersContainer>
        <Div
          ref={reference}
          css={{
            overflow: 'scroll',
            position: 'relative',
            height:
              props.maxHeight || props.css?.height || props.css?.maxHeight,
          }}
        >
          <ListContainer
            css={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              overflow: 'scroll',
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
              const worker = workers?.data?.[virtualRow.index]
              const isSelected = updatedValue?.includes(worker?.id) || false

              return (
                <ListItemContainer
                  css={{
                    display: 'flex',

                    paddingLeft: '$2',

                    borderRadius: '$sm',

                    cursor: areActionsDisabled ? 'progress' : 'pointer',

                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${virtualRow.start}px)`,
                    height: `${virtualRow.size}px`,

                    backgroundColor: isSelected ? '#EAF4FF' : 'white',
                    border: isSelected ? '1px solid #C4D9FF' : 'none',
                  }}
                  key={worker?.id}
                  onClick={() => handleWorkerClick(worker?.id || '')}
                >
                  <Avatar
                    alt="avatar"
                    src={worker?.photo_url || ''}
                    height={24}
                    width={24}
                  />
                  <Text
                    variant={'description'}
                    css={{
                      marginLeft: 8,
                      color: isSelected
                        ? '$brand_primary_pure'
                        : '$interface_dark_down',
                    }}
                  >
                    {' '}
                    {worker?.name ?? ''}{' '}
                  </Text>
                </ListItemContainer>
              )
            })}
          </ListContainer>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          paddingLeft: shouldShowSelected ? 16 : 0,
        }}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '$1',

            overflow: 'hidden',

            minHeight: 32,

            '> svg': {
              cursor: 'pointer',
              height: 16,
              width: 16,
              marginRight: 16,
              minWidth: 16,
              minHeight: 16,
              color: '$interface_dark_down',
            },
          }}
        >
          <Button
            type="button"
            variant={'tertiary'}
            onClick={() => setShouldShowSelected(!shouldShowSelected)}
            size="sm"
            icon={
              shouldShowSelected ? (
                <ChevronDoubleRightIcon />
              ) : (
                <ChevronDoubleLeftIcon />
              )
            }
            css={{
              fontSize: '$sm',
              color: '$interface_dark_down',
            }}
          >
            Seleccionados
          </Button>
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              // gap: '$4',
            }}
          >
            <Div>{isFetchingSelectedWorkers && <Progress />}</Div>
            <Div
              onClick={handleUnselectAll}
              css={{
                svg: {
                  minWidth: 16,
                  minHeight: 16,
                },

                cursor: 'pointer',

                display: 'flex',
                alignItems: 'center',
                gap: '$2',
              }}
            >
              <Text
                variant="caption"
                css={{
                  color: '$interface_dark_deep',
                }}
              >
                Limpiar
              </Text>
              <XMarkIcon />
            </Div>
          </Div>
        </Div>
        <Div
          css={{
            maxHeight: `${
              +(
                props.css?.height ||
                props.css?.maxHeight ||
                props.maxHeight ||
                rowVirtualizer.getTotalSize() ||
                0
              ) + 110
            }px`,
            overflow: 'scroll',
            paddingBottom: 12,
          }}
        >
          {selectedWorkers?.map((user) => (
            <Div
              key={user.id}
              css={{
                marginRight: '$2',
                marginBottom: '$2',
              }}
            >
              <Tag variant={'neutral'}>
                <Div
                  css={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '$2',
                    svg: {
                      color: '$brand_primary_pure',
                    },
                  }}
                >
                  <Div css={{ minWidth: 16 }}>
                    <Avatar
                      alt="avatar"
                      src={user?.photo_url || ''}
                      height={20}
                      width={20}
                    />
                  </Div>
                  <Text
                    css={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'block',
                      color: '$brand_primary_pure',
                      flex: 1,
                    }}
                    variant={'caption'}
                  >
                    {user.name}
                  </Text>
                  <Div css={{ minWidth: 12 }}>
                    <XMarkIcon
                      onClick={() => handleUnselectWorker(user.id)}
                      style={{ cursor: 'pointer', strokeWidth: 3 }}
                      height={12}
                      width={12}
                    />
                  </Div>
                </Div>
              </Tag>
            </Div>
          ))}
        </Div>
      </Div>
    </Container>
  )
}
