import { ContextualMenuLayout, Div } from '@/components'
import {
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import {
  Button,
  Divider,
  Progress,
  Tabs,
  Tag,
  Text,
  TextArea,
  styled,
} from '@punto-ui/react'
// import { AuditComponent } from './components/Audit'
import { MapComponent } from './components/Map'
import { PhotoComponent } from './components/Photo'
import { DetailsComponent } from './components/Details'
import { useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { typeDecoder } from '@/components/Forms/ControlledInput/toPattern'
import dayjs from 'dayjs'
import { getDateWithTime } from '@/utils/date'
import { usePunchInfo } from '@/libs/react-query/hooks'
import { usePunchSummary } from '@/pages/marcaciones/context'
import {
  PunchesInfosContentType,
  PunchesInfosTableType,
} from '@/pages/marcaciones/types'
import {
  DraggableParams,
  SmartTableFormItemValueType,
} from '@/components/SmartTable/types'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'

export const PunchInfosMenu = (
  props: DraggableParams<PunchesInfosContentType>,
) => {
  const [isEditing, setIsEditing] = useState(false)
  const { control } = useFormContext<PunchesInfosTableType>()

  const { handleDeletePunch, handleCreatePunch } = usePunchSummary()
  const [isDeleting, setIsDeleting] = useState(false)

  const allRows = useWatch({
    control,
    name: 'data',
  })

  const data = useMemo(() => allRows[props.index], [allRows, props.index])

  const field = useMemo(() => {
    const DEFAULT_FIELD_VALUE = {
      value: '',
      original: '',
      status: '',
    }

    if (props.isArray) {
      const arrayField = data[props.fieldName] as SmartTableFormItemValueType[]
      return (arrayField[props.arrayIndex] ||
        DEFAULT_FIELD_VALUE) as SmartTableFormItemValueType
    } else {
      return (data[props.fieldName] ||
        DEFAULT_FIELD_VALUE) as SmartTableFormItemValueType
    }
  }, [data, props])

  const options = [
    {
      id: '1',
      onClick: () => setIsEditing(!isEditing),
      icon: <PencilSquareIcon />,
    },
    {
      id: '2',
      onClick: () => {
        if (isDeleting) {
          const time = field.original as string

          if (time) {
            const correctDate: Date = field?.metadata?.date
            const date = getDateWithTime(correctDate, time)
            if (!date) return

            handleDeletePunch({
              date,
              user_id: data.userId,
            })
            setIsDeleting(false)
          }
        } else {
          setIsDeleting(true)
        }
      },
      icon: isDeleting ? (
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            svg: { color: '$status_danger_deep' },
          }}
        >
          <Text css={{ marginRight: '$2', color: '$status_danger_deep' }}>
            Confirmar
          </Text>
          <TrashIcon />
        </Div>
      ) : (
        <TrashIcon />
      ),
    },
  ]

  const addOption = [
    {
      id: '1',
      onClick: () => setIsEditing(!isEditing),
      icon: <PlusCircleIcon />,
    },
  ]

  if (field?.metadata?.isAutomatic) {
    return (
      <ContextualMenuLayout
        options={[
          {
            id: '1',
            onClick: async () => {
              if (!field?.metadata?.date) return

              const punchDateString = dayjs(field?.metadata?.date).format(
                'YYYY-MM-DD',
              )

              const newDate = dayjs(
                `${punchDateString} ${field.value}`,
              ).toISOString()
              await handleCreatePunch({
                date: newDate,
                user_id: data.userId,
              })
            },
            icon: <PlusCircleIcon />,
            label: 'Agregar marcación definitiva',
          },
        ]}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 100,
          }}
        >
          <Text variant={'paragraph'}>
            Punto creado automaticamente: <strong>{field.value}</strong>
          </Text>
        </Div>
      </ContextualMenuLayout>
    )
  }

  return (
    <ContextualMenuLayout options={field.original ? options : addOption}>
      <PunchMenuContent
        isEditing={isEditing}
        name={`${data.name.value} ${data.surname.value}`}
        setIsEditing={setIsEditing}
        data={data}
        field={field}
      />
    </ContextualMenuLayout>
  )
}

interface PunchMenuContentProps {
  isEditing: boolean
  name: string
  data: PunchesInfosContentType
  setIsEditing: (value: boolean) => void
  field: SmartTableFormItemValueType
}

export const PunchMenuContent = ({
  isEditing,
  name,
  setIsEditing,
  data,
  field,
}: PunchMenuContentProps) => {
  const { handleCreatePunch, handleUpdatePunch } = usePunchSummary()
  const { data: company } = useMyCompany()

  const [newHour, setNewHour] = useState('')

  const fieldValue = field.value as string
  const previousValue = field.original as string

  const selectedDate = field.metadata?.date
  const { data: punchInfos, isLoading: isLoadingPunchInfos } = usePunchInfo({
    date: previousValue ? getDateWithTime(selectedDate, previousValue) : null,
    user_id: data.userId,
  })

  const createPunch = async () => {
    const punchDateWithHour = getDateWithTime(selectedDate, newHour)
    if (!punchDateWithHour) return

    await handleCreatePunch({
      date: punchDateWithHour,
      user_id: data.userId,
    })

    setIsEditing(false)
  }

  const updatePunch = async () => {
    const date = dayjs(selectedDate).format('YYYY-MM-DD')

    const punchDateWithHour = dayjs(`${date} ${newHour}`, 'YYYY-MM-DD HH:mm')
    const punchOldDateWithHour = dayjs(
      `${date} ${previousValue}`,
      'YYYY-MM-DD HH:mm',
    )

    if (!punchDateWithHour.isValid() || !punchOldDateWithHour.isValid()) {
      return
    }

    await handleUpdatePunch({
      newDate: punchDateWithHour.toISOString(),
      previousDate: punchOldDateWithHour.toISOString(),
      user_id: data.userId,
    })

    setIsEditing(false)
  }

  const totemName = useMemo(() => {
    const totemTag = punchInfos?.punch.tags.find((tag) =>
      tag.tag.includes('totem:'),
    )
    const totemId = totemTag?.tag.split(':')[1]

    const totem = company?.totems.find((t) => t.id === totemId)

    return totem?.name
  }, [company?.totems, punchInfos?.punch.tags])

  const punchTabs = []
  const isCreatedPunch = punchInfos?.punch.tags?.filter(
    (tag) =>
      tag.tag === 'CREATED_BY_API' ||
      tag.tag === 'Creación manual' ||
      tag.tag === 'AUTOMATIC_CREATION_CONFIRMED',
  )?.length

  const isOffline = !!punchInfos?.punch.tags?.filter((tag) =>
    tag.tag.toLowerCase().includes('offline'),
  )?.length

  if (!isCreatedPunch && punchInfos?.punch.photo_url) {
    punchTabs.push({
      label: 'Foto',
      content: <PhotoComponent url={punchInfos?.punch.photo_url || ''} />,
    })
  }

  if (punchInfos?.punch.latitude && punchInfos?.punch.longitude) {
    punchTabs.push({
      label: 'Mapa',
      content: (
        <MapComponent
          latitude={punchInfos?.punch.latitude}
          longitude={punchInfos?.punch.longitude}
          pictureUrl={punchInfos?.punch.photo_url || ''}
        />
      ),
    })
  }

  punchTabs.push({
    label: 'Detalles',
    content: (
      <DetailsComponent
        livenessPrecision={punchInfos?.punch.metadata.liveness_precision}
        isLiveness={punchInfos?.punch.metadata.is_real_face}
        distance={punchInfos?.punch.distance}
        isValidByDistance={punchInfos?.punch?.isValidByDistance}
        minAllowedDistance={punchInfos?.punch?.minAllowedDistance}
        timezone={data.timezone}
        hasFaceId={!!punchInfos?.punch.photo_url}
        totemName={totemName}
        createdAt={
          punchInfos?.punch.tags?.filter(
            (tag) =>
              tag.tag === 'CREATED_BY_API' ||
              tag.tag === 'Creación manual' ||
              tag.tag === 'AUTOMATIC_CREATION_CONFIRMED' ||
              tag.tag.toLowerCase().includes('offline'),
          )?.length
            ? punchInfos?.punch.created_at
            : null
        }
        date={punchInfos?.punch.date || ''}
        precision={punchInfos?.punch.metadata.rekognition_precision || 0}
        url={punchInfos?.punch.photo_url || ''}
        tags={
          punchInfos?.punch.tags
            .filter((t) => !t.tag.includes('totem:'))
            .map((tag) => tag.tag) || []
        }
      />
    ),
  })

  return (
    <form>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: '$4',
        }}
      >
        <Div css={{ padding: '$4', marginRight: '$8' }}>
          {isEditing ? (
            <PunchMenuInput
              placeholder={fieldValue}
              autoFocus
              value={typeDecoder('hour').input(newHour || '')}
              onChange={(event) => {
                const text = event.target.value

                const decodedV = typeDecoder('hour').output(text)

                setNewHour(decodedV as any)
              }}
            />
          ) : (
            <Text variant={'subtitle2'}>
              {fieldValue || previousValue || 'Sin marcación'}
            </Text>
          )}
          <Text
            variant="description"
            css={{ marginTop: '$2', color: '$interface_dark_up' }}
          >
            {dayjs(selectedDate).format('DD/MM/YYYY')} - Marcación de {name}.
          </Text>
        </Div>
        {punchInfos && field.original && isOffline && (
          <Div css={{ marginRight: '$2' }}>
            <Tag variant="warning">Offline</Tag>
          </Div>
        )}
        {punchInfos &&
          field.original &&
          punchInfos?.punch.isValidByDistance && (
            <Div>
              <Tag>Válido</Tag>
            </Div>
          )}
        {punchInfos &&
          field.original &&
          !punchInfos?.punch.isValidByDistance && (
            <Div>
              <Tag variant="negative">Inválido</Tag>
            </Div>
          )}
        {!punchInfos && field.original && (
          <Div>
            <Tag variant="neutral">
              <Progress
                size="sm"
                css={{
                  marginRight: '$2',
                }}
              />
              Cargando
            </Tag>
          </Div>
        )}
      </Div>
      {isLoadingPunchInfos && (
        <Div
          css={{
            minHeight: 450,
            minWidth: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Progress />
        </Div>
      )}
      {isEditing ? (
        <>
          <Div css={{ paddingLeft: '$4', paddingRight: '$4' }}>
            <TextArea
              disabled
              placeholder="Escriba una justificativa para la creación..."
              css={{
                width: '100%',
                borderWidth: 1,
                borderColor: '$interface_dark_up',
              }}
            />
          </Div>
          {/* <ControlledFileInput /> */}
          <Div css={{ marginTop: '$4' }}>
            <Divider
              css={{
                height: 1,
                width: '100%',
                background: '$interface_light_down',
              }}
            />
          </Div>
          <Div
            css={{
              padding: '$4',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant={'primary'}
              type="submit"
              onClick={async (e) => {
                e.preventDefault()
                if (
                  !field.original ||
                  data.entrance.original === data.exit.original
                ) {
                  await createPunch()
                } else {
                  await updatePunch()
                }
              }}
            >
              Guardar
            </Button>
          </Div>
        </>
      ) : !isLoadingPunchInfos && field.original && punchTabs.length > 1 ? (
        <Tabs tabs={punchTabs} />
      ) : !isLoadingPunchInfos && punchTabs.length === 1 ? (
        punchTabs[0].content
      ) : null}
    </form>
  )
}

const PunchMenuInput = styled('input', {
  all: 'unset',
  padding: '$2',
  background: 'transparent',
  fontFamily: '$default',
  borderBottom: '1px solid',
  borderColor: '$interface_dark_down',
})
