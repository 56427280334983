import { useMemo } from 'react'
import { AguinaldoPlaygroundFields } from '../types'

export const useAguinaldoEarnings = (isClosed?: boolean) => {
  const configuration: AguinaldoPlaygroundFields = useMemo(() => {
    const form: AguinaldoPlaygroundFields = [
      {
        _type: 'switch',
        disabled: isClosed,
        label: 'Vacaciones',
        name: 'configuration.vacation_value',
      },
      {
        _type: 'switch',
        label: 'Bonificación Familiar',
        name: 'configuration.familyBonus',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Movilidad',
        name: 'configuration.mobility',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Bonos',
        name: 'configuration.bonus',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Comisión',
        name: 'configuration.commission',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Gratificaciones',
        name: 'configuration.gift',
        disabled: isClosed,
      },
      {
        _type: 'switch',
        label: 'Otros Ingressos',
        name: 'configuration.otherPayments',
        disabled: isClosed,
      },
    ]

    return form
  }, [isClosed])

  return {
    data: configuration,
  }
}
