import { CrownIcon } from '@/assets/icons/CrownIcon'
import { Div } from '../Div'
import { Tooltip } from '@punto-ui/react'
import { IFeatureTypes, payrollPlanFeatures } from '@/hooks/useIsFeatureInPlan'
import { theme } from '@/styles'

export const PremiumFeatureIcon = (props: { feature?: IFeatureTypes }) => {
  const featureInPayrollPlan = payrollPlanFeatures.includes(
    props.feature as IFeatureTypes,
  )

  return (
    <Div>
      <Tooltip
        message={`Funcionalidad disponible en plan ${
          featureInPayrollPlan ? '"Operativo"' : '"Avanzado"'
        }`}
      >
        <Div
          css={{
            height: '$6',
            width: '$6',
          }}
        >
          <CrownIcon fill={theme.colors.brand_primary_pure} />
        </Div>
      </Tooltip>
    </Div>
  )
}
