import {
  IReportBuilderColumnType,
  IReportBuilderRowTypes,
  IReportBuilderTypes,
} from '../type'
import { multipleMovementColumns } from './columns-by-module/multiple-movements-columns'
import { multiplePaymentsColumns } from './columns-by-module/multiple-payments-columns'
import { multiplePayrollColumns } from './columns-by-module/multiple-payroll-columns'
import { multiplePunchesColumns } from './columns-by-module/multiple-punches-columns'
import { multipleShiftsColumns } from './columns-by-module/multiple-shifts-columns'
import { multipleVacationsColumns } from './columns-by-module/multiple-vacations-columns'
import { permissionsColumns } from './columns-by-module/permissions-columns'
import { singleMovementColumns } from './columns-by-module/single-movement-columns'
import { singlePaymentColumns } from './columns-by-module/single-payment-columns'
import { singlePayrollColumns } from './columns-by-module/single-payroll-columns'
import { singlePunchColumns } from './columns-by-module/single-punch-columns'
import { singleShiftColumns } from './columns-by-module/single-shift-columns'
// import { singleSueldosColumns } from './columns-by-module/single-sueldos-columns'
import { singleVacationColumns } from './columns-by-module/single-vacation-columns'
import { singleWorkersColumns } from './columns-by-module/single-worker-columns'

export const reportBuilderColumnsByRowAndTypeAndSubtype: Record<
  IReportBuilderTypes,
  Partial<
    Record<
      IReportBuilderRowTypes | string,
      Partial<Record<IReportBuilderRowTypes, IReportBuilderColumnType[]>>
    >
  >
> = {
  single: {},
  collective: {
    entities: {
      payments: singlePaymentColumns,
      movements: singleMovementColumns,
      vacations: singleVacationColumns,
    },
    workers: {
      day: [
        ...singleWorkersColumns,
        ...permissionsColumns,
        ...singleMovementColumns,
        ...singlePunchColumns,
        ...singleShiftColumns,
        ...singlePaymentColumns,
        ...singleVacationColumns,
      ],
      month: [
        ...singleWorkersColumns,
        ...permissionsColumns,
        ...multipleMovementColumns,
        ...multiplePunchesColumns,
        ...multipleShiftsColumns,
        ...multiplePaymentsColumns,
        ...multipleVacationsColumns,
        ...singlePayrollColumns,
      ],
      year: [
        ...singleWorkersColumns,
        ...permissionsColumns,
        ...multipleMovementColumns,
        ...multiplePunchesColumns,
        ...multipleShiftsColumns,
        ...multiplePaymentsColumns,
        ...multipleVacationsColumns,
        ...multiplePayrollColumns,
      ],
    },
  },
  totals: {},
}
