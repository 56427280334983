import { Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import { BellAlertIcon, BellSnoozeIcon } from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { NotificationManagement } from './NotificationManagement'
import { NotificationsList } from './NotificationsList'
import { FormProvider, useForm } from 'react-hook-form'
import {
  INITIAL_NOTIFICATIONS_STATE,
  INotificationsStateSchema,
} from './notifications-state'

export default function Notifications({
  onlyTabs = false,
}: {
  onlyTabs?: boolean
}) {
  const { setActiveTab, activeTab } = useTabStore((state) => ({
    setActiveTab: state.setActiveTab,
    activeTab: state.notifications.activeTab,
  }))

  const methods = useForm<INotificationsStateSchema>({
    defaultValues: INITIAL_NOTIFICATIONS_STATE,
  })

  return (
    <FormProvider {...methods}>
      <Router
        onlyTabs={onlyTabs}
        defaultTab="notifications"
        actions={[]}
        tabs={[
          {
            component: <NotificationsList />,
            icon: <BellAlertIcon />,
            label: 'Notificaciones',
            value: 'notifications',
          },
          {
            component: <NotificationManagement />,
            icon: <BellSnoozeIcon />,
            label: 'Gerenciar notificaciones',
            value: 'manage-notifications',
          },
        ]}
        setTab={(id) => setActiveTab('notifications', id)}
        tab={activeTab || 'notifications'}
      />
    </FormProvider>
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
