import { AppBar, Div, ControlledFilterBar, SmartTable } from '@/components'
import {
  SmartColumnType,
  SubmitValueProps,
} from '@/components/SmartTable/types'
import { useCallback, useMemo } from 'react'
import { useSmartShifts } from '@/libs/react-query/hooks'
import { PlusIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { useFormContext, useWatch } from 'react-hook-form'
import { PunchesInfosTableType } from './types'
import { IMovementV2, IWorkerUser } from '@/libs/react-query/types'
import { useMovementsContext } from '@/pages/movimientos/context/hooks/useMovementsContext'
import { PunchInfosMenu, TotalPunchInfosMenu } from '../menus'
import { PunchesAppBar } from './components/AppBar'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useUserWorkedHours } from './context'
import { useCan } from '@/hooks'
import { useSmartTableColumnManipulation } from '@/components/SmartTable/hooks/useSmartTableColumnManipulation'

interface SubmitHoursParams {
  shouldCreate: boolean
  data: string
  userId: string
  previousData?: string
  punchDate: Date
}

export const PunchesTable = () => {
  const canEditPunches = useCan(['punches.edit'])
  const { data: smartShifts } = useSmartShifts()
  const { handleOpenMovementDrawer } = useMovementsContext()
  const {
    handleCreatePunch,
    handleUpdatePunch,
    handleCreateShiftSchedule,
    isLoading,
  } = useUserWorkedHours()
  const { getValues, control } = useFormContext<PunchesInfosTableType>()
  const numberOfIntervals = useWatch({
    control,
    name: 'numberOfIntervals',
  })

  const handleSubmitHour = useCallback(
    async ({
      data,
      shouldCreate,
      userId,
      previousData,
      punchDate,
    }: SubmitHoursParams) => {
      if (shouldCreate) {
        const punchDateString = dayjs(punchDate).format('YYYY-MM-DD')
        const newDate = dayjs(`${punchDateString} ${data}`).toISOString()

        await handleCreatePunch({
          date: newDate,
          user_id: userId,
        })
      } else {
        await handleUpdatePunch({
          newDate: dayjs(
            `${dayjs(punchDate).format('YYYY-MM-DD')} ${data}`,
          ).toISOString(),
          previousDate: dayjs(
            `${dayjs(punchDate).format('YYYY-MM-DD')} ${previousData}`,
          ).toISOString(),
          user_id: userId,
        })
      }
    },
    [handleCreatePunch, handleUpdatePunch],
  )

  const handleUpdateShift = useCallback(
    async (shiftId: string, userId: string, date: Date) => {
      await handleCreateShiftSchedule({
        params: [
          {
            dates: [dayjs(date).startOf('day').toDate()],
            userId,
          },
        ],
        shiftId,
        isRest: false,
      })
    },
    [handleCreateShiftSchedule],
  )

  const columns: SmartColumnType[] = useMemo(() => {
    return [
      {
        type: 'subheader',
        header: 'Informaciones del Usuario',
        name: 'teste',
        items: [
          {
            type: 'checkbox',
            name: 'checked',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'input-text',
            name: 'date',
            draggable: null,
            header: '',
            disabled: true,
            width: 100,
          },
          {
            type: 'input-text',
            name: 'dayOfWeek',
            draggable: null,
            header: '',
            disabled: true,
            width: 80,
          },
        ],
      },
      {
        header: 'Informaciones Adicionales',
        type: 'subheader',
        name: 'teste-2',
        items: [
          {
            type: 'tags',
            name: 'status',
            onTagClick: () => console.log('wtfff'),
            header: 'Status',
            width: 100,
          },
          {
            type: 'tags',
            width: 100,
            name: 'movements',
            onTagClick: (
              index: number,
              name: string,
              previousValue: string,
              movement: IMovementV2,
            ) => {
              if (!canEditPunches) {
                return
              }

              const user = {
                id: getValues().data[index].userId,
              } as IWorkerUser

              handleOpenMovementDrawer({
                users: movement.users?.map(
                  (u) =>
                    ({
                      id: u.user_id,
                    } as IWorkerUser),
                ) || [user],
                movement: {
                  id: movement.id,
                  batch_id: '',
                  description: movement.description,
                  identifier_id: movement.identifier_id,
                  periods: movement.periods.map((period) => ({
                    ...period,
                  })),
                  type: movement.type as any,
                  category: movement.category as any,
                  should_include_in_ips: movement.should_include_in_ips,
                  should_include_hours: !!movement.should_include_hours,
                  should_pay_period: movement.should_pay_period,
                  title: movement.title,
                  created_at: movement.created_at,
                  deleted_at: '',
                  updated_at: '',
                },
              })
            },
            header: 'Movim.',
          },
          {
            type: 'dropdown',
            width: 150,
            name: 'shiftId',
            header: 'Turno',
            disabled: !canEditPunches,
            options: smartShifts?.map((shift) => ({
              label: shift.shiftName,
              value: shift.shiftId,
            })),
            handleSubmitValue: async (props: SubmitValueProps) => {
              const items = getValues().data
              const item = items[props.index]

              await handleUpdateShift(
                String(props.data.value),
                getValues().data[props.index].userId,
                dayjs(item.date.metadata.date).toDate(),
              )
            },
          },
        ],
      },
      {
        header: 'Marcaciones',
        type: 'subheader',
        name: 'teste-3',
        items: [
          {
            type: 'input-text',
            width: 100,
            name: 'add',
            draggable: canEditPunches ? PunchInfosMenu : null,
            header: 'Agregar',
            disabled: !canEditPunches,
            placeholder: '--:--',
            icon: <PlusIcon />,
            inputType: 'hour',
            handleSubmitValue: async (props: SubmitValueProps) => {
              const { data } = getValues()
              const item = data[props.index]
              const date = dayjs(item.date.metadata.date).format('YYYY-MM-DD')

              const punchDateWithHour = dayjs(
                `${date} ${props.data.value}`,
                'YYYY-MM-DD HH:mm',
              ).toISOString()

              await handleCreatePunch({
                date: punchDateWithHour,
                user_id: item.userId,
              })
            },
            onIconClick: (index: number, data: string) => {
              console.log('icon click', index, data)
            },
          },
          {
            type: 'input-text',
            name: 'entrance',
            inputType: 'hour',
            width: 100,
            disabled: !canEditPunches,
            header: 'Entrada',
            draggable: canEditPunches ? PunchInfosMenu : null,
            handleSubmitValue: (props: SubmitValueProps) => {
              const item = getValues('data')[props.index]

              handleSubmitHour({
                data: String(props.data.value),
                previousData: String(props.data.original),
                shouldCreate: !props.data.original,
                userId: item.userId,
                punchDate: props.data.metadata?.date,
              })
            },
            onIconClick: (index: number, data: string) => {
              console.log('icon click', index, data)
            },
          },
          {
            type: 'input-text',
            name: 'intervals',
            array: true,
            length: numberOfIntervals * 2,
            draggable: canEditPunches ? PunchInfosMenu : null,
            inputType: 'hour',
            width: 100,
            disabled: !canEditPunches,
            header: 'Inter.',
            handleSubmitValue: (props: SubmitValueProps) => {
              const data = String(props.data.value)
              const previousValue = props.data.original
              const item = getValues('data')[props.index]

              handleSubmitHour({
                data,
                previousData: String(previousValue),
                shouldCreate: !previousValue,
                userId: item.userId,
                punchDate: props.data.metadata?.date,
              })
            },
            onIconClick: (index: number, data: string) => {
              console.log('icon click', index, data)
            },
          },
          {
            inputType: 'hour',
            disabled: !canEditPunches,
            type: 'input-text',
            width: 100,
            name: 'exit',
            draggable: canEditPunches ? PunchInfosMenu : null,
            header: 'Salida',
            handleSubmitValue: (props: SubmitValueProps) => {
              const data = String(props.data.value)
              const previousValue = String(props.data.original)
              const item = getValues('data')[props.index]

              handleSubmitHour({
                data,
                previousData: previousValue,
                shouldCreate: !previousValue,
                userId: item.userId,
                punchDate: props.data.metadata?.date,
              })
            },
            onIconClick: (index: number, data: string) => {
              console.log('icon click', index, data)
            },
          },
        ],
      },
      {
        type: 'subheader',
        name: 'teste-4',
        header: 'Totales',
        items: [
          {
            type: 'input-text',
            width: 100,
            name: 'totalMorningHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Total Diur.',
          },
          {
            type: 'input-text',
            name: 'totalNightHours',
            width: 100,
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Total Noct.',
          },
        ],
      },
      {
        header: 'Totales de Horas',
        type: 'subheader',
        name: 'teste-3',
        items: [
          {
            type: 'input-text',
            width: 100,
            inputType: 'hour',
            name: 'morningHours',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            header: 'Or. Diur.',
            disabled: true,
          },
          {
            type: 'input-text',
            width: 100,
            inputType: 'hour',
            name: 'nightHours',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Or. Noct.',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'morningExtraHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Ext. Diur.',
          },
          {
            width: 100,
            type: 'input-text',
            name: 'nightExtraHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Ext. Noct',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'totalIntervalHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Intervalo',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'morningDiscountHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Desc. Diur.',
          },
          {
            type: 'input-text',
            name: 'nightDiscountHours',
            width: 100,
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Desc. Noct.',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'morningHolidayHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Fer. Diur.',
          },
          {
            type: 'input-text',
            width: 100,
            name: 'nightHolidayHours',
            inputType: 'hour',
            draggable: canEditPunches ? TotalPunchInfosMenu : null,
            disabled: true,
            header: 'Fer. Noct.',
          },
        ],
      },
    ]
  }, [
    canEditPunches,
    smartShifts,
    getValues,
    handleCreatePunch,
    numberOfIntervals,
    handleSubmitHour,
    handleOpenMovementDrawer,
    handleUpdateShift,
  ])

  const tableStyles = useMemo(() => {
    return {
      borderInLines: true,
      height: 600,
    }
  }, [])

  const {
    defaultColumnsOrder,
    defaultHiddenColumns,
    resetStateColumnOrder,
    saveColumnsOrderCallback,
    saveHiddenColumnsCallback,
    defaultLeftFixedColumns,
    defaultRightFixedColumns,
    saveLeftFixedColumnsCallback,
    saveRightFixedColumnsCallback,
  } = useSmartTableColumnManipulation({
    columns,
    tableOrderUniqueName: 'userPunchesColumnsOrder',
    tableHiddenUniqueName: 'userPunchesHiddenColumns',
    tableLeftFixedUniqueName: 'userPunchesLeftFixedColumns',
    tableRightFixedUniqueName: 'userPunchesRightFixedColumns',

    defaultLeftFixedColumns: ['date', 'dayOfWeek'],
  })

  return (
    <>
      <ControlledFilterBar
        dateName={'date'}
        // nameFilterName="name"
        dateType="single"
        isLoading={isLoading}
        policiesIds={permissionsArray.filter((p) => p.includes('punches'))}
      />
      <Div
        css={{
          position: 'relative',
          height: 'calc(100vh - 90px)',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 16,
          paddingRight: 16,
          overflowX: 'scroll',
          marginTop: '$4',
        }}
      >
        <SmartTable
          style={tableStyles}
          columns={columns}
          defaultColumnOrder={defaultColumnsOrder}
          defaultHiddenColumns={defaultHiddenColumns}
          saveColumnsOrderCallback={saveColumnsOrderCallback}
          saveHiddenColumnsCallback={saveHiddenColumnsCallback}
          areColumnsDraggable
          resetColumnOrder={resetStateColumnOrder}
          maxDepth={2}
          defaultLeftFixedColumns={defaultLeftFixedColumns}
          defaultRightFixedColumns={defaultRightFixedColumns}
          saveLeftFixedColumnsCallback={saveLeftFixedColumnsCallback}
          saveRightFixedColumnsCallback={saveRightFixedColumnsCallback}
        />
        <MaybeAppBar />
      </Div>
    </>
  )
}

const MaybeAppBar = () => {
  const { control } = useFormContext<PunchesInfosTableType>()

  const data = useWatch({
    control,
    name: 'data',
  })

  const isShowingAppBar = useMemo(
    () => data.some((p) => p.checked.value),
    [data],
  )

  return (
    <AppBar isShowing={isShowingAppBar}>
      <PunchesAppBar />
    </AppBar>
  )
}
