import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../useMe'
import { FetchAuditsProps, IPaginatedAudits } from '../../types/audits'

const fetchAudits = async (props: FetchAuditsProps) => {
  try {
    const response = await apiV2.post<IPaginatedAudits>(
      '/audit/list-audits',
      {
        page: props.page !== undefined ? props.page : undefined,
        perPage: props.perPage !== undefined ? props.perPage : undefined,
        types: props.types,
        name: props.name,
      },
      {
        params: {
          cellsIds: props.cellsIds?.join(','),
          // perPage: props.pageSize,
          // usersIds: props.usersIds?.join(','),
          // usersIdsFilter: props.usersIdsFilter?.join(','),
          // name: props.name,
        },
      },
    )

    return response.data
  } catch (err) {
    console.log(err)
    return {
      data: [],
      total: 0,
      totalPages: 0,
      page: 0,
      pageSize: 0,
    }
  }
}

export const useAudits = (props: FetchAuditsProps) => {
  const { data: me } = useMe()

  const defaultEnabled = !!me && !!me.company_id && !!props.cellsIds?.length

  const query = useQuery(
    ['audits', props, me?.company_id, props.cellsIds],
    async () => {
      const workers = await fetchAudits(props)

      return workers
    },
    {
      keepPreviousData: true,
      enabled: defaultEnabled,
    },
  )

  return query
}
