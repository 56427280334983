import { ReactNode, useMemo, useState } from 'react'
import { Div } from '@/components'
import { useFormContext, useWatch } from 'react-hook-form'
import {
  IReceiptBuilderTypes,
  ReceiptBuilderRowOptionType,
  ReceiptBuilderSchemaType,
} from '../../type'
import {
  BanknotesIcon,
  CurrencyDollarIcon,
  DocumentCurrencyDollarIcon,
  MagnifyingGlassIcon,
  SunIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { DraggableExpandableSelector } from '../DraggableExpandableSelector'
import { styled } from '@punto-ui/react'

const fromRowTypeToIcon: Record<IReceiptBuilderTypes, ReactNode> = {
  liquidation: <BanknotesIcon />,
  payment: <CurrencyDollarIcon />,
  payroll: <UsersIcon />,
  vacations: <SunIcon />,
  aguinaldo: <DocumentCurrencyDollarIcon />,
}

// const fromRowTypeToLabel: Record<IReceiptBuilderTypes, string> = {
//   liquidation: 'Desvinculación',
//   payment: 'Pagos',
//   payroll: 'Planilla Nominal',
//   vacations: 'Vacaciones',
// }

export const RowBuilder = () => {
  const { control } = useFormContext<ReceiptBuilderSchemaType>()

  const [searchText, setSearchText] = useState('')

  const [rowsOptions] = useWatch({
    control,
    name: ['rowsOptions'],
  })

  const filteredRowsOptions: Record<string, ReceiptBuilderRowOptionType[]> =
    useMemo(() => {
      if (!rowsOptions) return {}

      return Object.entries(rowsOptions).reduce(
        (acc, [key, columns]) => ({
          ...acc,
          [key]: columns.filter((column) =>
            column.label.toLowerCase().includes(searchText.toLowerCase()),
          ),
        }),
        {},
      )
    }, [searchText, rowsOptions])

  return (
    <Div
      css={{
        padding: '$4',
      }}
    >
      <Div
        css={{
          marginBottom: '$4',
          display: 'flex',
          alignItems: 'center',
          gap: '$2',
          svg: {
            height: 16,
            width: 16,
            color: '$interface_dark_down',
          },
          height: 36,
          paddingLeft: 16,
          paddingRight: 16,
          background: '$interface_light_up',
          borderRadius: 16,
        }}
      >
        <MagnifyingGlassIcon />
        <ConceptsSearchBar
          placeholder="Buscar conceptos"
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Div>
      {Object.entries(filteredRowsOptions).map(([key, columns], index) => {
        if (!columns.length) return null

        return (
          <DraggableExpandableSelector
            key={index}
            isOpenName={`openColumns.${key}`}
            label={key}
            icon={fromRowTypeToIcon[key as IReceiptBuilderTypes]}
            options={columns.map((detail) => ({
              label: detail.label,
              value: detail.name,
              isDebit: detail.isDebit,
            }))}
          />
        )
      })}
    </Div>
  )
}

const ConceptsSearchBar = styled('input', {
  all: 'unset',
  backgroundColor: 'transparent',
  fontFamily: '$default',
  fontSize: '$sm',
  fontWeight: 'bold',
  color: '$interface_dark_down',
})
