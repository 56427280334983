import { Div, Flex } from '@/components'
import { TrashIcon } from '@heroicons/react/24/outline'
import {
  Box,
  Button,
  Dialog,
  DialogContainer,
  Text,
  TextInput,
} from '@punto-ui/react'
import { useFormContext } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { useState } from 'react'
import { useDeleteLiquidation } from '@/libs/react-query/mutations/company/useHandleDeleteLiquidation'
import useToast from '@/hooks/useToast'
import { useTabStore } from '@/store'

export type DeleteLiquidationModalProps = {
  isOpen: boolean
  setIsOpen(isOpen: boolean): void
  name: string
}

export const DeleteLiquidationModal = ({
  isOpen,
  name,
  setIsOpen,
}: DeleteLiquidationModalProps) => {
  const [inputValue, setInputValue] = useState('')
  const [isConfirming, setIsConfirming] = useState(false)
  const methods = useFormContext<InactivateWorkerSchema>()

  const toast = useToast()

  const { removeTab, setActiveTab, activeTab, workerTabs } = useTabStore(
    (state) => ({
      removeTab: state.removeTab,
      setActiveTab: state.setActiveTab,
      activeTab: state.worker.activeTab,
      workerTabs: state.worker.tabs,
    }),
  )

  const {
    mutateAsync: handleDeleteLiquidation,
    isLoading: isLoadingDeleteLiquidation,
  } = useDeleteLiquidation()

  const handleDeleteUserLiquidation = async () => {
    const values = methods.getValues()
    try {
      if (!values.liquidationId) {
        toast.addToast({
          title: 'No se pudo eliminar la liquidación',
          description: 'Por favor, intenta nuevamente.',
          type: 'negative',
          id: Date.now(),
        })
        return
      }

      await handleDeleteLiquidation(values.liquidationId)

      const liquidationTab = workerTabs.find(
        (tab) => tab.id === `${values.data.user_id}-inactivate`,
      )

      if (liquidationTab) {
        removeTab('worker', liquidationTab.id)
        setActiveTab('worker', 'list')
      }

      toast.addToast({
        title: 'Liquidación eliminada correctamente',
        description: '',
        type: 'positive',
        id: Date.now(),
      })
    } catch (error) {
      console.error(error)
    }

    setIsOpen(false)
  }

  const isLoading = isLoadingDeleteLiquidation

  return (
    <DialogContainer
      open={isOpen}
      onOpenChange={() => {
        setInputValue('')
        setIsConfirming(false)
        setIsOpen(!isOpen)
      }}
    >
      <Dialog
        overlayCss={{
          zIndex: 15,
        }}
        containerCss={{
          zIndex: 16,
        }}
        content={
          <Box>
            <Flex
              css={{ flexDirection: 'column', marginBottom: '$4', gap: '$4' }}
            >
              <Text variant={'heading2'}>
                ¿Está seguro que desea eliminar la liquidación del colaborador?
              </Text>
              <Text variant="paragraph" size={'4xl'}>
                {name}
              </Text>

              <Text variant="paragraph">
                Eliminar una liquidación no hace que el colaborador vuelva a
                estar activo, en caso de que ya esté desactivado. Eliminar una
                liquidación conlleva cambios en los registros laborales, así
                como en el estado del colaborador durante los días en los que
                estaba marcado como liquidado. Si el colaborador está
                desactivado, es necesario reactivarlo en la lista de
                colaboradores liquidados mediante la opción &quot;Activar
                Colaborador&quot;.
              </Text>
            </Flex>

            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '$4',
              }}
            >
              <TextInput
                label="Escribe: 'Eliminar' para confirmar"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </Div>

            <Flex css={{ flex: 1, marginTop: '$4' }}>
              <Button
                css={{ flex: 1, marginRight: '$1' }}
                onClick={() => {
                  if (isConfirming) {
                    handleDeleteUserLiquidation()
                  }

                  setIsConfirming(true)
                }}
                isLoading={isLoading}
                variant={isConfirming ? 'primaryCritical' : 'primary'}
                disabled={inputValue !== 'Eliminar'}
                icon={<TrashIcon />}
              >
                {isConfirming ? 'Confirmar' : 'Eliminar Liquidación'}
              </Button>
              <Button css={{ flex: 1, marginRight: '$1' }} variant="tertiary">
                Cancelar
              </Button>
            </Flex>
          </Box>
        }
      />
    </DialogContainer>
  )
}
