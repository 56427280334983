import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { allModules, PermissionModule } from './useGetAllPermissions'
import { PlanType } from './useIsFeatureInPlan'
import { useMemo } from 'react'

export const punchesPlanModules: PermissionModule[] = [
  'company',
  'workers',
  'punches',
  'shifts',
  'moves',
  'permisos',
  'configurations',
  'reports',

  // 'liquidation',
  // 'receipts',
  // 'payroll',
  // 'configuration-salaries',
  // 'configuration-vacations',
  // 'configuration-aguinaldos',
  // 'configuration-liquidation',
]

export const payrollPlanModules: PermissionModule[] = [
  ...punchesPlanModules,

  'payments',
  'payroll',
  'vacation',
  // 'liquidation',
  // 'receipts',
  // 'payroll',
  // 'configuration-salaries',
  // 'configuration-vacations',
  // 'configuration-aguinaldos',
  // 'configuration-liquidation',
]

const enterprisePlanModules: PermissionModule[] = [
  ...payrollPlanModules,

  'documents',
  'notifications',
  'requests',
  'audit',
  'imports',
  // 'receipt-builder',
  // 'punto-ia',
  // 'hour-authorization',
  // 'requests',
  // 'template-builder',
]

const modulesByPlan: Record<PlanType, PermissionModule[]> = {
  punches: punchesPlanModules,
  payroll: payrollPlanModules,
  enterprise: enterprisePlanModules,
}

interface IAvailableModulesByPlan {
  module: PermissionModule
  plan: PlanType | ''
  available: boolean
  planLabel: string
}

export function useIsModulesByPlan(): IAvailableModulesByPlan[] {
  const { data: company, isLoading: isLoadingCompany } = useMyCompany()

  const result = useMemo(() => {
    const companyNotAvailable = !company || isLoadingCompany
    const planModules = company?.plan ? modulesByPlan[company.plan] || [] : []

    const result: IAvailableModulesByPlan[] = allModules.map((module) => ({
      module,
      plan: companyNotAvailable
        ? ''
        : punchesPlanModules.includes(module)
        ? 'punches'
        : payrollPlanModules.includes(module)
        ? 'payroll'
        : 'enterprise',
      available: planModules.includes(module),
      planLabel: companyNotAvailable
        ? ''
        : punchesPlanModules.includes(module)
        ? 'Marcación'
        : payrollPlanModules.includes(module)
        ? 'Operativo'
        : 'Avanzado',
    }))

    return result
  }, [company, isLoadingCompany])

  return result
}
