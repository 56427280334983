import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { AguinaldoConfigurations } from './components/AguinaldoConfigurations'
import { useAvailableCells } from '@/libs/react-query/hooks'
import { AguinaldoSteps } from './components/AguinaldoSteps'
import { ControlledFilterBar, Div, Drawer, DrawerContainer } from '@/components'
import { useEffect, useState } from 'react'
import { useAguinaldoPlaygroundStore } from './store'
import { useHandleStepActions } from './hooks/useHandleStepActions'
import { AguinaldoPayrolls } from './components/AguinaldoPayrolls'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useDebounce } from '@/hooks'
import { ExportPayroll } from './components/drawers/ExportPayroll'
import { AguinaldoPlaygroundTable, AguinaldoPlaygroundTableType } from './types'
import { useAguinaldoDetails } from '@/libs/react-query/hooks/useAguinaldos/useAguinaldoDetails'
import { fromAguinaldoDetailedToPlayground } from './parser'
import { AguinaldoValues } from './components/AguinaldoValues'
import { useHandleUpdateAguinaldo } from '@/libs/react-query/mutations/aguinaldo/useHandleUpdateAguinaldo'
import { BatchEditing } from './components/drawers'
import { useAllAvailableCells } from '@/libs/react-query/hooks/useAvailableCells/useAllAvailableCells'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { CloseAguinaldoDialog } from './components/dialogs/CloseAguinaldoDialog'

interface AguinaldoPlaygroundProps {
  id?: string
}

export const AguinaldoPlayground = (props: AguinaldoPlaygroundProps) => {
  const [isCloseAguinaldoModalOn, setIsCloseAguinaldoModalOn] = useState(false)
  const { data: company } = useMyCompany()
  const { handleChangeStepIndex, currentStep, drawer, handleCloseDrawer } =
    useAguinaldoPlaygroundStore((state) => ({
      currentStep: state.currentStepValue,
      drawer: state.drawer,
      handleCloseDrawer: state.handleCloseDrawer,
      handleChangeStepIndex: state.handleChangeStepIndex,
    }))

  const [hasFinishedParsing, setHasFinishedParsing] = useState(false)

  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('payroll')),
  )

  const methods = useForm<AguinaldoPlaygroundTableType>({
    resolver: zodResolver(AguinaldoPlaygroundTable),
    defaultValues: {
      data: [],
      name: '',
      description: '',
      users_ids: [],
      cellsIds: availableCells?.map((c) => c.id) || [],
      nameFilter: '',
      pagination: {
        page: 0,
        perPage: 20,
      },
      configuration: {
        nightOrdinaryHours: true,
        morningOrdinaryHours: true,
        morningExtraHours: true,
        nightExtraHours: true,
        morningHolidayHours: true,
        nightHolidayHours: true,
        morningDiscountHours: true,
        nightDiscountHours: true,

        payments: true,
        familyBonus: true,
        mobility: true,
        bonus: true,
        commission: true,
        gift: true,
        otherPayments: true,

        ips9: true,
        ips16: true,

        antecipate: true,
        loan: true,
        lost: true,
        purchase: true,
        judicial: true,
        excess: true,
        otherDiscount: true,

        vacation_value: true,
        concepts: company?.concepts.map(() => true) || [],
      },
    },
  })

  useAllAvailableCells({
    methods,
    name: 'cellsIds',
    permission: 'payroll',
  })
  const [cellsIds, nameFilter, page, perPage] = useWatch({
    control: methods.control,
    name: ['cellsIds', 'nameFilter', 'pagination.page', 'pagination.perPage'],
  })

  const debouncedCellsIds = useDebounce(cellsIds, 250)
  const debouncedNameFilter = useDebounce(nameFilter, 250)

  const { data: detailedAguinaldo, isFetching: isFetchingAguinaldoDetail } =
    useAguinaldoDetails({
      aguinaldoId: props.id || '',
      cellsIds: debouncedCellsIds || cellsIds,
      name: debouncedNameFilter || nameFilter,
      page,
      perPage,
    })

  useHandleStepActions(methods, props.id, () =>
    setIsCloseAguinaldoModalOn(true),
  )

  useEffect(() => {
    if (!props.id) {
      handleChangeStepIndex(0)
    }
  }, [handleChangeStepIndex, props.id])

  useEffect(() => {
    if (!detailedAguinaldo?.data || !company) {
      return
    }
    const { cellsIds, nameFilter } = methods.getValues()
    const playgroundPayroll = fromAguinaldoDetailedToPlayground(
      detailedAguinaldo,
      nameFilter,
      cellsIds,
      company,
    )
    methods.reset({
      ...playgroundPayroll,
      cellsIds,
      nameFilter,
      pagination: {
        page: detailedAguinaldo.page,
        perPage: detailedAguinaldo.pageSize,
        total: detailedAguinaldo.total,
        totalPages: detailedAguinaldo.totalPages,
      },
    })

    setHasFinishedParsing(true)
  }, [methods, detailedAguinaldo, company])

  const { isLoading: isLoadingUpdateAguinaldo } = useHandleUpdateAguinaldo()
  return (
    <DrawerContainer open={drawer.isOpen} onOpenChange={handleCloseDrawer}>
      <FormProvider {...methods}>
        <ControlledFilterBar
          nameFilterName="nameFilter"
          withAdvancedCells
          cellsIdsName="cellsIds"
          isLoading={isFetchingAguinaldoDetail || isLoadingUpdateAguinaldo}
          policiesIds={[
            ...permissionsArray.filter((p) => p.includes('payroll')),
            'deleted',
          ]}
        />
        <Div css={{ padding: 16 }}>{props.id && <AguinaldoSteps />}</Div>
        {(hasFinishedParsing || !props.id) && (
          <>
            {currentStep === 'CONFIG' && <AguinaldoConfigurations />}
            {currentStep === 'PAYROLLS' && <AguinaldoPayrolls />}
            {currentStep === 'VALUES' && <AguinaldoValues />}
          </>
        )}

        <Drawer
          onOpenChange={handleCloseDrawer}
          noPadding={true}
          noClose={true}
          content={
            drawer.type === 'EXPORT' ? (
              <ExportPayroll handleCloseDrawer={handleCloseDrawer} />
            ) : drawer.type === 'BATCH_EDITING' ? (
              <BatchEditing handleCloseDrawer={handleCloseDrawer} />
            ) : null
          }
        />
        <CloseAguinaldoDialog
          isOpen={isCloseAguinaldoModalOn}
          name={detailedAguinaldo?.aguinaldo.name || ''}
          setIsOpen={setIsCloseAguinaldoModalOn}
        />
      </FormProvider>
    </DrawerContainer>
  )
}
