import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'

const handleDeleteAguinaldo = async (id: string) => {
  await apiV2.post<void>('/payroll/delete-aguinaldo', { id })

  queryClient.invalidateQueries()
}

export const useHandleDeleteAguinaldo = () => {
  const mutation = useCustomMutation(
    ['useHandleDeleteAguinaldo'],
    handleDeleteAguinaldo,
  )

  return mutation
}
