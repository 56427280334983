import { validateFormattedMinutesOrEmpty } from '@/utils/date'
import { z } from 'zod'

export const NotificationForm = z.object({
  name: z.string({
    required_error: 'El nombre es requerido',
  }),
  notification_time_before_reference_date: z
    .string({
      required_error: 'El tiempo antes de la fecha de referencia es requerido',
    })
    .min(1, {
      message: 'El tiempo antes de la fecha de referencia es requerido',
    }),
  notification_hour: z
    .string({
      required_error: 'La hora de la notificación es requerida',
    })
    .min(1, {
      message: 'La hora de la notificación es requerida',
    })
    .refine((data) => validateFormattedMinutesOrEmpty(data), {
      message: 'Hora invalida',
    }),
  reference_date: z
    .string({
      required_error: 'La fecha de referencia es requerida',
    })
    .min(1, {
      message: 'La fecha de referencia es requerida',
    }),
  is_active: z.boolean().optional(),
})

export type NotificationFormType = z.infer<typeof NotificationForm>
