import { deeplyCloneObject } from '@/utils/object'
import { GroupData, UserChildren } from './types'
import { IOrganogram } from '@/libs/react-query/types/organogram'

export const findGroupParentById = (
  group: GroupData[],
  id: string,
): GroupData | undefined => {
  for (const item of group) {
    if (item._type === 'group') {
      const groupFound = item.children.find((item) => item.id === id)
      if (groupFound) {
        return item
      }
      const groupFoundInsideGroup = findGroupParentById(item.children, id)
      if (groupFoundInsideGroup) {
        return groupFoundInsideGroup
      }
    }
  }
}

export const findGroupByIdInsideGroupData = (
  group: GroupData[],
  id: string,
): GroupData | undefined => {
  const groupFound = group.find((item) => item.id === id)
  if (groupFound) {
    return groupFound
  }

  for (const item of group) {
    if (item._type === 'group') {
      const groupFound = findGroupByIdInsideGroupData(item.children, id)
      if (groupFound) {
        return groupFound
      }
    }
  }
}

// Reorder every group children recursively:
// First comes all users
// Then, all groups
export const reorderGroup = (groups: GroupData[]): GroupData[] => {
  const groupResult = groups.map((group) => {
    if (group._type === 'user' || group._type === 'add') {
      return group
    }

    const groupChildren = group.children
    const adds = groupChildren.filter((item) => item._type === 'add')
    const users = groupChildren.filter(
      (item) => item._type === 'user',
    ) as UserChildren[]

    // Sort users isLeaders first
    users.sort((a, b) => {
      if (a.isLeader && !b.isLeader) {
        return -1
      }

      if (!a.isLeader && b.isLeader) {
        return 1
      }

      return 0
    })

    const groups = groupChildren.filter((item) => item._type === 'group')

    const groupsOrdered = reorderGroup(groups)

    const orderedChildren = [...users, ...adds, ...groupsOrdered]

    return {
      ...group,
      children: orderedChildren,
    }
  })

  return groupResult
}

// Remove by id recursively
export const removeGroupById = (
  groups: GroupData[],
  id: string,
): GroupData[] => {
  const dataToBeCloned = deeplyCloneObject(groups)
  const group = findGroupByIdInsideGroupData(dataToBeCloned, id)

  if (group?._type === 'group' && group) {
    const excludedGroupUserChildren = group.children.filter(
      (child) => child._type === 'user',
    )

    const groupParent = findGroupParentById(dataToBeCloned, id)

    if (groupParent && groupParent._type === 'group') {
      const groupParentChildren = groupParent.children
      const groupParentChildrenIndex = groupParentChildren.findIndex(
        (child) => child.id === id,
      )

      groupParentChildren.splice(groupParentChildrenIndex, 1)

      groupParentChildren.push(...excludedGroupUserChildren)
      return dataToBeCloned
    }
  }

  return []
}

export const buildGroupDataFromOrganogram = (
  organogram: IOrganogram,
): GroupData => {
  // First, we create a UserChildren array from org's "users" array
  const userChildren = organogram.users.map((user) => ({
    _type: 'user' as const,
    name: user.name,
    image_url: user.image_url || '',
    id: user.user_id,
    isLeader: user.is_leader,
  }))

  // Then, we recursively convert each child IOrganogram to a GroupData
  const childGroups = organogram.children.map(buildGroupDataFromOrganogram)

  // Finally, we create a GroupData object for this IOrganogram
  return {
    _type: 'group',
    id: organogram.id,
    name: organogram.name,
    children: [...userChildren, ...childGroups],
  }
}
