import { styled } from '@punto-ui/react'

export const NotificationTable = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',
})
export const NotificationTableHead = styled('thead')
export const NotificationTableBody = styled('tbody')
export const NotificationTableRow = styled('tr', {
  height: 40,
})
export const NotificationTableCell = styled('td', {
  height: 40,
})
