import { SmartColumnType } from '@/components/SmartTable/types'
import { useMemo } from 'react'
import { allMonths } from '@/components/InputDatePicker/utils'
import { usePayrolls } from '@/libs/react-query/hooks'
import { useFormContext, useWatch } from 'react-hook-form'
import { AguinaldoPlaygroundTableType } from '../../types'
import dayjs from 'dayjs'
import { useHandleUpdateAguinaldo } from '@/libs/react-query/mutations/aguinaldo/useHandleUpdateAguinaldo'

export const useAguinaldoPayrolls = () => {
  const { data: payrolls } = usePayrolls()
  const { control, getValues } = useFormContext<AguinaldoPlaygroundTableType>()

  const { mutateAsync: handleUpdateAguinaldo } = useHandleUpdateAguinaldo()

  const [year, status] = useWatch({
    control,
    name: ['year', 'status'],
  })

  const validPayrollsOptions = useMemo(() => {
    if (!payrolls) return []
    return payrolls
      .filter((payroll) => {
        const payrollYear = String(dayjs(payroll.start_date).year())
        return payrollYear === year
      })
      .sort((a, b) => {
        return dayjs(a.start_date).month() - dayjs(b.start_date).month()
      })
      .map((payroll) => {
        return {
          label: payroll.name,
          value: payroll.id,
        }
      })
  }, [payrolls, year])

  const columns: SmartColumnType[] = useMemo(() => {
    return [
      {
        type: 'subheader',
        header: 'Informaciones del Colaborador',
        name: 'Informaciones del Colaborador',
        items: [
          {
            type: 'checkbox',
            name: 'checked',
            draggable: null,
            header: '',
            width: 24,
          },
          {
            type: 'avatar',
            name: 'avatar',
            draggable: null,
            header: '',
            width: 32,
          },
          {
            type: 'input-text',
            name: 'name',
            draggable: null,
            header: 'Nombre',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'surname',
            draggable: null,
            header: 'Appellido',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
          {
            type: 'input-text',
            name: 'ci',
            draggable: null,
            header: 'CI',
            disabled: true,
            inputType: 'no-mask',
            width: 100,
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Planillas',
        items: [
          {
            type: 'dropdown',
            name: 'monthsPayrolls',
            width: 120,
            array: true,
            length: 12,
            disabled: status === 'closed',
            options: validPayrollsOptions,
            headers: allMonths.map((month) => month.label),
            handleSubmitValue: async (value) => {
              const formData = getValues()

              await handleUpdateAguinaldo({
                id: formData.id,
                month: value.subindex || 0,
                payrollId: value.data.value as string,
                usersIds: [formData.data[value.index].userId],
                value: null,
              })
            },
          },
        ],
      },
      {
        type: 'subheader',
        header: 'Subtotal',
        items: [
          {
            type: 'input-text',
            name: 'subtotal',
            inputType: 'money-guarani',
            header: '',
            width: 140,
            disabled: true,
          },
        ],
      },
    ] as SmartColumnType[]
  }, [validPayrollsOptions, status])

  return columns
}
