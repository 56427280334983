import { Div } from '@/components'
import { ControlledWorkerSearchBar, FormRenderer } from '@/components/Forms'
import { styled } from '@punto-ui/react'
import { useAguinaldoBasicInfos } from '../../form/useAguinaldoConfiguration'
import { AguinaldoCard } from '../AguinaldoCard'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useFormContext, useWatch } from 'react-hook-form'
import { AguinaldoPlaygroundTableType } from '../../types'
import { useAguinaldoDiscounts } from '../../form/useAguinaldoDiscounts'
import { useAguinaldoEarnings } from '../../form/useAguinaldoEarnings'
import { useAguinaldoHours } from '../../form/useAguinaldoHours'
import { useAguinaldoConcepts } from '../../form/useAguinaldoConcepts'

export const AguinaldoConfigurations = () => {
  const { control } = useFormContext<AguinaldoPlaygroundTableType>()

  const usersIds = useWatch({
    control,
    name: 'users_ids',
  })

  const status = useWatch({
    control,
    name: 'status',
  })

  const { data: payrollConfigurationForm } = useAguinaldoBasicInfos(
    status === 'closed',
  )
  const { data: discountForm } = useAguinaldoDiscounts(status === 'closed')
  const { data: earningsForm } = useAguinaldoEarnings(status === 'closed')
  const { data: hoursForm } = useAguinaldoHours(status === 'closed')
  const { data: conceptsForm } = useAguinaldoConcepts(status === 'closed')

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        paddingLeft: '$4',
        paddingRight: '$4',
      }}
    >
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '$4',
          height: 'calc(100vh - 144px)',
        }}
      >
        <AguinaldoCard label="Informaciones básicas">
          <AguinaldoCardContent>
            <FormRenderer items={payrollConfigurationForm} />
          </AguinaldoCardContent>
        </AguinaldoCard>
        <Div
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '$4',
            overflow: 'scroll',
            flex: 1,
            height: '100%',
          }}
        >
          <AguinaldoCard label="Horas">
            <AguinaldoCardContent>
              <FormRenderer items={hoursForm} />
            </AguinaldoCardContent>
          </AguinaldoCard>
          <AguinaldoCard label="Otros Ingressos">
            <AguinaldoCardContent>
              <FormRenderer items={earningsForm} />
            </AguinaldoCardContent>
          </AguinaldoCard>
          <AguinaldoCard label="Descuentos">
            <AguinaldoCardContent>
              <FormRenderer items={discountForm} />
            </AguinaldoCardContent>
          </AguinaldoCard>
          <AguinaldoCard label="Conceptos">
            <AguinaldoCardContent>
              <FormRenderer items={conceptsForm} />
            </AguinaldoCardContent>
          </AguinaldoCard>
        </Div>
      </Div>
      <Div
        css={{
          height: 'calc(100vh - 166px)',
          flex: 1,
          marginLeft: '$4',
          marginTop: '20px',
          overflow: 'scroll',
        }}
      >
        <ControlledWorkerSearchBar<AguinaldoPlaygroundTableType>
          maxHeight={'calc(100vh - 320px)'}
          name={'users_ids'}
          activeDateFilter
          activeSelection
          usersIds={usersIds}
          disabled={status === 'closed'}
          policiesIds={permissionsArray.filter((p) => p.includes('workers'))}
        />
      </Div>
    </Div>
  )
}

const AguinaldoCardContent = styled('div', {
  background: '$interface_light_pure',
  padding: '$4',
  minWidth: 'calc(20vw - 12px)',
  borderRadius: '$md',
})
