import { useQuery } from 'react-query'
import { apiV2 } from '@/services/api'
import { FetchPunchInfosProps, IPunchEnvelope } from '@/libs/react-query/types'
import dayjs from 'dayjs'
import { queryClient } from '@/libs/react-query'

const fetchPunchInfo = async (props: FetchPunchInfosProps) => {
  try {
    const response = await apiV2.post<IPunchEnvelope>(
      '/punches/get-punch-from-time',
      {
        date: props.date,
        user_id: props.user_id,
      },
    )

    return response.data
  } catch (error) {
    queryClient.invalidateQueries('workers-hours')
    throw error
  }
}

export interface FetchPunchInfosNullableProps {
  date: string | null
  user_id: string
}

export const usePunchInfo = (props: FetchPunchInfosNullableProps) => {
  const query = useQuery(
    ['workers-hours', props.date, props.user_id],
    () =>
      fetchPunchInfo({
        date: props.date!,
        user_id: props.user_id!,
      }),
    {
      keepPreviousData: true,
      enabled: !!props?.date && !!props.user_id && dayjs(props.date).isValid(),
      onError: () => {
        queryClient.invalidateQueries('workers-hours')
      },
    },
  )
  return query
}
