import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { IUpdateCompanyBankAccountDTO } from '@/libs/react-query/mutations/company/types'
import { queryClient } from '@/libs/react-query'

export const useHandleUpdateCompanyBankAccount = () => {
  const mutation = useMutation(
    ['useHandleUpdateCompanyBankAccount'],
    handleUpdateCompanyBankAccount,
  )

  return mutation
}

const handleUpdateCompanyBankAccount = async (
  props: IUpdateCompanyBankAccountDTO,
) => {
  try {
    await apiV2.post<void>('/company/update-company-bank-account', props)

    queryClient.invalidateQueries()
  } catch (error) {
    console.log(error)
  }
}
