import { Div } from '@/components'
import { FormProvider, useForm } from 'react-hook-form'
import { AddShiftData, addShiftSchema } from './form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSmartShifts } from '@/libs/react-query/hooks'
import { useMemo } from 'react'
import { useTabStore } from '@/store'
import { convertSmartShiftToAddShiftData, getInitialShiftWeeks } from './parser'
import { useExecuteOnceWhen } from '@/hooks'
import { useHandleCreateShiftActions } from './actions'
import { ConfigurationsForm } from './ConfigurationForm'
import { ShiftCalendarDefinition } from './ShiftCalendarDefinition'

export const CreateShiftV2 = () => {
  const { shiftTabs, activeTab } = useTabStore((state) => ({
    shiftTabs: state.shifts.tabs,
    activeTab: state.shifts.activeTab,
  }))

  const id = useMemo(() => {
    const tab = shiftTabs.find((t) => t.id === activeTab)
    return tab?.id
  }, [shiftTabs, activeTab])

  const { data: smartShifts, isLoading: isLoadingSmartShifts } =
    useSmartShifts()
  const updatingShift = useMemo(
    () => smartShifts?.find((shift) => shift.shiftId === id),
    [smartShifts, id],
  )

  const methods = useForm<AddShiftData>({
    resolver: zodResolver(addShiftSchema),
    defaultValues: updatingShift
      ? convertSmartShiftToAddShiftData(updatingShift)
      : getInitialShiftWeeks(new Date()),
  })

  useExecuteOnceWhen(() => {
    if (updatingShift) {
      methods.reset(convertSmartShiftToAddShiftData(updatingShift))
    }
  }, !isLoadingSmartShifts && !!updatingShift)

  useHandleCreateShiftActions({
    isUpdatingShift: !!updatingShift,
    methods,
    shiftId: updatingShift?.shiftId,
  })

  return (
    <FormProvider {...methods}>
      <CreateShiftContent />
    </FormProvider>
  )
}

const CreateShiftContent = () => {
  return (
    <Div
      css={{
        padding: '$5',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      <Div css={{ width: '100%', overflowX: 'auto', marginBottom: '$4' }}>
        <ConfigurationsForm />
      </Div>
      <Div css={{ width: '100%', overflowX: 'auto' }}>
        <ShiftCalendarDefinition />
      </Div>
    </Div>
  )
}
