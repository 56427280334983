export const getNumberModule = (v: number) => {
  return v < 0 ? v * -1 : v
}

export const getNumberWith3Decimals = (v: number) => {
  return Math.round(v * 1000) / 1000
}

export const getPositiveOrZero = (v: number) => {
  return v < 0 ? 0 : v
}
