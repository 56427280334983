import { queryClient } from '@/libs/react-query'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleDeleteReceipt = async (id: string) => {
  await apiV2.post<any>(`/report-builder/delete-receipt`, { id })
  queryClient.invalidateQueries('receipts')
}

export const useHandleDeleteReceipt = () => {
  const mutation = useCustomMutation(
    ['useHandleDeleteReceipt'],
    handleDeleteReceipt,
  )

  return mutation
}
