import { Div, FormRenderer } from '@/components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Text } from '@punto-ui/react'
import { useCompanyBankAccounts } from '@/libs/react-query/hooks/company/useCompanyBankAccounts'
import { useHandleUpdateCompanyBankAccount } from '@/libs/react-query/mutations/company/useHandleUpdateCompanyBankAccount'
import useToast from '@/hooks/useToast'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { z } from 'zod'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'
import { useEffect } from 'react'

const CompanyBankAccountSchema = z.object({
  bank_name: z.string().min(1, 'El nombre del banco es requerido'),
  account_number: z.string().min(1, 'El número de cuenta es requerido'),
})

type CompanyBankAccountSchemaType = z.infer<typeof CompanyBankAccountSchema>

const CompanyBankAccount = () => {
  const { data: companyBankAccounts, isFetching: isLoadingBankAccounts } =
    useCompanyBankAccounts()
  const { mutateAsync: handleUpdateCompanyBankAccount, isLoading } =
    useHandleUpdateCompanyBankAccount()
  const toast = useToast()

  const form = useForm<CompanyBankAccountSchemaType>({
    resolver: zodResolver(CompanyBankAccountSchema),
    defaultValues: {
      bank_name: companyBankAccounts?.bankAccounts[0]?.bank_name || '',
      account_number:
        companyBankAccounts?.bankAccounts[0]?.bank_account_number || '',
    },
  })

  useEffect(() => {
    const bankAccountFormName = form.getValues('bank_name')

    if (companyBankAccounts?.bankAccounts?.length && !bankAccountFormName) {
      form.setValue(
        'bank_name',
        companyBankAccounts?.bankAccounts[0]?.bank_name || '',
      )
      form.setValue(
        'account_number',
        companyBankAccounts?.bankAccounts[0]?.bank_account_number || '',
      )
    }
  }, [companyBankAccounts])

  const inputForm: ControlledFieldProps<CompanyBankAccountSchemaType>[] = [
    {
      _type: 'input',
      name: 'bank_name',
      label: 'Nombre del banco',
      disabled: isLoadingBankAccounts,
    },
    {
      _type: 'input',
      name: 'account_number',
      label: 'Número de cuenta',
      disabled: isLoadingBankAccounts,
    },
  ]

  const submitBankAccountUpdate = async (
    data: CompanyBankAccountSchemaType,
  ) => {
    try {
      await handleUpdateCompanyBankAccount({
        id: companyBankAccounts?.bankAccounts[0]?.id,
        bank_name: data.bank_name,
        bank_account_number: data.account_number,
        bank_account_type: null,
      })

      toast.addToast({
        title: 'Cuenta bancaria actualizada con éxito',
        type: 'positive',
        description:
          'Los datos de la cuenta bancaria han sido actualizados con éxito',
        id: Date.now(),
      })
    } catch (err) {
      toast.addToast({
        title: 'Error al actualizar cuenta bancaria',
        type: 'negative',
        description:
          'Ha ocurrido un error al actualizar los datos de la cuenta bancaria',
        id: Date.now(),
      })
    }
  }

  return (
    <FormProvider {...form}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            background: '$interface_light_pure',
            minWidth: 360,
            padding: '$4',
            margin: '$4',
            borderRadius: '$md',
            gap: '$4',
          }}
        >
          <Text
            variant="subtitle2"
            css={{
              color: '$brand_primary_pure',
            }}
          >
            Cuenta bancaria
          </Text>
          <FormRenderer items={inputForm} />
          <Button
            isLoading={isLoading}
            disabled={isLoadingBankAccounts || isLoading}
            onClick={() => {
              form.handleSubmit(submitBankAccountUpdate)()
            }}
            icon={<CheckCircleIcon />}
          >
            Actualizar cuenta bancaria
          </Button>
        </Div>
      </Div>
    </FormProvider>
  )
}

export default CompanyBankAccount
