import { DomTHead, DomTable, TableContainer } from './styles'
import React, { useEffect, useRef, useState } from 'react'
import { ShiftCellMemo } from './components/ShiftCell'
import { useSideBarContext } from '@/contexts/hooks'
import { useSmartShift } from '../../context'
import { useShiftManagementTable } from './hooks'
import { useShiftsPage } from '@/pages/turnos/context'
import { ShiftHeaderMemo } from './components/ShiftHeader'
import { ControlledFilterBar } from '@/components'
import { useVirtualizer } from '@tanstack/react-virtual'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { ShiftsTopBar } from '../ShiftsTopBar'
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

export const SHIFT_TABLE_CELL_WIDTH = 120
export const SHIFT_TABLE_CELL_WORKER_WIDTH = 220
export const SHIFT_TABLE_CELL_HEIGHT = 50

export const MainContent = () => {
  const table = useShiftManagementTable()
  const tableContainerRef = useRef<any>(null)
  const { isOpen } = useSideBarContext()
  const { isLoading, isLoadingFreshData, isFetching, setIsOverSelected } =
    useSmartShift()
  const { isFullscreen } = useShiftsPage()

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: rows.length,
    overscan: 5,
    estimateSize: React.useCallback(() => SHIFT_TABLE_CELL_HEIGHT, []),
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
  })

  const [firstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFirstRender(false)
    }, 10)
    return () => clearInterval(interval)
  }, [])

  const SIDEBAR = isOpen ? '300px' : isFullscreen ? '0px' : '70px'

  const dropRef = useRef<any>(null)

  useEffect(() => {
    if (!dropRef.current) {
      return
    }

    return dropTargetForElements({
      element: dropRef.current,
      canDrop: ({ source }) => source.data.type === 'shift-management',
      onDragEnter: () => {
        setIsOverSelected(false)
      },
    })
  }, [])

  return (
    <>
      <ControlledFilterBar
        dateName={'dates'}
        withoutTotalButtons
        nameFilterName="name"
        dateType="range"
        cellsIdsName="cellsIds"
        withAdvancedCells
        isLoading={isFetching || isLoading || firstRender}
        policiesIds={permissionsArray.filter((p) => p.includes('shifts'))}
      />
      <ShiftsTopBar />
      {!firstRender && !isLoadingFreshData && (
        <TableContainer
          ref={tableContainerRef}
          css={{
            // marginTop: 16,
            width: `calc(100vw - 30px - ${SIDEBAR})`,
            paddingBottom: 120,
            overflow: 'auto',
            height: '100%',
            position: 'relative',
          }}
        >
          <DomTable>
            <DomTHead ref={dropRef}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((column) => (
                    <ShiftHeaderMemo
                      key={column.id}
                      column={column}
                      table={table}
                    />
                  ))}
                </tr>
              ))}
            </DomTHead>
            <tbody
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index]
                return (
                  <tr
                    key={row.id}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      height: `${virtualRow.size}px`,
                      transform: `translateY(calc(${virtualRow.start}px + 55px))`,
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <ShiftCellMemo key={cell.id} cell={cell} table={table} />
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </DomTable>
        </TableContainer>
      )}
    </>
  )
}

export const TableMemo = React.memo(MainContent)
