import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { UpdateUserPayload } from '../types'
import { queryClient } from '@/libs/react-query'

export const useHandleUpdateUser = () => {
  const handleUpdateUser = async ({
    newParentId,
    oldParentId,
    userId,
    isLeader,
  }: UpdateUserPayload) => {
    const apiPayload = [
      {
        newParentId,
        oldParentId,
        isLeader,
        userId,
      },
    ]

    await apiV2.patch<void>('/permission/update-organogram-user', apiPayload)
    queryClient.invalidateQueries()
  }

  const mutation = useMutation(['useHandleUpdateUser'], handleUpdateUser)

  return mutation
}
