import { styled } from '@punto-ui/react'
import { DomTHead, DomTable, TableContainer, TableData } from './styles'
import { Cell, HeaderGroup, Table as ReactTable } from '@tanstack/react-table'
import React, { useMemo, useRef } from 'react'
import {
  IPaginationProps,
  SmartColumnType,
  SmartTableFormDataSchemaType,
  SmartTableStyles,
} from '../../types'
import { EditingCell, ROW_HEIGHT } from './components/EditingCell'
import { findColumnByName } from '../../columns'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Div } from '@/components/Div'
import { SmartTablePagination } from './components/Pagination'
import { TableHeadCell } from './components/HeadCell'
import { AddRowDropdown } from './components/AddRowDropdown'
import useIsTouchDevice from '@/hooks/useIsTouchDevice'
// import { useVirtual } from '@tanstack/react-virtual'

export type TableProps = {
  table: ReactTable<SmartTableFormDataSchemaType>
  isLoading?: boolean
  withPagination?: boolean
  numberOfRows?: number
  columns: SmartColumnType[]
  allRawColumns: SmartColumnType[]
  paginationProps?: IPaginationProps
  styles?: SmartTableStyles
  noPadding?: boolean
  selectedIndex?: number
  areColumnsDraggable?: boolean
  hiddenColumns?: string[]
  maxDepth?: number

  subRowName?: string

  leftFixedColumns?: string[]
  rightFixedColumns?: string[]
  setColumnOrders?: React.Dispatch<React.SetStateAction<string[]>>
  setHiddenColumns?: React.Dispatch<React.SetStateAction<string[]>>
  setLeftFixedColumns: React.Dispatch<React.SetStateAction<string[]>>
  setRightFixedColumns: React.Dispatch<React.SetStateAction<string[]>>
}

const TBody = styled('tbody', {})
const Tr = styled('tr', {})

export const Table = ({
  noPadding,
  table,
  numberOfRows,
  columns,
  paginationProps,
  withPagination,
  styles,
  selectedIndex,
  areColumnsDraggable,
  allRawColumns,
  hiddenColumns,
  maxDepth,
  subRowName,

  setColumnOrders,
  setHiddenColumns,
  setLeftFixedColumns,
  setRightFixedColumns,
  leftFixedColumns,
  rightFixedColumns,
}: TableProps) => {
  // console.log('re-render table raw data!!!')

  const isTouchDevice = useIsTouchDevice()

  const tableContainerRef = useRef<HTMLDivElement>(null)
  const rowVirtualizer = useVirtualizer({
    count: numberOfRows || 0,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: React.useCallback(() => ROW_HEIGHT, []),
    overscan: 10,
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    paddingStart: isTouchDevice ? 64 : undefined,
  })

  if (!numberOfRows) {
    return null
  }

  // console.log(table.getRowModel().rows)

  return (
    <>
      <TableContainer
        noPadding={noPadding}
        ref={tableContainerRef}
        css={{
          overflow: 'scroll',
          // width: '100%',
          position: 'relative',

          paddingBottom: 8,
        }}
      >
        <DomTable borderInLines={!!styles?.borderInLines}>
          <DomTHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableHeaderContent
                setLeftFixedColumns={setLeftFixedColumns}
                setRightFixedColumns={setRightFixedColumns}
                leftFixedColumns={leftFixedColumns}
                rightFixedColumns={rightFixedColumns}
                key={headerGroup.id}
                columns={columns}
                styles={styles}
                headerGroup={headerGroup}
                setColumnOrders={setColumnOrders}
                areColumnsDraggable={areColumnsDraggable}
                setHiddenColumns={setHiddenColumns}
                maxDepth={maxDepth}
              />
            ))}
          </DomTHead>
          <TBody
            css={{
              position: 'relative',
            }}
          >
            <Div
              css={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                // position: 'relative',
                width: '100%',
                zIndex: 1,
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = table.getRowModel().rows[virtualRow.index]

                return (
                  <Div
                    key={row.original.id}
                    css={{
                      display: 'flex',
                      position: 'absolute',
                      zIndex: 1,
                      top: 0,
                      left: 0,
                      width: '100%',
                      transform: `translateY(${virtualRow.start}px)`,
                      height: `${virtualRow.size}px`,

                      '&:hover': {
                        '> div': {
                          backgroundColor: '$interface_light_down',
                        },
                      },
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <MemoTableDataContent
                          leftFixedColumns={leftFixedColumns}
                          rightFixedColumns={rightFixedColumns}
                          key={cell.id}
                          cell={cell}
                          styles={styles}
                          columns={columns}
                          table={table}
                          selectedIndex={selectedIndex}
                          rowIndex={virtualRow.index}
                          subRowName={subRowName}
                        />
                      )
                    })}
                  </Div>
                )
              })}
            </Div>
          </TBody>
        </DomTable>

        {!!setHiddenColumns && !!setColumnOrders && (
          <AddRowDropdown
            setLeftFixedColumns={setLeftFixedColumns}
            setRightFixedColumns={setRightFixedColumns}
            setHiddenColumns={setHiddenColumns}
            hiddenColumns={hiddenColumns || []}
            columns={allRawColumns}
            setColumnsOrder={setColumnOrders}
          />
        )}
      </TableContainer>
      {withPagination && (
        <SmartTablePagination
          paginationProps={paginationProps}
          table={table}
          totalNumberOfRows={numberOfRows}
        />
      )}
      {!withPagination && <Div css={{ height: 24 }} />}
    </>
  )
}

export type TableDataContentProps = {
  table: ReactTable<SmartTableFormDataSchemaType>
  columns: SmartColumnType[]
  styles?: SmartTableStyles
  cell: Cell<SmartTableFormDataSchemaType, unknown>
  selectedIndex?: number
  rowIndex: number
  subRowName?: string

  leftFixedColumns?: string[]
  rightFixedColumns?: string[]
}

const TableDataContent = ({
  cell,
  styles,
  columns,
  table,
  selectedIndex,
  rowIndex,
  subRowName,

  leftFixedColumns,
  rightFixedColumns,
}: TableDataContentProps) => {
  // console.log('re-render table data content!!!')

  const columnRef = useMemo(() => {
    if (cell.column.columnDef.meta?.isArray) {
      const correctId = cell.column.id.split('.')[0]
      return findColumnByName(columns, correctId)
    } else {
      return findColumnByName(columns, cell.column.id)
    }
  }, [cell.column.id, columns, cell.column.columnDef.meta?.isArray])

  const headColumnRef = useMemo(() => {
    return columns.find((c) => {
      if (c.items) {
        const columnItemRef = c.items.find(
          (item) => item.name === cell.column.id,
        )
        if (columnItemRef) {
          return true
        }
      }

      return false
    })
  }, [cell.column.id, columns])

  const lastColumnRefItem = useMemo(() => {
    return headColumnRef?.items?.[headColumnRef?.items?.length - 1]
  }, [headColumnRef?.items])

  const isColumnFixed = useMemo(() => {
    return (
      leftFixedColumns?.includes(cell.column.id) ||
      rightFixedColumns?.includes(cell.column.id) ||
      (leftFixedColumns?.length &&
        (columnRef?.name === 'checked' || columnRef?.name === 'expandable'))
    )
  }, [leftFixedColumns, rightFixedColumns, cell.column.id, columnRef])

  const leftPosition = useMemo(() => {
    const leftPositionIndex = leftFixedColumns?.findIndex(
      (col) => col === cell.column.id,
    )
    const checkedColumn = findColumnByName(columns, 'checked')
    const expansibleColumn = findColumnByName(columns, 'expandable')

    if (columnRef?.name === 'checked' && leftFixedColumns?.length) {
      return 0
    }

    if (columnRef?.name === 'expandable' && leftFixedColumns?.length) {
      return checkedColumn?.width ? checkedColumn.width + 2 : 0
    }

    if (leftPositionIndex === undefined || leftPositionIndex === -1) return

    const defaultCheckedColumnWidth = checkedColumn?.width
      ? checkedColumn.width + 2
      : 0
    const defaultExpansibleColumn = expansibleColumn?.width
      ? expansibleColumn.width + 2
      : 0

    let left = defaultCheckedColumnWidth + defaultExpansibleColumn

    leftFixedColumns?.forEach((col, index) => {
      if (index >= leftPositionIndex) return
      const column = findColumnByName(columns, col)
      left += (column?.width || 0) + 2
    })

    return left
  }, [leftFixedColumns, cell.column.id, columns])

  const rightPosition = useMemo(() => {
    const reversedRightFixedColumns = [...(rightFixedColumns || [])].reverse()
    const rightPositionIndex = reversedRightFixedColumns?.findIndex(
      (col) => col === cell.column.id,
    )
    if (rightPositionIndex === undefined || rightPositionIndex === -1) return

    let right = 0

    reversedRightFixedColumns?.forEach((col, index) => {
      if (index >= rightPositionIndex) return
      const column = findColumnByName(columns, col)
      right += (column?.width || 0) + 2
    })

    return right
  }, [rightFixedColumns, cell.column.id, columns])

  const shouldApplyBorder =
    lastColumnRefItem?.name === cell.column.id ||
    (columnRef?.length || 0) - 1 ===
      cell.column.columnDef.meta?.subarrayIndex ||
    isColumnFixed

  return (
    <TableData
      borderInLines={!!styles?.borderInLines}
      css={{
        overflow: 'visible !important',
        borderRight: shouldApplyBorder
          ? '1px solid $interface_light_down'
          : undefined,
        // borderLeft: shouldApplyBorder
        //   ? '1px solid $interface_light_down'
        //   : undefined,

        position: isColumnFixed ? 'sticky' : undefined,
        left: leftPosition,
        right: rightPosition,
        // background: 'transparent',
        background:
          rowIndex % 2 ? '$interface_light_pure' : '$interface_light_up',
        zIndex: isColumnFixed ? 10 : 1,

        minWidth: columnRef?.width ? columnRef?.width + 2 : undefined,
        maxWidth: columnRef?.width ? columnRef?.width + 2 : undefined,
        width: columnRef?.width ? columnRef?.width + 2 : undefined,

        input: {
          color:
            selectedIndex === cell.row.index
              ? '$brand_primary_pure'
              : undefined,
          textAlign: styles?.centered ? 'center' : undefined,
        },

        height: ROW_HEIGHT,
        maxHeight: ROW_HEIGHT,
        // zIndex: overflowingOption ? overflowingOption.zIndex : undefined,
      }}
    >
      <MemoEditingCell
        subRowName={subRowName}
        columnRef={columnRef}
        row={cell.row}
        column={cell.column}
        table={table}
      />
    </TableData>
  )
}

const MemoTableDataContent = React.memo(TableDataContent)
const MemoEditingCell = React.memo(EditingCell)

const TableHeaderContent = ({
  headerGroup,
  columns,
  setColumnOrders,
  styles,
  areColumnsDraggable,
  setHiddenColumns,
  maxDepth,

  setLeftFixedColumns,
  setRightFixedColumns,
  leftFixedColumns,
  rightFixedColumns,
}: {
  columns: SmartColumnType[]
  styles?: SmartTableStyles
  headerGroup: HeaderGroup<any>

  setColumnOrders?: React.Dispatch<React.SetStateAction<string[]>>
  setHiddenColumns?: React.Dispatch<React.SetStateAction<string[]>>
  setLeftFixedColumns?: React.Dispatch<React.SetStateAction<string[]>>
  setRightFixedColumns?: React.Dispatch<React.SetStateAction<string[]>>

  leftFixedColumns?: string[]
  rightFixedColumns?: string[]

  areColumnsDraggable?: boolean
  maxDepth?: number
}) => {
  const isDraggable =
    headerGroup.depth === 1 || (headerGroup.depth === 0 && maxDepth === 1)

  return (
    <Tr
      key={headerGroup.id}
      css={{
        position: 'relative',
        zIndex: 10,
      }}
    >
      {headerGroup.headers.map((column) => {
        return (
          <TableHeadCell
            setHiddenColumns={setHiddenColumns}
            setColumnOrders={setColumnOrders}
            setLeftFixedColumns={setLeftFixedColumns}
            setRightFixedColumns={setRightFixedColumns}
            leftFixedColumns={leftFixedColumns}
            rightFixedColumns={rightFixedColumns}
            column={column}
            columns={columns}
            key={column.id}
            styles={styles}
            isDraggable={isDraggable && areColumnsDraggable}
          />
        )
      })}
    </Tr>
  )
}
