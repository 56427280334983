import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'

const handleUpdateNotificationReadStatus = async (params: {
  ids: string[]
  is_read: boolean
}) => {
  const response = await apiV2.post(`/notifications/read-status`, params)
  queryClient.invalidateQueries()
  return response.data
}

export const useHandleUpdateNotificationReadStatus = (
  notificationId?: string,
) => {
  const mutation = useCustomMutation(
    ['update-notification-read-status', notificationId],
    handleUpdateNotificationReadStatus,
  )

  return mutation
}
