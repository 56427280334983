import { useWorker } from '@/libs/react-query/hooks'
import { useExecuteOnceWhen } from '@/hooks'
import {
  createCompleteWorkerData,
  createWorkerSchema,
  createWorkerDefaultValues as defaultValues,
} from '@/libs/react-query/mutations/workers'
import { ICompleteWorkerEnvelope } from '@/libs/react-query/types/worker'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTabStore } from '@/store'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { getUtcDateFromString } from '@/utils/date'

interface CreateWorkerContextData {
  formStep: number
  setFormStep: React.Dispatch<React.SetStateAction<number>>
  isLastStep: boolean
  totalSteps: number
  isCreation: boolean
  isLoading: boolean
  worker: ICompleteWorkerEnvelope | undefined
}

export const CreateWorkerContext = createContext<CreateWorkerContextData>(
  {} as CreateWorkerContextData,
)

interface CreateWorkersProviderProps {
  children: React.ReactNode
}

export const CreateWorkersProvider = ({
  children,
}: CreateWorkersProviderProps) => {
  const { data: company } = useMyCompany()

  const { activeTab, tabs } = useTabStore((state) => ({
    activeTab: state.worker.activeTab,
    tabs: state.worker.tabs,
  }))
  const [formStep, setFormStep] = useState(0)
  const [isFormatted, setIsFormatted] = useState(false)

  const id = useMemo(() => {
    const tab = tabs.find((t) => t.id === activeTab)
    return tab?.id !== 'create' ? tab?.id : undefined
  }, [tabs, activeTab])

  const { data: worker, isLoading: isLoadingWorker } = useWorker(id as string)

  const totalSteps = useMemo(() => {
    return 4
  }, [])

  const isLastStep = useMemo(() => {
    return formStep === totalSteps - 1
  }, [formStep, totalSteps])

  const methods = useForm<createCompleteWorkerData>({
    resolver: zodResolver(createWorkerSchema),
    defaultValues,
  })

  useEffect(() => {
    if (id) return

    const defaultPaymentConfigId = company?.paymentConfigGroups[0]?.id || ''
    const defaultPunchConfigId = company?.punchConfigGroups[0]?.id || ''

    methods.setValue('payment_config_id', defaultPaymentConfigId)
    methods.setValue('punch_config_ids', defaultPunchConfigId)
  }, [id])

  useExecuteOnceWhen(() => {
    if (worker) {
      const dataparExternal = worker.company.externalCodes.find(
        (c) => c.label === 'datapar_user_id',
      )

      const punchExternal = worker.company.externalCodes.find(
        (c) => c.label === 'marcacion_id',
      )

      const defaultConfig = worker.company.externalCodes.find(
        (c) => c.label === 'default_config_id',
      )

      // console.log('=========')
      // console.log(worker.company.hiring_date)
      // console.log(getUtcDateFromString(worker.company.hiring_date))
      // console.log(dayjs(worker.company.hiring_date).format('YYYY-MM-DD'))
      // console.log(dayjs(worker.company.hiring_date).utc().format('YYYY-MM-DD'))

      methods.reset({
        id: worker.user.id,
        email: worker.user.email,
        password: '',
        confirm_password: '',
        ci: worker.user.document || '',

        name: worker.user.firstName,
        surname: worker.user.surname,

        position: worker.user.position || '',
        carreer_plan: worker.user.carreer_plan || '',
        profession: worker.user.profession || '',

        phone: worker.user.phone1 || '',
        birthday_date: worker.user.birthday
          ? dayjs(worker.user.birthday).format('DD/MM/YYYY')
          : '',
        marital_state: worker.user.civil_status || '',
        nationality: worker.user.nationality || '',
        gender: worker.user.gender || '',

        entrance_ips_date: worker.company.ips_registration_date
          ? dayjs(worker.company.ips_registration_date)
              .utc()
              .format('DD/MM/YYYY')
          : '',

        hiring_date: worker.company.hiring_date
          ? dayjs(worker.company.hiring_date).utc().format('DD/MM/YYYY')
          : '',

        contacts: worker.user.contacts.map((contact) => ({
          id: contact.id,
          name: contact.name,
          birthday: contact.birthday,
          document: contact.document,
          isEmergencyContact: contact.is_emergency_contact,
          phone: contact.phone_number,
          relationship: contact.relationship,
        })),
        children: worker.user.children.map((child) => ({
          id: child.id,
          birthday: child.birthday
            ? dayjs(child.birthday).format('DD/MM/YYYY')
            : '',
          document: child.document,
          name: child.name,
          shouldReceiveChildrenBonus: child.should_receive_bonus,
        })),

        street: worker.user.address[0]?.street || '',
        number: worker.user.address[0]?.number || '',
        complement: worker.user.address[0]?.complement || '',
        neighborhood: worker.user.address[0]?.neighboor || '',
        city: worker.user.address[0]?.city || '',
        state: worker.user.address[0]?.state || '',
        country: worker.user.address[0]?.country || '',

        // face_id_photo: '',
        // profile_photo: '',

        shift_id: '',

        punch_config_ids: worker.company.punch_config_ids[0],
        payment_config_id: worker.company.payment_config_id,
        vacation_config_id: worker.company.vacation_config_id,
        aguinaldo_config_id: worker.company.aguinaldo_config_id,
        liquidation_config_id: worker.company.liquidation_config_id,

        cell_id: worker.cell_id,

        // salary_group_id: worker.salary.salary_group_id
        //   ? worker.salary.salary_group_id
        //   : 'RAW_VALUE',
        // salary_raw_value: worker.salary.value || 0,
        salaryHistory: worker.salaryHistory.map((salary) => {
          return {
            id: salary.id,
            start_date: salary.start_date
              ? [getUtcDateFromString(salary.start_date)]
              : [null],
            end_date: salary.end_date
              ? [getUtcDateFromString(salary.end_date)]
              : [null],
            is_raw_value: !!salary.value,
            group_id: salary.group_id,
            value: salary.value,
            concepts: salary.concepts.map((concept) => ({
              id: concept.id,
              name: concept.name,
              value: concept.value,
              ips: !!concept.ips,
              description: concept.description || '',
            })),
          }
        }),
        conceptToAdd: {
          ips: false,
          name: '',
          value: 0,
        },
        concepts: worker.company.concepts,

        bank_name: worker.user.bankAccounts[0]?.bank_name ?? '',
        bank_account: worker.user.bankAccounts[0]?.bank_account ?? '',
        currency: {
          language: '',
          currency: '',
        },

        datapar_id: dataparExternal?.external_code || '',
        marcacion_id: punchExternal?.external_code || '',
        default_config_id: defaultConfig?.external_code || '',
      })
      setIsFormatted(false)
    }
  }, !!worker?.user.id)

  return (
    <FormProvider {...methods}>
      <CreateWorkerContext.Provider
        value={{
          formStep,
          setFormStep,
          totalSteps,
          isLastStep,
          isCreation: !id,
          isLoading: isLoadingWorker || isFormatted,
          worker,
        }}
      >
        {children}
      </CreateWorkerContext.Provider>
    </FormProvider>
  )
}
