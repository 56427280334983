import { GrabIcon } from '@/assets/icons/GrabIcon'
import { Div } from '@/components'
import { styled } from '@/styles'
import {
  Bars3BottomLeftIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  QueueListIcon,
  RectangleGroupIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline'

import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

import { Text } from '@punto-ui/react'
import React, { useEffect, useState } from 'react'
import { useReportTemplates } from '@/libs/react-query/hooks/useReportTemplates'

interface IContentOption {
  label: string
  value: string
  onDrop?: () => void
}

interface IContentPdfOption {
  id: string
  icon: React.ReactNode
  label: string
  options: IContentOption[]
}

export const ContentForm = () => {
  const { data: templates } = useReportTemplates({
    page: 0,
    perPage: 30,
  })

  const nonDefaultTemplates = templates?.data.filter(
    (t) => !t.id?.includes('default') && !t.id?.includes('fixed:'),
  )

  const contentOptions: IContentPdfOption[] = [
    {
      id: 'empty',
      label: 'Vacio',
      icon: <RectangleGroupIcon />,
      options: [
        {
          label: 'Vacio',
          value: 'empty',
          onDrop: () => {
            console.log('hey')
          },
        },
      ],
    },
    {
      id: 'texts',
      label: 'Textos',
      icon: <Bars3BottomLeftIcon />,
      options: [
        {
          label: 'Texto',
          value: 'text',
          onDrop: () => {
            console.log('hey')
          },
        },
        {
          label: 'Campo para firma',
          value: 'signature',
          onDrop: () => {
            console.log('hey')
          },
        },
      ],
    },
    {
      id: 'enfeites',
      icon: <QueueListIcon />,
      label: 'Enfeites',
      options: [
        {
          label: 'Logo',
          value: 'image',
          onDrop: () => {
            console.log('hey')
          },
        },
        {
          label: 'Divisor',
          value: 'divider',
          onDrop: () => {
            console.log('hey')
          },
        },
        {
          label: 'Distanciador',
          value: 'distance',
          onDrop: () => {
            console.log('hey')
          },
        },
      ],
    },
    {
      id: 'tables',
      label: 'Tabla',
      icon: <TableCellsIcon />,
      options:
        nonDefaultTemplates?.map((t) => ({
          value: `table:${t.id}` || '',
          label: t.name || '',
        })) || [],
    },
  ]

  return (
    <Div>
      <ContentSearchBar />
      {contentOptions.map((content) => (
        <ContentList key={content.id} content={content} />
      ))}
    </Div>
  )
}

const RawInput = styled('input', {
  all: 'unset',

  fontFamily: '$default',
  fontSize: '12px',
  lineHeight: '18px',
})

const ContentSearchBar = () => {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: '$2',

        height: 34,
        background: '$interface_light_up',

        paddingLeft: '$2',
        paddingRight: '$2',

        '> svg': {
          color: '$interface_dark_down',
          height: 16,
          width: 16,
        },

        border: '1px solid $interface_light_deep',
      }}
    >
      <MagnifyingGlassIcon />
      <RawInput placeholder="Buscar contenido" css={{}} />
    </Div>
  )
}

const ContentList = ({ content }: { content: IContentPdfOption }) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Div>
      <Div
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        css={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          height: 34,
          background: '$interface_light_up',

          paddingLeft: '$2',
          paddingRight: '$2',

          border: '1px solid $interface_light_deep',
        }}
      >
        <Div
          css={{
            '> svg': {
              color: '$interface_dark_down',
              height: 16,
              width: 16,
            },

            display: 'flex',
            alignItems: 'center',
            gap: '$2',
          }}
        >
          {content.icon}
          <Text
            variant="caption"
            css={{
              color: '$interface_dark_down',
            }}
          >
            {content.label}
          </Text>
        </Div>
        <Div
          css={{
            '> svg': {
              color: '$interface_dark_down',
              height: 16,
              width: 16,
              strokeWidth: 2,
            },

            display: 'flex',
            alignItems: 'center',

            transition: 'transform 0.2s',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <ChevronDownIcon />
        </Div>
      </Div>
      <Div
        css={{
          transition: 'grid-template-rows 500ms',
          gridTemplateRows: isOpen ? '1fr' : '0fr',
          display: 'grid',
        }}
      >
        <Div
          css={{
            overflow: 'hidden',
          }}
        >
          {content.options.map((o) => {
            return <ContentOption key={o.value} option={o} />
          })}
        </Div>
      </Div>
    </Div>
  )
}

const ContentOption = ({ option }: { option: IContentOption }) => {
  const optionRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!optionRef.current) return

    return draggable({
      element: optionRef.current,
      canDrag: () => true,
      onDrag: ({ source }) => {
        optionRef.current?.style.setProperty('opacity', '0.2')
        // console.log('dragging')
      },
      onDrop: ({ source }) => {
        optionRef.current?.style.setProperty('opacity', '1')
        option.onDrop?.()
      },
      getInitialData: () => {
        return {
          optionType: option.value,
          type: 'pdf-builder-option',
        }
      },
    })
  }, [])

  return (
    <Div
      ref={optionRef}
      css={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        height: 34,
        background: '$interface_light_pure',

        paddingLeft: '$2',
        paddingRight: '$2',

        border: '1px solid $interface_light_deep',
      }}
    >
      <Div
        css={{
          '> svg': {
            color: '$interface_dark_down',
            height: 16,
            width: 16,
          },

          display: 'flex',
          alignItems: 'center',
          gap: '$2',
        }}
      >
        <GrabIcon />
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
          }}
        >
          {option.label}
        </Text>
      </Div>
    </Div>
  )
}
