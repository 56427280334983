import { getDeepVal } from '@/utils/object'
import { FieldValues, useFormContext, useWatch } from 'react-hook-form'
import { SearchableSelect } from '@/components/SearchableSelect'
import { ControlledSearchableSelectP } from './types'

export const ControlledSearchableSelect = <TFieldValues extends FieldValues>({
  SearchableComponent = SearchableSelect,
  onChange,
  ...props
}: ControlledSearchableSelectP<TFieldValues>) => {
  const {
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext()

  const value = useWatch({
    control,
    name: props.name,
    defaultValue: getValues(props.name),
  })

  const inputError = getDeepVal(errors, props.name)

  return (
    <SearchableComponent
      {...props}
      optionsWidth={props.optionsWidth}
      value={value}
      onChangeValue={(option) => {
        setValue(props.name, option.value as any)
        onChange?.(option.value)
      }}
      errorMessage={inputError ? inputError.message : undefined}
    />
  )
}
