import { queryClient } from '@/libs/react-query'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleDeleteTemplate = async (id: string) => {
  await apiV2.post<any>(`/report-builder/delete-template`, {
    id,
  })
  queryClient.invalidateQueries('templates')
}

export const useHandleDeleteTemplate = () => {
  const mutation = useCustomMutation(
    ['useHandleDeleteTemplate'],
    handleDeleteTemplate,
  )

  return mutation
}
