import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../../useMe'
import { IPaginatedNotifications } from '../../../types/notifications'

export interface IFetchNotificationsParams {
  page: number
  per_page: number
  is_read?: boolean
  modules?: string[]
  date?: string
  hour?: string
}

const fetchNotifications = async (data: IFetchNotificationsParams) => {
  const response = await apiV2.get<IPaginatedNotifications>('/notifications', {
    params: {
      ...data,
      modules: data.modules?.join(','),
    },
  })

  return response.data
}

export const useNotifications = (data: IFetchNotificationsParams) => {
  const { data: me } = useMe()

  const defaultEnabled = !!me && !!me.company_id

  const query = useQuery(
    [
      'notifications',
      me?.company_id,
      data.page,
      data.per_page,
      data.is_read,
      data.modules,
      data.date,
      data.hour,
    ],
    async () => {
      const notifications = await fetchNotifications(data)

      return notifications
    },
    {
      keepPreviousData: true,
      enabled: defaultEnabled,
      refetchInterval: 4 * 60 * 1000, // 4 minutes,
    },
  )

  return query
}
