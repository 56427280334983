import { z } from 'zod'
import { ControlledFieldProps } from '@/components/Forms/FormRenderer/types'

import { paymentOptions } from '@/libs/react-query/types/payment'
import { paymentSchema } from './create-batch/form'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Tag } from '@punto-ui/react'

export type AddPaymentSimpleData = z.infer<typeof paymentSchema>
export type AddPaymentFields = Array<ControlledFieldProps<AddPaymentSimpleData>>

export const usePaymentFields = () => {
  const { control } = useFormContext<AddPaymentSimpleData>()

  const [valueType, paymentType, id, ips] = useWatch({
    control,
    name: ['value_type', 'payment_type', 'id', 'ips'],
  })

  const paymentField: AddPaymentFields = useMemo(() => {
    const defaultPaymentType =
      paymentOptions.find((option) => option.value === paymentType) ||
      paymentOptions[0]

    const disabledIpsPaymentTypes = [
      'antecipate',
      'aguinaldo_antecipate',
      'loan',
      'lost',
      'purchase',
      'judicial',
      'excess',
    ]

    const isWithoutIps = disabledIpsPaymentTypes.includes(paymentType)

    const policiesIds = ['payments.all', 'payments.edit', 'payments.request']

    if (!id) policiesIds.push('payments.create')

    return [
      {
        _type: 'row',
        numberOfColumns: 2,
        fields: [
          {
            _type: 'input',
            label: 'Titulo del Pagamento',
            name: 'title',
          },
          {
            _type: 'dropdown',
            label: 'Tipo de Pagamento',
            name: 'payment_type',
            autoComplete: 'off',
            options: paymentOptions,
            defaultOption: defaultPaymentType,
          },
        ],
      },
      {
        _type: 'row',
        numberOfColumns: 2,
        fields: [
          {
            _type: 'input',
            label: 'Descripcion del Pagamento',
            name: 'description',
          },
          {
            label: 'Fecha del pago',
            _type: 'calendar',
            name: 'payment_date',
            calendarType: 'single',
            firstDate: new Date(),
            secondDate: new Date(),
            maxDates: 100,
          },
        ],
      },
      {
        _type: 'worker_search_bar',
        policiesIds,
        label: 'Colaboradores',
        maxHeight: 200,
        name: 'usersIds',
        showUserGrid: true,
        activeSelection: true,
        activeDateFilter: true,
      },
      {
        _type: 'row',
        numberOfColumns: 2,
        fields: isWithoutIps
          ? [
              {
                _type: 'checkbox',
                label: 'Descontar 9% IPS',
                name: 'discount_ips9',
              },
            ]
          : [
              {
                _type: 'checkbox',
                label: 'Incluir en la base imponible del IPS',
                name: 'ips',
              },
              {
                _type: 'checkbox',
                label: 'Descontar 9% IPS',
                name: 'discount_ips9',
              },
            ],
      },
      {
        _type: 'row',
        numberOfColumns: 2,
        fields: [
          {
            label: 'Tipo de Valor de Pago',
            _type: 'dropdown',
            placeholder: 'Tipo de Valor de Pago',
            name: 'value_type',
            options: [
              {
                label: 'Porcentaje del Salario',
                value: 'SALARY_PERCENTAGE',
              },
              {
                label: 'Porcentaje del Total com Conceptos',
                value: 'FULL_PERCENTAGE',
              },
              { label: 'Nominal', value: 'VALUE' },
            ],
            defaultOption: {
              label: 'Nominal',
              value: 'VALUE',
            },
          },
          {
            _type: 'input',
            label: 'Valor',
            name: 'value',
            inputType: valueType === 'VALUE' ? 'money-guarani' : 'percentage',
          },
          {
            _type: 'custom',
            component: (
              <>
                {ips && (
                  <Tag>
                    {`¿Está seguro de crear este pago?, Al seleccionar "Incluir en la base imponible del IPS" el valor será adicionado a la base imponible para el cálculo del IPS`}
                  </Tag>
                )}
              </>
            ),
          },
        ],
      },
    ]
  }, [valueType, paymentType, ips, id])

  return {
    data: paymentField,
  }
}
