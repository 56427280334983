import { Div } from '@/components/Div'
import { DropdownV2 } from '@/components/DropdownV2'
import { useAvailableCells } from '@/libs/react-query/hooks'
import { ExportPdfSchemaType } from '../form'
import { useFormContext, useWatch } from 'react-hook-form'
import { Progress } from '@punto-ui/react'
import { useAguinaldos } from '@/libs/react-query/hooks/useAguinaldos/useAguinaldos'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { IAguinaldo } from '@/libs/react-query/types/aguinaldo'

export const AguinaldoStep = () => {
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('payroll')),
  )
  const { data: aguinaldos, isFetching: isFetchingAguinaldos } = useAguinaldos({
    cellsIds: availableCells?.map((v) => v.id) || [],
  })

  const methods = useFormContext<ExportPdfSchemaType>()

  const aguinaldoId = useWatch({
    control: methods.control,
    name: 'aguinaldoId',
  })

  const selectedPayment = aguinaldos?.find((s) => s.id === aguinaldoId)

  const getAguinaldoLabel = (s: IAguinaldo) => {
    return `${s.name} - ${s.year} - ${s.users.length} ${
      s.users.length === 1 ? `colaborador` : `colaboradores`
    }`
  }

  return (
    <Div>
      <DropdownV2
        value={aguinaldoId || ''}
        defaultValue={aguinaldoId || ''}
        onChangeValue={(value) => {
          if (!value.value) {
            return
          }

          methods.setValue('aguinaldoId', value.value)
          const aguinaldo = aguinaldos?.find((s) => s.id === value.value)
          methods.setValue(
            'usersIds',
            aguinaldo?.users.map((u) => u.user_id) || [],
          )
        }}
        defaultOption={{
          label: selectedPayment
            ? getAguinaldoLabel(selectedPayment)
            : 'Selecciona un aguinaldo',
          value: selectedPayment?.id || '',
        }}
        options={
          aguinaldos?.map((s) => {
            return {
              label: getAguinaldoLabel(s),
              value: s.id,
            }
          }) || []
        }
      />
      {isFetchingAguinaldos && (
        <Div
          css={{
            paddingTop: '$4',
          }}
        >
          <Progress />
        </Div>
      )}
    </Div>
  )
}
