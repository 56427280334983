import { getConceptRowByType } from './constants/concept-rows-by-type'
import { rowsOptionsByReceiptType } from './constants/rows-options-by-receipt-type'
import { IReceiptBuilderTypes, ReceiptBuilderRowOptionType } from './type'
import { v4 as uuid } from 'uuid'
import { IReceipt } from '@/libs/react-query/types/reports'
import { getLiquidationConceptRowByType } from './constants/liquidation-concept-rows-by-type'

export const RECEIPT_TYPES = [
  'payroll',
  'liquidation',
  'payment',
  'vacations',
  'aguinaldo',
] as const

export const fromReceiptTypeToLabel = (type: string) => {
  const labelsByType: Record<IReceiptBuilderTypes, string> = {
    payroll: 'Planilla Nominal',
    liquidation: 'Desvinculación',
    payment: 'Pagos',
    vacations: 'Vacaciones',
    aguinaldo: 'Aguinaldo',
  }

  return labelsByType[type as IReceiptBuilderTypes]
}

export const fromReceiptHeaderTypeToLabel = (type: string) => {
  const labelsByType: Record<string, string> = {
    'no-image': 'Sin imagen',
    'with-logo': 'Con logo',
    'with-3-images': 'Con 3 imagenes',
    'with-big-logo': 'Con logo',
    'with-logo-and-mtess': 'Con logo y MTSS',
    'liquidation-header': 'Encabezado para liquidación',
    'liquidation-logo-header': 'Encabezado para liquidación con logo',
  }

  return labelsByType[type as IReceiptBuilderTypes]
}

export const fromReceiptFooterTypeToLabel = (type: string) => {
  const labelsByType: Record<string, string> = {
    'one-signature': 'Firma colaborador',
    'two-signatures': 'Firma colaborador + Firma empresa',
    'liquidation-one-signature': 'Liquidación (firma colaborador)',
    'liquidation-two-signatures': 'Liquidación (firma colaborador + empresa)',
  }

  return labelsByType[type as IReceiptBuilderTypes]
}

export const fromReceiptTypeToRowsOptions = (
  type: IReceiptBuilderTypes,
  allCompanyConcepts: string[],
  allCompanyLiquidationConcepts: {
    id: string
    name: string
  }[],
): Record<string, ReceiptBuilderRowOptionType[]> => {
  let rowsOptions = rowsOptionsByReceiptType[type] || []

  const conceptsRows = rowsOptions.filter((column) =>
    column.value.includes('salary_concepts'),
  )

  const liquidationConceptsRows = rowsOptions.filter((column) =>
    column.value.includes('liquidation_concepts'),
  )

  liquidationConceptsRows.forEach((conceptRow) => {
    const reportRowConceptIndex = liquidationConceptsRows.findIndex((column) =>
      column.value.includes(conceptRow.value),
    )

    if (reportRowConceptIndex === -1) {
      return
    }

    const isIps = conceptRow.value.includes('ips')

    const liquidationConceptColumns = getLiquidationConceptRowByType(
      allCompanyLiquidationConcepts,
      conceptRow.type,
      isIps,
      conceptRow.subgroup || 'Salario del Colaborador',
    )

    rowsOptions = [...rowsOptions, ...liquidationConceptColumns]
  })

  conceptsRows.forEach((conceptRow) => {
    const reportRowConceptIndex = conceptsRows.findIndex((column) =>
      column.value.includes(conceptRow.value),
    )

    if (reportRowConceptIndex === -1) {
      return
    }

    const isIps = conceptRow.value.includes('ips')

    const conceptColumns = getConceptRowByType(
      allCompanyConcepts,
      conceptRow.type,
      isIps,
      conceptRow.subgroup || 'Salario del Colaborador',
    )

    rowsOptions = [...rowsOptions, ...conceptColumns]
  })

  const result: Record<string, ReceiptBuilderRowOptionType[]> = {}

  rowsOptions.forEach((row) => {
    if (!row.label || row.label === ' (IPS)') return

    if (!result[row.subgroup]) {
      result[row.subgroup] = []
    }

    result[row.subgroup].push({
      subgroup: row.subgroup,
      label: row.label,
      isDebit: row.isDebit,
      includedRowsIds: [],
      amountType: '',
      name: row.value,
      row_type: row.type,
    })
  })

  return result
}

export const getCurrentReceiptRows = (
  receiptType: string,
  companyConcepts: string[],
  companyLiquidationConcepts: {
    id: string
    name: string
  }[],
  receipt: IReceipt,
) => {
  const rowsOptions = fromReceiptTypeToRowsOptions(
    receiptType as IReceiptBuilderTypes,
    companyConcepts || [],
    companyLiquidationConcepts || [],
  )

  const rowsOptionsArray = Object.values(rowsOptions).flat()

  const selectedRowsOptions = rowsOptionsArray.filter((r) =>
    receipt.concepts.some((c) => c.values.find((v) => v.value === r.name)),
  )

  const rows = receipt.concepts.map((c) => {
    const rowConcepts = selectedRowsOptions.filter((r) =>
      c.values.find((v) => v.value === r.name),
    )

    return {
      id: uuid(),
      amount: {
        value: '-',
        status: '',
      },
      conceptLabel: {
        value: c.label,
      },
      includedConceptsInRow: c.values.map((c) => c.value),

      concepts: rowConcepts.map((r) => {
        return {
          id: r.name,
          name: r.label,
          variant: r.isDebit ? 'negative' : 'positive',
          data: r,
        }
      }),
    }
  })

  return rows
}
