import { useEffect } from 'react'
import { useTabStore } from '@/store'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useCan } from '@/hooks'

export const useHandleReceiptHubActions = () => {
  const canCreateReceipt = useCan(['reports.createReceipt'])
  const { setActions, addTab, setActiveTab, tabs } = useTabStore((state) => ({
    removeTab: state.removeTab,
    setDefaultTab: () => state.setActiveTab('reportsV2', 'generator'),
    setActiveTab: state.setActiveTab,
    tabs: state.reportsV2.tabs,
    setActions: state.setActionsTab,
    addTab: state.addTab,
  }))

  useEffect(() => {
    if (canCreateReceipt) {
      setActions('reportsV2', [
        {
          size: 'sm',
          action: () => {
            const id = Date.now().toString()

            let existingTab = tabs.find((tab) => tab.type === 'add-receipt')
            if (!existingTab) {
              existingTab = {
                id,
                type: 'add-receipt',
                label: 'Crear Recibo',
              }
              addTab('reportsV2', [existingTab])
            }
            setActiveTab('reportsV2', existingTab.id)
          },
          label: 'Crear Recibo',
          width: 120,
          disabled: !canCreateReceipt,
          variant: 'primary',
          icon: <PlusIcon />,
        },
      ])
    } else {
      setActions('reportsV2', [])
    }
  }, [canCreateReceipt])
}
