import { movementOptions } from '@/libs/react-query/types/movements'
import { useEffect, useMemo } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { AddMovementsFields } from '@/pages/movimientos/form'
import dayjs from 'dayjs'
import { Div } from '@/components'

export const useMovementForm = (methods: UseFormReturn) => {
  // const { control } = useFormContext<AddMovementSimpleData>()

  const periodType = useWatch({
    control: methods.control,
    name: 'period_type',
  })

  const movementType = useWatch({
    control: methods.control,
    name: 'type',
  })

  const defaultMultipleDates = useWatch({
    control: methods.control,
    name: 'periods',
  })

  const movementCategory = useWatch({
    control: methods.control,
    name: 'category',
  })

  useEffect(() => {
    if (movementCategory === 'presence') {
      methods.setValue('remuneration', true)
    }
  }, [movementCategory])

  const movementFields: AddMovementsFields = useMemo(() => {
    const periodTypes = [
      {
        label: 'Periodo Unico',
        value: 'range',
      },
      {
        label: 'Multiplos Dias',
        value: 'multiple',
      },
    ]

    const defaultMovementOption =
      movementOptions.find((option) => option.value === movementType) ||
      movementOptions[0]

    const numberOfDaysInMovements =
      periodType === 'range' &&
      defaultMultipleDates[0] &&
      defaultMultipleDates[1] &&
      dayjs(defaultMultipleDates[0]).isSame(
        dayjs(defaultMultipleDates[1]),
        'day',
      )
        ? 1
        : periodType === 'range' &&
          defaultMultipleDates[0] &&
          defaultMultipleDates[1]
        ? dayjs(defaultMultipleDates[1]).diff(defaultMultipleDates[0], 'days') +
          1
        : periodType === 'multiple'
        ? defaultMultipleDates.length
        : 0

    const isPresenceMovement = movementCategory === 'presence'

    const result: AddMovementsFields = [
      {
        _type: 'row',
        numberOfColumns: 2,
        fields: [
          {
            _type: 'input',
            label: 'Titulo del movimiento',
            name: 'title',
          },
          {
            _type: 'dropdown',
            label: 'Tipo de movimiento',
            name: 'category',
            options: movementOptions,
            defaultOption: defaultMovementOption,
            autoComplete: 'off',
          },
        ],
      },
      {
        _type: 'input',
        label: 'Descripcion del movimiento',
        name: 'description',
      },
      {
        _type: 'row',
        numberOfColumns: 2,
        fields: [
          {
            _type: 'dropdown',
            label: 'Periodo del Movimiento',
            name: 'period_type',
            defaultOption: periodTypes.find(
              (option) => option.value === periodType,
            ) || {
              label: 'Periodo Unico',
              value: 'range',
            },
            options: periodTypes,
          },
          {
            name: 'periods',
            label: 'Fecha del movimiento',
            hourName: 'withHours',
            multipleDates: defaultMultipleDates,
            calendarType: periodType,
            _type: 'calendar',
            maxDates: 99999,
            withHours: periodType === 'range',
            firstDate: new Date(),
            secondDate: new Date(),
          },
        ],
      },
      {
        _type: 'worker_search_bar',
        policiesIds: [
          'moves.all',
          'moves.edit',
          'moves.request',
          'moves.create',
        ],
        label: 'Colaboradores',
        maxHeight: 200,
        name: 'users',
        activeDateFilter: true,
        activeSelection: true,
      },
      {
        _type: 'checkbox',
        label: `Movimiento contabiliza como dia remunerado  (+ ${numberOfDaysInMovements} dias naturales)`,
        name: 'remuneration',
        disabled: isPresenceMovement,
      },
      {
        _type: 'checkbox',
        label: `Movimiento contabiliza en la bases imponible del IPS (+ ${numberOfDaysInMovements} dias ao IPS)`,
        name: 'ips',
      },
    ]

    if (isPresenceMovement) {
      result.push({
        _type: 'checkbox',
        label: `Contabilizar horas del movimiento`,
        name: 'should_include_hours',
      })
    } else {
      result.push({
        _type: 'custom',
        component: (
          <Div
            css={{
              height: 64,
            }}
          />
        ),
      })
    }

    return result
  }, [movementCategory, periodType, movementType, defaultMultipleDates])

  return {
    data: movementFields,
  }
}
