import type { AppProps } from 'next/app'
import { QueryClientProvider } from 'react-query'

import { queryClient } from '@/libs/react-query'
import { AuthProvider } from '@/contexts/AuthContext'
import { CompanyContextProvider } from '@/contexts/CompanyContext'
import { SideBarProvider } from '@/contexts/SideBarContext'
import { globalStyles } from './styles/global'
import { TooltipProvider } from '@punto-ui/react'
import Head from 'next/head'
import { ToastContextProvider } from '@/contexts/ToastContext'
import { DownloadContextProvider } from '@/contexts/DownloadContext'

import dayjs from 'dayjs'

import updateLocale from 'dayjs/plugin/updateLocale'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import { ExportReceiptProvider } from '@/components/ExportReceipt'
import { useTokenChangeListener } from '@/hooks/useTokenChangeListener'

dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(updateLocale)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(isBetween)

dayjs.updateLocale('en', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
})

dayjs.updateLocale('en', {
  weekdays: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ],
})

globalStyles()

export default function App({ Component, pageProps, router }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <DownloadContextProvider>
        <ToastContextProvider>
          <ExportReceiptProvider>
            <TooltipProvider>
              <SideBarProvider>
                <AuthProvider>
                  <CompanyContextProvider>
                    {/* <DndProvider backend={HTML5Backend}> */}
                    <AppContent
                      pageProps={pageProps}
                      Component={Component}
                      router={router}
                    />
                    {/* </DndProvider> */}
                  </CompanyContextProvider>
                </AuthProvider>
              </SideBarProvider>
            </TooltipProvider>
          </ExportReceiptProvider>
        </ToastContextProvider>
      </DownloadContextProvider>
    </QueryClientProvider>
  )
}

const AppContent = ({ Component, pageProps }: AppProps) => {
  useTokenChangeListener()

  return (
    <>
      <Head>
        <title>Punto Ok!</title>
      </Head>
      <Component {...pageProps} />
    </>
  )
}
