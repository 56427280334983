import { apiV2 } from '@/services/api'
import { useMutation } from 'react-query'
import { IShift } from '@/libs/react-query/types'
import { fromAddShiftDataToCreationPayload } from '@/utils/shifts/parseShiftToMutateV2'
import { CreateShiftDTO } from '@/libs/react-query/types/shift'

const handleCreateShift = async ({ ...props }: CreateShiftDTO) => {
  const parsedProps = fromAddShiftDataToCreationPayload(props)
  const apiPayload = {
    ...parsedProps,
  }

  const response = await apiV2.post<IShift>('/shift/create-shift', apiPayload)

  return response.data
}

export const useHandleCreateShifts = () => {
  const mutation = useMutation(['useHandleMovement'], handleCreateShift)

  return mutation
}
