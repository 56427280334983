import { useQuery } from 'react-query'
import { IListVacationsDTO, IPaginatedVacations } from '../../types/vacations'
import { apiV2 } from '@/services/api'

const fetchVacations = async (data: IListVacationsDTO) => {
  const vacations = await apiV2.post<IPaginatedVacations>(
    '/vacation/list-vacations',
    data,
    {
      params: {
        cellsIds: (data.cellsIds || []).join(','),
      },
    },
  )
  return vacations.data
}

export const useVacations = (props: IListVacationsDTO) => {
  const query = useQuery(
    ['vacations', props.page, props.perPage, props.cellsIds, props.name],
    () => fetchVacations(props),
    {
      keepPreviousData: true,
      enabled: !!props.cellsIds?.length && props.cellsIds?.length > 0,
    },
  )
  return query
}
