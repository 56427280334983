import { Avatar, Div, FormGroup } from '@/components'
import { useFormContext, useWatch } from 'react-hook-form'
import { VacationFormType } from '../../types'
import { useMainCreateVacationInputs } from './form'
import { useTabStore } from '@/store'
import React, { useEffect, useRef } from 'react'
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useHandleCreateVacations } from '@/libs/react-query/mutations/vacations/useHandleCreateVacation'
import dayjs from 'dayjs'
import useToast from '@/hooks/useToast'
import { useVacation } from '@/libs/react-query/hooks/useVacations/useVacation'
import { useCan, useDebounce } from '@/hooks'
import { Divider, Progress, Tag, Text } from '@punto-ui/react'
import { VacationsStatusCreation } from './VacationsStatusCreation'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useWorkers } from '@/libs/react-query/hooks'
import { useVirtualizer } from '@tanstack/react-virtual'
import { getUtcDateFromString } from '@/utils/date'
import { useHandleUpdateVacation } from '@/libs/react-query/mutations/vacations/useHandleUpdateVacation'

export const CreateVacationsV2 = () => {
  const canRequest = useCan(['vacation.request'], true)
  const { addToast } = useToast()
  const {
    formState: { errors: formErrors },
    control,
    getValues,
    setValue,
    trigger,
  } = useFormContext<VacationFormType>()
  const {
    mutateAsync: handleCreateVacationMutation,
    isLoading: isLoadingCreateVacations,
  } = useHandleCreateVacations()
  const {
    mutateAsync: handleUpdateVacationMutation,
    isLoading: isLoadingUpdateVacations,
  } = useHandleUpdateVacation()

  const { setActions, activeTab, removeTab, setActiveTab } = useTabStore(
    (state) => ({
      setActions: state.setActionsTab,
      activeTab: state.vacations.activeTab,
      removeTab: state.removeTab,
      setActiveTab: state.setActiveTab,
    }),
  )

  const maybeVacationId = activeTab !== 'create' ? activeTab : undefined

  const { data: vacation, isLoading: isLoadingVacation } = useVacation({
    id: maybeVacationId,
  })

  const { dateSelectorForm, periodInfos, vacationTitleInfos } =
    useMainCreateVacationInputs()

  const [usersIds, dates, vacationId] = useWatch({
    control,
    name: ['create.users_ids', 'create.dates', 'create.id'],
  })

  const handleCreateVacation = async () => {
    const values = getValues()
    const isValid = await trigger('create')

    if (!isValid) {
      console.log('formErrors', formErrors)
      return
    }

    if (maybeVacationId) {
      await handleUpdateVacationMutation({
        id: values.create.id || '',
        comeback_date: dayjs(values.create.reintegration_date[0])
          .startOf('day')
          .toISOString(),
        users_ids: values.create.users_ids,
        communication_date: dayjs(values.create.communication_date[0])
          .startOf('day')
          .toISOString(),
        dates: values.create.dates.map((d) => dayjs(d).toISOString()),
        name: values.create.name,
        pay_within_payroll: !!values.create.should_pay_in_payroll,
        payment_date: dayjs(values.create.payment_date[0])
          .startOf('day')
          .toISOString(),
        period: +values.create.period,
        description: values.create.description,
      })
    } else {
      await handleCreateVacationMutation({
        comeback_date: dayjs(values.create.reintegration_date[0])
          .startOf('day')
          .toISOString(),
        users_ids: values.create.users_ids,
        communication_date: dayjs(values.create.communication_date[0])
          .startOf('day')
          .toISOString(),
        dates: values.create.dates.map((d) =>
          dayjs(d).startOf('day').toISOString(),
        ),
        name: values.create.name,
        pay_within_payroll: !!values.create.should_pay_in_payroll,
        payment_date: dayjs(values.create.payment_date[0])
          .startOf('day')
          .toISOString(),
        period: +values.create.period,
        description: values.create.description,
      })
    }

    addToast({
      title: canRequest
        ? 'Vacaciones solicitadas'
        : maybeVacationId
        ? 'Vacaciones actualizadas'
        : 'Vacaciones creadas',
      description: canRequest
        ? 'Las vacaciones se solicitaron correctamente'
        : maybeVacationId
        ? 'Las vacaciones se actualizaron correctamente'
        : 'Las vacaciones se crearon correctamente',
      type: 'positive',
      id: Date.now(),
    })

    if (!maybeVacationId) {
      removeTab('vacations', 'create')
      setActiveTab('vacations', 'list')
    }
  }

  useEffect(() => {
    setActions('vacations', [
      {
        label: canRequest
          ? 'Solicitar'
          : maybeVacationId
          ? 'Actualizar'
          : 'Crear',
        icon: <PlusIcon />,
        action: handleCreateVacation,
        variant: 'primary' as const,
        isLoading: isLoadingCreateVacations || isLoadingUpdateVacations,
        disabled: !usersIds?.length || !dates?.length,
        disableMessage: !dates?.length
          ? 'Debe seleccionar al menos una fecha'
          : !usersIds?.length
          ? 'Debe seleccionar al menos un colaborador'
          : undefined,
      },
    ])
  }, [
    canRequest,
    usersIds,
    isLoadingCreateVacations,
    isLoadingUpdateVacations,
    dates,
    setActions,
  ])

  useEffect(() => {
    if (!vacation) {
      return
    }

    const {
      id,
      name,
      description,
      dates,
      period,
      pay_within_payroll: payWithinPayroll,
      payment_date: paymentDate,
      users,
      comeback_date: comebackDate,
      communication_date: communicationDate,
    } = vacation

    const usersIds = users.map((u) => u.user_id)

    const values: VacationFormType['create'] = {
      id,
      name,
      description: description || undefined,
      // dates: dates.map((d) => dayjs(d.date).utc().toDate()) as [
      dates: dates.map((d) => getUtcDateFromString(d.date)) as [
        Date,
        ...Date[],
      ],
      period: period.toString(),
      should_pay_in_payroll: payWithinPayroll,
      payment_date: [getUtcDateFromString(paymentDate)],
      users_ids: usersIds,
      communication_date: [getUtcDateFromString(communicationDate)],
      reintegration_date: [getUtcDateFromString(comebackDate)],
    }

    setValue('create', values)
  }, [vacation])

  if (maybeVacationId && (isLoadingVacation || !vacationId)) {
    return (
      <Div
        css={{
          display: 'flex',
          justifyContent: 'center',
          // alignItems: 'center',
          paddingTop: 32,
          height: '100%',
        }}
      >
        <Progress />
      </Div>
    )
  }

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',

        paddingTop: 24,
        paddingLeft: 24,
        paddingRight: 24,

        height: 'calc(100vh - 80px)',
        overflow: 'hidden',
      }}
    >
      <Div
        css={{
          background: '$interface_light_pure',

          zIndex: 99,

          borderTopLeftRadius: '$md',
          borderTopRightRadius: '$md',
          // minWidth: 300,
          // marginRight: 32,
          // overflowY: 'scroll',
          // height: 'calc(100vh - 80px)',

          display: 'flex',

          // justifyContent: 'space-around',
          // alignItems: 'stretch',
          width: '100%',
        }}
      >
        <Div
          css={{
            padding: '$4',
            maxWidth: 350,
          }}
        >
          <FormGroup items={vacationTitleInfos} />
        </Div>

        <Divider
          css={{
            borderRadius: '$lg',
            width: 1,
            background: '$interface_light_down',
            marginLeft: '$6',
            marginRight: '$6',
          }}
        />
        <Div
          css={{
            maxWidth: 400,

            height: 324,

            padding: '$4',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            // alignItems: 'center',
          }}
        >
          {/* {((maybeVacationId && !isLoadingVacation) || !maybeVacationId) && ( */}
          <FormGroup items={dateSelectorForm} />
          {/* )} */}
        </Div>
        <Divider
          css={{
            borderRadius: '$lg',
            width: 1,
            background: '$interface_light_down',
            marginLeft: '$6',
            marginRight: '$6',
          }}
        />
        <Div
          css={{
            padding: '$4',
            maxWidth: 300,
          }}
        >
          <FormGroup items={periodInfos} />
        </Div>
        <Divider
          css={{
            borderRadius: '$lg',
            width: 1,
            background: '$interface_light_down',
            marginLeft: '$6',
            marginRight: '$6',
          }}
        />
        <SelectedUsersConfig />
      </Div>
      <VacationsStatusCreation />
    </Div>
  )
}

const SelectedUsersConfig = () => {
  const { control, setValue } = useFormContext<VacationFormType>()

  const selectedUsersIds = useWatch({
    control,
    name: 'create.users_ids',
  })
  const debouncedSelectedUsersIds = useDebounce(selectedUsersIds, 500)

  const { data: selectedUserWorkers, isFetching: isFetchingSelectedWorkers } =
    useWorkers({
      policiesIds: [
        ...permissionsArray.filter((p) => p.includes('vacation')),
        'deleted',
      ],
      usersIds: debouncedSelectedUsersIds,
      page: -1,
      allUsers: true,
    })

  const selectedWorkers = selectedUserWorkers?.data?.filter((w) =>
    selectedUsersIds?.includes(w.id),
  )

  const handleUnselectWorker = (userId: string) => {
    const currentUsersIds = selectedUsersIds || []
    const newUsersIds = currentUsersIds.filter((id) => id !== userId)

    setValue('create.users_ids', newUsersIds)
  }

  const reference = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: selectedWorkers?.length || 0,
    getScrollElement: () => reference.current,
    estimateSize: React.useCallback(() => 45, []),
    overscan: 15,
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
  })

  return (
    <Div
      css={{
        display: 'flex',
        paddingLeft: 0,
        marginRight: '$4',
        padding: '$4',
        flex: 1,
        flexDirection: 'column',
        maxHeight: 300,
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '$2',
        }}
      >
        <Text variant={'caption'}>Colaboradores selecionados</Text>
        {isFetchingSelectedWorkers && <Progress />}
      </Div>
      {!selectedWorkers?.length && (
        <Text
          css={{
            color: '$interface_dark_up',
          }}
        >
          Sin colaboradores selecionados
        </Text>
      )}
      <Div
        ref={reference}
        css={{
          overflow: 'scroll',
          height: 300,
        }}
      >
        <Div
          css={{
            // maxWidth: 450,
            // height: `${rowVirtualizer.getTotalSize()}px`,
            // height: 250,
            // height: '100%',
            // overflow: 'scroll',
            position: 'relative',
            width: '100%',
            height: `${rowVirtualizer.getTotalSize()}px`,
            // overflow: 'scroll',

            // display: 'flex',
            // opacity: 1,
            // flexDirection: 'column',
            // alignItems: 'stretch',
          }}
        >
          <Div>
            {rowVirtualizer.getVirtualItems()?.map((virtualRow) => {
              const user = selectedWorkers?.[virtualRow.index]

              if (!user) return null

              return (
                <Div
                  key={user.id}
                  css={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${virtualRow.start}px)`,
                    height: `${virtualRow.size}px`,

                    marginRight: '$2',
                    marginBottom: '$2',
                  }}
                >
                  <Tag variant={'neutral'}>
                    <Div
                      css={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '$2',
                        svg: {
                          color: '$brand_primary_pure',
                        },
                      }}
                    >
                      <Div css={{ minWidth: 16 }}>
                        <Avatar
                          alt="avatar"
                          src={user?.photo_url || ''}
                          height={20}
                          width={20}
                        />
                      </Div>
                      <Text
                        css={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block',
                          color: '$brand_primary_pure',
                          flex: 1,
                        }}
                        variant={'caption'}
                      >
                        {user.name}
                      </Text>
                      <Div css={{ minWidth: 12 }}>
                        <XMarkIcon
                          onClick={() => handleUnselectWorker(user.id)}
                          style={{ cursor: 'pointer', strokeWidth: 3 }}
                          height={12}
                          width={12}
                        />
                      </Div>
                    </Div>
                  </Tag>
                </Div>
              )
            })}
          </Div>
        </Div>
      </Div>
    </Div>
  )
}
