import { GrabIcon } from '@/assets/icons/GrabIcon'
import { Div } from '@/components'
import {
  ArrowPathRoundedSquareIcon,
  Bars3BottomLeftIcon,
  CalendarIcon,
  ChevronDownIcon,
  CurrencyDollarIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
  ServerIcon,
  SunIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { Button, styled, Text, Tooltip } from '@punto-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { PdfBuilderSchemaType } from '../../types'
import { useFormContext, useWatch } from 'react-hook-form'
import { extractVariablesFromText } from '../../pdf-builder'
import { workersPdfData } from './modules-pdf-data/workers'
import { shiftsPdfData } from './modules-pdf-data/shifts'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { capitalizeFirstLetter } from '@/utils/workers/name'
import { movementsPdfData } from './modules-pdf-data/movements'
import { paymentsPdfData } from './modules-pdf-data/payments'
import { vacationsPdfData } from './modules-pdf-data/vacations'
import { liquidationsPdfData } from './modules-pdf-data/liquidations'
import { payrollsPdfData } from './modules-pdf-data/payrolls'
import { systemPdfData } from './modules-pdf-data/system'
import { aguinaldosPdfData } from './modules-pdf-data/aguinaldo'

export interface IContentOption {
  label: string
  value: string
  onDrop?: () => void
}

export interface IContentPdfOption {
  id: string
  icon: React.ReactNode
  label: string
  defaultOpen?: boolean
  options: IContentOption[]
  disableMessage?: string
}

export const DataForm = () => {
  const methods = useFormContext<PdfBuilderSchemaType>()

  const { data: company } = useMyCompany()

  const [allContent, manualVariables] = useWatch({
    control: methods.control,
    name: ['pdf.content', 'manualVariables'],
  })

  const allVariables = useMemo(() => {
    return allContent
      .flatMap((c) => c.data.map((d) => d))
      .filter((c) => c.type === 'text' || c.type === 'signature')
      .flatMap((c) => {
        const variablesInText = extractVariablesFromText(c.text || '')
        const variablesDupped = variablesInText
          .filter((v) => v.type === 'variable')
          .map((v) => v.text.replace('{', '').replace('}', ''))

        const variables = [...(new Set(variablesDupped) as any)]

        return variables as string[]
      })
  }, [allContent])

  const isUsingVacaciones = allVariables.some((v) => v.includes('vacaciones.'))
  const isUsingMovement = allVariables.some((v) => v.includes('movimiento.'))
  const isUsingPayments = allVariables.some((v) => v.includes('pago.'))
  const isUsingPayrolls = allVariables.some((v) => v.includes('nomina.'))
  const isUsingLiquidation = allVariables.some((v) =>
    v.includes('liquidacion.'),
  )

  const isNotUsingOnlyVacations =
    isUsingMovement || isUsingPayments || isUsingPayrolls || isUsingLiquidation
  const isNotUsingOnlyMovements =
    isUsingVacaciones ||
    isUsingPayments ||
    isUsingPayrolls ||
    isUsingLiquidation
  const isNotUsingOnlyPayments =
    isUsingVacaciones ||
    isUsingMovement ||
    isUsingPayrolls ||
    isUsingLiquidation
  const isNotUsingOnlyPayrolls =
    isUsingVacaciones ||
    isUsingMovement ||
    isUsingPayments ||
    isUsingLiquidation
  const isNotUsingOnlyLiquidation =
    isUsingVacaciones || isUsingMovement || isUsingPayments || isUsingPayrolls
  const isNotUsingOnlyAguinaldos =
    isUsingVacaciones || isUsingMovement || isUsingPayments || isUsingPayrolls

  const staticPayrollOptionsWithoutLastElement = [...payrollsPdfData]
  const staticPayrollOptionsLastElement =
    staticPayrollOptionsWithoutLastElement.pop()

  const staticPayrollOptions = [
    ...staticPayrollOptionsWithoutLastElement,
    ...(company?.concepts.map((concept) => ({
      label: `${capitalizeFirstLetter(concept)} (sin IPS)` as string,
      value: `nomina.conceptos.${concept}` as string,
    })) || []),
    ...(company?.concepts.map((concept) => ({
      label: `${capitalizeFirstLetter(concept)} (IPS)` as string,
      value: `nomina.conceptos_ips.${concept}` as string,
    })) || []),
  ]

  if (staticPayrollOptionsLastElement) {
    staticPayrollOptions.push(staticPayrollOptionsLastElement)
  }

  const staticOptions: IContentPdfOption[] = [
    {
      id: 'system',
      label: 'Variables del Sistema',
      icon: <ServerIcon />,
      options: systemPdfData,
    },
    {
      id: 'workers',
      label: 'Colaboradores',
      icon: <UserIcon />,
      options: [
        ...workersPdfData,
        ...(company?.concepts.map((concept) => ({
          label: `Concepto - ${capitalizeFirstLetter(concept)}` as string,
          value: `colaborador.conceptos.${concept}` as string,
        })) || []),
      ],
    },
    {
      id: 'shifts',
      label: 'Turnos',
      icon: <CalendarIcon />,
      options: shiftsPdfData,
    },
    {
      id: 'movements',
      icon: <ArrowPathRoundedSquareIcon />,
      label: 'Movimientos',
      disableMessage: isNotUsingOnlyMovements
        ? 'No se puede usar movimientos con pagos, vacaciones, liquidaciones o nominas'
        : undefined,
      options: movementsPdfData,
    },
    {
      id: 'payments',
      label: 'Pagos',
      icon: <CurrencyDollarIcon />,
      disableMessage: isNotUsingOnlyPayments
        ? 'No se puede usar pagos con movimientos, vacaciones, liquidaciones o nominas'
        : undefined,
      options: paymentsPdfData,
    },
    {
      id: 'vacations',
      label: 'Vacaciones',
      disableMessage: isNotUsingOnlyVacations
        ? 'No se puede usar vacaciones con movimientos, pagos, liquidaciones o nominas'
        : undefined,
      icon: <SunIcon />,
      options: vacationsPdfData,
    },
    {
      id: 'liquidations',
      label: 'Liquidaciones',
      icon: <UserIcon />,
      disableMessage: isNotUsingOnlyLiquidation
        ? 'No se puede usar liquidaciones con movimientos, pagos o vacaciones'
        : undefined,
      options: [
        ...liquidationsPdfData,
        ...(company?.concepts.map((concept) => ({
          label: `${capitalizeFirstLetter(
            concept,
          )} (Concepto de Salario)` as string,
          value: `liquidacion.conceptos.${concept}` as string,
        })) || []),
        ...(company?.liquidationConcepts.map((concept) => ({
          label: `${capitalizeFirstLetter(
            concept.name,
          )} (Concepto de Liquidación)` as string,
          value: `liquidacion.conceptos_ips.${concept.name}` as string,
        })) || []),
      ],
    },
    {
      id: 'payroll',
      label: 'Nomina',
      icon: <UserIcon />,
      disableMessage: isNotUsingOnlyPayrolls
        ? 'No se puede usar nominas con movimientos, pagos, vacaciones o liquidaciones'
        : undefined,
      options: [...staticPayrollOptions],
    },
    {
      id: 'aguinaldo',
      label: 'Aguinaldo',
      icon: <UserIcon />,
      disableMessage: isNotUsingOnlyAguinaldos
        ? 'No se puede usar nominas con movimientos, pagos, vacaciones o liquidaciones'
        : undefined,
      options: aguinaldosPdfData,
    },
  ]

  const allCustomVariables: string[] = allContent
    .flatMap((c) => c.data.map((d) => d))
    .filter((c) => c.type === 'text' || c.type === 'signature')
    .flatMap((c) => {
      const variablesInText = extractVariablesFromText(c.text || '')
      const variablesDupped = variablesInText
        .filter((v) => v.type === 'variable')
        .map((v) => v.text.replace('{', '').replace('}', ''))

      const notStaticVariables = variablesDupped.filter(
        (v) =>
          !staticOptions
            .flatMap((o) => o.options.map((oo) => oo.value))
            .includes(v.replace(':extenso', '')),
      )

      const variables = [...(new Set(notStaticVariables) as any)]

      return variables
    })

  const customVariablesOptions = [...(new Set(allCustomVariables) as any)].map(
    (v) => ({
      label: v,
      value: v,
      onDrop: () => {
        console.log('hey')
      },
    }),
  )

  manualVariables.forEach((v) => {
    if (
      !customVariablesOptions.find((c) => c.value === v) &&
      !staticOptions.find((c) => c.options.find((o) => o.value === v))
    ) {
      customVariablesOptions.push({
        label: v,
        value: v,
        onDrop: () => {
          console.log('hey')
        },
      })
    }
  })

  const customVariables: IContentPdfOption = {
    id: 'custom-variables',
    label: 'Variables Personalizadas',
    icon: <UserIcon />,
    defaultOpen: true,
    options: customVariablesOptions,
  }

  const contentOptions = [customVariables, ...staticOptions]

  return (
    <Div>
      <ContentSearchBar />
      <AddVariableBar />
      {contentOptions.map((content) => (
        <ContentList key={content.id} content={content} />
      ))}
    </Div>
  )
}

const RawInput = styled('input', {
  all: 'unset',

  fontFamily: '$default',
  fontSize: '12px',
  lineHeight: '18px',
})

const ContentSearchBar = () => {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: '$2',

        height: 34,
        background: '$interface_light_up',

        paddingLeft: '$2',
        paddingRight: '$2',

        '> svg': {
          color: '$interface_dark_down',
          height: 16,
          width: 16,
        },

        border: '1px solid $interface_light_deep',
      }}
    >
      <MagnifyingGlassIcon />
      <RawInput placeholder="Buscar datos" css={{}} />
    </Div>
  )
}

const AddVariableBar = () => {
  const [variableName, setVariableName] = useState('')
  const methods = useFormContext<PdfBuilderSchemaType>()
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: '$2',

        height: 34,
        background: '$interface_light_up',

        paddingLeft: '$2',
        paddingRight: '$2',

        '> svg': {
          color: '$interface_dark_down',
          height: 16,
          width: 16,
        },

        border: '1px solid $interface_light_deep',
      }}
    >
      <PlusCircleIcon />
      <RawInput
        placeholder="Agregar Variable Personalizada"
        css={{
          flex: 1,
        }}
        value={variableName}
        onChange={(e) => {
          setVariableName(e.target.value)
        }}
      />
      <Button
        onClick={() => {
          if (!variableName) return

          const manualVariables = methods.getValues('manualVariables')
          const variableInIt = manualVariables.find((v) => v === variableName)

          if (variableInIt) return

          methods.setValue('manualVariables', [
            ...manualVariables,
            variableName,
          ])
        }}
        variant="tertiary"
        css={{
          color: '$interface_dark_down',
        }}
      >
        Agregar
      </Button>
    </Div>
  )
}

const ContentList = ({ content }: { content: IContentPdfOption }) => {
  const [isOpen, setIsOpen] = useState(content.defaultOpen || false)

  return (
    <Div>
      <Div
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        css={{
          opacity: content.disableMessage ? 0.5 : 1,

          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          height: 34,
          background: '$interface_light_up',

          paddingLeft: '$2',
          paddingRight: '$2',

          border: '1px solid $interface_light_deep',
        }}
      >
        <Div
          css={{
            '> svg': {
              color: '$interface_dark_down',
              height: 16,
              width: 16,
            },

            display: 'flex',
            alignItems: 'center',
            gap: '$2',
          }}
        >
          <Bars3BottomLeftIcon />
          <Text
            variant="caption"
            css={{
              color: '$interface_dark_down',
            }}
          >
            {content.label}
          </Text>
        </Div>
        <Div
          css={{
            '> svg': {
              color: '$interface_dark_down',
              height: 16,
              width: 16,
              strokeWidth: 2,
            },

            display: 'flex',
            alignItems: 'center',

            transition: 'transform 0.2s',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <ChevronDownIcon />
        </Div>
      </Div>
      <Div
        css={{
          transition: 'grid-template-rows 500ms',
          gridTemplateRows: isOpen ? '1fr' : '0fr',
          display: 'grid',
        }}
      >
        <Div
          css={{
            overflow: 'hidden',
          }}
        >
          {content.options.map((o) => {
            return (
              <ContentOption
                key={o.value}
                option={o}
                disableMessage={content.disableMessage}
              />
            )
          })}
        </Div>
      </Div>
    </Div>
  )
}

const ContentOption = ({
  option,
  disableMessage,
}: {
  disableMessage?: string
  option: IContentOption
}) => {
  const optionRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!optionRef.current) return

    return draggable({
      element: optionRef.current,
      canDrag: () => !disableMessage,
      onDrag: ({ source }) => {
        optionRef.current?.style.setProperty('opacity', '0.2')
        // console.log('dragging')
      },
      onDrop: ({ source }) => {
        optionRef.current?.style.setProperty('opacity', '1')
        option.onDrop?.()
      },
      getInitialData: () => {
        return {
          optionType: option.value,
          type: 'pdf-builder-data',
        }
      },
    })
  }, [disableMessage, option])

  return (
    <Tooltip message={disableMessage} arrow>
      <Div
        ref={optionRef}
        css={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          height: 34,
          background: '$interface_light_pure',

          paddingLeft: '$2',
          paddingRight: '$2',

          border: '1px solid $interface_light_deep',

          opacity: disableMessage ? 0.5 : 1,
        }}
      >
        <Div
          css={{
            '> svg': {
              color: '$interface_dark_down',
              height: 16,
              width: 16,
            },

            display: 'flex',
            alignItems: 'center',
            gap: '$2',
          }}
        >
          <GrabIcon />
          <Text
            variant="caption"
            css={{
              color: '$interface_dark_down',
            }}
          >
            {option.label}
          </Text>
        </Div>
      </Div>
    </Tooltip>
  )
}
