import { AddShiftFields } from '../form'

export const useFixedShiftConfigurations = () => {
  const data: AddShiftFields = [
    {
      _type: 'checkbox',
      label: 'Banco de intervalos',
      name: 'config.should_consider_interval_credits',
    },
    {
      _type: 'checkbox',
      label: 'Ignorar intervalos incompletos',
      name: 'config.ignore_incomplete_punches',
    },
  ]

  return {
    data,
  }
}
