import { Div, ModernTextInput } from '@/components'
import {
  ControlledDropdown,
  ControlledInput,
  ControlledSwitch,
} from '@/components/Forms'
import { CheckBadgeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@punto-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  notificationsReferenceDatesOptionsByModule,
  notificationTimeBeforeReferenceDateOptions,
} from '../notifications'
import {
  NotificationTableCell,
  NotificationTableRow,
} from '../NotificationTable.styles'
import { NotificationForm, NotificationFormType } from './notification-form'
import { INotificationsSchedules } from '@/libs/react-query/types/notifications'
import { useHandleUpdateNotifications } from '@/libs/react-query/mutations/notifications/useHandleUpdateNotification'

export const EditNotificationRow = ({
  notification,
  closeEditNotification,
}: {
  notification: INotificationsSchedules
  closeEditNotification: () => void
}) => {
  const {
    mutateAsync: handleUpdateNotification,
    isLoading: isLoadingUpdateNotification,
  } = useHandleUpdateNotifications()

  const methods = useForm<NotificationFormType>({
    resolver: zodResolver(NotificationForm),
    defaultValues: {
      name: notification.name,
      notification_hour: notification.notification_hour,
      notification_time_before_reference_date:
        notification.notification_time_before_reference_date,
      reference_date: notification.reference_date,
      is_active: notification.is_active,
    },
  })

  const handleSubmit = methods.handleSubmit(async (data) => {
    await handleUpdateNotification({
      id: notification.id,
      name: data.name,
      notification_hour: data.notification_hour,
      notification_time_before_reference_date:
        data.notification_time_before_reference_date,
      reference_date: data.reference_date,
      is_active: !!data.is_active,
      module: notification.module,
    })

    closeEditNotification()
  })

  const referenceDatesOptions =
    notificationsReferenceDatesOptionsByModule[notification.module]

  return (
    <FormProvider {...methods}>
      <NotificationTableRow
        css={{
          background: 'transparent',

          '&:hover': {
            background: '$interface_light_down',
          },
        }}
      >
        <NotificationTableCell
          css={{
            paddingLeft: '64px',
          }}
        >
          <Div css={{ display: 'flex', alignItems: 'center' }}>
            <ControlledInput
              name="name"
              placeholder="Mi notificación"
              InputComponent={ModernTextInput}
            />
          </Div>
        </NotificationTableCell>
        <NotificationTableCell align="center" valign="middle">
          <Div
            css={{
              display: 'flex',
              maxWidth: 200,
              justifyContent: 'center',
            }}
          >
            <ControlledDropdown
              name="notification_time_before_reference_date"
              InputComponent={ModernTextInput}
              options={notificationTimeBeforeReferenceDateOptions}
            />
          </Div>
        </NotificationTableCell>
        <NotificationTableCell align="center" valign="middle">
          <Div css={{ maxWidth: 200 }}>
            <ControlledDropdown
              name="reference_date"
              InputComponent={ModernTextInput}
              options={referenceDatesOptions}
            />
          </Div>
        </NotificationTableCell>
        <NotificationTableCell align="center" valign="middle">
          <Div css={{ maxWidth: 120 }}>
            <ControlledInput
              name="notification_hour"
              inputType="hour"
              InputComponent={ModernTextInput}
              placeholder="12:00"
              css={{
                textAlign: 'center',
              }}
            />
          </Div>
        </NotificationTableCell>
        <NotificationTableCell align="center" valign="middle">
          <Div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ControlledSwitch name="is_active" />
          </Div>
        </NotificationTableCell>
        <NotificationTableCell align="right" valign="middle">
          <Div
            css={{
              paddingRight: '$4',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '$2',
            }}
          >
            <Button
              variant="tertiaryCritical"
              size="sm"
              onClick={closeEditNotification}
            >
              <XMarkIcon />
              Cancelar
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={handleSubmit}
              isLoading={isLoadingUpdateNotification}
            >
              <CheckBadgeIcon />
              Editar
            </Button>
          </Div>
        </NotificationTableCell>
      </NotificationTableRow>
    </FormProvider>
  )
}
