import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import { ControlledInput, Div } from '@/components'
import { PolicyBuilder } from './components'
import { PolicyBuilderProvider } from './components/PolicyBuilder/context'
import { ICreateCompletePolicyData } from './components/PolicyBuilder/types'
import { LoadingLayer } from './components/PolicyBuilder/components'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import React, { useMemo, useState } from 'react'
import { FieldPath, useFormContext, useWatch } from 'react-hook-form'
import {
  ControlledFullWorkerSearchBar,
  ControlledSwitch,
} from '@/components/Forms'
import { useHandlePermissionCreationActions } from './actions'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
import { Stepper, StepProps } from '@/components/Stepper'

const CreatePermissionContent = () => {
  const [step, setStep] = useState(0)

  const { data: isRequestAvailable, detail: requestFeatureDetail } =
    useIsFeatureInPlan('requests')

  const [selectedModule, setSelectedModule] =
    useState<FieldPath<ICreateCompletePolicyData>>('workers')

  const methods = useFormContext<ICreateCompletePolicyData>()

  const [usersIds, hasAuthorizations, usersToAuthorize] = useWatch({
    control: methods.control,
    name: ['users', 'hasAuthorizations', 'usersToAuthorize'],
  })

  useHandlePermissionCreationActions()

  return (
    <Div
      css={{
        margin: '$5',
        background: '$interface_light_pure',
        padding: '$5',
        borderRadius: '$lg',

        maxHeight: 'calc(100vh - 100px)',

        overflowY: 'scroll',
        overflowX: 'hidden',

        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Div
        css={{
          marginBottom: '$4',
        }}
      >
        <PermissionsSteppers step={step} setStep={setStep} />
      </Div>
      {step === 0 && (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',

            marginRight: '$8',
            maxWidth: '100%',
            minWidth: '100%',
          }}
        >
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'space-between',

              marginBottom: '$8',
            }}
          >
            <ControlledInput label={'Nombre de la Politica'} name={'name'} />
            <Div css={{ marginRight: '$4' }} />
            {/* <SearchableSelect
              label="Modulo"
              // options={}
              options={systemModuleLabelToValue}
              defaultValue="Colaboradores"
              onChangeValue={(e) => {
                const toValue = systemModuleLabelToValue.find(
                  (x) => x.value === e.value,
                )

                if (!toValue) return

                setSelectedModule(
                  toValue.value as FieldPath<ICreateCompletePolicyData>,
                )
              }}
            /> */}
          </Div>
          <Div>
            <ControlledFullWorkerSearchBar
              css={{
                maxHeight: 'calc(50vh)',
                zIndex: 0,
              }}
              name={'users'}
              policiesIds={permissionsArray.filter((p) =>
                p.includes('permisos'),
              )}
              label="Membros de la Politica"
              usersIds={usersIds}
            />
          </Div>
          <Div css={{ marginTop: '$4', marginBottom: '$4' }}>
            <ControlledSwitch
              disabled={!isRequestAvailable}
              help={requestFeatureDetail}
              name={'hasAuthorizations'}
              label="Politica tiene solicitudes?"
            />
          </Div>
          {hasAuthorizations && (
            <Div>
              <ControlledFullWorkerSearchBar
                css={{
                  maxHeight: 'calc(30vh)',
                  zIndex: 0,
                }}
                name={'usersToAuthorize'}
                policiesIds={permissionsArray.filter((p) =>
                  p.includes('permisos'),
                )}
                label="Autorizadores de la politica"
                usersIds={usersToAuthorize}
              />
            </Div>
          )}
        </Div>
      )}
      {step === 1 && (
        <Div
          css={{
            display: 'flex',
            overflow: 'hidden',
          }}
        >
          <PolicyBuilder
            systemModule={selectedModule}
            setSelectedModule={setSelectedModule as any}
          />
        </Div>
      )}
    </Div>
  )
}

export default function CreatePermissionV2({ id }: { id?: string }) {
  return (
    <PolicyBuilderProvider id={id}>
      <LoadingLayer />
      <CreatePermissionContent />
    </PolicyBuilderProvider>
  )
}

const PermissionsSteppers = ({
  step,
  setStep,
}: {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
}) => {
  const stepsArray = useMemo(() => {
    const setFormStep = (index: number) => setStep(index)

    return [
      {
        setFormStep,
        text: '1 Datos Basicos',
        selectedIndex: step,
        index: 0,
        error: false,
      },
      {
        setFormStep,
        text: '2 Permisos',
        selectedIndex: step,
        disabled: false,
        index: 1,
        error: false,
      },
    ] as StepProps[]
  }, [step])

  return <Stepper stepsArray={stepsArray} />
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
