import { ICompanyBankAccount } from '@/libs/react-query/types/company'
import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'

const fetchCompanyBankAccounts = async () => {
  try {
    const response = await apiV2.get<{ bankAccounts: ICompanyBankAccount[] }>(
      '/company/get-company-bank-account',
    )

    return response.data
  } catch (err) {
    return {
      bankAccounts: [],
    }
  }
}

export const useCompanyBankAccounts = () => {
  const query = useQuery(
    ['company-bank-accounts'],
    async () => {
      const workers = await fetchCompanyBankAccounts()

      return workers
    },
    {
      keepPreviousData: true,
    },
  )

  return query
}
