import { ControlledFilterBar, Div } from '@/components'
import { useSmartShifts } from '@/libs/react-query/hooks'
import { Menu, styled, Tag, TagProps, Text } from '@punto-ui/react'
import { SmartShift } from '../components/SmartShiftManagement/types'
import React, { useMemo, useRef } from 'react'
import { useTabStore } from '@/store'
import { useHandleDuplicateShift } from '@/libs/react-query/mutations/shifts/userHandleDuplicate'
import { useCan, useDebounce } from '@/hooks'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { CloudIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import { getWorkHours } from '@/utils/shifts/getWorkHours'
import { formatMinutes } from '@/utils/date'

type ShiftListFilterProps = {
  name: string
}

export const ShiftListV2 = () => {
  const methods = useForm<ShiftListFilterProps>({
    defaultValues: {
      name: '',
    },
  })
  const { isFetching: isFetchingShifts } = useSmartShifts()

  return (
    <FormProvider {...methods}>
      <Div>
        <ControlledFilterBar
          nameFilterName="name"
          nameFilterPlaceholder="Buscar turno..."
          policiesIds={[]}
          isLoading={isFetchingShifts}
        />
        <Div
          css={{
            padding: '$4',
          }}
        >
          <ShiftListingTable />
        </Div>
      </Div>
    </FormProvider>
  )
}

const ShiftListingTable = () => {
  const { data: shifts } = useSmartShifts()

  const methods = useFormContext<ShiftListFilterProps>()

  const name = useWatch({
    control: methods.control,
    name: 'name',
  })

  const debouncedName = useDebounce(name, 500)

  const filteredShifts = useMemo(() => {
    if (!debouncedName) return shifts
    return shifts?.filter((shift) =>
      shift.shiftName.toLowerCase().includes(debouncedName.toLowerCase()),
    )
  }, [debouncedName, shifts])

  return (
    <Div
      css={{
        maxHeight: 'calc(100vh - 128px)',
        overflow: 'auto',
        borderRadius: '$lg',
        border: '1px solid',
        borderColor: '$interface_light_down',
      }}
    >
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',

          backgroundColor: '$interface_light_pure',
          borderRadius: '$lg',

          minWidth: 1200,
        }}
      >
        <Table>
          <TableHead
            css={{
              position: 'sticky',
              background: '$interface_light_pure',
              top: 0,
            }}
          >
            <TableRow>
              <TableCell
                css={{
                  paddingLeft: '$4',
                }}
              >
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                  }}
                >
                  Titulo
                </Text>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                    textAlign: 'center',
                  }}
                >
                  Tipo
                </Text>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                  }}
                >
                  Total
                </Text>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                  }}
                >
                  Diurnas
                </Text>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                    textAlign: 'center',
                  }}
                >
                  Nocturnas
                </Text>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                    textAlign: 'center',
                  }}
                >
                  Mixtas
                </Text>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                    textAlign: 'center',
                  }}
                >
                  Intervalo
                </Text>
              </TableCell>
              <TableCell
                align="center"
                valign="middle"
                css={{
                  borderLeft: 'solid 1px',
                  borderColor: '$interface_light_down',
                }}
              >
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                  }}
                >
                  Regimen
                </Text>
              </TableCell>
              <TableCell align="right" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                    paddingRight: '$4',
                  }}
                >
                  Opciones
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredShifts?.map((shift, index) => (
              <ShiftListRow index={index} shift={shift} key={shift.shiftId} />
            ))}
          </TableBody>
        </Table>
      </Div>
    </Div>
  )
}

const ShiftListRow = ({
  shift,
  index,
}: {
  shift: SmartShift
  index: number
}) => {
  const isFlexible = shift.config.is_flexible_journey
  const dailyType = shift.daily_type

  const dailyTypeLabel =
    dailyType === 'morning'
      ? 'Diurno'
      : dailyType === 'night'
      ? 'Nocturno'
      : 'Mixto'

  const dailyTagType: TagProps['variant'] =
    dailyType === 'morning'
      ? 'warning'
      : dailyType === 'night'
      ? 'neutral'
      : 'positive'

  const dailyTagIcon =
    dailyType === 'morning' ? (
      <SunIcon />
    ) : dailyType === 'night' ? (
      <MoonIcon />
    ) : (
      <CloudIcon />
    )

  const firstWorkingDay = shift.originalDays.find(
    (d) => d.startTime !== d.endTime,
  )

  const startTime = firstWorkingDay?.startTime
  const endTime = firstWorkingDay?.endTime

  const totalIntervalDuration = firstWorkingDay?.intervals.reduce(
    (acc, day) => {
      return acc + day.maxMinutes
    },
    0,
  )

  const { dayHours, nightHours, total } =
    startTime && endTime
      ? getWorkHours({
          entrance: startTime,
          exit: endTime,
          intervals: (firstWorkingDay?.intervals || []).map((interval) => ({
            endTime: interval.end_time,
            startTime: interval.start_time,
            duration: formatMinutes(interval.maxMinutes),
          })),
          fromDayToNight: '20:00',
          fromNightToDay: '06:00',
        })
      : { dayHours: 0, nightHours: 0, total: 0 }

  const flexibleMorningHours = shift.config.flexible_morning_hours
  const flexibleNightHours = shift.config.flexible_night_hours
  const flexibleMixedHours = shift.config.flexible_mixed_hours

  const morningLabelHours =
    isFlexible && +flexibleMorningHours
      ? formatMinutes(+flexibleMorningHours * 60)
      : isFlexible
      ? '-'
      : formatMinutes(dayHours * 60)

  const nightLabelHours =
    isFlexible && +flexibleNightHours
      ? formatMinutes(+flexibleNightHours * 60)
      : isFlexible
      ? '-'
      : formatMinutes(nightHours * 60)

  const mixedLabelHours =
    isFlexible && +flexibleMixedHours
      ? formatMinutes(+flexibleMixedHours * 60)
      : '-'

  const totalLabelHours =
    isFlexible && +flexibleMorningHours
      ? formatMinutes(+flexibleMorningHours * 60)
      : isFlexible && +flexibleMixedHours
      ? formatMinutes(+flexibleMixedHours * 60)
      : isFlexible && +flexibleNightHours
      ? formatMinutes(+flexibleNightHours * 60)
      : formatMinutes(total * 60)

  return (
    <TableRow
      css={{
        // hover
        background: index % 2 === 0 ? '$interface_light_up' : 'transparent',

        '&:hover': {
          background: '$interface_light_down',
        },
      }}
    >
      <TableCell
        css={{
          paddingLeft: '$4',
        }}
      >
        <Div css={{ display: 'flex', alignItems: 'center', gap: '$2' }}>
          <Text variant="caption" css={{ color: '$interface_dark_down' }}>
            {shift.shiftName}
          </Text>
          <Tag>{shift.shiftCode}</Tag>
        </Div>
      </TableCell>
      <TableCell align="center" valign="middle">
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Tag variant={dailyTagType}>
            {dailyTagIcon} {dailyTypeLabel}
          </Tag>
        </Div>
      </TableCell>
      <TableCell align="center" valign="middle">
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {totalLabelHours}
        </Text>
      </TableCell>
      <TableCell align="center" valign="middle">
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {morningLabelHours}
        </Text>
      </TableCell>
      <TableCell align="center" valign="middle">
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {nightLabelHours}
        </Text>
      </TableCell>
      <TableCell align="center" valign="middle">
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {mixedLabelHours}
        </Text>
      </TableCell>
      <TableCell align="center" valign="middle">
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {formatMinutes(totalIntervalDuration || 0)}
        </Text>
      </TableCell>
      <TableCell
        align="center"
        valign="middle"
        css={{
          borderLeft: 'solid 1px',
          borderColor: '$interface_light_down',
        }}
      >
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {isFlexible ? 'Turno Flexible' : 'Turno Fijo'}
        </Text>
      </TableCell>
      <TableCell align="right" valign="middle">
        <Div css={{ paddingRight: '$4' }}>
          <DropdownMenu shift={shift} id={shift.shiftId} />
        </Div>
      </TableCell>
    </TableRow>
  )
}

const DropdownMenu = ({ id, shift }: { id: string; shift: SmartShift }) => {
  const canCreateShift = useCan(['shifts.create'])
  const canEditShift = useCan(['shifts.edit'])

  const { mutateAsync: handleDuplicateShift } = useHandleDuplicateShift()

  const { setActiveTab, addTab } = useTabStore((state) => ({
    addTab: state.addTab,
    setActiveTab: state.setActiveTab,
  }))

  const remove = useRef<any>(null)

  React.useEffect(() => {
    return () => {
      clearTimeout(remove.current)
    }
  }, [])

  return (
    <Menu
      items={[
        {
          id: '1',
          disabled: !canEditShift,
          label: 'Editar',
          onClick: () => {
            addTab('shifts', [
              {
                id,
                type: 'edit',
                label: shift.shiftName,
              },
            ])
            remove.current = setTimeout(() => {
              setActiveTab('shifts', id)
            }, 1)
          },
        },
        // {
        //   id: '2',
        //   label: 'Ver Detalles',
        //   onClick: () => console.log('Detalles'),
        // },
        {
          id: '3',
          label: 'Duplicar',
          disabled: !canCreateShift && !canEditShift,
          onClick: async () => {
            await handleDuplicateShift(id)
          },
        },
        // {
        //   id: '4',
        //   label: 'Exportar Turno',
        //   onClick: () => console.log('Exportar'),
        // },
        // {
        //   id: '5',
        //   label: 'Arquivar',
        //   onClick: () => console.log('Arquivar'),
        // },
        // {
        //   id: '6',
        //   label: 'Excluir',
        //   onClick: () => console.log('Excluir'),
        // },
      ]}
    />
  )
}

const Table = styled('table', {
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: 0,
})
const TableHead = styled('thead')
const TableBody = styled('tbody')
const TableRow = styled('tr', {
  height: 40,
})
const TableCell = styled('td', {
  height: 40,
})
