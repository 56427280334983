import { useReportTemplate } from '@/libs/react-query/hooks/useReportTemplate'
import {
  fromReportTypeAndRowTypesToColumns,
  fromRowTypeToLabel,
} from '@/pages/reportes-v2/ReportBuilder/report-builder'
import {
  IReportBuilderRowTypes,
  IReportBuilderTypes,
} from '@/pages/reportes-v2/ReportBuilder/type'
import { useMemo } from 'react'

export const useColumnOptions = (props: { templateId: string }) => {
  const { data: template } = useReportTemplate(props.templateId)

  const columns = useMemo(() => {
    if (!template?.type && !template?.columns) {
      return []
    }

    const row = {
      detailSelectedKeys: [],
      detailKey: template.rowDetails,
      type: template.row as IReportBuilderRowTypes,
    }

    const newColumns = fromReportTypeAndRowTypesToColumns(
      template?.type as IReportBuilderTypes,
      [row],
      [],
      template?.columns || [],
    )

    const columnsArray = Object.values(newColumns).flatMap((key) => key)

    if (template.type === 'single' && template.row === 'month') {
      columnsArray.unshift({
        label: 'Mes',
        column_type: 'key',
        valueType: 'string',
        value: 'key',
        selected: true,
        disabled: false,
      })
    }
    if (template.type === 'single' && template.row === 'day') {
      columnsArray.unshift({
        label: 'Día',
        column_type: 'key',
        valueType: 'date',
        value: 'key',
        selected: true,
        disabled: false,
      })
    }

    return columnsArray
  }, [template])

  const columnsOptions = useMemo(() => {
    return columns
      .filter((s) => s.selected)
      .map((c) => ({
        label: `${c.label} ${
          fromRowTypeToLabel[c.column_type as IReportBuilderRowTypes] ? '-' : ''
        } ${fromRowTypeToLabel[c.column_type as IReportBuilderRowTypes] || ''}`,
        value: `${c.column_type}.${c.value}`,
        type: c.valueType,
      }))
  }, [columns])

  return {
    columnsOptions,
    columns,
  }
}
