import { IContentOption } from '../DataForm'

export const aguinaldosPdfData: IContentOption[] = [
  {
    label: 'Subtotal Aguinaldo',
    value: 'aguinaldo.subtotal',
  },
  {
    label: 'Anticipo de Aguinaldo',
    value: 'aguinaldo.anticipo',
  },
  {
    label: 'Total a Pagar',
    value: 'aguinaldo.total',
  },
]
