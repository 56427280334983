import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'
import { useMe } from '../../useMe'
import { useUserPermissions } from '../../useUserPermissions'
import dayjs from 'dayjs'

const fetchUnreadNotificationsCount = async ({
  date,
  hour,
}: {
  date?: string
  hour?: string
}) => {
  const response = await apiV2.get<number>('/notifications/unread-count', {
    params: { date, hour },
  })

  return response.data
}

export const useUnreadNotificationsCount = () => {
  const { data: me } = useMe()
  const { data: permissions } = useUserPermissions()

  const defaultEnabled =
    !!me &&
    !!me.company_id &&
    !!permissions?.some((p) => p.includes('notifications'))

  const query = useQuery(
    ['notifications', 'count', me?.company_id],
    async () => {
      const notifications = await fetchUnreadNotificationsCount({
        date: dayjs().format('YYYY-MM-DD'),
        hour: dayjs().format('HH:mm'),
      })

      return notifications
    },
    {
      keepPreviousData: true,
      enabled: defaultEnabled,
      refetchInterval: 4 * 60 * 1000, // 4 minutes,
    },
  )

  return query
}
