import { Button, Text } from '@punto-ui/react'
import { useAllowedPunch, useAppConfig, useCompanyAllowedPunch } from './form'
import { FormGroup } from '@/components/Forms/FormGroup'
import { Div, Drawer, DrawerContainer } from '@/components'
import { ConfigSchema } from '../../..'
import { useFormContext, useWatch } from 'react-hook-form'
import { useHandleCreateCompanyConfig } from '@/libs/react-query/mutations/company/useHandleCreateCompanyConfig'
import { queryClient } from '@/libs/react-query'
import { useHandleUpdateCompanyConfig } from '@/libs/react-query/mutations/company'
import { useCan } from '@/hooks'

interface PaymentConfigDrawerProps {
  isOpen: boolean
  handleCloseDrawer(): void
  isCreation?: boolean
}

const MAX_DRAWER_WIDTH = '70vw'

export const PunchesConfigDrawer = ({
  handleCloseDrawer,
  isCreation,
  isOpen,
}: PaymentConfigDrawerProps) => {
  const canCreatePunches = useCan(['configurations.punches'])
  const { data: allowedPunch } = useAllowedPunch()
  const { data: companyAllowedPunch } = useCompanyAllowedPunch()
  const { data: appConfigForm } = useAppConfig()

  const {
    getValues,
    trigger,
    formState: { errors },
    control,
  } = useFormContext<ConfigSchema>()

  const places = useWatch({
    control,
    name: 'punches.placesNames',
  })

  const {
    mutateAsync: handleCreateCompanyConfig,
    isLoading: isLoadingCreation,
  } = useHandleCreateCompanyConfig()
  const { mutateAsync: handleUpdateCompanyConfig, isLoading: isLoadingUpdate } =
    useHandleUpdateCompanyConfig()

  const handleCreatePunchConfig = async () => {
    const isValid = await trigger('punches')

    if (!isValid) {
      console.log(errors)
      return
    }

    const data = getValues()

    if (isCreation) {
      await handleCreateCompanyConfig({
        punches: {
          ...data.punches,
          companyPlaceIds: data.punches.companyPlaceIds || [],
        },
      })

      queryClient.invalidateQueries('my-company')
    } else {
      await handleUpdateCompanyConfig({
        punches: {
          ...data.punches,
          companyPlaceIds: data.punches.companyPlaceIds || [],
          id: data.punches.id || '',
        },
      })
    }

    handleCloseDrawer()
  }

  return (
    <DrawerContainer open={isOpen} onOpenChange={handleCloseDrawer}>
      <Drawer
        onOpenChange={handleCloseDrawer}
        content={
          <Div
            css={{
              overflow: 'scroll',
              maxHeight: '100vh',
              paddingBottom: 32,
              maxWidth: '40vw',
            }}
          >
            <FormGroup
              type="fixed"
              title="Informaciones de Permision de Marcación"
              items={companyAllowedPunch}
              cols={1}
              css={{ maxWidth: MAX_DRAWER_WIDTH }}
            />
            <FormGroup
              type="fixed"
              title="Informaciones de Marcación"
              items={allowedPunch}
              cols={1}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$4' }}
            />
            <FormGroup
              type="fixed"
              title="Informaciones del Aplicativo"
              items={appConfigForm}
              cols={1}
              css={{ maxWidth: MAX_DRAWER_WIDTH, marginTop: '$4' }}
            />
            {places && (
              <Div css={{ marginTop: '$4' }}>
                <Text
                  size="xl"
                  css={{
                    color: '$interface_dark_deep',
                    fontWeight: '$bold',
                    marginBottom: '$2',
                  }}
                >
                  Locales de Marcación
                </Text>
                <Div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '$2',
                  }}
                >
                  {places?.map((place) => (
                    <Div
                      key={place}
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '$1',
                      }}
                    >
                      <Div
                        css={{
                          width: 10,
                          height: 10,
                          background: '$brand_primary_deep',
                          borderRadius: '50%',
                        }}
                      />
                      <Text
                        variant="caption"
                        css={{
                          color: '$interface_dark_deep',
                        }}
                      >
                        {place}
                      </Text>
                    </Div>
                  ))}
                  {places.length === 0 && (
                    <Text
                      variant="caption"
                      css={{ color: '$interface_dark_deep' }}
                    >
                      No se han agregado locales de marcación
                    </Text>
                  )}
                </Div>
              </Div>
            )}
            <Button
              disabled={!canCreatePunches}
              css={{ marginTop: '$10', minWidth: 300 }}
              onClick={handleCreatePunchConfig}
              isLoading={isLoadingCreation || isLoadingUpdate}
            >
              {isCreation ? 'Crear' : 'Actualizar'}
            </Button>
          </Div>
        }
      />
    </DrawerContainer>
  )
}
