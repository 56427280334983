import { IContentOption } from '../DataForm'

export const workersPdfData: IContentOption[] = [
  {
    label: 'Nombre',
    value: 'colaborador.nombre',
  },
  {
    label: 'Apellido',
    value: 'colaborador.apellido',
  },
  {
    label: 'CI',
    value: 'colaborador.documiento',
  },
  {
    label: 'Correo eletronico',
    value: 'colaborador.email',
  },
  {
    label: 'Celular',
    value: 'colaborador.celular',
  },
  {
    label: 'Nacionalidad',
    value: 'colaborador.nacionalidad',
  },
  {
    label: 'Fecha Nacimiento',
    value: 'colaborador.nacimiento',
  },
  {
    label: 'Sexo',
    value: 'colaborador.sexo',
  },
  {
    label: 'Banco',
    value: 'colaborador.banco',
  },
  {
    label: 'Cuenta Bancaria',
    value: 'colaborador.cuenta_bancaria',
  },
  {
    label: 'Pais de Domicilio',
    value: 'colaborador.pais',
  },
  {
    label: 'Departamento',
    value: 'colaborador.departamento',
  },
  {
    label: 'Calle',
    value: 'colaborador.calle',
  },
  {
    label: 'Ciudad',
    value: 'colaborador.ciudad',
  },
  {
    label: 'Dirección',
    value: 'colaborador.direccion',
  },
  {
    label: 'Complemento',
    value: 'colaborador.complement',
  },
  {
    label: 'Numero',
    value: 'colaborador.numero',
  },
  {
    label: 'Profesión',
    value: 'colaborador.profesion',
  },
  {
    label: 'Cargo',
    value: 'colaborador.cargo',
  },
  {
    label: 'Plan de carrera',
    value: 'colaborador.plan_de_carrera',
  },
  {
    label: 'Sector (IAM)',
    value: 'colaborador.sector',
  },
  {
    label: 'Líder directo (IAM)',
    value: 'colaborador.lider_directo',
  },
  {
    label: 'Fecha de ingresso',
    value: 'colaborador.ingresso',
  },
  {
    label: 'Fecha de ingresso IPS',
    value: 'colaborador.ingresso_ips',
  },
  {
    label: 'Salario',
    value: 'colaborador.salario',
  },
]
