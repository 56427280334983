import * as XLSX from 'xlsx'

export const importXlsxFile = (fileContent: string, raw = true) => {
  const excelFileContent = XLSX.read(fileContent, {
    type: 'binary',
    cellDates: true,
  })

  const firstSheetName = excelFileContent.SheetNames[0]
  const firstSheet = excelFileContent.Sheets[firstSheetName]

  const data = XLSX.utils.sheet_to_json(firstSheet, {
    raw,
  })

  return data
}
