import { apiV2 } from '@/services/api'
import { queryClient, useCustomMutation } from '@/libs/react-query'

export const useDeleteLiquidation = () => {
  const mutation = useCustomMutation(
    ['useDeleteLiquidation'],
    handleDeleteLiquidation,
  )

  return mutation
}

const handleDeleteLiquidation = async (liquidationId: string) => {
  try {
    await apiV2.post<void>('/company/delete-user-liquidation', {
      liquidation_id: liquidationId,
    })

    queryClient.invalidateQueries('worker-liquidation')
    queryClient.invalidateQueries('workers')
  } catch (error) {
    console.log(error)
  }
}
