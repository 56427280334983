import { UserCircleIcon } from '@heroicons/react/24/outline'
import { Button, Text, styled } from '@punto-ui/react'
import { useSideBarContext } from '@/contexts/hooks'
import { DefaultProfilePic, EnterpriseInfosCardContainer } from './styles'
import { useMe } from '@/libs/react-query/hooks'
import { destroyCookie } from 'nookies'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import Image from 'next/image'
import { Div } from '@/components/Div'
import { useGetMultiEnterpriseCompanies } from '@/libs/react-query/hooks/multi-enterprise/useGetMultiEntepriseCompanies'
import { DropdownV2 } from '@/components/DropdownV2'
import { useHandleChangeMultiEnterpriseCompany } from '@/libs/react-query/mutations/multi-enterprise/useHandleChangeMultiEnterpriseCompany'
import { useTabStore } from '@/store'
import { queryClient } from '@/libs/react-query'

export const UserInfo = () => {
  const { data: me } = useMe()
  const { data: company } = useMyCompany()
  const { isOpen } = useSideBarContext()

  const { data: multiEnterpriseCompanies } = useGetMultiEnterpriseCompanies()
  const {
    mutateAsync: handleChangeMultiEnterpriseCompany,
    isLoading: isLoadingChangeMultiEnterpriseCompany,
  } = useHandleChangeMultiEnterpriseCompany()

  const { reset } = useTabStore((state) => ({
    reset: state.reset,
  }))

  return (
    <EnterpriseInfosCardContainer>
      <Div
        css={{
          width: 36,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 36,
        }}
      >
        {company?.companyPictureUrl ? (
          <CompanyProfilePic
            alt={'Company Profile Picture'}
            width={36}
            height={36}
            src={company.companyPictureUrl}
          />
        ) : (
          <DefaultProfilePic>
            <UserCircleIcon />
          </DefaultProfilePic>
        )}
      </Div>
      {isOpen && !multiEnterpriseCompanies?.length && (
        <>
          <Text size="sm" css={{ marginLeft: '$2' }}>
            {me?.name}
          </Text>
          <Button
            variant={'tertiary'}
            onClick={() => {
              destroyCookie(undefined, '@PuntoOk:token', {
                path: '/',
              })
              location.reload()
            }}
          >
            Salir
          </Button>
        </>
      )}
      {isOpen && !!multiEnterpriseCompanies?.length && (
        <>
          <Div css={{ paddingLeft: '$4', paddingRight: '$4' }}>
            <DropdownV2
              containerWidth={350}
              value={me?.company_id || ''}
              isLoading={isLoadingChangeMultiEnterpriseCompany}
              options={multiEnterpriseCompanies.map((company) => ({
                label: company.name,
                value: company.id,
              }))}
              onChangeValue={async (v) => {
                if (me?.company_id !== v.value && v.value) {
                  await handleChangeMultiEnterpriseCompany(v.value)

                  reset()
                  queryClient.resetQueries()
                }
              }}
            />
          </Div>
          <Button
            variant={'tertiary'}
            onClick={() => {
              destroyCookie(undefined, '@PuntoOk:token', {
                path: '/',
              })
              location.reload()
            }}
          >
            Salir
          </Button>
        </>
      )}
    </EnterpriseInfosCardContainer>
  )
}

const CompanyProfilePic = styled(Image, {
  borderRadius: '$full',
  border: '2px solid',
  objectFit: 'cover',
  objectPosition: 'center',
  borderColor: '$interface_dark_pure',
  boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
})
