import { FormProvider, useForm } from 'react-hook-form'
import { ExportPdfSchemaType } from './form'
import { Button, Progress, Text } from '@punto-ui/react'
import { Drawer, DrawerContainer } from '@/components/Drawer'
import { Div } from '@/components/Div'
import { IDefaultDrawerProps } from '../types'
import useToast from '@/hooks/useToast'
import { usePdfTemplate } from '@/libs/react-query/hooks/usePdfTemplate'
import { Stepper } from '@/components/Stepper'
import React, { useEffect } from 'react'
import { useHandleRequestExportPdf } from '@/libs/react-query/mutations/reports/useHandleRequestExportPdf'
import { useExportPdfSteps } from './steps'
import { WorkerStep } from './steps/WorkerStep'
import { VacationStep } from './steps/VacationsStep'
import { MovementStep } from './steps/MovementStep'
import { PaymentStep } from './steps/PaymentStep'
import { ShiftStep } from './steps/ShiftStep'
import { CustomVariableStep } from './steps/CustomVariableStep'
import { getPdfVariablesFromLayouts } from './variable'
import { TableStep } from './steps/TableStep'
import { PayrollStep } from './steps/PayrollStep'
import { LiquidationStep } from './steps/LiquidationStep'
import { AguinaldoStep } from './steps/AguinaldoStep'

export const ExportPDFDrawer = (
  props: IDefaultDrawerProps & {
    pdfId: string
    onClickExport?: () => void
  },
) => {
  const methods = useForm<ExportPdfSchemaType>({
    defaultValues: {
      payrollId: '',
      id: props.pdfId,
      usersIds: [],
    },
  })

  const { data: pdfTemplate, isLoading: isLoadingPdf } = usePdfTemplate(
    props.pdfId,
  )

  useEffect(() => {
    if (!pdfTemplate) return

    const variables = getPdfVariablesFromLayouts(pdfTemplate.layouts)

    const customVariables = variables.filter((variable) => {
      const variableHasDot = variable.includes('.')
      return !variableHasDot
    })

    methods.setValue(
      'variables',
      customVariables.map((c) => {
        return {
          name: c,
          value: '',
        }
      }),
    )

    methods.setValue('id', pdfTemplate.id)
  }, [pdfTemplate])

  const {
    isLoading: isLoadingRequestPdfExport,
    mutateAsync: handleRequestExportPdf,
  } = useHandleRequestExportPdf()

  const toast = useToast()

  const { steps, stepId, step, variables, setStep } =
    useExportPdfSteps(pdfTemplate)

  return (
    <FormProvider {...methods}>
      <DrawerContainer
        open={props.isOpen}
        onOpenChange={(isOpen) => {
          props.setIsOpen(isOpen)
          setStep(0)
        }}
      >
        <Drawer
          onOpenChange={(isOpen) => {
            props.setIsOpen(isOpen)
            setStep(0)
          }}
          open={props.isOpen}
          dialog
          overlayCss={{
            zIndex: 99,
          }}
          containerCss={{
            zIndex: 100,
            padding: 0,
          }}
          content={
            <Div
              css={{
                position: 'relative',
                minWidth: 900,
                minHeight: '50vh',
              }}
            >
              <Div
                css={{
                  height: 36,
                  background: '$interface_light_up',
                  borderTopRightRadius: 8,
                  borderTopLeftRadius: 8,
                }}
              />
              <Div
                css={{
                  padding: '$4',
                  paddingTop: '$2',
                }}
              >
                {(!pdfTemplate || isLoadingPdf) && (
                  <Div>
                    <Progress />
                  </Div>
                )}
                <Text
                  variant={'subtitle1'}
                  size="xl"
                  css={{
                    color: '$interface_dark_deep',
                  }}
                >
                  Exportación de {pdfTemplate?.name}
                </Text>
              </Div>
              <Div>
                <Div css={{ padding: '0 $4' }}>
                  <Stepper
                    stepsArray={steps}
                    isLoading={
                      isLoadingRequestPdfExport ||
                      // isLoadingPayrolls ||
                      isLoadingPdf
                      // isFetchingPayments ||
                      // isFetchingVacations
                    }
                  />
                </Div>
                <Div
                  css={{
                    height: 1,
                    width: '100%',
                    background: '$interface_light_up',
                    marginTop: '$2',
                    marginBottom: '$2',
                  }}
                />
                <Div
                  css={{
                    padding: '0 $4',
                    minHeight: 575,
                    minWidth: 1000,
                  }}
                >
                  {stepId === 'workers' && <WorkerStep variables={variables} />}
                  {stepId === 'vacations' && <VacationStep />}
                  {stepId === 'movements' && <MovementStep />}
                  {stepId === 'payments' && <PaymentStep />}
                  {stepId === 'payroll' && <PayrollStep />}
                  {stepId === 'liquidation' && <LiquidationStep />}
                  {stepId === 'aguinaldo' && <AguinaldoStep />}
                  {stepId === 'shifts' && <ShiftStep />}
                  {stepId?.includes('table') && (
                    <TableStep index={step.tableIndex || 0} id={stepId} />
                  )}
                  {stepId === 'custom-variables' && (
                    <CustomVariableStep pdf={pdfTemplate} />
                  )}
                </Div>
              </Div>
              <Div
                css={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: 56,
                  // background: '$interface_light_pure',

                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  css={{ marginLeft: '$4' }}
                  disabled={
                    isLoadingRequestPdfExport || isLoadingPdf
                    // isLoadingPayrolls ||
                    // isFetchingPayments ||
                    // isFetchingVacations
                  }
                  isLoading={isLoadingRequestPdfExport}
                  onClick={
                    props.onClickExport
                      ? props.onClickExport
                      : async () => {
                          const values = methods.getValues()
                          await handleRequestExportPdf({
                            pdf_template_id: props.pdfId,
                            users_ids: values.usersIds,
                            movementId: values.movementId,
                            liquidationId: values.liquidationId,
                            paymentId: values.paymentId,
                            payrollId: values.payrollId,
                            shiftId: values.shiftId,
                            shiftDate: values.shiftDate,
                            vacationId: values.vacationId,
                            aguinaldoId: values.aguinaldoId,
                            templates:
                              values.templates?.map((table) => {
                                return {
                                  template_id: table.templateId,
                                  data: {
                                    template_id: table.templateId,
                                    users_ids: values.usersIds,
                                    payrollIds: table.payrollIds,
                                    aguinaldoIds: table.aguinaldoIds,
                                    date: {
                                      startDate:
                                        table.dates?.[0]?.toISOString(),
                                      endDate: table.dates?.[1]?.toISOString(),
                                      day: table.day?.[0]?.toISOString(),
                                      month: table.month,
                                      year: table.year,
                                    },
                                    filters: table.filters
                                      .filter(
                                        (f) =>
                                          f.value !== '' ||
                                          f.dates.filter((c) => !!c).length >
                                            0 ||
                                          !f.hasValue,
                                      )
                                      .map((f) => ({
                                        name: f.column.split('.')[1],
                                        type: f.column.split('.')[0],
                                        valueType: f.columnType,
                                        condition: f.condition,
                                        value: f.value,
                                        date: f.dates[0]
                                          ? f.dates[0]
                                          : undefined,
                                      })),
                                  },
                                }
                              }) || [],
                            variables:
                              values.variables
                                ?.filter((v) => v.name && v.value)
                                .map((v) => {
                                  return {
                                    variable: v.name,
                                    value: v.value,
                                  }
                                }) || [],
                          })

                          const toastId = Date.now()

                          toast.addToast({
                            title: 'PDF solicitado',
                            description:
                              'El PDF se esta generando, va al modulo de reportes para conferir su estado.',
                            type: 'positive',
                            timeout: 12000,
                            id: toastId,
                            buttonLabel: 'Cerrar',
                            callback: () => {
                              toast.removeToast(toastId)
                            },
                          })
                        }
                  }
                >
                  Exportar reporte
                </Button>
              </Div>
            </Div>
          }
        />
      </DrawerContainer>
    </FormProvider>
  )
}
