import { Div } from '@/components/Div'
import { ExportTemplateBasicConcepts } from '@/components/Drawers/ExportTemplate/ExportTemplateBasicConcepts'
import { ExportTemplateFilters } from '@/components/Drawers/ExportTemplate/ExportTemplateFilters'
import { useFormContext } from 'react-hook-form'
import { ExportPdfSchemaType } from '../../form'
import { useEffect } from 'react'
import { useReportTemplate } from '@/libs/react-query/hooks/useReportTemplate'
import dayjs from 'dayjs'
import { IReportBuilderColumnFilterTypes } from '@/pages/reportes-v2/ReportBuilder/filter-types'
import { reportBuilderFilterTypes } from '@/pages/reportes-v2/ReportBuilder/constants/report-builder-filter-types'

export const TableStep = (props: { id: string; index: number }) => {
  const templateId = props.id.split(':')[1]
  const methods = useFormContext<ExportPdfSchemaType>()

  const { data: template } = useReportTemplate(templateId)

  useEffect(() => {
    const templateValues = methods.getValues(`templates.${props.index}`)
    const defaultValues = {
      dates: [],
      day: [],
      month: '0',
      payrollIds: [],
      year: '2024',
      filters: [],
      templateId,
      aguinaldoIds: [],

      groupByColumn: '',
      withHours: false,
    }

    methods.setValue(`templates.${props.index}`, {
      ...defaultValues,
      ...templateValues,
      templateId,
    })

    const values = methods.getValues(`templates.${props.index}`)

    if (template && !values?.filters?.length) {
      methods.setValue(
        `templates.${props.index}.filters`,
        template.filters.map((f) => {
          const filtersOperators =
            reportBuilderFilterTypes[
              (f.valueType as IReportBuilderColumnFilterTypes) || 'string'
            ]
          const operationsOptions = filtersOperators.operations.map((f) => ({
            label: f.label,
            value: f.value,
            requiredInput: f.requiredInput,
          }))

          const selectedOperationHasValue = !!operationsOptions.find(
            (c) => c.value === f.condition,
          )?.requiredInput

          return {
            id: (Date.now() + Math.random() * 100).toString(),
            column: `${f.type}.${f.name}`,
            type: f.type,
            columnType: f.valueType,
            condition: f.condition,

            hasValue: selectedOperationHasValue !== null,

            value: f.value ? f.value : '',
            dates: f.date ? [dayjs(f.date).toDate()] : [],

            options: operationsOptions,
          }
        }),
      )
    }
  }, [template, props.index])

  return (
    <Div
      css={{
        display: 'flex',

        '> div:first-child': {
          minWidth: '27vw',
          width: '27vw',

          paddingBottom: 48,
        },

        '> div:nth-child(2)': {
          minWidth: '1px !important',
          width: '1px !important',
        },
      }}
    >
      <ExportTemplateBasicConcepts
        templateId={templateId}
        prefix={`templates.${props.index}`}
        shouldNotAskUsers
        height={'575px'}
        noPadding
      />
      <Div
        css={{
          height: '575px',
          width: '1px',
          background: '$interface_light_deep',

          marginLeft: '$4',
          marginRight: '$4',
        }}
      />
      <ExportTemplateFilters
        templateId={templateId}
        prefix={`templates.${props.index}`}
        height={'575px'}
        noPadding
      />
    </Div>
  )
}
