import { Div, Flex } from '@/components'
import {
  CheckCircleIcon,
  DocumentTextIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import {
  Box,
  Button,
  Dialog,
  DialogContainer,
  Divider,
  Text,
} from '@punto-ui/react'
import { InactivateWorkerSubtotal } from './Subtotal'
import { useUpdateUserLiquidation } from '@/libs/react-query/mutations/company/useUpdateUserLiquidation'
import { useFormContext } from 'react-hook-form'
import { InactivateWorkerSchema } from '../form'
import { compareAndConstructUserLiquidation } from '../parser'
import { useHandleLiquidateUser } from '@/libs/react-query/mutations/company/useHandleLiquidateUser'
import { usePayrolls } from '@/libs/react-query/hooks'
import dayjs from 'dayjs'
import { useHandleUpdatePayrollSettings } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollSettings'
import { PayrollListing } from '@/libs/react-query/types/payroll'
import { useState } from 'react'

export type InactivateWorkerModalProps = {
  isOpen: boolean
  setIsOpen(isOpen: boolean): void
  name: string
}

export const InactivateWorkerModal = ({
  isOpen,
  name,
  setIsOpen,
}: InactivateWorkerModalProps) => {
  const { data: payrolls, isLoading: isLoadingPayrolls } = usePayrolls()

  const methods = useFormContext<InactivateWorkerSchema>()

  const {
    mutateAsync: handleUpdateLiquidation,
    isLoading: isLoadingUpdateLiquidation,
  } = useUpdateUserLiquidation()
  const {
    mutateAsync: handleLiquidateUser,
    isLoading: isLoadingLiquidateUser,
  } = useHandleLiquidateUser()

  const handleLiquidateWorker = async () => {
    const values = methods.getValues()

    const dto = compareAndConstructUserLiquidation(values.original, values.data)

    await handleUpdateLiquidation({
      ...dto,
      liquidation_id: values.liquidationId,
    })

    await handleLiquidateUser(dto.user_id)

    methods.setValue('enabled_changes', false)
    setIsOpen(false)
  }

  const isLoading =
    isLoadingUpdateLiquidation || isLoadingLiquidateUser || isLoadingPayrolls

  const liquidation = methods.getValues()

  const openPayrollsInLiquidationMonth = payrolls?.filter(
    (payroll) =>
      payroll.users.some((user) => user.id === liquidation.data.user_id) &&
      payroll.status === 'open' &&
      liquidation.data.inactivation_date[0] &&
      dayjs(payroll.start_date).month() ===
        dayjs(liquidation.data.inactivation_date[0]).month(),
  )

  return (
    <DialogContainer open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <Dialog
        overlayCss={{
          zIndex: 15,
        }}
        containerCss={{
          zIndex: 16,
        }}
        content={
          <Box>
            <Flex
              css={{ flexDirection: 'column', marginBottom: '$4', gap: '$4' }}
            >
              <Text variant={'heading2'}>
                ¿Está seguro que desea liquidar el colaborador?
              </Text>
              <Text variant="paragraph" size={'4xl'}>
                {name}
              </Text>
            </Flex>

            {!!openPayrollsInLiquidationMonth?.length && (
              <>
                <Text
                  css={{
                    marginBottom: '$2',
                  }}
                >
                  El colaborador está registrado en las siguientes hojas de
                  salarios abiertas, verifique si desea eliminarlo:
                </Text>
                <Text
                  variant="caption"
                  css={{
                    fontWeight: '$bold',
                    marginBottom: '$2',
                  }}
                >
                  Nominas:
                </Text>

                <Div>
                  {openPayrollsInLiquidationMonth?.map((payroll) => (
                    <DeletingWorkerPayroll
                      key={payroll.id}
                      payroll={payroll}
                      userId={liquidation.data.user_id}
                    />
                  ))}
                </Div>

                <Divider
                  css={{
                    width: '100%',
                    height: 1,
                    marginTop: '$6',
                  }}
                />
              </>
            )}

            <InactivateWorkerSubtotal />

            <Flex css={{ flex: 1, marginTop: '$4' }}>
              <Button
                css={{ flex: 1, marginRight: '$1' }}
                onClick={handleLiquidateWorker}
                isLoading={isLoading}
                icon={<TrashIcon />}
              >
                Liquidar
              </Button>
              <Button css={{ flex: 1, marginRight: '$1' }} variant="tertiary">
                Cancelar
              </Button>
            </Flex>
          </Box>
        }
      />
    </DialogContainer>
  )
}

const DeletingWorkerPayroll = ({
  payroll,
  userId,
}: {
  payroll: PayrollListing
  userId: string
}) => {
  const [isConfirming, setIsConfirming] = useState(false)
  const {
    mutateAsync: handleUpdatePayrollSettings,
    isLoading,
    isSuccess,
  } = useHandleUpdatePayrollSettings()

  const handleRemoveUserFromPayroll = async () => {
    await handleUpdatePayrollSettings({
      id: payroll.id,
      description: payroll.description || undefined,
      signature_date: payroll.signature_date || undefined,
      endDate: payroll.end_date,
      startDate: payroll.start_date,
      isFavorite: payroll.is_favorite,
      name: payroll.name,
      usersIds: payroll.users
        .map((user) => user.id)
        .filter((id) => id !== userId),
    })
    console.log('removing user from payroll')
  }

  return (
    <Div
      key={payroll.id}
      css={{
        padding: '$1 $2',
        // background: 'red',
        borderRadius: '$md',
        border: 'solid 1px $interface_light_deep',
        boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',

        display: 'flex',
        alignItems: 'center',
        marginBottom: '$2',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '$2',
          svg: {
            height: 16,
            width: 16,
          },
        }}
      >
        <DocumentTextIcon />
      </Div>
      <Text
        variant="description"
        css={{
          width: '35%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {payroll.name}
      </Text>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',

          width: '45%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <Text
          variant="caption"
          css={{
            marginRight: '$2',
          }}
        >
          de {dayjs(payroll.start_date).utc().format('DD/MM/YYYY')}
        </Text>
        <Text variant="caption">
          hasta {dayjs(payroll.end_date).utc().format('DD/MM/YYYY')}
        </Text>
      </Div>
      <Div
        css={{
          flex: 1,
          minWidth: 200,
        }}
      >
        {!isSuccess && (
          <Button
            variant={isConfirming ? 'secondaryInverse' : 'secondaryCritical'}
            css={{
              marginLeft: 'auto',
            }}
            isLoading={isLoading}
            icon={<TrashIcon />}
            onClick={() => {
              if (isConfirming) {
                handleRemoveUserFromPayroll()
              }

              setIsConfirming(true)
            }}
          >
            {isConfirming ? 'Confirmar eliminación' : 'Eliminar de Nomina'}
          </Button>
        )}
        {isSuccess && (
          <Div
            css={{
              svg: {
                height: 16,
                width: 16,
                color: '$status_success_deep',
              },

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '$2',

              height: 32,
            }}
          >
            <CheckCircleIcon />
            <Text
              variant="caption"
              css={{
                color: '$status_success_deep',
              }}
            >
              Removido
            </Text>
          </Div>
        )}
      </Div>
    </Div>
  )
}
