import { Avatar, Div } from '@/components'
import { TableData } from './styles'
import {
  SmartShift,
  WorkersShifts,
} from '@/pages/turnos/components/SmartShiftManagement/types'
import { Cell, Table } from '@tanstack/react-table'
import { Checkbox, Text } from '@punto-ui/react'
import { ControlledCheckbox } from '@/components/Forms'
import dayjs from 'dayjs'
import { FieldPath, useFormContext, useWatch } from 'react-hook-form'
import { SmartShiftsFormSchema } from '@/pages/turnos/components/SmartShiftManagement/forms'
import {
  UpdateShiftsParams,
  useSmartShift,
} from '@/pages/turnos/components/SmartShiftManagement/context'
import { ShiftCellPopover } from './components'
import { memo, useEffect, useRef, useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'

import {
  SHIFT_TABLE_CELL_HEIGHT,
  SHIFT_TABLE_CELL_WIDTH,
  SHIFT_TABLE_CELL_WORKER_WIDTH,
} from '../..'
import { useCan } from '@/hooks'

type ShiftCellProps = {
  cell: Cell<WorkersShifts, unknown>
  table: Table<WorkersShifts>
}

export const ShiftCell = ({ cell }: ShiftCellProps) => {
  // console.log('re-rendering data')
  const { column, row } = cell
  const [isOpen, setIsOpen] = useState(false)
  const {
    minimalistVision,
    setIsOverSelected,
    isOverSelected,
    handleUpdateShifts,
    workersShifts,
  } = useSmartShift()
  const canManageShifts = useCan(['shifts.gestionar'])
  const { getValues, control } = useFormContext<SmartShiftsFormSchema>()
  const { dates } = getValues()

  const periodStartTime = dayjs(dates[0]).toDate()

  const workerShifts = row.original
  const worker = workerShifts.worker

  const isWorkerColumn = column.id.includes('worker')
  const workerShiftsIndex = isWorkerColumn
    ? 0
    : workerShifts.shifts.findIndex(
        (s) =>
          dayjs(dayjs(s.date).endOf('day')).diff(
            dayjs(column.id).endOf('day'),
            'days',
          ) === 0,
      )
  const checkedShiftIndex = dayjs(dayjs(column.id).endOf('day')).diff(
    dayjs(periodStartTime).startOf('day'),
    'days',
  )

  const withoutShiftInDate = workerShiftsIndex === -1
  const checkboxName: FieldPath<SmartShiftsFormSchema> = `shiftsSelection.${row.index}.${checkedShiftIndex}`

  const isColumnChecked = useWatch({
    control,
    name: checkboxName,
  })

  const currentShift = workerShifts.shifts[workerShiftsIndex]
  const currentShiftWeekday = dayjs(currentShift?.date).day()

  const isRestDay =
    currentShift?.isRestDay ||
    (currentShift?.currentDateShift?.intervals[0]?.startTime === '00:00' &&
      currentShift?.currentDateShift?.intervals[0]?.endTime === '00:00')

  const handleDropShiftInsideAnother = (shift: SmartShift) => {
    if (!isOverSelected) {
      handleUpdateShifts(
        [
          {
            dates: [dayjs(column.id).toDate()],
            userId: worker.id,
          },
        ],
        shift.shiftId,
      )
    } else {
      const selectedWorkersShifts: UpdateShiftsParams[] = []
      workersShifts.forEach((workerShifts, workerIndex) => {
        const dates: Date[] = []
        const { shiftsSelection } = getValues()

        const sortedShifts = workerShifts.shifts.sort((a, b) =>
          dayjs(a.date).diff(dayjs(b.date)),
        )

        sortedShifts.forEach((shift, index) => {
          if (shiftsSelection[workerIndex][index]) {
            dates.push(shift.date)
          }
        })

        selectedWorkersShifts.push({
          dates,
          userId: workerShifts.worker.id,
        })
      })
      handleUpdateShifts(selectedWorkersShifts, shift.shiftId)
    }
  }

  const dropRef = useRef<any | null>(null)
  const [draggedShift, setDraggedShift] = useState<SmartShift | null>(null)
  const [isOver, setIsOver] = useState<boolean | null>(null)
  const [canDrop, setCanDrop] = useState<boolean | null>(null)

  useEffect(() => {
    if (!dropRef.current) return

    return combine(
      dropTargetForElements({
        element: dropRef.current,
        canDrop: ({ source }) => {
          if (!source.data.shift || source.data.type !== 'shift-management') {
            return false
          }

          const canDropResult = !!(
            (source.data?.shift as any)?.shiftId !== currentShift?.shiftId
          )

          // console.log('canDropResult', canDropResult)

          setCanDrop(canDropResult)

          return canDropResult
        },
        onDrop: ({ source }) => {
          if (!source.data.shift || source.data.type !== 'shift-management') {
            return
          }

          handleDropShiftInsideAnother(source.data.shift as SmartShift)
          setIsOverSelected(false)
        },
        onDragLeave: () => {
          // setDraggedShift(null)
          // setCanDrop(null)
          setIsOver(null)

          // if (isOverSelected) {
          //   setIsOverSelected(false)
          // }
        },
        onDrag: ({ source }) => {
          // console.log('DRAGGING')
          if (!source.data.shift || source.data.type !== 'shift-management') {
            return
          }

          if (
            (source.data.shift as SmartShift).shiftId !== draggedShift?.shiftId
          ) {
            setDraggedShift(source.data.shift as SmartShift)
          }
        },
        onDragEnter: ({ source }) => {
          if (!source.data.shift || source.data.type !== 'shift-management') {
            return
          }
          // console.log('DRAG ENTER ', isColumnChecked, isOverSelected)

          if (isColumnChecked && !isOverSelected) {
            setIsOverSelected(true)
          } else if (isColumnChecked && isOverSelected) {
            setIsOverSelected(true)
          } else if (!isColumnChecked && isOverSelected) {
            setIsOverSelected(false)
          }

          if (draggedShift === null) {
            setDraggedShift(source.data.shift as SmartShift)
          }

          if (isOver === null) {
            setIsOver(true)
          }
        },
      }),
      monitorForElements({
        canMonitor: ({ source }) => source.data.type === 'shift-management',
        onDrag: ({ source }) => {
          // console.log('DRAG MONITOR STARTED')
          if (!source.data.shift || source.data.type !== 'shift-management') {
            return
          }

          if (
            isColumnChecked &&
            (source.data.shift as SmartShift).shiftId !== draggedShift?.shiftId
          ) {
            setDraggedShift(source.data.shift as SmartShift)
          }
        },
      }),
    )
  }, [
    currentShift?.shiftId,
    isColumnChecked,
    isOverSelected,
    draggedShift,
    isOver,
    canDrop,
  ])

  const draggedShiftWorkday = draggedShift?.days?.find(
    (d) => d.weekDay === currentShiftWeekday,
  )
  const shouldShowDraggedShift =
    (isOver && draggedShiftWorkday) ||
    (isOverSelected && isColumnChecked && draggedShiftWorkday)

  if (isWorkerColumn) {
    return <WorkerColumnData cell={cell} />
  }

  if (withoutShiftInDate && !shouldShowDraggedShift) {
    return (
      <TableData
        ref={dropRef}
        css={{
          minWidth: SHIFT_TABLE_CELL_WIDTH,
          width: SHIFT_TABLE_CELL_WIDTH,
          maxWidth: SHIFT_TABLE_CELL_WIDTH,
          height: SHIFT_TABLE_CELL_HEIGHT,
          overflow: 'hidden',
          background: '$interface_light_up',
        }}
        key={cell.id}
      >
        <Div
          css={{
            background: '$interface_light_up',
            width: '100%',
          }}
        >
          <Div css={{ display: 'flex', alignItems: 'stretch' }}>
            {canManageShifts && (
              <Div
                css={{
                  marginRight: '$3',
                  marginLeft: 16,
                }}
              >
                <ControlledCheckbox name={checkboxName} />
              </Div>
            )}
            <Div
              css={{
                paddingTop: 16,
                paddingBottom: 16,
              }}
            >
              <Text
                variant="paragraph"
                css={{
                  fontWeight: '$bold',
                  color: '$interface_dark_down',
                  width: 80,
                }}
              >
                Sin Turno
              </Text>
            </Div>
          </Div>
        </Div>
      </TableData>
    )
  }

  if (isRestDay && !shouldShowDraggedShift) {
    return (
      <TableData
        ref={dropRef}
        key={cell.id}
        css={{
          minWidth: SHIFT_TABLE_CELL_WIDTH,
          width: SHIFT_TABLE_CELL_WIDTH,
          maxWidth: SHIFT_TABLE_CELL_WIDTH,
          overflow: 'hidden',
          height: SHIFT_TABLE_CELL_HEIGHT,
          background:
            shouldShowDraggedShift && draggedShift?.color
              ? draggedShift?.color
              : isRestDay || !currentShift?.currentDateShift?.intervals.length
              ? '$interface_light_deep'
              : currentShift?.color,
        }}
      >
        <DropdownMenu.Root
          open={isOpen}
          onOpenChange={(b) => setIsOpen(b)}
          modal
        >
          <Div
            css={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '$interface_light_deep',
            }}
          >
            {canManageShifts && (
              <Div css={{ marginRight: '$2', marginLeft: '$3' }}>
                <ControlledCheckbox name={checkboxName} label={''} />
              </Div>
            )}
            <DropdownMenu.Trigger asChild disabled={!canManageShifts}>
              <Div
                css={{
                  // paddingTop: 16,
                  // paddingRight: 16,
                  // paddingBottom: 16,

                  display: 'flex',
                  cursor: 'pointer',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_deep',
                    fontWeight: '$bold',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: 80,
                  }}
                >
                  {currentShift?.isRestDay
                    ? 'Descanso'
                    : shouldShowDraggedShift
                    ? draggedShift?.shiftName
                    : currentShift?.shiftName}
                </Text>
              </Div>
            </DropdownMenu.Trigger>
          </Div>
          <ShiftCellPopover
            isRest={currentShift?.isRestDay}
            currentShift={currentShift}
            userId={worker.id}
          />
        </DropdownMenu.Root>
      </TableData>
    )
  }

  return (
    <TableData
      ref={dropRef}
      key={cell.id}
      css={{
        minWidth: SHIFT_TABLE_CELL_WIDTH,
        width: SHIFT_TABLE_CELL_WIDTH,
        maxWidth: SHIFT_TABLE_CELL_WIDTH,
        // overflow: 'hidden',
        height: SHIFT_TABLE_CELL_HEIGHT,
        maxHeight: SHIFT_TABLE_CELL_HEIGHT,
        background:
          shouldShowDraggedShift && draggedShift?.color
            ? draggedShift?.color
            : isRestDay || !currentShift?.currentDateShift?.intervals.length
            ? '$interface_light_deep'
            : currentShift?.color,
      }}
    >
      <DropdownMenu.Root open={isOpen} onOpenChange={(b) => setIsOpen(b)} modal>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: SHIFT_TABLE_CELL_HEIGHT,
            background:
              shouldShowDraggedShift && draggedShift?.color
                ? draggedShift?.color
                : isRestDay || !currentShift?.currentDateShift?.intervals.length
                ? '$interface_light_deep'
                : currentShift?.color,
          }}
        >
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 24,
            }}
          >
            {canManageShifts && (
              <ControlledCheckbox
                name={checkboxName}
                label={''}
                css={{ marginRight: '$2' }}
              />
            )}
          </Div>
          {/* <Div css={{ marginRight: '$3', marginLeft: '$3' }}></Div> */}
          <DropdownMenu.Trigger asChild disabled={!canManageShifts}>
            <Div
              css={{
                // paddingTop: 16,
                // paddingBottom: 16,

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                cursor: 'pointer',
              }}
            >
              <Div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                {(shouldShowDraggedShift &&
                  !draggedShiftWorkday?.intervals.length) ||
                (!shouldShowDraggedShift &&
                  !currentShift?.currentDateShift?.intervals.length) ? (
                  <Text
                    variant="caption"
                    css={{
                      color: '$interface_dark_deep',
                      fontWeight: 'bold',
                      width: 80,
                    }}
                  >
                    Sin Turno
                  </Text>
                ) : (
                  <Text
                    variant="caption"
                    css={{
                      maxWidth: 80,
                      width: 80,
                      color: currentShift?.isRestDay
                        ? '$interface_dark_deep'
                        : shouldShowDraggedShift
                        ? draggedShift?.primaryColor
                        : currentShift?.primaryColor,
                      overflow: 'clip',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {shouldShowDraggedShift
                      ? draggedShift?.shiftName
                      : currentShift?.shiftName}
                  </Text>
                )}
                {!shouldShowDraggedShift && isRestDay && (
                  <Text
                    variant="caption"
                    css={{
                      color: '$interface_dark_deep',
                      fontWeight: 'bold',
                      width: 80,
                    }}
                  >
                    Descanso
                  </Text>
                )}
              </Div>
              <Div
                css={{
                  maxHeight: 32,
                }}
              >
                {!minimalistVision &&
                  !shouldShowDraggedShift &&
                  !!currentShift?.currentDateShift?.intervals?.length && (
                    <Text
                      variant="caption"
                      css={{ color: '$interface_dark_down' }}
                    >
                      {currentShift?.currentDateShift?.intervals[0].startTime} -{' '}
                      {
                        currentShift?.currentDateShift?.intervals[
                          currentShift?.currentDateShift?.intervals.length - 1
                        ].endTime
                      }
                    </Text>
                  )}
              </Div>
            </Div>
          </DropdownMenu.Trigger>
        </Div>
        <ShiftCellPopover currentShift={currentShift} userId={worker.id} />
      </DropdownMenu.Root>
    </TableData>
  )
}

interface WorkerColumnProps {
  cell: Cell<WorkersShifts, unknown>
}

const WorkerColumnData = ({ cell }: WorkerColumnProps) => {
  const canManageShifts = useCan(['shifts.gestionar'])
  const { setValue, getValues, control } =
    useFormContext<SmartShiftsFormSchema>()

  const { shiftsSelection } = useWatch({
    control,
  })

  const isWorkerColumnChecked = shiftsSelection?.[cell.row.index]?.every(
    (s) => s === true,
  )

  const workerShifts = cell.row.original
  const worker = workerShifts.worker

  return (
    <TableData
      key={cell.id}
      css={{
        borderLeftWidth: 0,
        position: 'sticky',
        left: 0,
        width: SHIFT_TABLE_CELL_WORKER_WIDTH,
        maxWidth: SHIFT_TABLE_CELL_WORKER_WIDTH,
        height: SHIFT_TABLE_CELL_HEIGHT,
        background: '$interface_light_pure',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          width: SHIFT_TABLE_CELL_WORKER_WIDTH,
          maxWidth: SHIFT_TABLE_CELL_WORKER_WIDTH,
        }}
      >
        {canManageShifts && (
          <Div
            css={{
              minWidth: 24,
              width: 24,
              maxWidth: 24,
            }}
          >
            <Checkbox
              css={{ marginRight: '$4' }}
              onCheckedChange={(b) => {
                const { shiftsSelection } = getValues()

                // set every shift of the worker to the same value
                const newShiftsSelection = shiftsSelection.map((shifts, i) => {
                  if (i === cell.row.index) {
                    return shifts.map(() => Boolean(b))
                  }
                  return shifts
                })

                setValue('shiftsSelection', newShiftsSelection)
                // set the new shifts selection
              }}
              checked={isWorkerColumnChecked}
            />
          </Div>
        )}
        <Div css={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src={worker.avatar_url} height={24} width={24} alt="" />
          <Div css={{ marginLeft: '$2' }}>
            <Text
              variant="caption"
              css={{
                color: '$interface_dark_down',
                maxWidth: 120,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {worker.name}
            </Text>
            {/* {!minimalistVision && (
              <Text variant="caption" css={{ color: '$interface_dark_down' }}>
                {worker.document}
              </Text>
            )} */}
          </Div>
        </Div>
      </Div>
    </TableData>
  )
}

export const ShiftCellMemo = memo(ShiftCell)
