import { Div } from '@/components'
import {
  ArrowDownTrayIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  Square2StackIcon,
  StarIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Button, Text } from '@punto-ui/react'
import React, { useState, ReactNode } from 'react'

export interface IListedTable {
  id: string
  isFavorite: boolean
  name: string
  tag?: React.ReactNode
  tagInName?: ReactNode

  isRemovable: boolean
  isEditable: boolean
  canDuplicate: boolean
  isDownloadable: boolean
}

interface ITableListProps {
  name: string
  tag?: ReactNode
  handleGenerate: (id: string) => void
  handleEdit?: (id: string, name: string) => void
  handleDuplicate?: (id: string) => Promise<void>
  handleDelete?: (id: string) => Promise<void>
  handleFavorite?: (id: string) => Promise<void>
  tables: Array<IListedTable>
}

export const TableList = ({
  tables,
  name,
  tag,
  handleGenerate,
  handleEdit,
  handleDuplicate,
  handleDelete,
  handleFavorite,
}: ITableListProps) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Div
      css={{
        background: '$interface_light_pure',
        borderRadius: '$lg',
        border: '1px solid $interface_light_down',
        boxShadow: '0px 0px 16px 0px #343A4014',
        overflow: 'scroll',
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '$2',

          height: 40,

          paddingLeft: '$4',
          paddingRight: '$4',
        }}
      >
        <Div
          onClick={() => setIsOpen(!isOpen)}
          css={{
            cursor: 'pointer',
            height: 20,
            width: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            svg: {
              height: 16,
              width: 16,

              transition: 'all 0.2s ease-in-out',
              transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
            },
          }}
        >
          <ChevronDownIcon />
        </Div>

        <Div
          css={{
            flex: 1,
            minWidth: 500,
            display: 'flex',
            alignItems: 'center',
            gap: '$2',
          }}
        >
          <Text variant="caption">{name}</Text>
          {tag}
        </Div>
        <Div
          css={{
            paddingRight: 90,
          }}
        >
          <Text variant="caption">Opciones</Text>
        </Div>
      </Div>

      {isOpen &&
        tables.map((table, index) => (
          <TableListItem
            index={index}
            key={table.id}
            table={table}
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleGenerate={handleGenerate}
            handleDelete={handleDelete}
            handleFavorite={handleFavorite}
          />
        ))}
    </Div>
  )
}

const TableListItem = ({
  table,
  handleEdit,
  handleDuplicate,
  handleGenerate,
  handleDelete,
  handleFavorite,
  index,
}: {
  index: number
  table: IListedTable
  handleEdit?: (id: string, name: string) => void
  handleDuplicate?: (id: string) => void
  handleGenerate: (id: string) => void
  handleDelete?: (id: string) => void
  handleFavorite?: (id: string) => void
}) => {
  const [isConfirmingDuplicate, setIsConfirmingDuplicate] = useState(false)
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

  return (
    <Div
      key={table.id}
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: '$2',

        height: 40,
        paddingLeft: '$4',
        paddingRight: '$4',

        background:
          index % 2 === 1 ? '$interface_light_pure' : '$interface_light_up',

        '&:hover': {
          background: '$interface_light_down',
        },
      }}
    >
      <Div
        onClick={() => handleFavorite?.(table.id)}
        css={{
          height: 20,
          width: 20,
          cursor: 'pointer',

          svg: {
            height: 20,
            width: 20,
            color: '$brand_primary_pure',
            fill: table.isFavorite ? '$brand_primary_pure' : 'transparent',
          },
        }}
      >
        <StarIcon />
      </Div>
      <Div
        css={{
          flex: 1,
          minWidth: 500,

          display: 'flex',
          alignItems: 'center',
          gap: '$2',
        }}
      >
        <Text variant="caption">{table.name}</Text>
        {table.tagInName}
      </Div>
      {!!table.tag && (
        <Div
          css={{
            minWidth: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {table.tag}
        </Div>
      )}
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '$2',

          minWidth: '30%',
        }}
      >
        {table.isRemovable && (
          <Div>
            <Button
              size="sm"
              variant={
                isConfirmingDelete ? 'primaryCritical' : 'tertiaryCritical'
              }
              onClick={() => {
                if (isConfirmingDelete) {
                  handleDelete?.(table.id)
                  setIsConfirmingDelete(false)
                }

                setIsConfirmingDelete(!isConfirmingDelete)
              }}
            >
              <TrashIcon />
              {isConfirmingDelete ? 'Confirmar Eliminación' : 'Eliminar'}
            </Button>
          </Div>
        )}
        {table.isEditable && (
          <Div>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => handleEdit?.(table.id, table.name)}
            >
              <Cog6ToothIcon />
              Editar
            </Button>
          </Div>
        )}
        {table.canDuplicate && (
          <Div>
            <Button
              size="sm"
              variant={isConfirmingDuplicate ? 'primary' : 'secondary'}
              onClick={() => {
                if (isConfirmingDuplicate) {
                  handleDuplicate?.(table.id)
                  setIsConfirmingDuplicate(false)
                }

                setIsConfirmingDuplicate(!isConfirmingDuplicate)
              }}
            >
              <Square2StackIcon />
              {isConfirmingDuplicate ? 'Confirmar Duplicación' : 'Duplicar'}
            </Button>
          </Div>
        )}
        <Div>
          <Button
            size="sm"
            disabled={!table.isDownloadable}
            onClick={() => handleGenerate(table.id)}
          >
            <ArrowDownTrayIcon />
            Generar
          </Button>
        </Div>
      </Div>
    </Div>
  )
}
