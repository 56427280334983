import { Div, FormRenderer } from '@/components'
import { ReceiptBuilderFields } from '../../type'
import { RECEIPT_TYPES, fromReceiptTypeToLabel } from '../../receipt-builder'

export const ConfigurationForm = () => {
  const form: ReceiptBuilderFields = [
    {
      name: 'configurations.name',
      label: 'Nombre del recibo',
      _type: 'input',
    },
    {
      name: 'configurations.title',
      label: 'Encabezado del recibo',
      _type: 'input',
    },
    {
      name: 'headerConfig.title',
      label: 'Titulo del recibo',
      _type: 'input',
    },
    {
      name: 'configurations.description',
      label: 'Descripción del Recibo',
      _type: 'input',
    },
    {
      name: 'configurations.isFavorite',
      label: 'Recibo Favorito',
      _type: 'checkbox',
    },
    {
      name: 'configurations.isWithoutQuantities',
      label: 'Sin cantidades',
      _type: 'checkbox',
    },
    {
      name: 'configurations.type',
      label: 'Tipo de recibo',
      _type: 'bullet-points',
      direction: 'vertical',
      defaultOption: {
        label: fromReceiptTypeToLabel(RECEIPT_TYPES[0]),
        value: RECEIPT_TYPES[0],
      },
      options: RECEIPT_TYPES.map((r) => ({
        label: fromReceiptTypeToLabel(r),
        value: r,
      })),
    },
  ]

  return (
    <Div
      css={{
        padding: '$6',
      }}
    >
      <FormRenderer items={form} />
    </Div>
  )
}
