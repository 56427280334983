import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { AddShiftData, ShiftData } from '../form'
import { getWorkHours } from '@/utils/shifts/getWorkHours'
import { formattedHourToMinutes, formatMinutes } from '@/utils/date'
import {
  CheckCircleIcon as CheckCircleIconSolid,
  ExclamationTriangleIcon,
  PlusIcon,
} from '@heroicons/react/16/solid'
import { v4 as uuid } from 'uuid'
import { Checkbox, styled, Text, Tooltip } from '@punto-ui/react'
import { ControlledInput, Div } from '@/components'
import {
  ArrowDownOnSquareStackIcon,
  ExclamationCircleIcon,
  HomeIcon,
  Square2StackIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'

export const ShiftRow = ({
  shift,
  index,
}: {
  shift: ShiftData
  index: number
}) => {
  const methods = useFormContext<AddShiftData>()

  const { append, remove } = useFieldArray({
    control: methods.control,
    name: `shifts.${index}.intervals`,
  })

  const intervals = useWatch({
    control: methods.control,
    name: `shifts.${index}.intervals`,
  })

  const copiedShiftWeekday = useWatch({
    control: methods.control,
    name: 'copiedShiftWeekday',
  })

  const { dayHours, nightHours, total } = getWorkHours({
    entrance: shift.startTime,
    exit: shift.endTime,
    intervals: shift.intervals,
    fromDayToNight: '20:00',
    fromNightToDay: '06:00',
  })

  const totalInterval = shift.intervals.reduce((acc, interval) => {
    return acc + Number(formattedHourToMinutes(interval.duration))
  }, 0)

  const handleAddInterval = () => {
    append({
      id: uuid(),
      startTime: '',
      endTime: '',
      duration: '',
    })
  }

  const handleDeleteInterval = (intervalIndex: number) => {
    remove(intervalIndex)
  }

  const hasErrorInStartTime =
    !!methods.formState.errors.shifts?.[index]?.startTime
  const hasErrorInEndTime = !!methods.formState.errors.shifts?.[index]?.endTime
  const hasErrorInSwitchShiftsTime =
    !!methods.formState.errors.shifts?.[index]?.switchShiftsTime

  return (
    <TableRow
      key={shift.dayOfWeek}
      css={{
        background:
          index % 2 === 0 ? '$interface_light_up' : '$interface_light_pure',
      }}
    >
      <TableCell width={40} css={{ paddingLeft: '$4' }}>
        <Checkbox />
      </TableCell>
      <TableCell width={32}>
        {copiedShiftWeekday === shift.dayOfWeek && (
          <Tooltip message="Cancelar copia" arrow>
            <Div
              onClick={() => methods.setValue('copiedShiftWeekday', null)}
              css={{
                cursor: 'pointer',
                width: 18,
                height: 18,
                svg: {
                  color: '$interface_dark_down',
                  width: 18,
                  height: 18,
                },
              }}
            >
              <XCircleIcon />
            </Div>
          </Tooltip>
        )}
        {(!copiedShiftWeekday || copiedShiftWeekday !== shift.dayOfWeek) && (
          <Tooltip
            message={
              copiedShiftWeekday
                ? `Pegar ${copiedShiftWeekday} en ${shift.dayOfWeek}`
                : 'Copiar dia'
            }
            arrow
          >
            <Div
              css={{
                width: 18,
                height: 18,
                cursor: 'pointer',

                svg: {
                  width: 18,
                  height: 18,
                  color: '$interface_dark_down',
                },
              }}
              onClick={() => {
                if (copiedShiftWeekday) {
                  const values = methods.getValues()

                  const copiedShiftDay = values.shifts.find(
                    (shift) => shift.dayOfWeek === copiedShiftWeekday,
                  )

                  if (copiedShiftDay) {
                    methods.setValue(`shifts.${index}`, {
                      date: shift.date,
                      dayOfWeek: shift.dayOfWeek,
                      isSwitchTimeInvalid: copiedShiftDay.isSwitchTimeInvalid,
                      startTime: copiedShiftDay.startTime,
                      endTime: copiedShiftDay.endTime,
                      switchShiftsTime: copiedShiftDay.switchShiftsTime,
                      intervals: copiedShiftDay.intervals,
                    })
                  }
                } else {
                  methods.setValue('copiedShiftWeekday', shift.dayOfWeek)
                }
              }}
            >
              {copiedShiftWeekday ? (
                <ArrowDownOnSquareStackIcon />
              ) : (
                <Square2StackIcon />
              )}
            </Div>
          </Tooltip>
        )}
      </TableCell>
      <TableCell width={80}>
        <Text
          variant="caption"
          css={{
            color: '$interface_dark_down',
          }}
        >
          {shift.dayOfWeek}
        </Text>
      </TableCell>
      <TableCell align="center" valign="middle" width={32}>
        <ShiftStatusCell shift={shift} index={index} />
      </TableCell>
      <TableCell align="center" valign="middle" width={120}>
        <Tooltip
          message={methods.formState.errors.shifts?.[index]?.startTime?.message}
          arrow
        >
          <Div>
            <ControlledInput
              name={`shifts.${index}.startTime`}
              inputType="hour"
              InputComponent={ShiftHourInput}
              error={hasErrorInStartTime}
            />
          </Div>
        </Tooltip>
      </TableCell>
      <TableCell
        align={shift.intervals.length > 0 ? 'left' : 'left'}
        valign="middle"
        width={120}
      >
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '$1',
          }}
        >
          <IntervalButton onClick={handleAddInterval}>
            Agregar
            <PlusIcon />
          </IntervalButton>
          {intervals.map((interval, intervalIndex) => (
            <ShiftIntervalCell
              index={intervalIndex}
              shiftIndex={index}
              interval={interval}
              handleDeleteInterval={handleDeleteInterval}
              key={intervalIndex}
            />
          ))}
        </Div>
      </TableCell>
      <TableCell align="center" valign="middle" width={120}>
        <Tooltip
          message={methods.formState.errors.shifts?.[index]?.endTime?.message}
          arrow
        >
          <Div>
            <ControlledInput
              name={`shifts.${index}.endTime`}
              inputType="hour"
              InputComponent={ShiftHourInput}
              error={hasErrorInEndTime}
            />
          </Div>
        </Tooltip>
      </TableCell>
      <TableCell align="center" valign="middle" width={120}>
        <Tooltip
          message={
            shift.isSwitchTimeInvalid
              ? 'Hora de cambio de jornada invalida, no puede estar entre la entrada y salida del turno'
              : methods.formState.errors.shifts?.[index]?.switchShiftsTime
                  ?.message
          }
          arrow
        >
          <Div>
            <ControlledInput
              name={`shifts.${index}.switchShiftsTime`}
              inputType="hour"
              InputComponent={ShiftHourInput}
              error={hasErrorInSwitchShiftsTime || shift.isSwitchTimeInvalid}
              onBlur={() => {
                methods.setValue(
                  `shifts.${index}.isSwitchTimeInvalid`,
                  shift.switchShiftsTime > shift.startTime &&
                    shift.switchShiftsTime < shift.endTime,
                )
              }}
            />
          </Div>
        </Tooltip>
      </TableCell>
      <TableCell
        align="center"
        valign="middle"
        width={80}
        css={{
          borderLeft: 'solid 1px',
          borderColor: '$interface_light_down',
        }}
      >
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {formatMinutes(total * 60)}
        </Text>
      </TableCell>
      <TableCell width={80} align="center" valign="middle">
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {formatMinutes(dayHours * 60)}
        </Text>
      </TableCell>
      <TableCell width={80} align="center" valign="middle">
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {formatMinutes(nightHours * 60)}
        </Text>
      </TableCell>
      <TableCell width={80} align="center" valign="middle">
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {formatMinutes(totalInterval)}
        </Text>
      </TableCell>
    </TableRow>
  )
}

const ShiftStatusCell = ({
  shift,
  index,
}: {
  shift: ShiftData
  index: number
}) => {
  const methods = useFormContext<AddShiftData>()

  const hasErrorInStartTime =
    !!methods.formState.errors.shifts?.[index]?.startTime
  const hasErrorInEndTime = !!methods.formState.errors.shifts?.[index]?.endTime
  const intervalHasHourError =
    !!methods.formState.errors.shifts?.[index]?.intervals?.length
  const hasErrorInSwitchShiftsTime =
    !!methods.formState.errors.shifts?.[index]?.switchShiftsTime

  const anyIntervalIsIncomplete = shift.intervals.some(
    (interval) =>
      interval.startTime === '' ||
      interval.endTime === '' ||
      interval.duration === '',
  )

  if (
    hasErrorInStartTime ||
    hasErrorInEndTime ||
    intervalHasHourError ||
    hasErrorInSwitchShiftsTime ||
    shift.isSwitchTimeInvalid
  ) {
    return (
      <Div
        css={{
          width: 16,
          height: 16,

          svg: {
            width: 16,
            height: 16,
            color: '$status_danger_deep',
          },
        }}
      >
        <ExclamationTriangleIcon />
      </Div>
    )
  }

  if (anyIntervalIsIncomplete) {
    return (
      <Div
        css={{
          width: 16,
          height: 16,

          svg: {
            width: 16,
            height: 16,
            color: '$status_warning_deep',
          },
        }}
      >
        <ExclamationCircleIcon />
      </Div>
    )
  }

  if (shift.startTime === shift.endTime) {
    return (
      <Div
        css={{
          width: 16,
          height: 16,

          svg: {
            width: 16,
            height: 16,
            color: '$brand_primary_down',
          },
        }}
      >
        <HomeIcon />
      </Div>
    )
  }

  return (
    <Div
      css={{
        width: 16,
        height: 16,

        svg: {
          width: 16,
          height: 16,
          color: '$status_success_deep',
        },
      }}
    >
      <CheckCircleIconSolid />
    </Div>
  )
}

const ShiftIntervalCell = ({
  index,
  shiftIndex,
  handleDeleteInterval,
  interval,
}: {
  index: number
  shiftIndex: number
  interval: {
    startTime: string
    endTime: string
    duration: string
  }
  handleDeleteInterval: (intervalIndex: number) => void
}) => {
  const methods = useFormContext<AddShiftData>()

  const intervalHasHourError =
    !!methods.formState.errors.shifts?.[shiftIndex]?.intervals?.[index]

  const hasErrorInStartTime =
    !!methods.formState.errors.shifts?.[shiftIndex]?.intervals?.[index]
      ?.startTime
  const hasErrorInEndTime =
    !!methods.formState.errors.shifts?.[shiftIndex]?.intervals?.[index]?.endTime
  const hasErrorInDuration =
    !!methods.formState.errors.shifts?.[shiftIndex]?.intervals?.[index]
      ?.duration

  const intervalHasSemanticalError = interval.startTime > interval.endTime

  const intervalIsIncomplete =
    (interval.startTime === '' && !hasErrorInStartTime) ||
    (interval.endTime === '' && !hasErrorInEndTime) ||
    (interval.duration === '' && !hasErrorInDuration)

  const borderColor =
    intervalHasHourError || intervalHasSemanticalError
      ? '$status_danger_deep'
      : intervalIsIncomplete
      ? '$status_warning_deep'
      : '$interface_light_down'

  const iconColor =
    intervalHasHourError || intervalHasSemanticalError
      ? '$status_danger_deep'
      : intervalIsIncomplete
      ? '$status_warning_deep'
      : '$interface_dark_down'

  return (
    <Div
      css={{
        height: 20,
        border: 'solid 1px',
        borderColor,
        backgroundColor: '#F6F7F6',
        borderRadius: '$sm',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '$1',
        paddingLeft: '$2',
        paddingRight: '$2',

        cursor: 'pointer',

        svg: {
          width: 16,
          height: 16,
          color: iconColor,
        },

        fontFamily: '$default',
        fontSize: '$xs',
        color: '$interface_dark_down',
        textAlign: 'center',
        zIndex: 100,
      }}
    >
      <Tooltip
        message={
          methods.formState.errors.shifts?.[shiftIndex]?.intervals?.[index]
            ?.startTime?.message || 'Entrada del intervalo'
        }
        arrow
      >
        <Div>
          <ControlledInput
            name={`shifts.${shiftIndex}.intervals.${index}.startTime`}
            inputType="hour"
            InputComponent={TransparentInput}
            placeholder="00:00"
          />
        </Div>
      </Tooltip>
      <Text
        css={{
          color: '$interface_dark_down',
        }}
      >
        -
      </Text>
      <Tooltip
        message={
          methods.formState.errors.shifts?.[shiftIndex]?.intervals?.[index]
            ?.endTime?.message || 'Salida del intervalo'
        }
        arrow
      >
        <Div>
          <ControlledInput
            name={`shifts.${shiftIndex}.intervals.${index}.endTime`}
            inputType="hour"
            InputComponent={TransparentInput}
            placeholder="00:00"
          />
        </Div>
      </Tooltip>
      <Text css={{ color: '$interface_dark_down' }}>|</Text>
      <Tooltip
        message={
          methods.formState.errors.shifts?.[shiftIndex]?.intervals?.[index]
            ?.duration?.message || 'Duración del intervalo'
        }
        arrow
      >
        <Div>
          <ControlledInput
            name={`shifts.${shiftIndex}.intervals.${index}.duration`}
            inputType="hour"
            InputComponent={TransparentInput}
            placeholder="00:00"
          />
        </Div>
      </Tooltip>
      <Div
        css={{
          cursor: 'pointer',
          height: 16,
          width: 16,
        }}
        onClick={() => handleDeleteInterval(index)}
      >
        <TrashIcon />
      </Div>
    </Div>
  )
}

const TableRow = styled('tr', {
  height: 40,
})
const TableCell = styled('td', {
  height: 40,
})

const IntervalButton = styled('button', {
  all: 'unset',

  height: 20,
  border: 'solid 1px',
  borderColor: '$interface_light_down',
  backgroundColor: '#F6F7F6',
  maxWidth: 77,
  borderRadius: '$sm',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$1',
  paddingLeft: '$2',
  paddingRight: '$2',

  cursor: 'pointer',

  svg: {
    width: 16,
    height: 16,
    color: '$interface_dark_down',
  },

  fontFamily: '$default',
  fontSize: '$xs',
  color: '$interface_dark_down',
  textAlign: 'center',
})

const ShiftHourInput = styled('input', {
  all: 'unset',

  fontFamily: '$default',
  fontSize: '$xs',
  textAlign: 'center',
  color: '$interface_dark_down',

  height: 20,
  border: 'solid 1px',
  borderColor: '$interface_light_down',
  backgroundColor: '#F6F7F6',
  maxWidth: 80,
  borderRadius: '$sm',

  '&:focus': {
    borderColor: '$brand_primary_down',
  },

  variants: {
    error: {
      true: {
        backgroundColor: '$status_danger_up',
        borderColor: '$status_danger_deep',

        '&:focus': {
          borderColor: '$status_danger_pure',
        },
      },
    },
  },
})

const TransparentInput = styled('input', {
  all: 'unset',

  fontFamily: '$default',
  fontSize: '$xs',
  textAlign: 'center',
  color: '$interface_dark_down',
  height: 20,

  maxWidth: 32,

  '&::placeholder': {
    color: '$interface_dark_up',
  },
})
