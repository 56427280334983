import { Router } from '@/pages/router'
import { withSSRAuth } from '@/pages/utils/withSSRAuth'
import {
  BookmarkIcon,
  CalendarDaysIcon,
  ListBulletIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { Div } from '@/components'
import { ShiftsProvider } from './context'
import { SmartShiftManagement } from './components'
import { useCan } from '@/hooks'
import { ShiftsList } from './list/index.page'
import { useEffect, useMemo, useRef } from 'react'
import { useTabStore } from '@/store'
import CreateShift from './create/index.page'
import { useHandleShiftsActions } from './useHandleShiftsActions'
import { CreateShiftV2 } from './createV2'
import { ShiftListV2 } from './listV2'

export default function Turnos({ onlyTabs = false }: { onlyTabs?: boolean }) {
  return (
    <ShiftsProvider>
      <Page onlyTabs={onlyTabs} />
    </ShiftsProvider>
  )
}

const Page = ({ onlyTabs = false }: { onlyTabs?: boolean }) => {
  const canUpdateShiftDays = useCan([
    'shifts.gestionar',
    'shifts.all',
    'shifts.view',
  ])

  const remove = useRef<any>(null)

  useEffect(() => {
    return () => {
      clearTimeout(remove.current)
    }
  }, [])

  const {
    shiftTabsLength,
    setActiveTab,
    removeTab,
    shiftTabs,
    activeTab,
    shiftsActions,
  } = useTabStore((state) => ({
    addTab: state.addTab,
    removeTab: state.removeTab,
    shiftTabs: state.shifts.tabs,
    shiftsActions: state.shifts.actions,
    shiftTabsLength: state.shifts.tabs.length,
    setActiveTab: state.setActiveTab,
    activeTab: state.shifts.activeTab,
  }))

  useHandleShiftsActions()

  const canSeeShifts = useCan(['shifts.view', 'shifts.gestionar', 'shifts.all'])

  const tabsConfig = useMemo(() => {
    const tabs = []
    if (canSeeShifts) {
      tabs.push({
        icon: <CalendarDaysIcon />,
        label: 'Gerenciar Turnos',
        value: 'gerency',
        scrollable: true,
        component: (
          <Div
            css={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '$4',
            }}
          >
            <Div css={{ height: '88vh' }}>
              {canUpdateShiftDays ? (
                <SmartShiftManagement />
              ) : canSeeShifts ? (
                <ShiftListV2 />
              ) : null}
            </Div>
          </Div>
        ),
      })
    }

    if (canSeeShifts) {
      tabs.push({
        icon: <ListBulletIcon />,
        label: 'Turnos',
        value: 'shifts',
        component: <ShiftListV2 />,
      })
    }

    if (shiftTabsLength) {
      shiftTabs.forEach((tab) => {
        if (tab.type === 'create') {
          tabs.push({
            icon: <PlusIcon />,
            label: tab.label,
            closeable: true,
            scrollable: true,
            handleClose: () => {
              removeTab('shifts', tab.id)
              remove.current = setTimeout(() => {
                setActiveTab('shifts', 'shifts')
              }, 1)
            },
            value: tab.id,
            component: <CreateShiftV2 />,
          })
        }
        if (tab.type === 'edit') {
          tabs.push({
            icon: <BookmarkIcon />,
            label: tab.label,
            scrollable: true,
            closeable: true,
            handleClose: () => {
              removeTab('shifts', tab.id)
              remove.current = setTimeout(() => {
                setActiveTab('shifts', 'shifts')
              }, 1)
            },
            value: tab.id,
            component: <CreateShiftV2 />,
          })
        }
      })
    }
    return tabs
  }, [
    canSeeShifts,
    canUpdateShiftDays,
    shiftTabsLength,
    shiftTabs,
    removeTab,
    setActiveTab,
  ])

  const pageActions = shiftsActions

  return (
    <Router
      onlyTabs={onlyTabs}
      defaultTab="gerency"
      actions={pageActions}
      tabs={tabsConfig}
      setTab={(id) => setActiveTab('shifts', id)}
      tab={activeTab || 'gerency'}
    />
  )
}

export const getServerSideProps = withSSRAuth(async (ctx) => {
  return {
    props: {},
  }
})
