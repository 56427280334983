import { queryClient } from '@/libs/react-query'
import { useCustomMutation } from '@/libs/react-query/useCustomMutation'
import { apiV2 } from '@/services/api'

const handleDeletePdf = async (id: string) => {
  await apiV2.post<any>(`/report-builder/delete-pdf-template`, {
    id,
  })
  queryClient.invalidateQueries('pdfs')
}

export const useHandleDeletePdf = (id?: string) => {
  const mutation = useCustomMutation(
    ['useHandleDeletePdf', id],
    handleDeletePdf,
  )

  return mutation
}
