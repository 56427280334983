import {
  Avatar,
  ControlledFilterBar,
  Div,
  PremiumFeatureIcon,
  Table as TableComponent,
} from '@/components'
import { useCan, useDebounce } from '@/hooks'
import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { AddWorkerSimpleData } from '../forms'
import { Box, Checkbox, Dialog, DialogContainer, Menu } from '@punto-ui/react'
import { StyledContentText } from './WorkersList'
import { useLiquidatedWorkers } from '@/libs/react-query/hooks/useLiquidatedWorkers'
import { IWorkerUser } from '@/libs/react-query/types'
import { useTabStore } from '@/store'
import dayjs from 'dayjs'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { SimpleDialog } from '@/components/Dialogs/SimpleDialogs'
import { useHandleReactivateUser } from '@/libs/react-query/mutations'
import { EyeIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useAllAvailableCells } from '@/libs/react-query/hooks/useAvailableCells/useAllAvailableCells'
import utc from 'dayjs/plugin/utc'
import { QuickWorkerRehire } from './QuickWorkerRehire'
import { getLiquidationLabel } from '@/utils/workers/liquidation'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
dayjs.extend(utc)

export const LiquidatedWorkers = () => {
  const [reactivationWorkerId, setReactivationWorkerId] = React.useState('')
  const [rehireWorkerId, setRehireWorkerId] = React.useState('')
  const [rowSelection, setRowSelection] = React.useState({})

  const { mutateAsync: handleReactivateUser } = useHandleReactivateUser()

  const methods = useFormContext<AddWorkerSimpleData>()

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 20,
    })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  const [name, cellsIds] = useWatch({
    control: methods.control,
    name: ['liquidatedFilters.name', 'liquidatedFilters.cellsIds'],
  })

  const debouncedName = useDebounce(name, 200)

  const { data: workers, isFetching: isFetchingWorkers } = useLiquidatedWorkers(
    {
      page: pageIndex,
      pageSize,
      name: debouncedName,
      cellsIds,
    },
  )

  const columns = React.useMemo<ColumnDef<IWorkerUser>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Box css={{ padding: '$3 $2', background: 'transparent' }}>
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
              onClick={row.getToggleSelectedHandler()}
              disabled={!row.getCanSelect()}
              checked={row.getIsSelected()}
            />
          </Box>
        ),
      },
      {
        header: 'Foto',
        id: 'photo',
        footer: (info) => info.column.id,
        cell: ({ row }) => (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 32,
            }}
          >
            <Avatar
              src={row?.original?.photo_url || ''}
              height={32}
              width={32}
              alt=""
              unoptimized={true}
            />
          </Div>
        ),
      },
      {
        header: 'CI',
        footer: (info) => info.column.id,
        cell: (info) => info.getValue(),
        accessorFn: (row) => row.document,
        id: 'doc',
      },
      {
        header: 'Nombre',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.firstName,
        id: 'name',
      },
      {
        header: 'Apellido',
        footer: (info) => info.column.id,
        cell: (info) => info.getValue(),
        accessorFn: (row) => row.surname,
        id: 'nickname',
      },
      {
        header: 'E-mail',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.email,
        id: 'email',
      },
      {
        header: 'Teléfono',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.phone1,
        id: 'phone',
      },
      {
        header: 'Fecha de Liquidación',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) =>
          row.liquidated_at
            ? dayjs(row.liquidated_at).utc().format('DD/MM/YYYY')
            : '',
        id: 'liquidation_date',
      },
      {
        header: 'Fecha de Exclusión',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
        accessorFn: (row) =>
          row.deleted_at
            ? dayjs(row.deleted_at).format('DD/MM/YYYY HH:mm')
            : '',
        id: 'deleted_date',
      },
      {
        header: () => null,
        cell: (info) => (
          <StyledContentText>
            <DropdownMenu
              rowItem={info.row.original as IWorkerUser}
              setReactivationWorkerId={setReactivationWorkerId}
              setRehireWorkerId={setRehireWorkerId}
            />
          </StyledContentText>
        ),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.id,
        id: '_id',
      },
    ],
    [],
  )

  const table = useReactTable({
    data: workers?.data ?? [],
    columns,
    pageCount: workers?.totalPages ? workers.totalPages : -1,
    state: {
      pagination,
      rowSelection,
    },
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  useAllAvailableCells({
    methods,
    name: 'liquidatedFilters.cellsIds',
    permission: 'workers',
  })

  return (
    <>
      <ControlledFilterBar
        nameFilterName="liquidatedFilters.name"
        cellsIdsName="liquidatedFilters.cellsIds"
        isLoading={isFetchingWorkers}
        policiesIds={permissionsArray.filter((p) => p.includes('workers'))}
      />
      <Div css={{ marginBottom: '$4' }} />
      <Div
        css={{
          marginLeft: '$4',
          marginRight: '$4',
          marginTop: '$2',
          border: 'solid 1px $interface_light_down',
          borderRadius: '$md',
        }}
      >
        <TableComponent<IWorkerUser>
          totalNumberOfRows={workers?.total ?? 0}
          numberOfRows={workers?.data?.length ?? 0}
          table={table}
          isLoading={isFetchingWorkers}
        />
      </Div>
      <DialogContainer
        open={!!reactivationWorkerId}
        onOpenChange={() => setReactivationWorkerId('')}
      >
        <SimpleDialog
          description={'¿Está seguro que desea reactivar el colaborador?'}
          title={'Reactivación de Colaborador'}
          handleClose={() => setReactivationWorkerId('')}
          handleConfirm={() => {
            handleReactivateUser({ user_id: reactivationWorkerId })
            setReactivationWorkerId('')
          }}
          icon={<PlusCircleIcon />}
          overlayCss={{
            zIndex: 999,
          }}
          containerCSS={{
            zIndex: 9999,
          }}
          confirmLabel="Reactivar"
        />
      </DialogContainer>

      <DialogContainer
        open={!!rehireWorkerId}
        onOpenChange={() => setRehireWorkerId('')}
      >
        {rehireWorkerId && (
          <Dialog
            content={
              <QuickWorkerRehire
                closeModal={() => setRehireWorkerId('')}
                workerId={rehireWorkerId}
              />
            }
            overlayCss={{
              zIndex: 999,
            }}
            containerCss={{
              zIndex: 9999,
              padding: 0,
            }}
          />
        )}
      </DialogContainer>
    </>
  )
}

interface DropdownMenuProps {
  rowItem: IWorkerUser
  setReactivationWorkerId: React.Dispatch<React.SetStateAction<string>>
  setRehireWorkerId: React.Dispatch<React.SetStateAction<string>>
}

const DropdownMenu = ({
  rowItem,
  setReactivationWorkerId,
  setRehireWorkerId,
}: DropdownMenuProps) => {
  const canRehire = useCan(['workers.rehire'])
  const canEdit = useCan([
    'workers.all',
    'workers.liquidate',
    'workers.editLiquidation',
  ])
  const { data: isLiquidationInPlan } = useIsFeatureInPlan('liquidation')
  const { setActiveTab, addTab } = useTabStore((state) => ({
    setActiveTab: state.setActiveTab,
    addTab: state.addTab,
  }))

  const handleInactivateWorker = (liquidationId?: string) => {
    const inactivateWorkerTabId = `${rowItem.id}-inactivate`
    const liquidation = rowItem.liquidations.find(
      (l) => l.liquidation_id === liquidationId,
    )

    const label = getLiquidationLabel(liquidation, rowItem.name)

    addTab('worker', [
      {
        id: inactivateWorkerTabId,
        type: 'inactivate-worker',
        label,
        metadata: {
          liquidationId,
        },
      },
    ])
    setActiveTab('worker', inactivateWorkerTabId)
  }

  const timeout = useRef<any>(null)

  const handleEditWorker = () => {
    addTab('worker', [
      {
        id: rowItem.id,
        type: 'edit-worker',
        label: rowItem.name,
        metadata: {
          disabled: true,
        },
      },
    ])
    setActiveTab('worker', rowItem.id)
  }

  const items = [
    {
      id: '3',
      label: 'Ver detalles',
      icon: <EyeIcon />,
      onClick: () => {
        setTimeout(() => handleEditWorker(), 1)
      },
    },
    {
      id: '5',
      label: !rowItem.liquidations.length
        ? 'Agregar liquidación'
        : 'Detalles de Liquidaciónes',
      onClick: () => {
        if (!rowItem.liquidations.length) {
          handleInactivateWorker()
        }
      },
      disabled: !isLiquidationInPlan || !canEdit,
      icon: !rowItem.liquidations.length ? (
        <PlusIcon />
      ) : isLiquidationInPlan ? null : (
        <PremiumFeatureIcon feature="liquidation" />
      ),
      children: rowItem.liquidations.map((l) => {
        const label = getLiquidationLabel(l)

        return {
          onClick: () => handleInactivateWorker(l.liquidation_id),
          id: l.liquidation_id,
          label,
        }
      }),
    },
    // {
    //   id: '1',
    //   label: 'Detalles de Liquidación',
    //   onClick: () => handleEditWorker(),
    // },
    {
      id: '2',
      divideBellow: true,
      label: 'Recontratar Colaborador',
      icon: <PlusCircleIcon />,
      disabled: !canRehire,
      onClick: () => {
        timeout.current = setTimeout(() => {
          setRehireWorkerId(rowItem.id)
        }, 1)
      },
    },
    {
      id: '3',
      label: 'Activar Colaborador',
      onClick: () => {
        setTimeout(() => setReactivationWorkerId(rowItem.id), 1)
      },
    },
  ]

  return (
    <>
      <Menu items={items} />
    </>
  )
}
