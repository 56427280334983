import { useFormContext } from 'react-hook-form'
import { Div, Flex } from '@/components'
import { Box, Button, Dialog, DialogContainer, Text } from '@punto-ui/react'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import { AguinaldoPlaygroundTableType } from '../../types'
import { useHandleUpdateAguinaldoConfig } from '@/libs/react-query/mutations/aguinaldo/useHandleUpdateAguinaldoConfig'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'

export const CloseAguinaldoDialog = ({
  isOpen,
  name,
  setIsOpen,
}: {
  isOpen: boolean
  name: string
  setIsOpen: (isOpen: boolean) => void
}) => {
  const { data: company } = useMyCompany()
  const { getValues } = useFormContext<AguinaldoPlaygroundTableType>()
  const {
    mutateAsync: handleUpdateAguinaldoSettings,
    isLoading: isLoadingAguinaldoSettingsUpdate,
  } = useHandleUpdateAguinaldoConfig()

  return (
    <Div>
      <DialogContainer open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <Dialog
          overlayCss={{
            zIndex: 99,
          }}
          containerCss={{
            zIndex: 100,
          }}
          content={
            <Box>
              <Flex
                css={{ flexDirection: 'column', marginBottom: '$4', gap: '$4' }}
              >
                <Text variant={'heading2'}>
                  ¿Está seguro de cerrar el aguinaldo {name}?
                </Text>
                <Text
                  variant="paragraph"
                  css={{ width: 500, textAlign: 'center' }}
                >
                  Los aguinaldos cerrados no pueden ser editados.
                </Text>
              </Flex>

              <Flex css={{ flex: 1 }}>
                <Button
                  css={{ flex: 1, marginRight: '$1' }}
                  onClick={async () => {
                    const values = getValues()
                    if (!values.id || !company) return

                    const orderedCompanyConcepts = [
                      ...(company?.concepts || []),
                    ]
                    orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

                    const includedConcepts = orderedCompanyConcepts.filter(
                      (_, index) => values.configuration.concepts[index],
                    )

                    await handleUpdateAguinaldoSettings({
                      id: values.id,
                      status: 'closed',
                      description: values.description || undefined,
                      isFavorite: !!values.isFavorite,
                      name: values.name,
                      usersIds: values.users_ids,
                      antecipate: values.configuration.antecipate,
                      bonus: values.configuration.bonus,
                      commission: values.configuration.commission,
                      excess: values.configuration.excess,
                      extraMorningHours: values.configuration.morningExtraHours,
                      extraNightHours: values.configuration.nightExtraHours,
                      discountMorningHours:
                        values.configuration.morningDiscountHours,
                      discountNightHours:
                        values.configuration.nightDiscountHours,
                      familiarBonus: values.configuration.familyBonus,
                      gift: values.configuration.gift,
                      holidayMorningHours:
                        values.configuration.morningHolidayHours,
                      holidayNightHours: values.configuration.nightHolidayHours,
                      ips16: values.configuration.ips16,
                      ips9: values.configuration.ips9,
                      judicial: values.configuration.judicial,
                      loan: values.configuration.loan,
                      lost: values.configuration.lost,
                      mobility: values.configuration.mobility,
                      ordinaryMorningHours:
                        values.configuration.morningOrdinaryHours,
                      ordinaryNightHours:
                        values.configuration.nightOrdinaryHours,
                      otherDiscount: values.configuration.otherDiscount,
                      otherEarns: values.configuration.otherPayments,
                      payments: values.configuration.payments,
                      purchase: values.configuration.purchase,
                      year: values.year,
                      concepts: includedConcepts,
                      vacation_value: values.configuration.vacation_value,
                    })
                    setIsOpen(false)
                  }}
                  isLoading={isLoadingAguinaldoSettingsUpdate}
                  icon={<LockClosedIcon />}
                >
                  Cerrar
                </Button>
                <Button css={{ flex: 1, marginRight: '$1' }} variant="tertiary">
                  Cancelar
                </Button>
              </Flex>
            </Box>
          }
        />
      </DialogContainer>
    </Div>
  )
}
