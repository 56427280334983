import { Div, Table } from '@/components'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { usePunchGroupsColumns } from './table'
import { IPunchConfigGroup } from '@/libs/react-query/types/company'
import { PunchesConfigDrawer } from './components/PunchesConfigDrawer'
import { useFormContext } from 'react-hook-form'
import { ConfigSchema } from '..'
import { Button, TextInput } from '@punto-ui/react'
import { useCan } from '@/hooks'

interface PaymentGroupDrawerState {
  isOpen: boolean
  selectedPunchConfig: IPunchConfigGroup | null
}

export const PunchesConfigurationGroups = () => {
  const canCreatePunches = useCan(['configurations.punches'])
  const [rowSelection, setRowSelection] = React.useState({})
  const { setValue, reset } = useFormContext<ConfigSchema>()
  const { data: company } = useMyCompany()

  const [drawerState, setDrawerState] = React.useState<PaymentGroupDrawerState>(
    {
      isOpen: false,
      selectedPunchConfig: null,
    },
  )

  const columns = usePunchGroupsColumns()

  const handleCreatePunchConfig = () => {
    reset()

    setValue('punches', {
      is_able_to_punch_face_id: false,
      is_able_to_punch_from_anywhere: false,
      is_able_to_see_punch_history: false,
      should_show_invalid_geo_punches: false,
      should_allow_facial_registration: false,
      should_allow_unique_facial_registration: false,
      is_able_to_punch_geolocation: false,
      is_able_to_punch_geolocation_face_id: false,
      is_able_to_punch_offline: false,
      is_presence_default: false,
      required_aws_precision: 50,
      required_liveness_precision: 50,
      should_use_liveness: false,
      name: '',
      companyPlaceIds: [],
      placesNames: [],
    })
    setDrawerState({
      isOpen: true,
      selectedPunchConfig: null,
    })
  }

  const handleSelectPunchConfig = (row: IPunchConfigGroup) => {
    reset()

    setValue('punches', {
      id: row.id,
      is_able_to_punch_face_id: row.is_able_to_punch_face_id,
      is_able_to_punch_from_anywhere: row.is_able_to_punch_from_anywhere,
      is_able_to_see_punch_history: row.is_able_to_see_punch_history,
      should_show_invalid_geo_punches: row.should_show_invalid_geo_punches,
      should_allow_facial_registration: !!row.should_allow_facial_registration,
      should_allow_unique_facial_registration:
        !!row.should_allow_unique_facial_registration,
      is_able_to_punch_geolocation: row.is_able_to_punch_geolocation,
      is_able_to_punch_geolocation_face_id:
        row.is_able_to_punch_geolocation_face_id,
      is_able_to_punch_offline: row.is_able_to_punch_offline,
      is_presence_default: row.is_presence_default,
      required_aws_precision: row.required_aws_precision,
      required_liveness_precision: row.required_liveness_precision,
      should_use_liveness: row.should_use_liveness,
      name: row.name,
      companyPlaceIds: row.companyConfigPlaces.map((cp) => cp.company_place_id),
      placesNames: row.companyConfigPlaces.map((cp) => cp.companyPlace.name),
    })
    setDrawerState({
      isOpen: true,
      selectedPunchConfig: row,
    })
  }

  const table = useReactTable({
    data: company?.punchConfigGroups ?? [],
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    onRowSelectionChange: (updateOrValue) => {
      if (typeof updateOrValue === 'function') {
        setRowSelection(updateOrValue(rowSelection))
      } else {
        setRowSelection(updateOrValue)
      }
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <Div css={{ marginTop: '$4' }}>
      <Div
        css={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '$4',
        }}
      >
        <TextInput label="Nombre del Local" placeholder="Nombre del Local" />
        {canCreatePunches && (
          <Button onClick={() => handleCreatePunchConfig()}>
            Crear configuración de marcación
          </Button>
        )}
      </Div>

      <Div
        css={{
          margin: '$4',
          overflow: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        <Table<IPunchConfigGroup>
          table={table}
          numberOfRows={company?.paymentConfigGroups?.length ?? 0}
          withPagination={false}
          onRowClick={handleSelectPunchConfig}
        />
      </Div>

      <PunchesConfigDrawer
        isCreation={drawerState.selectedPunchConfig === null}
        isOpen={drawerState.isOpen}
        handleCloseDrawer={() =>
          setDrawerState({
            isOpen: false,
            selectedPunchConfig: null,
          })
        }
      />
    </Div>
  )
}
