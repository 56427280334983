import { useRef, useEffect, RefObject, useCallback } from 'react'

export function useHorizontalScroll<T extends HTMLElement = HTMLElement>(
  isEnabled = true,
): {
  ref: RefObject<T>
} {
  const elRef = useRef<T>(null)

  const onWheel = useCallback(
    (e: WheelEvent) => {
      if (!isEnabled) return
      const el = elRef.current
      if (e.deltaY === 0 || !el) return
      e.preventDefault()
      smoothScrollBy(el, 3 * e.deltaY, 250) // 250ms animation
    },
    [isEnabled],
  )

  useEffect(() => {
    const el = elRef.current
    if (el) {
      el.addEventListener('wheel', onWheel, { passive: false })
      return () => el.removeEventListener('wheel', onWheel)
    }
  }, [onWheel])

  return { ref: elRef }
}

function smoothScrollBy(element: HTMLElement, delta: number, duration: number) {
  const start = element.scrollLeft
  const startTime = performance.now()

  function scroll() {
    const now = performance.now()
    const time = Math.min(1, (now - startTime) / duration)
    const timeFunction = time * (2 - time) // easeInOutQuad easing
    element.scrollLeft = start + timeFunction * delta

    if (time < 1) {
      requestAnimationFrame(scroll)
    }
  }

  requestAnimationFrame(scroll)
}
