import { ControlledFilterBar, Div } from '@/components'
import { useNotifications } from '@/libs/react-query/hooks/notifications/useNotifications'
import { useFormContext, useWatch } from 'react-hook-form'
import { INotificationsStateSchema } from '../notifications-state'
import { Button, Progress, Tag, Text, Tooltip } from '@punto-ui/react'
import {
  NotificationTable,
  NotificationTableBody,
  NotificationTableCell,
  NotificationTableHead,
  NotificationTableRow,
} from '../NotificationTable.styles'
import { INotification } from '@/libs/react-query/types/notifications'
import {
  iconPerNotificationManagementModule,
  labelPerNotificationManagementModule,
  notificationTimeValueToLabel,
  tagVariantPerNotificationManagementModule,
} from '../notifications'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { capitalizeFirstLetters, shortenName } from '@/utils/workers/name'
import { useHandleUpdateNotificationReadStatus } from '@/libs/react-query/mutations/notifications/useHandleUpdateNotificationReadStatus'

const MAIN_COLUMNS_WIDTH = 220

export const NotificationsList = () => {
  const methods = useFormContext<INotificationsStateSchema>()

  const [page, readFilter, notificationModules] = useWatch({
    control: methods.control,
    name: [
      'notificationsList.page',
      'notificationsList.readFilter',
      'notificationsList.notificationModules',
    ],
  })

  const readState =
    readFilter.includes('read') && readFilter.includes('unread')
      ? undefined
      : readFilter.includes('read')
      ? true
      : readFilter.includes('unread')
      ? false
      : undefined

  const { data: notifications, isFetching: isFetchingNotifications } =
    useNotifications({
      page,
      per_page: 20,
      is_read: readState,
      modules: notificationModules,
      hour: dayjs().format('HH:mm'),
      date: dayjs().format('YYYY-MM-DD'),
    })

  const {
    mutate: handleUpdateNotificationReadStatus,
    isLoading: isUpdatingNotificationReadStatus,
  } = useHandleUpdateNotificationReadStatus()

  return (
    <>
      <ControlledFilterBar
        policiesIds={[]}
        isLoading={isFetchingNotifications}
        buttonsInFilterBar={[
          {
            label: 'Marcar todos como leído',
            icon: <EnvelopeOpenIcon />,
            onClick: () => {
              handleUpdateNotificationReadStatus({
                ids: notifications?.data?.map((n) => n.id) || [],
                is_read: true,
              })
            },
            variant: 'secondary',
            isLoading: isUpdatingNotificationReadStatus,
          },
        ]}
        genericFilters={[
          {
            options: [
              {
                label: 'Leídos',
                value: 'read',
              },
              {
                label: 'No leídos',
                value: 'unread',
              },
            ],
            single: false,
            name: 'notificationsList.readFilter',
            label: 'Estado',
            defaultValues: ['read', 'unread'],
          },
          {
            options: Object.entries(labelPerNotificationManagementModule).map(
              ([key, value]) => ({
                label: value,
                value: key,
              }),
            ),
            single: false,
            name: 'notificationsList.notificationModules',
            label: 'Módulo',
            defaultValues: [],
          },
        ]}
      />
      <Div css={{ padding: '$4' }}>
        <Div
          css={{
            maxHeight: 'calc(100vh - 200px)',
            overflow: 'auto',
            borderRadius: '$lg',
            border: '1px solid',
            borderColor: '$interface_light_down',
          }}
        >
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',

              backgroundColor: '$interface_light_pure',
              borderRadius: '$lg',

              minWidth: 1500,
            }}
          >
            <NotificationTable>
              <NotificationTableHead
                css={{
                  position: 'sticky',
                  background: '$interface_light_pure',
                  top: 0,
                }}
              >
                <NotificationTableRow>
                  <NotificationTableCell
                    width={64}
                    align="center"
                    valign="middle"
                  />
                  <NotificationTableCell
                    css={{
                      paddingLeft: '$4',
                    }}
                  >
                    <Div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '$2',

                        svg: {
                          color: '$interface_dark_down',
                          height: 16,
                          width: 16,
                        },
                      }}
                    >
                      <Text
                        variant="caption"
                        css={{
                          color: '$interface_dark_down',
                        }}
                      >
                        Titulo
                      </Text>
                    </Div>
                  </NotificationTableCell>
                  <NotificationTableCell align="center" valign="middle">
                    <Text
                      variant="caption"
                      css={{
                        color: '$interface_dark_down',
                        textAlign: 'center',
                      }}
                    >
                      Descripción
                    </Text>
                  </NotificationTableCell>
                  <NotificationTableCell align="center" valign="middle">
                    <Text
                      variant="caption"
                      css={{
                        color: '$interface_dark_down',
                        textAlign: 'center',
                      }}
                    >
                      Tiempo de Notificación
                    </Text>
                  </NotificationTableCell>
                  <NotificationTableCell align="center" valign="middle">
                    <Text
                      variant="caption"
                      css={{
                        color: '$interface_dark_down',
                        textAlign: 'center',
                      }}
                    >
                      Colaboradores
                    </Text>
                  </NotificationTableCell>
                  <NotificationTableCell align="center" valign="middle">
                    <Text
                      variant="caption"
                      css={{
                        color: '$interface_dark_down',
                      }}
                    >
                      Fecha de Notificación
                    </Text>
                  </NotificationTableCell>
                  <NotificationTableCell align="right" valign="middle">
                    <Text
                      variant="caption"
                      css={{
                        color: '$interface_dark_down',
                        paddingRight: '$4',
                      }}
                    >
                      Opciones
                    </Text>
                  </NotificationTableCell>
                </NotificationTableRow>
              </NotificationTableHead>
              <NotificationTableBody>
                {notifications?.data?.map((notification, index) => (
                  <NotificationRow
                    key={notification.id}
                    index={index}
                    notification={notification}
                  />
                ))}
              </NotificationTableBody>
            </NotificationTable>

            {!notifications?.data?.length && (
              <Div
                css={{
                  padding: '$4',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 40,
                }}
              >
                <Text variant="caption" css={{ color: '$interface_dark_down' }}>
                  No hay notificaciones configuradas
                </Text>
              </Div>
            )}
          </Div>
        </Div>
      </Div>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '$4',
          padding: '$6',
        }}
      >
        <Button
          onClick={() => methods.setValue('notificationsList.page', 1)}
          disabled={page === 1}
        >
          <ChevronDoubleLeftIcon />
        </Button>
        <Button
          onClick={() => methods.setValue('notificationsList.page', page - 1)}
          disabled={page === 1}
        >
          <ChevronLeftIcon />
        </Button>
        <Button
          onClick={() => methods.setValue('notificationsList.page', page + 1)}
          disabled={page === notifications?.totalPages}
        >
          <ChevronRightIcon />
        </Button>
        <Button
          onClick={() =>
            methods.setValue(
              'notificationsList.page',
              notifications?.totalPages || 1,
            )
          }
          disabled={page === notifications?.totalPages}
        >
          <ChevronDoubleRightIcon />
        </Button>
        <span>
          <Text as="strong">
            {page} de {notifications?.totalPages}
          </Text>
        </span>
        {notifications?.total && (
          <span
            style={{
              marginLeft: 'auto',
            }}
          >
            <Text as="strong"> Total: {notifications?.total} </Text>
          </span>
        )}
      </Div>
    </>
  )
}

const NotificationRow = ({
  notification,
  index,
}: {
  notification: INotification
  index: number
}) => {
  const {
    mutate: handleUpdateNotificationReadStatus,
    isLoading: isUpdatingNotificationReadStatus,
  } = useHandleUpdateNotificationReadStatus(notification.id)

  return (
    <NotificationTableRow
      css={{
        background: index % 2 === 0 ? '$interface_light_up' : 'transparent',

        '&:hover': {
          background: '$interface_light_down',
        },
      }}
    >
      <NotificationTableCell
        width={64}
        align="center"
        valign="middle"
        css={{
          svg: {
            height: 16,
            width: 16,
            color: '$interface_dark_down',
            cursor: 'pointer',
          },
        }}
      >
        {isUpdatingNotificationReadStatus && (
          <Div>
            <Progress />
          </Div>
        )}
        {!isUpdatingNotificationReadStatus && notification.is_read && (
          <Tooltip message="Marcar como leído" arrow>
            <Div
              onClick={() =>
                handleUpdateNotificationReadStatus({
                  ids: [notification.id],
                  is_read: false,
                })
              }
            >
              <EnvelopeOpenIcon />
            </Div>
          </Tooltip>
        )}
        {!isUpdatingNotificationReadStatus && !notification.is_read && (
          <Tooltip message="Marcar como leído" arrow>
            <Div
              onClick={() =>
                handleUpdateNotificationReadStatus({
                  ids: [notification.id],
                  is_read: true,
                })
              }
            >
              <EnvelopeIcon />
            </Div>
          </Tooltip>
        )}
      </NotificationTableCell>
      <NotificationTableCell
        width={2 * MAIN_COLUMNS_WIDTH}
        css={{
          paddingLeft: '$4',
        }}
      >
        <Div css={{ display: 'flex', alignItems: 'center', gap: '$2' }}>
          <Text
            variant="caption"
            css={{
              color: '$interface_dark_down',
              fontWeight: notification.is_read ? 'normal' : 'bold',
            }}
          >
            {notification.title}
          </Text>
          <Tag
            variant={
              tagVariantPerNotificationManagementModule[notification.module]
            }
          >
            {iconPerNotificationManagementModule[notification.module]}
            {labelPerNotificationManagementModule[notification.module]}
          </Tag>
        </Div>
      </NotificationTableCell>
      <NotificationTableCell
        align="center"
        valign="middle"
        width={5 * MAIN_COLUMNS_WIDTH}
      >
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            variant="caption"
            css={{
              color: '$interface_dark_down',

              fontWeight: notification.is_read ? 'normal' : 'bold',
              paddingTop: '$2',
              paddingBottom: '$2',
            }}
          >
            {notification.description}
          </Text>
        </Div>
      </NotificationTableCell>
      <NotificationTableCell
        align="center"
        valign="middle"
        width={MAIN_COLUMNS_WIDTH}
      >
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text variant="caption" css={{ color: '$interface_dark_down' }}>
            {notificationTimeValueToLabel(
              notification.notification_time_before_reference_date,
            )}
          </Text>
        </Div>
      </NotificationTableCell>
      <NotificationTableCell
        align="center"
        valign="middle"
        width={MAIN_COLUMNS_WIDTH}
      >
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text variant="caption" css={{ color: '$interface_dark_down' }}>
            {shortenName(
              capitalizeFirstLetters(notification?.user?.name || ''),
            )}
          </Text>
        </Div>
      </NotificationTableCell>
      <NotificationTableCell
        align="center"
        valign="middle"
        width={MAIN_COLUMNS_WIDTH}
      >
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {`${dayjs(notification.notification_date)
            .utc()
            .format('DD/MM/YYYY')} ${notification.notification_hour}`}
        </Text>
      </NotificationTableCell>
      <NotificationTableCell
        align="right"
        valign="middle"
        width={MAIN_COLUMNS_WIDTH}
      >
        <Div
          css={{
            paddingRight: '$4',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '$4',
          }}
        >
          <Div
            onClick={() => null}
            css={{
              height: 16,
              width: 16,

              svg: {
                color: '$interface_dark_down',
                height: 16,
                width: 16,
                cursor: 'pointer',
              },
            }}
          >
            <PencilSquareIcon />
          </Div>
        </Div>
      </NotificationTableCell>
    </NotificationTableRow>
  )
}
