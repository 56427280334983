import { AddShiftFields } from '../form'

export const useHoursAmountForm = ({
  isFlexibleMorning,
  isFlexibleMixed,
  isFlexibleNight,
}: {
  isFlexibleMorning?: boolean
  isFlexibleMixed?: boolean
  isFlexibleNight?: boolean
}) => {
  const data: AddShiftFields = []

  if (isFlexibleMorning) {
    data.push(
      {
        _type: 'input',
        label: 'Cantidad de horas flexibles diurnas',
        name: 'config.flexible_morning_hours',
        placeholder: '00:00',
        inputType: 'hour',
      },
      {
        _type: 'input',
        label: 'Intervalo padrón jornadas diurnas',
        name: 'config.default_interval_morning',
        placeholder: '00:00',
        inputType: 'hour',
      },
    )
  }

  if (isFlexibleMixed) {
    data.push(
      {
        _type: 'input',
        label: 'Cantidad de horas flexibles mixtas',
        placeholder: '00:00',
        name: 'config.flexible_mixed_hours',
        inputType: 'hour',
      },
      {
        _type: 'input',
        label: 'Intervalo padrón diurno jornadas mixtas',
        name: 'config.default_interval_mixed_morning',
        placeholder: '00:00',
        inputType: 'hour',
      },
      {
        _type: 'input',
        label: 'Intervalo padrón nocturno jornadas mixtas',
        name: 'config.default_interval_mixed_night',
        placeholder: '00:00',
        inputType: 'hour',
      },
    )
  }

  if (isFlexibleNight) {
    data.push(
      {
        _type: 'input',
        label: 'Cantidad de horas flexibles nocturnas',
        placeholder: '00:00',
        name: 'config.flexible_night_hours',
        inputType: 'hour',
      },
      {
        _type: 'input',
        label: 'Intervalo padrón jornadas nocturnas',
        name: 'config.default_interval_night',
        placeholder: '00:00',
        inputType: 'hour',
      },
    )
  }

  return {
    data,
  }
}
