import { Menu } from '@punto-ui/react'
import { useGroupBuilder } from '../../../context'
import { findGroupByIdInsideGroupData } from '../../../utils'
import { UserChildren } from '../../../types'

interface DropdownMenuProps {
  id: string
}

export const UserDropdownMenu = ({ id }: DropdownMenuProps) => {
  const { groups, toggleUserLeadership } = useGroupBuilder()

  const node = findGroupByIdInsideGroupData(groups, id) as UserChildren

  return (
    <Menu
      items={[
        {
          id: '1',
          disabled: false,
          label: node.isLeader ? 'Remover Lider' : 'Tornar Lider',
          onClick: () => {
            toggleUserLeadership(id, !node.isLeader)
          },
        },
      ]}
    />
  )
}
