import { useNotificationsSchedules } from '@/libs/react-query/hooks/notifications/useNotificationsSchedules'
import {
  iconPerNotificationManagementModule,
  INotificationManagementModuleProps,
  labelPerNotificationManagementModule,
} from '../notifications'
import { useState } from 'react'
import { Div } from '@/components'
import {
  ChevronDownIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Button, Text } from '@punto-ui/react'
import {
  NotificationTable,
  NotificationTableBody,
  NotificationTableCell,
  NotificationTableHead,
  NotificationTableRow,
} from '../NotificationTable.styles'
import { AddNotificationRow } from './AddNotificationRow'
import { NotificationManagementRow } from './NotificationManagementRow'

export const NotificationManagementModule = ({
  module,
}: INotificationManagementModuleProps) => {
  const [isOpen, setIsOpen] = useState(true)

  const { data: notificationsSchedules } = useNotificationsSchedules()
  const [shouldShowAddNotification, setShouldShowAddNotification] =
    useState(false)

  const notificationsSchedulesByModule = notificationsSchedules?.filter(
    (notification) => notification.module === module,
  )

  return (
    <Div
      css={{
        maxHeight: 'calc(100vh - 128px)',
        overflow: 'auto',
        borderRadius: '$lg',
        border: '1px solid',
        borderColor: '$interface_light_down',
      }}
    >
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',

          backgroundColor: '$interface_light_pure',
          borderRadius: '$lg',

          minWidth: 1300,
        }}
      >
        <NotificationTable>
          <NotificationTableHead
            css={{
              position: 'sticky',
              background: '$interface_light_pure',
              top: 0,
            }}
          >
            <NotificationTableRow>
              <NotificationTableCell
                css={{
                  paddingLeft: '$4',
                }}
              >
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '$2',

                    svg: {
                      color: '$interface_dark_down',
                      height: 16,
                      width: 16,
                    },
                  }}
                >
                  <Div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',

                      svg: {
                        color: '$interface_dark_down',
                        height: 16,
                        width: 16,

                        transition: 'transform 0.2s ease-in-out',
                        transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                      },
                    }}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <ChevronDownIcon />
                  </Div>
                  {iconPerNotificationManagementModule[module]}
                  <Text
                    variant="caption"
                    css={{
                      color: '$interface_dark_down',
                    }}
                  >
                    {labelPerNotificationManagementModule[module]}
                  </Text>
                </Div>
              </NotificationTableCell>
              <NotificationTableCell align="center" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                    textAlign: 'center',
                  }}
                >
                  Notificación
                </Text>
              </NotificationTableCell>
              <NotificationTableCell align="center" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                  }}
                >
                  Fecha base
                </Text>
              </NotificationTableCell>
              <NotificationTableCell align="center" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                  }}
                >
                  Horario
                </Text>
              </NotificationTableCell>
              <NotificationTableCell align="center" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                    textAlign: 'center',
                  }}
                >
                  Activo
                </Text>
              </NotificationTableCell>
              <NotificationTableCell align="right" valign="middle">
                <Text
                  variant="caption"
                  css={{
                    color: '$interface_dark_down',
                    paddingRight: '$4',
                  }}
                >
                  Opciones
                </Text>
              </NotificationTableCell>
            </NotificationTableRow>
          </NotificationTableHead>
          <NotificationTableBody>
            {isOpen &&
              notificationsSchedulesByModule?.map((notification, index) => (
                <NotificationManagementRow
                  key={notification.id}
                  index={index}
                  notification={notification}
                />
              ))}
            {shouldShowAddNotification && (
              <AddNotificationRow
                module={module}
                closeAddNotification={() => setShouldShowAddNotification(false)}
              />
            )}
          </NotificationTableBody>
        </NotificationTable>

        {!notificationsSchedulesByModule?.length && (
          <Div
            css={{
              padding: '$4',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 40,
            }}
          >
            <Text variant="caption" css={{ color: '$interface_dark_down' }}>
              No hay notificaciones configuradas
            </Text>
          </Div>
        )}

        <Div
          css={{
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant={
              shouldShowAddNotification ? 'secondaryCritical' : 'secondary'
            }
            size="sm"
            type="button"
            onClick={() =>
              setShouldShowAddNotification(!shouldShowAddNotification)
            }
          >
            {shouldShowAddNotification ? <XMarkIcon /> : <PlusIcon />}
            {shouldShowAddNotification ? 'Cancelar' : 'Añadir'}
          </Button>
        </Div>
      </Div>
    </Div>
  )
}
