import { Avatar, Div } from '@/components'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { CompanyPlaceMapMarker } from '@/pages/marcaciones/Map'
import GoogleMapReact from 'google-map-react'
import { useState } from 'react'

interface PunchLocation {
  latitude: number | undefined
  longitude: number | undefined
  pictureUrl: string
}

const Marker = ({ lat, lng, url }: any) => (
  <Div
    css={{
      width: 20,
      height: 20,
      borderRadius: '50%',
      backgroundColor: '$brand_primary_pure',
      position: 'relative',
    }}
  >
    {!!url && (
      <Div
        css={{
          position: 'absolute',
          bottom: '100%',
          left: '-50%',
          height: 40,
          width: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '$brand_primary_pure',
          borderRadius: '100%',
        }}
      >
        <Avatar src={url} alt="" height={30} width={30} />
      </Div>
    )}
  </Div>
)

export const MapComponent = ({
  latitude,
  longitude,
  pictureUrl,
}: PunchLocation) => {
  const { data: company } = useMyCompany()
  const defaultProps = {
    center: {
      lat: latitude || 0,
      lng: longitude || 0,
    },
    zoom: 15,
  }
  const [isZooming, setIsZooming] = useState(false)
  const [zoom, setZoom] = useState(defaultProps.zoom)

  return (
    <Div css={{ height: 400 }}>
      <GoogleMapReact
        // yesIWantToUseGoogleMapApiInternals
        // bootstrapURLKeys={{ key: '' }}
        bootstrapURLKeys={{
          key: 'AIzaSyAcsDF9M9gUPgVFb1MtnuXPaMA18eX2hsQ',
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onChange={({ zoom }) => {
          setZoom(zoom)
        }}
        onZoomAnimationStart={() => setIsZooming(true)}
        onZoomAnimationEnd={() => setIsZooming(false)}
      >
        {latitude &&
          longitude &&
          !Number.isNaN(+latitude) &&
          !Number.isNaN(+longitude) && (
            <Marker lat={latitude} lng={longitude} url={pictureUrl} />
          )}

        {company?.companyPlaces.map((place) => {
          if (Number.isNaN(+place.latitude) || Number.isNaN(+place.longitude)) {
            return null
          }

          return (
            <CompanyPlaceMapMarker
              key={place.id}
              place={place}
              lat={+place.latitude}
              lng={+place.longitude}
              zoom={zoom}
              showBorder={!isZooming}
            />
          )
        })}
      </GoogleMapReact>
    </Div>
  )
}
