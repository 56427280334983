import { INotificationsSchedules } from '@/libs/react-query/types/notifications'
import {
  NotificationTableCell,
  NotificationTableRow,
} from '../NotificationTable.styles'
import { Div } from '@/components'
import { Button, Switch, Text } from '@punto-ui/react'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import {
  notificationReferenceDateValueToLabel,
  notificationTimeValueToLabel,
} from '../notifications'
import { useState } from 'react'
import { useHandleDeleteNotifications } from '@/libs/react-query/mutations/notifications/useHandleDeleteNotification'
import { EditNotificationRow } from './EditNotificationRow'

const MAIN_COLUMNS_WIDTH = 220

export const NotificationManagementRow = ({
  index,
  notification,
}: {
  index: number
  notification: INotificationsSchedules
}) => {
  const {
    mutate: handleDeleteNotification,
    isLoading: isLoadingDeleteNotification,
  } = useHandleDeleteNotifications(notification.id)

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  if (isEditing) {
    return (
      <EditNotificationRow
        notification={notification}
        closeEditNotification={() => setIsEditing(false)}
      />
    )
  }

  return (
    <NotificationTableRow
      css={{
        background: index % 2 === 0 ? '$interface_light_up' : 'transparent',

        '&:hover': {
          background: '$interface_light_down',
        },
      }}
    >
      <NotificationTableCell
        css={{
          paddingLeft: '64px',
        }}
      >
        <Div css={{ display: 'flex', alignItems: 'center', gap: '$2' }}>
          <Text variant="caption" css={{ color: '$interface_dark_down' }}>
            {notification.name}
          </Text>
        </Div>
      </NotificationTableCell>
      <NotificationTableCell
        align="center"
        valign="middle"
        width={MAIN_COLUMNS_WIDTH}
      >
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text variant="caption" css={{ color: '$interface_dark_down' }}>
            {notificationTimeValueToLabel(
              notification.notification_time_before_reference_date,
            )}
          </Text>
        </Div>
      </NotificationTableCell>
      <NotificationTableCell
        align="center"
        valign="middle"
        width={MAIN_COLUMNS_WIDTH}
      >
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {notificationReferenceDateValueToLabel(
            notification.reference_date,
            notification.module,
          )}
        </Text>
      </NotificationTableCell>
      <NotificationTableCell
        align="center"
        valign="middle"
        width={MAIN_COLUMNS_WIDTH}
      >
        <Text variant="caption" css={{ color: '$interface_dark_down' }}>
          {notification.notification_hour}
        </Text>
      </NotificationTableCell>
      <NotificationTableCell
        align="center"
        valign="middle"
        width={MAIN_COLUMNS_WIDTH}
      >
        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Switch checked={notification.is_active} onChange={() => null} />
        </Div>
      </NotificationTableCell>
      <NotificationTableCell
        align="right"
        valign="middle"
        width={MAIN_COLUMNS_WIDTH}
      >
        <Div
          css={{
            paddingRight: '$4',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '$4',
          }}
        >
          <Div>
            <Button
              variant="tertiaryCritical"
              isLoading={isLoadingDeleteNotification}
              onClick={() => {
                if (isConfirmingDelete) {
                  handleDeleteNotification(notification.id)
                }

                setIsConfirmingDelete(!isConfirmingDelete)
              }}
            >
              {isConfirmingDelete ? 'Confirmar' : null}
              <TrashIcon />
            </Button>
          </Div>
          <Div
            onClick={() => {
              setIsEditing(true)
              setIsConfirmingDelete(false)
            }}
            css={{
              height: 16,
              width: 16,

              svg: {
                color: '$interface_dark_down',
                height: 16,
                width: 16,
                cursor: 'pointer',
              },
            }}
          >
            <PencilSquareIcon />
          </Div>
        </Div>
      </NotificationTableCell>
    </NotificationTableRow>
  )
}
