import { IWorkerUser } from '@/libs/react-query/types'
import dayjs from 'dayjs'

export const getLiquidationLabel = (
  liq?: IWorkerUser['liquidations'][0],
  name?: string,
) => {
  if (!liq && name) {
    return `Liquidación de ${name}`
  }

  if (!liq) {
    return 'Liquidación'
  }

  let base = 'Liquidación'

  if (name) {
    base = `${base} de ${name}`
  }

  const inactivationDate = liq.inactivation_date
    ? dayjs(liq.inactivation_date).utc().format('DD/MM/YYYY')
    : 'Sin fecha'

  const hiringDate = liq.hiring_date
    ? dayjs(liq.hiring_date).utc().format('DD/MM/YYYY')
    : ''

  if (hiringDate && inactivationDate) {
    return `${base} (${hiringDate} - ${inactivationDate})`
  }

  if (inactivationDate) {
    return `${base} (${inactivationDate})`
  }

  if (hiringDate) {
    return `${base} (${hiringDate})`
  }

  return base
}
