import { ControlledFilterBar, Div } from '@/components'
import { NotificationManagementModule } from './NotificationManagementModule'
import { useNotificationsSchedules } from '@/libs/react-query/hooks/notifications/useNotificationsSchedules'
import { FormProvider, useForm } from 'react-hook-form'
import { Progress } from '@punto-ui/react'

export const NotificationManagement = () => {
  const methods = useForm()

  const {
    isFetching: isFetchingNotificationsSchedules,
    isLoading: isLoadingNotificationsSchedules,
  } = useNotificationsSchedules()

  return (
    <FormProvider {...methods}>
      <ControlledFilterBar
        policiesIds={[]}
        isLoading={isFetchingNotificationsSchedules}
      />
      <Div
        css={{
          padding: '$4',
          display: 'flex',
          flexDirection: 'column',
          gap: '$4',
        }}
      >
        {isLoadingNotificationsSchedules ? (
          <Progress />
        ) : (
          <>
            <NotificationManagementModule module="vacations" />
            <NotificationManagementModule module="worker" />
          </>
        )}
      </Div>
    </FormProvider>
  )
}
