import { INotificationScheduleTypes } from '@/libs/react-query/types/notifications'
import { SunIcon, UsersIcon } from '@heroicons/react/24/outline'
import { TagProps } from '@punto-ui/react'

export interface INotificationManagementModuleProps {
  module: INotificationScheduleTypes
}

export const labelPerNotificationManagementModule = {
  vacations: 'Vacaciones',
  worker: 'Colaboradores',
}

export const iconPerNotificationManagementModule: Record<
  INotificationManagementModuleProps['module'],
  React.ReactNode
> = {
  vacations: <SunIcon />,
  worker: <UsersIcon />,
}

export const tagVariantPerNotificationManagementModule: Record<
  INotificationManagementModuleProps['module'],
  TagProps['variant']
> = {
  vacations: 'warning',
  worker: 'neutral',
}

export const notificationTimeBeforeReferenceDateOptions: Array<{
  label: string
  value: string
}> = [
  { label: 'En la fecha', value: 'in_the_date' },
  { label: '1 día antes', value: '1_day_before' },
  { label: '3 días antes', value: '3_days_before' },
  { label: '7 días antes', value: '7_days_before' },
  { label: '15 días antes', value: '15_days_before' },
  { label: '20 días antes', value: '20_days_before' },
  { label: '30 días antes', value: '30_days_before' },
]

export const notificationTimeValueToLabel = (value: string) => {
  return notificationTimeBeforeReferenceDateOptions.find(
    (option) => option.value === value,
  )?.label
}

export const notificationReferenceDateValueToLabel = (
  value: string,
  module: INotificationManagementModuleProps['module'],
) => {
  return notificationsReferenceDatesOptionsByModule[module].find(
    (option) => option.value === value,
  )?.label
}

export const notificationsReferenceDatesOptionsByModule: Record<
  INotificationManagementModuleProps['module'],
  Array<{ label: string; value: string }>
> = {
  vacations: [
    {
      label: 'Causamiento Vacaciones',
      value: 'causing_date',
    },
    {
      label: 'Vencimiento Vacaciones',
      value: 'expiration_date',
    },
  ],
  worker: [
    {
      label: 'Fecha nacimiento',
      value: 'birthday',
    },
    {
      label: 'Fecha de ingreso',
      value: 'hiring_date',
    },
    // {
    //   label: 'Fecha inscripción IPS',
    //   value: 'ips_entrance_date',
    // },
  ],
}
