import { Div, FormGroup, FormRenderer } from '@/components'
import {
  useHoursAmountForm,
  useShiftGeneralInfo,
  useToleranceForm,
  useDiscountOptions,
  useFixedShiftConfigurations,
} from './forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { AddShiftData } from './form'
import { Divider, Text } from '@punto-ui/react'

export const ConfigurationsForm = () => {
  const { control } = useFormContext<AddShiftData>()

  const { data: morningHoursAmountForm } = useHoursAmountForm({
    isFlexibleMorning: true,
  })
  const { data: mixedHoursAmountForm } = useHoursAmountForm({
    isFlexibleMixed: true,
  })
  const { data: nightHoursAmountForm } = useHoursAmountForm({
    isFlexibleNight: true,
  })
  const { data: discountOptions } = useDiscountOptions()
  const { data: shiftGeneralInfoForm } = useShiftGeneralInfo()
  const { data: toleranceForm } = useToleranceForm()
  const { data: fixedShiftConfigurations } = useFixedShiftConfigurations()

  const journeyType = useWatch({
    control,
    name: 'journeyType',
  })

  const isFlexible = journeyType === 'flexible'
  const isFixed = journeyType === 'fixed'

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',

        padding: '$4',
        backgroundColor: '$interface_light_pure',
        borderRadius: '$lg',
        border: '1px solid',
        borderColor: '$interface_light_down',

        minWidth: 1000,
      }}
    >
      <Text
        css={{
          marginBottom: '$2',
        }}
      >
        Configuraciones Basicas
      </Text>
      <FormGroup items={shiftGeneralInfoForm} cols={4} />

      <Div css={{ marginBottom: '$4' }} />
      <Divider
        css={{
          height: 1,
          width: '100%',
          background: '$interface_light_down',
        }}
      />
      <Div css={{ marginBottom: '$4' }} />

      {isFixed && (
        <>
          <Text>
            Configuraciones de <strong>jornada fija</strong>
          </Text>
          <Div css={{ marginBottom: '$4' }} />
          <FormGroup items={toleranceForm} cols={4} />
          <Div css={{ marginBottom: '$4' }} />
          <FormGroup items={fixedShiftConfigurations} cols={4} />
        </>
      )}
      {isFlexible && (
        <>
          <Text>
            Configuraciones de <strong>jornada flexible</strong>
          </Text>
          <Div css={{ marginBottom: '$4' }} />
          <Text variant="description">
            Configuraciones de jornada flexible diurna
          </Text>
          <Div css={{ marginBottom: '$2' }} />
          <FormRenderer items={morningHoursAmountForm} cols={3} inline />
          <Div css={{ marginBottom: '$4' }} />
          <Text variant="description">
            Configuraciones de jornada flexible nocturna
          </Text>
          <Div css={{ marginBottom: '$2' }} />
          <FormRenderer items={nightHoursAmountForm} cols={3} inline />
          <Div css={{ marginBottom: '$4' }} />
          <Text variant="description">
            Configuraciones de jornada flexible mixta
          </Text>
          <Div css={{ marginBottom: '$2' }} />
          <FormRenderer items={mixedHoursAmountForm} cols={3} inline />
          <Div css={{ marginBottom: '$4' }} />
          <FormRenderer items={discountOptions} cols={3} inline />
        </>
      )}
    </Div>
  )
}
