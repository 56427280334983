import { z } from 'zod'

export const notificationsStateSchema = z.object({
  notificationsList: z.object({
    name: z.string(),
    cellsIds: z.array(z.string()),
    page: z.number(),
    perPage: z.number(),
    type: z.string().array(),
    readFilter: z.array(z.string()),
    notificationModules: z.array(z.string()),
  }),
})

export type INotificationsStateSchema = z.infer<typeof notificationsStateSchema>

export const INITIAL_NOTIFICATIONS_STATE: INotificationsStateSchema = {
  notificationsList: {
    name: '',
    cellsIds: [],
    page: 1,
    perPage: 20,
    type: [],
    readFilter: ['read', 'unread'],
    notificationModules: [],
  },
}
