import { useEffect, useMemo } from 'react'
import { IRouteAction } from '@/pages/router'
import { CheckIcon, DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { UseFormReturn, useWatch } from 'react-hook-form'
import useToast from '@/hooks/useToast'
import { useMe } from '@/libs/react-query/hooks'
import { ReceiptBuilderSchemaType } from './type'
import { useHandleCreateReceipt } from '@/libs/react-query/mutations/reports/useHandleCreateReceipt'
import { useHandleUpdateReceipt } from '@/libs/react-query/mutations/reports/useHandleUpdateReceipt'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
import { useCan } from '@/hooks'

export const useHandleReceiptBuilderActions = ({
  methods,
  isLoading,
}: {
  methods: UseFormReturn<ReceiptBuilderSchemaType>
  isLoading?: boolean
}) => {
  const canCreateReceipt = useCan(['reports.createReceipt'])
  const { data: isReceiptBuilderAvailable, detail: receiptBuilderDetail } =
    useIsFeatureInPlan('receipt-builder')
  const { data: me } = useMe()

  const { setActions, removeTab, setTab, tabs } = useTabStore((state) => ({
    removeTab: state.removeTab,
    setTab: state.setActiveTab,
    setActions: state.setActionsTab,
    tabs: state.reportsV2.tabs,
  }))

  const toast = useToast()

  const [
    isUpdating,
    isMyReport,
    footerType,
    headerType,
    receiptName,
    receiptType,
    receiptData,
  ] = useWatch({
    control: methods.control,
    name: [
      'id',
      'identifier_id',
      'footerConfig.type',
      'headerConfig.type',
      'configurations.title',
      'configurations.type',
      'data',
    ],
  })

  const {
    mutateAsync: handleCreateReceipt,
    isLoading: isLoadingCreateReceipt,
  } = useHandleCreateReceipt()
  const {
    mutateAsync: handleUpdateReceipt,
    isLoading: isLoadingUpdateReceipt,
  } = useHandleUpdateReceipt()

  const disabledMessage = useMemo(() => {
    if (!isReceiptBuilderAvailable) {
      return receiptBuilderDetail
    }

    if (!headerType) {
      return 'Debe selecionar um tipo de encabezado'
    }

    if (!receiptName) {
      return 'Debe ingresar un nombre para el recibo'
    }

    if (!receiptType) {
      return 'Debe selecionar un tipo de recibo'
    }

    if (!receiptData.some((row) => row.includedConceptsInRow?.length)) {
      return 'El recibo debe conter al menos un concepto'
    }

    if (!footerType) {
      return 'Debe selecionar un tipo de pie de página'
    }

    return ''
  }, [
    headerType,
    footerType,
    receiptName,
    receiptType,
    receiptData,
    isReceiptBuilderAvailable,
    receiptBuilderDetail,
  ])

  useEffect(() => {
    const actions: IRouteAction[] = []

    const isLoadingOrDisabled =
      isLoading || isLoadingCreateReceipt || isLoadingUpdateReceipt

    const isDisabled = !isReceiptBuilderAvailable

    if (isUpdating) {
      actions.push({
        action: async () => {
          try {
            const values = methods.getValues()

            await handleCreateReceipt({
              name: `${values.configurations.name} - Copia`,
              title: values.configurations.title,
              description: values.configurations.description,
              type: values.configurations.type,
              favorite: values.configurations.isFavorite,
              withoutAmount: values.configurations.isWithoutQuantities,
              headerType: values.headerConfig.type,
              headerTitle: values.headerConfig.title,
              footerType: values.footerConfig.type,
              footerCity: values.footerConfig.city,
              concepts: values.data
                .filter((v) => !!v.includedConceptsInRow?.length)
                .map((row) => ({
                  label: row.conceptLabel.value as string,
                  values: row.includedConceptsInRow,
                })),
            })

            toast.addToast({
              title: 'Recibo duplicado',
              type: 'positive',
              description: 'El recibo ha sido duplicado con éxito',
              id: Date.now(),
            })
          } catch (err) {
            console.log(err)

            toast.addToast({
              title: 'Error al duplicar recibo',
              type: 'negative',
              description: '',
              id: Date.now(),
            })
          }
        },
        label: 'Duplicar Recibo',
        width: 180,
        disabled: !canCreateReceipt || isLoadingOrDisabled,
        isLoading: isLoadingOrDisabled || isDisabled,
        variant: 'secondary',
        icon: isUpdating ? <CheckIcon /> : <DocumentArrowDownIcon />,
      })
    }

    if (!isUpdating || isMyReport || me?.isAdmin) {
      actions.push({
        action: async () => {
          const values = methods.getValues()

          try {
            if (isUpdating) {
              await handleUpdateReceipt({
                id: values.id,
                name: values.configurations.name,
                title: values.configurations.title,
                description: values.configurations.description,
                type: values.configurations.type,
                favorite: values.configurations.isFavorite,
                headerType: values.headerConfig.type,
                withoutAmount: values.configurations.isWithoutQuantities,
                headerTitle: values.headerConfig.title,
                footerType: values.footerConfig.type,
                footerCity: values.footerConfig.city,
                concepts: values.data.map((row) => ({
                  label: row.conceptLabel.value as string,
                  values: row.includedConceptsInRow,
                })),
              })
            } else {
              await handleCreateReceipt({
                name: values.configurations.name,
                title: values.configurations.title,
                description: values.configurations.description,
                type: values.configurations.type,
                favorite: values.configurations.isFavorite,
                withoutAmount: values.configurations.isWithoutQuantities,
                headerType: values.headerConfig.type,
                headerTitle: values.headerConfig.title,
                footerType: values.footerConfig.type,
                footerCity: values.footerConfig.city,
                concepts: values.data.map((row) => ({
                  label: row.conceptLabel.value as string,
                  values: row.includedConceptsInRow,
                })),
              })

              const tab = tabs.find((t) => t.type === 'add-receipt')

              if (tab) {
                removeTab('reportsV2', tab.id)
                setTab('reportsV2', 'receipt-hub')
              }
            }

            toast.addToast({
              title: isUpdating
                ? 'Recibo actualizado'
                : 'Recibo creado con éxito',
              type: 'positive',
              description: isUpdating
                ? 'El recibo ha sido actualizado con éxito'
                : 'El recibo ha sido creado con éxito',
              id: Date.now(),
            })
          } catch (error) {
            console.log(error)
            toast.addToast({
              title: 'Error al crear recibo',
              type: 'negative',
              description: '',
              id: Date.now(),
            })
          }
        },
        label: isUpdating ? 'Actualizar Recibo' : 'Crear Recibo',
        width: 180,
        disableMessage: disabledMessage,
        disabled:
          !canCreateReceipt ||
          isLoadingOrDisabled ||
          !!disabledMessage ||
          isDisabled,
        isLoading: isLoadingOrDisabled,
        variant: 'primary',
        icon: isUpdating ? <CheckIcon /> : <DocumentArrowDownIcon />,
      })
    }

    setActions('reportsV2', actions)
  }, [
    isReceiptBuilderAvailable,
    canCreateReceipt,
    disabledMessage,
    me?.isAdmin,
    isMyReport,
    toast,
    isUpdating,
    isLoading,
    methods,
    setActions,
    isLoadingCreateReceipt,
    isLoadingUpdateReceipt,
    handleUpdateReceipt,
    handleCreateReceipt,
  ])
}
