import { useEffect } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import { useTabStore } from '@/store'
import {
  BookmarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentIcon,
  DocumentTextIcon,
  LockClosedIcon,
  LockOpenIcon,
  PlusIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline'
import { IRouteAction } from '@/pages/router'
import { AguinaldoPlaygroundTableType } from '../types'
import { useAguinaldoPlaygroundStore } from '../store'
import { useHandleCreateAguinaldo } from '@/libs/react-query/mutations/aguinaldo/useHandleCreateAguinaldo'
import { useHandleUpdateAguinaldoConfig } from '@/libs/react-query/mutations/aguinaldo/useHandleUpdateAguinaldoConfig'
import { useCan } from '@/hooks'
import useToast from '@/hooks/useToast'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { useExportReceiptModal } from '@/components/ExportReceipt'

export const useHandleStepActions = (
  methods: UseFormReturn<AguinaldoPlaygroundTableType>,
  id?: string,
  raiseConfirmCloseModal?: () => void,
) => {
  const {
    currentStep,
    handleNextStep,
    handlePreviousStep,
    canGoBack,
    canGoNext,
    handleOpenDrawer,
  } = useAguinaldoPlaygroundStore((state) => ({
    handleNextStep: state.handleNextStep,
    handlePreviousStep: state.handlePreviousStep,
    canGoBack: state.canGoBack,
    currentStep: state.currentStepValue,
    handleOpenDrawer: state.handleOpenDrawer,
    canGoNext: state.canGoNext,
  }))

  const toast = useToast()

  const { data: company } = useMyCompany()

  const { control, getValues } = methods
  const {
    mutateAsync: handleCreateAguinaldo,
    isLoading: isLoadingCreateAguinaldo,
  } = useHandleCreateAguinaldo()

  const {
    mutateAsync: handleUpdateAguinaldoSettings,
    isLoading: isLoadingAguinaldoSettingsUpdate,
  } = useHandleUpdateAguinaldoConfig()

  const { addActions, removeTab, changeTab } = useTabStore((state) => ({
    addActions: state.setActionsTab,
    removeTab: state.removeTab,
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const [name, usersIds] = useWatch({
    control,
    name: ['name', 'users_ids'],
  })

  const canClosePayroll = useCan(['payroll.aguinaldo'])
  const { handleOpenExportReceiptModal } = useExportReceiptModal()

  useEffect(() => {
    const values = getValues()

    const errorMessage = !name
      ? 'Nombre es requerido'
      : !usersIds.length
      ? 'Debe seleccionar al menos un colaborador'
      : ''

    if (id) {
      const actions: IRouteAction[] = [
        {
          label: 'Volver',
          icon: <ChevronLeftIcon />,
          variant: 'secondary' as const,
          action: handlePreviousStep,
          size: 'sm',
          disabled: !canGoBack,
        },
        {
          label: 'Avanzar',
          icon: <ChevronRightIcon />,
          size: 'sm',
          disabled: !canGoNext,
          variant: 'secondary' as const,
          action: handleNextStep,
        },
      ]

      if (values.status === 'closed') {
        actions.unshift({
          label: 'Planilla Cerrada',
          icon: <LockOpenIcon />,
          size: 'sm',
          width: 150,
          disabled: true,
          disableMessage: 'No se puede editar una planilla cerrada',
          variant: 'primary' as const,
          action: () => null,
        })
      }

      if (currentStep === 'CONFIG' && values.status === 'open') {
        actions.push({
          size: 'sm',
          label: 'Actualizar',
          icon: <BookmarkIcon />,
          variant: 'primary' as const,
          disabled: !!errorMessage || isLoadingAguinaldoSettingsUpdate,
          disableMessage: errorMessage,
          isLoading: isLoadingAguinaldoSettingsUpdate,
          width: 120,
          action: async () => {
            const values = getValues()

            const orderedCompanyConcepts = [...(company?.concepts || [])]
            orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

            const includedConcepts = orderedCompanyConcepts.filter(
              (_, index) => values.configuration.concepts[index],
            )

            await handleUpdateAguinaldoSettings({
              id,
              description: values.description || undefined,
              isFavorite: !!values.isFavorite,
              name: values.name,
              usersIds: values.users_ids,
              antecipate: values.configuration.antecipate,
              bonus: values.configuration.bonus,
              commission: values.configuration.commission,
              excess: values.configuration.excess,
              extraMorningHours: values.configuration.morningExtraHours,
              extraNightHours: values.configuration.nightExtraHours,
              discountMorningHours: values.configuration.morningDiscountHours,
              discountNightHours: values.configuration.nightDiscountHours,
              familiarBonus: values.configuration.familyBonus,
              gift: values.configuration.gift,
              holidayMorningHours: values.configuration.morningHolidayHours,
              holidayNightHours: values.configuration.nightHolidayHours,
              ips16: values.configuration.ips16,
              ips9: values.configuration.ips9,
              judicial: values.configuration.judicial,
              loan: values.configuration.loan,
              lost: values.configuration.lost,
              mobility: values.configuration.mobility,
              ordinaryMorningHours: values.configuration.morningOrdinaryHours,
              ordinaryNightHours: values.configuration.nightOrdinaryHours,
              otherDiscount: values.configuration.otherDiscount,
              otherEarns: values.configuration.otherPayments,
              payments: values.configuration.payments,
              purchase: values.configuration.purchase,
              year: values.year,
              concepts: includedConcepts,
              vacation_value: values.configuration.vacation_value,
            })
          },
        })
      } else if (values.status === 'open' && canClosePayroll) {
        actions.push({
          label: 'Cerrar Aguinaldo',
          icon: <LockClosedIcon />,
          variant: 'primary' as const,
          size: 'sm',
          disableMessage: errorMessage,
          isLoading: isLoadingAguinaldoSettingsUpdate,
          width: 150,
          action: async () => {
            raiseConfirmCloseModal?.()
          },
        })
      }

      actions.push({
        label: 'Exportar Recibo',
        icon: <DocumentTextIcon />,
        variant: 'secondary' as const,
        disabled: !!errorMessage || isLoadingAguinaldoSettingsUpdate,
        disableMessage: errorMessage,
        size: 'sm',
        isLoading: false,
        width: 130,
        action: async () => {
          const values = getValues()
          const value = (values.data?.[0]?.total?.value || 0) as number

          handleOpenExportReceiptModal({
            type: 'aguinaldo',
            usersIds,
            date: values.year,
            value,

            aguinaldoId: values.id,
            title: values.name,
            description: values.description,
          })
        },
      })

      actions.push({
        label: 'Actualizar en Lote',
        size: 'sm',
        icon: <QueueListIcon />,
        variant: 'secondary' as const,
        disabled: !!errorMessage || isLoadingAguinaldoSettingsUpdate,
        disableMessage: errorMessage,
        isLoading: isLoadingAguinaldoSettingsUpdate,
        width: 160,
        action: async () => {
          handleOpenDrawer('BATCH_EDITING', {})
        },
      })

      addActions('payroll', actions)
    } else {
      addActions('payroll', [
        {
          label: isLoadingCreateAguinaldo
            ? 'Creando... Eso puede tardar unos minutos'
            : 'Crear',
          icon: <PlusIcon />,
          variant: isLoadingCreateAguinaldo
            ? ('secondary' as const)
            : 'primary',
          disabled: !!errorMessage,
          size: 'sm',
          width: isLoadingCreateAguinaldo ? 320 : 80,
          disableMessage: errorMessage,
          isLoading: isLoadingCreateAguinaldo,
          action: async () => {
            const values = getValues()
            try {
              const orderedCompanyConcepts = [...(company?.concepts || [])]
              orderedCompanyConcepts.sort((a, b) => a.localeCompare(b))

              const includedConcepts = orderedCompanyConcepts.filter(
                (_, index) => values.configuration.concepts[index],
              )

              await handleCreateAguinaldo({
                description: values.description,
                antecipate: values.configuration.antecipate,
                bonus: values.configuration.bonus,
                commission: values.configuration.commission,
                excess: values.configuration.excess,
                extraMorningHours: values.configuration.morningExtraHours,
                extraNightHours: values.configuration.nightExtraHours,
                discountMorningHours: values.configuration.morningDiscountHours,
                discountNightHours: values.configuration.nightDiscountHours,
                familiarBonus: values.configuration.familyBonus,
                gift: values.configuration.gift,
                holidayMorningHours: values.configuration.morningHolidayHours,
                holidayNightHours: values.configuration.nightHolidayHours,
                ips16: values.configuration.ips16,
                ips9: values.configuration.ips9,
                judicial: values.configuration.judicial,
                loan: values.configuration.loan,
                lost: values.configuration.lost,
                mobility: values.configuration.mobility,
                ordinaryMorningHours: values.configuration.morningOrdinaryHours,
                ordinaryNightHours: values.configuration.nightOrdinaryHours,
                otherDiscount: values.configuration.otherDiscount,
                otherEarns: values.configuration.otherPayments,
                payments: values.configuration.payments,
                purchase: values.configuration.purchase,
                isFavorite: !!values.isFavorite,
                name: values.name,
                year: values.year,
                usersIds: values.users_ids,
                concepts: includedConcepts,
                vacation_value: values.configuration.vacation_value,
              })

              const toastId = Date.now()
              toast.addToast({
                title: 'Aguinaldo creado',
                description: 'El aguinaldo se ha creado correctamente',
                type: 'positive',
                id: toastId,
              })

              changeTab('payroll', 'aguinaldo')
              removeTab('payroll', 'new-aguinaldo')
            } catch (error) {
              const toastId = Date.now()
              toast.addToast({
                title: 'Error al crear aguinaldo',
                description: 'Ocurrio un error al crear el aguinaldo',
                type: 'negative',
                id: toastId,
              })
            }
          },
        },
      ])
    }
  }, [
    canClosePayroll,
    addActions,
    control,
    getValues,
    name,
    usersIds,
    handleCreateAguinaldo,
    isLoadingCreateAguinaldo,
    isLoadingAguinaldoSettingsUpdate,
    changeTab,
    removeTab,
    id,
    canGoBack,
    canGoNext,
    handleNextStep,
    handlePreviousStep,
    currentStep,
    handleUpdateAguinaldoSettings,
  ])
}
