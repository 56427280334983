import { IRouteAction } from '@/pages/router'
import { deeplyCloneObject } from '@/utils/object'
import { create } from 'zustand'

type TabTypes =
  | 'payroll'
  | 'punches'
  | 'worker'
  | 'shifts'
  | 'permissions'
  | 'reports'
  | 'vacations'
  | 'requests'
  | 'reportsV2'
  | 'configurations'
  | 'admin'
  | 'notifications'
  | 'imports'

interface ITabStore {
  type: string
  id: string
  metadata?: any
  label?: string
}

type ITabStoreProps<T> = {
  tabs: ITabStore[]
  activeTab: string
  actions: IRouteAction[]
  metadata?: T
}

interface TabStoreTypes {
  punches: ITabStoreProps<{
    isAutomaticCompleteEnabled: boolean
  }>
  payroll: ITabStoreProps<any>
  worker: ITabStoreProps<any>
  shifts: ITabStoreProps<any>
  permissions: ITabStoreProps<any>
  reports: ITabStoreProps<any>
  vacations: ITabStoreProps<any>
  requests: ITabStoreProps<any>
  reportsV2: ITabStoreProps<any>
  configurations: ITabStoreProps<any>
  admin: ITabStoreProps<any>
  notifications: ITabStoreProps<any>
  imports: ITabStoreProps<any>
  addTab: (name: TabTypes, tabs: ITabStore[]) => void
  removeTab: (name: TabTypes, id: string) => void
  setActiveTab: (name: TabTypes, id: string) => void
  setMetadata: (name: TabTypes, metadata: any) => void
  setTabMetadata: (name: TabTypes, id: string, metadata: any) => void
  setActionsTab: (name: TabTypes, actions: IRouteAction[]) => void
  reset: () => void
}

const INITIAL_STATE = {
  notifications: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  shifts: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  configurations: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  punches: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  payroll: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  worker: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  permissions: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  reports: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  reportsV2: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  vacations: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  requests: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  admin: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
  imports: {
    tabs: [],
    activeTab: '',
    actions: [],
  },
}

export const useTabStore = create<TabStoreTypes>((set, get) => {
  return {
    ...deeplyCloneObject(INITIAL_STATE),
    addTab: (name: TabTypes, tabs: ITabStore[]) => {
      set((state) => {
        let mergedTabs = state[name].tabs

        for (const tab of tabs) {
          const tabExists = mergedTabs.find((t) => t.id === tab.id)

          if (!tabExists) {
            mergedTabs.push(tab)
          } else {
            mergedTabs = mergedTabs.filter((t) => t.id !== tab.id)
            mergedTabs.push(tab)
          }
        }

        return {
          ...state,
          [name]: {
            ...state[name],
            tabs: mergedTabs,
          },
        }
      })
    },
    removeTab: (name: TabTypes, id: string) => {
      set((state) => {
        return {
          ...state,
          [name]: {
            ...state[name],
            tabs: state[name].tabs.filter((tab) => tab.id !== id),
          },
        }
      })
    },
    setActiveTab: (name: TabTypes, id: string) => {
      set((state) => {
        const newState = {
          ...state,
          [name]: {
            ...state[name],
            activeTab: id,
          },
        }
        return newState
      })
    },
    setActionsTab: (name: TabTypes, actions: IRouteAction[]) => {
      set((state) => {
        return {
          ...state,
          [name]: {
            ...state[name],
            actions,
          },
        }
      })
    },
    reset: () => {
      set(() => ({ ...deeplyCloneObject(INITIAL_STATE) }))
    },
    setMetadata: (name: TabTypes, metadata: any) => {
      set((state) => {
        return {
          ...state,
          [name]: {
            ...state[name],
            metadata,
          },
        }
      })
    },
    setTabMetadata: (name: TabTypes, id: string, metadata: any) => {
      set((state) => {
        return {
          ...state,
          [name]: {
            ...state[name],
            tabs: state[name].tabs.map((tab) =>
              tab.id === id ? { ...tab, metadata } : tab,
            ),
          },
        }
      })
    },
  }
})
