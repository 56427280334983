import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import { IMonth } from './types'

dayjs.extend(utc)
dayjs.extend(isBetween)

// Input: 2024-10-11T00:00:00.000Z
// Output: Date object: 2024-10-11T00:00:00.000Z
export function getUtcDateFromString(input: string): Date {
  const inputString = dayjs(input).utc().format('YYYY-MM-DD')
  const hoursString = dayjs(input).utc().format('HH:mm:ss')
  const output = dayjs(`${inputString} ${hoursString}`, 'YYYY-MM-DD HH:mm:ss')
    .utc()
    .toDate()

  return output
}

// Input: 2024-10-11T03:00:00.000Z in UTC (string)
// Output: 2024-10-11T00:00:00.000Z (string)
export function getLocalStringFromDate(input: string): string {
  if (!input) return ''

  const date = dayjs(input).format('YYYY-MM-DD')
  const hours = dayjs(input).format('HH:mm:ss')
  return `${date}T${hours}.000Z`
}

// Example:
// stringDate: '01/01/2021'
export function stringToDate(stringDate: string): number | null {
  const splited = String(stringDate).split('/')

  if (!splited[0] || !splited[1] || !splited[2]) return null

  const date = new Date(
    Number(splited[2]),
    Number(splited[1]) - 1,
    Number(splited[0]),
  ).setUTCHours(0, 0, 0, 0)

  // const dateOffset = dayjs
  //   .utc(date)
  //   .utcOffset(-date.getTimezoneOffset(), false)
  //   .format('YYYY-MM-DD HH:mm:ss')

  return date
}

export function dateToString(date: dayjs.ConfigType): string {
  const timezoneOffset = dayjs(date).utcOffset()

  return dayjs(date)
    .add(Math.abs(timezoneOffset), 'minutes')
    .format('DD/MM/YYYY')
}

// Input: DD/MM/YYYY
export function parseFormattedDate(dateString: string): Date | null {
  // Verifica se a string segue estritamente o formato DD/MM/YYYY
  if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    return null
  }

  // Extrai dia, mês e ano da string
  const [day, month, year] = dateString.split('/').map(Number)

  // Cria uma data com os valores extraídos
  const date = new Date(year, month - 1, day)

  // Verifica se a data é válida e se corresponde aos valores de entrada
  if (
    date.getFullYear() !== year ||
    date.getMonth() + 1 !== month ||
    date.getDate() !== day
  ) {
    return null
  }

  // Retorna a data se tudo estiver correto
  return date
}

// Experimental:
export function formatMinutes(number: number): string {
  const minutes = Math.round(number)

  const hh = Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0') // horas em formato 'HH'
  const mm = Math.round(minutes % 60)
    .toString()
    .padStart(2, '0') // minutos em formato 'mm'
  return `${hh}:${mm}`
}

export function validateFormattedMinutes(minutes: string): boolean {
  // Allowed format: HH:mm -> 25:00 is not allowed
  const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/g
  return regex.test(minutes)
}

export function validateFormattedMinutesAndSeconds(minutes: string): boolean {
  // Allowed format: HH:mm:ss -> 25:00:00 is not allowed
  const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/g
  return regex.test(minutes)
}

export function validateFormattedMinutesOrEmpty(minutes: string): boolean {
  if (!minutes) return true
  // Allowed format: HH:mm -> 25:00 is not allowed
  const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/g
  return regex.test(minutes)
}

export function validateTotalMinutesOrEmpty(minutes: string): boolean {
  if (!minutes) return true
  // Allowed format: HH:mm -> 25:00 is not allowed
  const regex = /^([0-9][0-9]):([0-5][0-9])$/
  return regex.test(minutes)
}

export function validateDateTimeString(dateTime?: string): boolean {
  if (!dateTime) return true

  const regex = /^([0-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/g
  return regex.test(dateTime)
}

export function minuteToHour(number: number): string {
  if (number === -1 || number === null || number === 0) return '00:00'

  const roundedNumber = Math.round(number)

  const minutes = roundedNumber % 60
  const hours = (roundedNumber - minutes) / 60

  const stringHours = (hours < 10 ? '0' : '') + hours
  const stringMinutes = (minutes < 10 ? '0' : '') + minutes

  return stringHours + ':' + stringMinutes
}

export const fromDateToFormattedHour = (date?: Date | string): string => {
  if (!date) {
    return ''
  }

  return dayjs(date).format('HH:mm')
}

export function formattedHourToMinutes(hour?: string): number {
  if (hour === undefined || hour === null || hour === '') return 0

  const stringHour = String(hour)

  if (stringHour.indexOf(':') === -1) {
    let minutes = 0

    const arrayHour = stringHour.split('')

    // only have minutes
    if (arrayHour.length <= 2) {
      minutes = Number(stringHour)
    } else {
      let hours = 0

      arrayHour.reverse().forEach((item, index) => {
        if (index <= 1) {
          minutes = minutes + Number(item) * (index === 0 ? 1 : index * 10)
        } else {
          const hourIndex = index - 2

          hours = hours + Number(item) * Math.pow(10, hourIndex)
        }
      })

      minutes = minutes + hours * 60
    }

    return minutes
  } else {
    const splited = String(hour)
      .split(':')
      .map((x) => Number(x))
    if (splited.length !== 2) return 0

    return Number((splited[0] || 0) * 60) + Number(splited[1] || 0)
  }
}

export const fromDayMonthYearFormatToDateString = (day?: string) => {
  if (!day) return ''
  const date = dayjs(day, 'DD/MM/YYYY')
  if (date.isValid()) {
    return date.toISOString()
  }

  return ''
}

export const months: IMonth[] = [
  {
    name: 'Enero',
    number: 1,
    jsNumber: 0,
  },
  {
    name: 'Febrero',
    number: 2,
    jsNumber: 1,
  },
  {
    name: 'Marzo',
    number: 3,
    jsNumber: 2,
  },
  {
    name: 'Abril',
    number: 4,
    jsNumber: 3,
  },
  {
    name: 'Mayo',
    number: 5,
    jsNumber: 4,
  },
  {
    name: 'Junio',
    number: 6,
    jsNumber: 5,
  },
  {
    name: 'Julio',
    number: 7,
    jsNumber: 6,
  },
  {
    name: 'Agosto',
    number: 8,
    jsNumber: 7,
  },
  {
    name: 'Septiembre',
    number: 9,
    jsNumber: 8,
  },
  {
    name: 'Octubre',
    number: 10,
    jsNumber: 9,
  },
  {
    name: 'Noviembre',
    number: 11,
    jsNumber: 10,
  },
  {
    name: 'Diciembre',
    number: 12,
    jsNumber: 11,
  },
]

export function handlePeriodWithExcludedDays(dates: Date[]): {
  start: Date
  end: Date
  excluded: Date[]
} {
  const start = dates[0]
  const end = dates[dates.length - 1]
  const excluded = []

  let currentDate = new Date(start)
  while (currentDate <= end) {
    // Check if the current date is not in the 'dates' array
    if (!dates.find((date) => date.getTime() === currentDate.getTime())) {
      excluded.push(new Date(currentDate))
    }
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1)) // Move to the next day
  }

  return {
    start,
    end,
    excluded,
  }
}

export const getHourAndMinuteFromString = (
  HHMM: string,
): {
  hour: number
  minute: number
} => {
  const [hour, minute] = HHMM.split(':').map((x) => Number(x))

  return {
    hour,
    minute,
  }
}

export function isWithinInterval(
  time: string,
  start: string,
  end: string,
): boolean {
  const startDateTimeString = dayjs(`2021-01-01 ${start}`)

  const timeIsInOtherDay = time < start

  const endDateTimeString = dayjs(`2021-01-${start > end ? '02' : '01'} ${end}`)
  const timeDateTimeString = dayjs(
    `2021-01-${timeIsInOtherDay ? '02' : '01'} ${time}`,
  )

  if (
    !timeDateTimeString.isValid() ||
    !endDateTimeString.isValid() ||
    !startDateTimeString.isValid()
  )
    return false

  return timeDateTimeString.isBetween(
    startDateTimeString,
    endDateTimeString,
    null,
    '[)',
  )
}

export const differenceInIntervalsInHours = (start: string, end: string) => {
  const startDateTimeString = dayjs(`2021-01-01 ${start}`)
  const endDateTimeString = dayjs(`2021-01-${start > end ? '02' : '01'} ${end}`)

  const diff = endDateTimeString.diff(startDateTimeString, 'minutes') / 60
  return diff
}

export const getDateWithTime = (date: Date, time: string) => {
  const dateString = dayjs(date).format('YYYY-MM-DD')

  const punchDateWithHour = dayjs(`${dateString} ${time}`, 'YYYY-MM-DD HH:mm')

  return punchDateWithHour.isValid() ? punchDateWithHour.toISOString() : null
}

export const isDateInstance = (date: Date | string): boolean => {
  return date instanceof Date && !isNaN(date.valueOf())
}

const dateHoursFormats: string[] = [
  'D/M/YY HH:mm',
  'DD/M/YY HH:mm',
  'DD/MM/YY HH:mm',
  'D/MM/YY HH:mm',
  'D/M/YYYY HH:mm',
  'DD/M/YYYY HH:mm',
  'DD/MM/YYYY HH:mm',
  'D/MM/YYYY HH:mm',
]

const dateFormats: string[] = [
  'YYYY-MM-DD',
  'D/M/YY',
  'DD/M/YY',
  'DD/MM/YY',
  'D/MM/YY',
  'D/M/YYYY',
  'DD/M/YYYY',
  'DD/MM/YYYY',
  'D/MM/YYYY',
]

export function identifyDateFormat(input: string): string {
  // Regular expressions for each date format
  const regexPatterns: { [key: string]: RegExp } = {
    'YYYY-MM-DD': /^\d{4}-\d{2}-\d{2}$/,
    'D/M/YY': /^\d{1,2}\/\d{1,2}\/\d{2}$/,
    'DD/M/YY': /^\d{2}\/\d{1,2}\/\d{2}$/,
    'DD/MM/YY': /^\d{2}\/\d{2}\/\d{2}$/,
    'D/MM/YY': /^\d{1,2}\/\d{2}\/\d{2}$/,
    'D/M/YYYY': /^\d{1,2}\/\d{1,2}\/\d{4}$/,
    'DD/M/YYYY': /^\d{2}\/\d{1,2}\/\d{4}$/,
    'DD/MM/YYYY': /^\d{2}\/\d{2}\/\d{4}$/,
    'D/MM/YYYY': /^\d{1,2}\/\d{2}\/\d{4}$/,
  }

  // Check each pattern to see if it matches the input
  for (const format of dateFormats) {
    const regex = regexPatterns[format]
    if (regex.test(input)) {
      return format
    }
  }

  return 'no-format' // Return null if no formats match
}

export function identifyDateHourFormat(input: string): string {
  // Regular expressions for each date format
  const regexPatterns: { [key: string]: RegExp } = {
    'D/M/YY HH:mm': /^\d{1,2}\/\d{1,2}\/\d{2} \d{2}:\d{2}$/,
    'DD/M/YY HH:mm': /^\d{2}\/\d{1,2}\/\d{2} \d{2}:\d{2}$/,
    'DD/MM/YY HH:mm': /^\d{2}\/\d{2}\/\d{2} \d{2}:\d{2}$/,
    'D/MM/YY HH:mm': /^\d{1,2}\/\d{2}\/\d{2} \d{2}:\d{2}$/,
    'D/M/YYYY HH:mm': /^\d{1,2}\/\d{1,2}\/\d{4} \d{2}:\d{2}$/,
    'DD/M/YYYY HH:mm': /^\d{2}\/\d{1,2}\/\d{4} \d{2}:\d{2}$/,
    'DD/MM/YYYY HH:mm': /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/,
    'D/MM/YYYY HH:mm': /^\d{1,2}\/\d{2}\/\d{4} \d{2}:\d{2}$/,
  }

  // Check each pattern to see if it matches the input
  for (const format of dateHoursFormats) {
    const regex = regexPatterns[format]
    if (regex.test(input)) {
      return format
    }
  }

  return 'no-format' // Return null if no formats match
}

// export function sortRawMonths(months: number[]): number[] {
//   return months
// }

export function sortRawMonths(rawMonths: number[]): number[] {
  const newMonths = [...rawMonths]

  // Sort the months in ascending order
  newMonths.sort((a, b) => a - b)

  // Find the index where the new year starts
  let startIndex = 0
  for (let i = 1; i < newMonths.length; i++) {
    // If there is a discontinuity in the sequence, that's where the new year starts
    if (newMonths[i] - newMonths[i - 1] > 1) {
      startIndex = i
      break
    }
  }

  // Check for year wrap-around
  if (newMonths[0] === 0 && newMonths[newMonths.length - 1] === 11) {
    // Find the break where 11 is followed by 0
    for (let i = 0; i < newMonths.length - 1; i++) {
      if (newMonths[i] === 11 && newMonths[i + 1] === 0) {
        startIndex = i + 1
        break
      }
    }
  }

  // Rotate the array to start from the start index
  return [...newMonths.slice(startIndex), ...newMonths.slice(0, startIndex)]
}
