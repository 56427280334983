import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { PayrollPlaygroundTable, PayrollPlaygroundTableType } from './types'
import { PayrollConfigurations } from './components/PayrollConfigurations'
import { useAvailableCells, usePayrollDetails } from '@/libs/react-query/hooks'
import { PayrollSteps } from './components/PayrollSteps'
import { ControlledFilterBar, Div, Drawer, DrawerContainer } from '@/components'
import { useEffect, useRef, useState } from 'react'
import { fromPayrollDetailedToPlayground } from './parser'
import { usePayrollPlaygroundStore } from './store'
import { useHandleStepActions } from './hooks/useHandleStepActions'
import { PayrollCalendar } from './components/PayrollCalendar'
import { PayrollPaidHours } from './components/PayrollPaidHours'
import { PayrollOtherEarns } from './components/PayrollOtherEarns'
import { PayrollEarnsSummary } from './components/PayrollEarnsSummary'
import { PayrollDiscounts } from './components/PayrollDiscounts'
import { PayrollIPS } from './components/PayrollIPS'
import { PayrollAccountPayments } from './components/PayrollAccountPayments'
import { PayrollGeneral } from './components/PayrollGeneral'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useDebounce } from '@/hooks'
import { DayDetail } from './components/drawers'
import { useHandleUpdatePayrollFields } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollFields'
import { useHandleUpdatePayrollPayment } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollPayment'
import { ClosePayrollDialog } from './components/dialogs/ClosePayrollDialog'
import { ExportPayroll } from './components/drawers/ExportPayroll'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { useIsUpdatingPayrollField } from './hooks/useIsUpdatingPayrollField'
import { useSetAvailableCells } from '@/libs/react-query/hooks/useAvailableCells/useAllAvailableCells'
import { useHandleUpdatePayrollConcept } from '@/libs/react-query/mutations/payroll/useHandleUpdatePayrollConcept'

interface PayrollPlaygroundProps {
  id?: string
}

export const PayrollPlayground = (props: PayrollPlaygroundProps) => {
  const firstRender = useRef(true)
  const { data: company } = useMyCompany()
  const [isClosePayrollModalOn, setIsClosePayrollModalOn] = useState(false)
  const { handleChangeStepIndex, currentStep, drawer, handleCloseDrawer } =
    usePayrollPlaygroundStore((state) => ({
      currentStep: state.currentStepValue,
      drawer: state.drawer,
      handleCloseDrawer: state.handleCloseDrawer,
      handleChangeStepIndex: state.handleChangeStepIndex,
    }))

  const [hasFinishedParsing, setHasFinishedParsing] = useState(false)

  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('payroll')),
  )

  const methods = useForm<PayrollPlaygroundTableType>({
    resolver: zodResolver(PayrollPlaygroundTable),
    defaultValues: {
      data: [],
      name: '',
      description: '',
      periods: [],
      users_ids: [],
      cellsIds: availableCells?.map((c) => c.id) || [],
      nameFilter: '',
      pagination: {
        page: 0,
        perPage: 20,
      },
    },
  })

  const [cellsIds, nameFilter, page, perPage] = useWatch({
    control: methods.control,
    name: ['cellsIds', 'nameFilter', 'pagination.page', 'pagination.perPage'],
  })

  useSetAvailableCells({
    methods,
    name: 'cellsIds',
    permission: 'payroll',
  })

  const debouncedCellsIds = useDebounce(cellsIds, 250)
  const debouncedNameFilter = useDebounce(nameFilter, 250)
  const { data: detailedPayroll, isFetching: isFetchingPayrollDetail } =
    usePayrollDetails({
      payrollId: props.id || '',
      cellsIds: debouncedCellsIds || cellsIds,
      name: debouncedNameFilter,
      page,
      perPage,
    })

  useHandleStepActions(methods, props.id, () => setIsClosePayrollModalOn(true))

  const isUpdatingPayrollField = useIsUpdatingPayrollField()

  useEffect(() => {
    if (!props.id) {
      handleChangeStepIndex(0)
    }
  }, [handleChangeStepIndex, props.id])

  useEffect(() => {
    if (
      !detailedPayroll?.data ||
      !detailedPayroll?.payroll ||
      !company ||
      isUpdatingPayrollField
      // (isFetchingPayrollDetail && !firstRender.current)
      // isFetchingPayrollDetail
    ) {
      return
    }

    firstRender.current = false

    const { cellsIds, nameFilter, pagination } = methods.getValues()

    const playgroundPayroll = fromPayrollDetailedToPlayground(
      detailedPayroll,
      nameFilter,
      cellsIds,
      company.concepts,
    )

    methods.reset({
      ...playgroundPayroll,
      pagination: {
        page: pagination.page,
        perPage: detailedPayroll.pageSize,
        total: detailedPayroll.total,
        totalPages: detailedPayroll.totalPages,
      },
    })

    setHasFinishedParsing(true)
  }, [detailedPayroll])

  const { isLoading: isLoadingUpdateField } = useHandleUpdatePayrollFields()
  const { isLoading: isLoadingUpdatePayment } = useHandleUpdatePayrollPayment()
  const { isLoading: isLoadingUpdateConcept } = useHandleUpdatePayrollConcept()

  return (
    <DrawerContainer open={drawer.isOpen} onOpenChange={handleCloseDrawer}>
      <FormProvider {...methods}>
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ControlledFilterBar
            nameFilterName="nameFilter"
            withAdvancedCells
            cellsIdsName="cellsIds"
            isLoading={
              isFetchingPayrollDetail ||
              isLoadingUpdateField ||
              isLoadingUpdatePayment ||
              isLoadingUpdateConcept
            }
            policiesIds={[
              ...permissionsArray.filter((p) => p.includes('payroll')),
              'deleted',
            ]}
          />
          <Div css={{ paddingTop: 16 }} />
          <Div
            css={{
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            {props.id && <PayrollSteps />}
          </Div>
          {(hasFinishedParsing || !props.id) && (
            <>
              <Div css={{ marginBottom: '$4' }} />

              {currentStep === 'CONFIG' && <PayrollConfigurations />}
              {currentStep === 'CALENDAR' && <PayrollCalendar />}
              {currentStep === 'PAID_HOURS' && <PayrollPaidHours />}
              {currentStep === 'OTHER_EARNS' && <PayrollOtherEarns />}
              {currentStep === 'SUMMARY_EARNS' && <PayrollEarnsSummary />}
              {currentStep === 'DISCOUNTS' && <PayrollDiscounts />}
              {currentStep === 'IPS' && <PayrollIPS />}
              {currentStep === 'ACCOUNT_PAYMENT' && <PayrollAccountPayments />}
              {currentStep === 'GENERAL_SUMMARY' && <PayrollGeneral />}
            </>
          )}
        </Div>

        <Drawer
          onOpenChange={handleCloseDrawer}
          noPadding={true}
          noClose={true}
          content={
            drawer.type === 'DAY_DETAIL' ? (
              <DayDetail />
            ) : drawer.type === 'EXPORT' ? (
              <ExportPayroll handleCloseDrawer={handleCloseDrawer} />
            ) : null
          }
        />
        <ClosePayrollDialog
          isOpen={isClosePayrollModalOn}
          name={detailedPayroll?.payroll.name || ''}
          setIsOpen={setIsClosePayrollModalOn}
        />
      </FormProvider>
    </DrawerContainer>
  )
}
