import React, { useMemo } from 'react'
import { ControlledFilterBar, Div, ExportTemplateDrawer } from '@/components'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useHandleTemplateHubActions } from './actions'
import { useHandleUpdateTemplate } from '@/libs/react-query/mutations/reports/useHandleUpdateTemplate'
import { IListedTable, TableList } from '../components/TableList'
import { useReportTemplates } from '@/libs/react-query/hooks/useReportTemplates'
import { Tag } from '@punto-ui/react'
import { StarIcon, TrophyIcon } from '@heroicons/react/24/outline'
import { useIsFeatureInPlan } from '@/hooks/useIsFeatureInPlan'
import { useHandleToggleFavoriteTemplate } from '@/libs/react-query/mutations/reports/useHandleToggleFavoriteTemplate'
import { useTabStore } from '@/store'
import { useHandleCreateTemplate } from '@/libs/react-query/mutations/reports/useHandleCreateTemplate'
import useToast from '@/hooks/useToast'
import { useHandleDeleteTemplate } from '@/libs/react-query/mutations/reports/useHandleDeleteTemplate'
import { useFormContext, useWatch } from 'react-hook-form'
import { IReportsStateSchema } from '../reports-state'
import { useMe } from '@/libs/react-query/hooks'

export const TemplateHub = () => {
  const toast = useToast()
  const { data: me } = useMe()

  const methods = useFormContext<IReportsStateSchema>()

  const isFavoriteFilter = useWatch({
    control: methods.control,
    name: 'filterFavorites',
  })

  const { data: isTemplateBuilderFullyAvailable } =
    useIsFeatureInPlan('payroll')

  const [selectedTemplateId, setSelectedTemplateId] = React.useState('')

  const { addTab, changeTab } = useTabStore((state) => ({
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const { data: templates, isFetching: isLoadingReports } = useReportTemplates({
    name: '',
    page: 0,
    perPage: 100,
  })
  const { isLoading: isLoadingUpdateTemplate } = useHandleUpdateTemplate()
  const {
    mutateAsync: handleDeleteTemplate,
    isLoading: isLoadingDeleteTemplate,
  } = useHandleDeleteTemplate()
  const {
    isLoading: isLoadingCreateTemplate,
    mutateAsync: handleCreateTemplate,
  } = useHandleCreateTemplate()
  const {
    isLoading: isLoadingToggleFavorite,
    mutateAsync: handleToggleFavorite,
  } = useHandleToggleFavoriteTemplate()

  useHandleTemplateHubActions()

  const formattedTemplates = useMemo(() => {
    const defaultTemplates: IListedTable[] =
      templates?.data
        .filter((d) => !d.identifier_id && !d.id.includes('default'))
        .filter((d) => {
          if (isFavoriteFilter) {
            return d.favorite
          }
          return true
        })
        .map((template) => ({
          id: template.id,
          name: template.name,
          isFavorite: template.favorite,
          isRemovable: false,
          isEditable:
            (!template.id.includes('default') &&
              !template.id.includes('fixed') &&
              me?.isAdmin) ||
            false,
          canDuplicate:
            (!template.id.includes('default') &&
              !template.id.includes('fixed')) ||
            false,
          isDownloadable: true,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) || []

    const customTemplates: IListedTable[] =
      templates?.data
        .filter((d) => d.identifier_id)
        .filter((d) => {
          if (isFavoriteFilter) {
            return d.favorite
          }
          return true
        })
        .map((template) => ({
          id: template.id,
          name: template.name,
          isFavorite: template.favorite,
          isRemovable: true,
          isEditable: true,
          canDuplicate: true,
          isDownloadable: true,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) || []

    const defaultGovernmentTemplates: IListedTable[] =
      templates?.data
        .filter(
          (d) =>
            !d.identifier_id &&
            d.id.includes('default') &&
            !d.id.includes('fixed'),
        )
        .filter((d) => {
          if (isFavoriteFilter) {
            return d.favorite
          }
          return true
        })
        .map((template) => ({
          id: template.id,
          name: template.name,
          isFavorite: template.favorite,
          isRemovable: false,
          isEditable: false,
          canDuplicate: false,
          isDownloadable: isTemplateBuilderFullyAvailable,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) || []

    return {
      defaultTemplates,
      customTemplates,
      defaultGovernmentTemplates,
    }
  }, [templates, isTemplateBuilderFullyAvailable, isFavoriteFilter])

  const handleEdit = (id: string, name: string) => {
    addTab('reportsV2', [
      {
        id,
        type: 'template',
        label: `Editar ${name}`,
      },
    ])
    changeTab('reportsV2', id)
  }

  const handleDuplicate = async (id: string) => {
    const template = templates?.data.find((t) => t.id === id)

    if (!template) {
      toast.addToast({
        title: 'Template no encontrado',
        type: 'negative',
        description: 'El template no se ha encontrado',
        id: Date.now(),
      })
      return
    }

    const columns = template.columns.map((c) => {
      return {
        type: c.type,
        value: c.name,
        valueType: c.valueType,
      }
    })

    await handleCreateTemplate({
      columns,
      description: template.description,
      name: `${template.name} - Copia`,
      row: template.row,
      favorite: false,
      rowDetails: template.rowDetails,
      allUsersInOneSheet: template.allUsersInOneSheet,
      rowSubDetails: template.rowSubDetails,
      type: template.type,
      filters: template.filters || [],
    })

    toast.addToast({
      title: 'Template duplicado con éxito',
      type: 'positive',
      description: 'El template ha sido duplicado con éxito',
      id: Date.now(),
    })
  }

  return (
    <>
      <Div
        css={{
          height: 'calc(100vh - 60px)',
        }}
      >
        <ControlledFilterBar
          booleanFilters={[
            {
              label: 'Favoritos',
              value: 'favorite',
              name: 'filterFavorites',
              icon: <StarIcon />,
            },
          ]}
          policiesIds={permissionsArray.filter((p) => p.includes('reports'))}
          isLoading={
            isLoadingUpdateTemplate ||
            isLoadingToggleFavorite ||
            isLoadingReports ||
            isLoadingCreateTemplate ||
            isLoadingDeleteTemplate
          }
          nameFilterName="configuration.name"
        />
        <Div
          css={{
            padding: '$4',
            height: 'calc(100vh - 84px)',
            overflowY: 'auto',
          }}
        >
          <TableList
            handleGenerate={(id: string) => setSelectedTemplateId(id)}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDeleteTemplate}
            name="Reportes del Sistema"
            handleFavorite={handleToggleFavorite}
            tables={formattedTemplates.defaultTemplates}
            handleEdit={handleEdit}
          />
          <Div css={{ marginBottom: '$4' }} />
          <TableList
            handleGenerate={(id: string) => setSelectedTemplateId(id)}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDeleteTemplate}
            name="Reportes Personalizados"
            tables={formattedTemplates.customTemplates}
            handleFavorite={handleToggleFavorite}
            handleEdit={handleEdit}
            tag={
              <Tag
                css={{
                  color: '$brand_primary_pure',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '$1',
                  svg: {
                    height: 12,
                    width: 12,
                  },
                }}
              >
                <TrophyIcon />
                Plan Operativo
              </Tag>
            }
          />
          <Div css={{ marginBottom: '$4' }} />
          <TableList
            handleGenerate={(id: string) => setSelectedTemplateId(id)}
            handleFavorite={handleToggleFavorite}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDeleteTemplate}
            name="Libros Laborales"
            tables={formattedTemplates.defaultGovernmentTemplates}
            handleEdit={handleEdit}
            tag={
              <Tag
                css={{
                  color: '$brand_primary_pure',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '$1',
                  svg: {
                    height: 12,
                    width: 12,
                  },
                }}
              >
                <TrophyIcon />
                Plan Operativo
              </Tag>
            }
          />
        </Div>
      </Div>
      <ExportTemplateDrawer
        templateId={selectedTemplateId}
        isOpen={!!selectedTemplateId}
        setIsOpen={() => setSelectedTemplateId('')}
      />
    </>
  )
}
