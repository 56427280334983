import { ICompanyAddress } from '@/libs/react-query/types/company'
import { apiV2 } from '@/services/api'
import { useQuery } from 'react-query'

const fetchCompanyAddress = async () => {
  try {
    const response = await apiV2.get<{ addresses: ICompanyAddress[] }>(
      '/company/get-company-address',
    )

    return response.data
  } catch (err) {
    return {
      addresses: [],
    }
  }
}

export const useCompanyAddress = () => {
  const query = useQuery(
    ['company-address'],
    async () => {
      const address = await fetchCompanyAddress()

      return address
    },
    {
      keepPreviousData: true,
    },
  )

  return query
}
