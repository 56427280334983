import dayjs from 'dayjs'
import { formattedHourToMinutes } from '../date'
import { CreateShiftDTO } from '@/libs/react-query/types/shift'

export interface CreateShitParams {
  name: string
  code: string
  type: 'WEEKLY' | 'REPEAT_AND_REST'
  rest_hours?: number
  work_hours?: number
  days: Array<{
    workday: number
    start_time: string
    turn_time: string
    end_time: string
    flexible_hours?: number
    intervals?: Array<{
      start_time: string
      end_time: string
      maxMinutes: number
    }>
  }>
  config: {
    beforeEntranceTolerance: number
    afterEntranceTolerance: number
    beforeExitTolerance: number
    afterExitTolerance: number

    isFlexibleJourney: boolean
    timezone: string

    switchFlexibleShift: number
    predefinedIntervals: boolean
    predefinedMorningInterval: number
    predefinedNightInterval: number
    predefinedMorningMixedInterval: number
    predefinedNightMixedInterval: number
    shouldAutocompleteIntervals: boolean
    flexibleMixed: number
    flexibleNight: number
    flexibleMorning: number
    shouldDiscountFlexibleHours: boolean
    shouldUseToleranceFlexible: boolean
    shouldUseIntervalsFlexible: boolean
    shouldUseHolidaysFlexible: boolean
    shouldConsiderIntervalCredits: boolean
    ignoreIncompleteInterval: boolean
  }
}

export const fromAddShiftDataToCreationPayload = (
  data: CreateShiftDTO,
): CreateShitParams => {
  const config: CreateShitParams['config'] = {
    timezone: data.config.timezone,
    isFlexibleJourney: data.config.is_flexible_journey,
    afterEntranceTolerance: +data.config.entrance_tolerance_after || 0,
    afterExitTolerance: +data.config.leave_tolerance_after || 0,
    beforeEntranceTolerance: +data.config.entrance_tolerance_before || 0,
    beforeExitTolerance: +data.config.leave_tolerance_before || 0,
    flexibleMixed:
      formattedHourToMinutes(data.config.flexible_mixed_hours) / 60 || 0,
    flexibleMorning:
      formattedHourToMinutes(data.config.flexible_morning_hours) / 60 || 0,
    flexibleNight:
      formattedHourToMinutes(data.config.flexible_night_hours) / 60 || 0,
    predefinedIntervals: data.config.use_predefined_intervals,
    predefinedMorningInterval:
      formattedHourToMinutes(data.config.default_interval_morning) / 60 || 0,
    predefinedMorningMixedInterval:
      formattedHourToMinutes(data.config.default_interval_mixed_morning) / 60 ||
      0,
    predefinedNightInterval:
      formattedHourToMinutes(data.config.default_interval_night) / 60 || 0,
    predefinedNightMixedInterval:
      formattedHourToMinutes(data.config.default_interval_mixed_night) / 60 ||
      0,
    shouldDiscountFlexibleHours: data.config.discount_time,
    shouldUseHolidaysFlexible: data.config.consider_holidays,
    shouldUseIntervalsFlexible: data.config.consider_interval,
    shouldUseToleranceFlexible: data.config.consider_tolerance,
    switchFlexibleShift: data.config.is_flexible_journey ? 1 : 0, // TODO: ???
    shouldConsiderIntervalCredits: data.config.should_consider_interval_credits,
    ignoreIncompleteInterval: data.config.ignore_incomplete_punches,
    shouldAutocompleteIntervals: data.config.should_autocomplete_intervals,
  }

  const days: CreateShitParams['days'] = data.shifts
    .slice(0, 7)
    .map((shift) => {
      const intervals = shift.intervals.map((interval) => ({
        end_time: interval.endTime,
        maxMinutes: formattedHourToMinutes(interval.duration),
        start_time: interval.startTime,
      }))

      return {
        end_time: shift.endTime,
        intervals,
        start_time: shift.startTime,
        turn_time: shift.switchShiftsTime,
        workday: dayjs(shift.date).day(),
      }
    })

  return {
    code: data.code,
    config,
    days,
    name: data.name,
    type: 'WEEKLY',
  }
}
