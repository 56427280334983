import { formatMinutes, formattedHourToMinutes } from '@/utils/date'
import dayjs from 'dayjs'
import { AddShiftData } from './form'
import { SmartShift } from '../components/SmartShiftManagement/types'
import { CreateShiftDTO } from '@/libs/react-query/types/shift'
import { v4 as uuid } from 'uuid'

export const convertSmartShiftToAddShiftData = (
  smartShift: SmartShift,
): AddShiftData => {
  const shiftDaysOrdered = smartShift.days.sort((a, b) => {
    if (a.weekDay === 0) return 1
    if (b.weekDay === 0) return -1
    return a.weekDay - b.weekDay
  })

  const shifts = shiftDaysOrdered.map((day) => {
    const date = dayjs()
      .set('month', new Date().getMonth())
      .startOf('week')
      .add(day.weekDay, 'days')

    const intervals: any[] = []
    day.intervals.forEach((_, index) => {
      if (index === 0) return

      intervals.push({
        id: uuid(),
        startTime: day.intervals[index - 1].endTime,
        endTime: day.intervals[index].startTime,
        duration: formatMinutes(day.intervals[index].maxMinutes),
      })
    })

    const startTime = day.intervals[0]?.startTime || ''
    const endTime = day.intervals[day.intervals.length - 1]?.endTime || ''

    return {
      date: date.format('YYYY-MM-DD'),
      dayOfWeek: date.format('dddd'), // You might need to adjust this
      startTime,
      endTime,
      switchShiftsTime: day.shiftTurn,
      isSwitchTimeInvalid: day.shiftTurn > startTime && day.shiftTurn < endTime,
      intervals,
    }
  })

  const addShiftData: AddShiftData = {
    copiedShiftWeekday: null,
    shifts,
    journeyType: smartShift.config.is_flexible_journey ? 'flexible' : 'fixed',
    name: smartShift.shiftName,
    code: smartShift.shiftCode,
    config: {
      timezone: smartShift.config.timezone,
      number_of_weeks: smartShift.config.number_of_weeks,
      entrance_tolerance_before: formatMinutes(
        +smartShift.config.entrance_tolerance_before || 0,
      ),
      entrance_tolerance_after: formatMinutes(
        +smartShift.config.entrance_tolerance_after || 0,
      ),
      leave_tolerance_before: formatMinutes(
        +smartShift.config.leave_tolerance_before || 0,
      ),
      leave_tolerance_after: formatMinutes(
        +smartShift.config.leave_tolerance_after || 0,
      ),
      is_smart_shift_change: smartShift.config.is_smart_shift_change,
      daily_hours_amount: smartShift.config.daily_hours_amount,
      mixed_hours_amount: smartShift.config.mixed_hours_amount,
      night_hours_amount: smartShift.config.night_hours_amount,
      discount_time: smartShift.config.discount_time,
      consider_holidays: smartShift.config.consider_holidays,
      consider_tolerance: smartShift.config.consider_tolerance,
      consider_interval: smartShift.config.consider_interval,
      should_autocomplete_intervals:
        smartShift.config.should_autocomplete_intervals,
      use_predefined_intervals: smartShift.config.use_predefined_intervals,
      flexible_morning_hours: formatMinutes(
        +smartShift.config.flexible_morning_hours * 60,
      ),
      flexible_mixed_hours: formatMinutes(
        +smartShift.config.flexible_mixed_hours * 60,
      ),
      flexible_night_hours: formatMinutes(
        +smartShift.config.flexible_night_hours * 60,
      ),
      default_interval_night: formatMinutes(
        +smartShift.config.default_interval_night * 60,
      ),
      default_interval_mixed_night: formatMinutes(
        +smartShift.config.default_interval_mixed_night * 60,
      ),
      default_interval_mixed_morning: formatMinutes(
        +smartShift.config.default_interval_mixed_morning * 60,
      ),
      default_interval_morning: formatMinutes(
        +smartShift.config.default_interval_morning * 60,
      ),
      should_consider_interval_credits:
        smartShift.config.should_consider_interval_credits,
      ignore_incomplete_punches: smartShift.config.ignore_incomplete_punches,
    },
  }

  return addShiftData
}

export const getInitialShiftWeeks = (date: Date): AddShiftData => {
  const firstDay = dayjs(date).startOf('week').add(1, 'day')

  const shifts = []

  for (let i = 0; i < 7; i++) {
    const day = firstDay.add(i, 'day')
    if (i === 5 || i === 6) {
      shifts.push({
        date: day.format('YYYY-MM-DD'),
        startTime: '00:00',
        endTime: '00:00',
        dayOfWeek: day.format('dddd'),
        intervals: [],
        switchShiftsTime: '00:00',
        totalDaily: '00:00',
        totalNight: '00:00',
        totalHours: '10:00',
      })
    } else {
      shifts.push({
        date: day.format('YYYY-MM-DD'),
        startTime: '06:00',
        endTime: '18:00',
        dayOfWeek: day.format('dddd'),
        intervals: [],
        switchShiftsTime: '00:00',
        totalDaily: '00:00',
        totalNight: '00:00',
        totalHours: '10:00',
      })
    }
  }

  return {
    shifts,
    copiedShiftWeekday: null,
    journeyType: 'fixed',
    name: '',
    code: '',
    config: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      consider_holidays: false,
      consider_interval: false,
      consider_tolerance: false,

      discount_time: false,
      // tolerance:
      entrance_tolerance_before: '',
      entrance_tolerance_after: '',
      leave_tolerance_before: '',
      leave_tolerance_after: '',
      // Default interval
      default_interval_night: '',
      default_interval_mixed_night: '',
      default_interval_mixed_morning: '',
      default_interval_morning: '',
      // Default hours for flexible.
      flexible_morning_hours: '',
      flexible_mixed_hours: '',
      flexible_night_hours: '',

      is_smart_shift_change: false,

      daily_hours_amount: '',
      mixed_hours_amount: '',
      night_hours_amount: '',
      use_predefined_intervals: false,
      number_of_weeks: 1,

      should_consider_interval_credits: false,
      ignore_incomplete_punches: false,
      should_autocomplete_intervals: false,
    },
  }
}

export const convertAddShiftDataToUpdateShiftData = (
  data: AddShiftData,
): CreateShiftDTO => {
  const isFlexible = data.journeyType === 'flexible'

  return {
    shifts: data.shifts.map((shift) => ({
      date: shift.date,
      dayOfWeek: shift.dayOfWeek,
      startTime: shift.startTime,
      endTime: shift.endTime,
      switchShiftsTime: shift.switchShiftsTime,
      intervals: shift.intervals.map((interval) => ({
        startTime: interval.startTime,
        duration: interval.duration,
        endTime: interval.endTime,
      })),
    })),
    name: data.name,
    code: data.code,
    config: {
      timezone: data.config.timezone,
      number_of_weeks: data.config.number_of_weeks,
      entrance_tolerance_before: formattedHourToMinutes(
        data.config.entrance_tolerance_before,
      ),
      entrance_tolerance_after: formattedHourToMinutes(
        data.config.entrance_tolerance_after,
      ),
      leave_tolerance_before: formattedHourToMinutes(
        data.config.leave_tolerance_before,
      ),
      leave_tolerance_after: formattedHourToMinutes(
        data.config.leave_tolerance_after,
      ),
      is_smart_shift_change: data.config.is_smart_shift_change,
      is_flexible_journey: isFlexible,
      daily_hours_amount: data.config.daily_hours_amount,
      mixed_hours_amount: data.config.mixed_hours_amount,
      night_hours_amount: data.config.night_hours_amount,
      discount_time: data.config.discount_time,
      consider_holidays: data.config.consider_holidays,
      consider_tolerance: data.config.consider_tolerance,
      consider_interval: data.config.consider_interval,
      should_autocomplete_intervals: data.config.should_autocomplete_intervals,
      use_predefined_intervals: data.config.use_predefined_intervals,
      flexible_morning_hours: data.config.flexible_morning_hours,
      flexible_mixed_hours: data.config.flexible_mixed_hours,
      flexible_night_hours: data.config.flexible_night_hours,
      default_interval_night: data.config.default_interval_night,
      default_interval_mixed_night: data.config.default_interval_mixed_night,
      default_interval_mixed_morning:
        data.config.default_interval_mixed_morning,
      default_interval_morning: data.config.default_interval_morning,
      should_consider_interval_credits:
        data.config.should_consider_interval_credits,
      ignore_incomplete_punches: data.config.ignore_incomplete_punches,
    },
  }
}
