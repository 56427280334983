import { UseFormReturn, useWatch } from 'react-hook-form'
import { StatisticsManagerSchemaType } from './types'
import {
  SmartColumnType,
  SubmitValueProps,
} from '@/components/SmartTable/types'
import { Div } from '@/components'
import { Button, Tag } from '@punto-ui/react'
import { useHandleUpdatePlan } from '@/libs/react-query/mutations/permissions/plans/useHandleUpdatePlan'
import { useHandleUpdateBusinessInfo } from '@/libs/react-query/mutations/admin/useHandleUpdateBusinessInfo'
import { CalendarIcon, CloudArrowDownIcon } from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { useMe } from '@/libs/react-query/hooks'
import { useHandleUpdateCompanyId } from '@/libs/react-query/mutations/admin/useHandleUpdateCompanyId'
import { queryClient } from '@/libs/react-query'
import { parseFormattedDate } from '@/utils/date'
import { BusinessCompanyInfoStatus } from '@/libs/react-query/types/company'
import { PlanType } from '@/hooks/useIsFeatureInPlan'
import { useMemo } from 'react'

export const useStatisticsManagerColumns = ({
  methods,
}: {
  methods: UseFormReturn<StatisticsManagerSchemaType>
}) => {
  const { data: me } = useMe()
  const { reset } = useTabStore((state) => ({
    reset: state.reset,
  }))

  const { mutateAsync: handleUpdateCompanyId } = useHandleUpdateCompanyId()
  const { mutateAsync: handleUpdatePlan } = useHandleUpdatePlan()
  const { mutateAsync: handleUpdateBusinessInfo } =
    useHandleUpdateBusinessInfo()

  const [weeksLength] = useWatch({
    control: methods.control,
    name: ['weeksLength'],
  })

  const weeksHeader = useMemo(() => {
    const weeks = Array.from({ length: weeksLength }, (_, i) => i + 1)

    return weeks.map((week) => `S. ${week}`)
  }, [weeksLength])

  const columns: SmartColumnType[] = [
    {
      type: 'subheader',
      header: 'Empresas en PuntoOK',
      name: 'teste',
      items: [
        {
          name: 'download',
          header: 'Cambiar',
          width: 160,
          type: 'custom',
          component: ({ index }: { index: number }) => {
            const company = methods.getValues(`data.${index}`)

            if (company?.id === me?.company_id)
              return (
                <Div
                  css={{
                    paddingLeft: '$4',

                    '> div': {
                      height: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                  }}
                >
                  <Tag variant={'positive'}>Empresa Atual</Tag>
                </Div>
              )

            return (
              <Div css={{ paddingLeft: '$4' }}>
                <Button
                  icon={<CloudArrowDownIcon />}
                  variant={'secondary'}
                  onClick={async () => {
                    if (me?.company_id !== company.id && company.id) {
                      await handleUpdateCompanyId(company.id)
                      reset()
                      queryClient.resetQueries()
                    }
                  }}
                  css={{ height: 24 }}
                >
                  Cambiar para
                </Button>
              </Div>
            )
          },
        },
        {
          type: 'input-text',
          name: 'count',
          draggable: null,
          disabled: true,
          inputType: 'no-mask',
          header: '',
          width: 40,
        },
        {
          type: 'input-text',
          name: 'name',
          disabled: true,
          draggable: null,
          inputType: 'no-mask',
          header: 'Nombre',
          width: 400,
        },
        {
          type: 'dropdown',
          name: 'status',
          draggable: null,
          header: 'Status',
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(`data.${props.index}.status`)

            if (data.original === data.value) return

            methods.setValue(`data.${props.index}.status.status`, 'loading')

            await handleUpdateBusinessInfo({
              company_id: company.id,
              status: data.value as BusinessCompanyInfoStatus,
            })

            methods.setValue(`data.${props.index}.status.status`, '')
          },
          options: [
            {
              label: 'Nueva Empresa',
              value: 'NEW',
            },
            {
              label: 'Cliente',
              value: 'CLIENT',
            },
            {
              label: 'Implementación',
              value: 'IMPLEMENTING',
            },
            {
              label: 'Cancelada',
              value: 'CANCELED',
            },
            {
              label: 'Deletada',
              value: 'DELETED',
            },
            {
              label: 'Prueba',
              value: 'TEST',
            },
          ],
          width: 150,
        },
        {
          type: 'dropdown',
          name: 'plan',
          draggable: null,
          header: 'Plan',
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const plan = props.data.value as PlanType

            await handleUpdatePlan({
              company_id: company.id,
              plan,
            })
          },
          options: [
            {
              label: 'Marcacion',
              value: 'punches',
            },
            {
              label: 'Operativo',
              value: 'payroll',
            },
            {
              label: 'Avanzado',
              value: 'enterprise',
            },
          ],
          width: 120,
        },
        {
          type: 'input-text',
          name: 'activeEmployees',
          draggable: null,
          inputType: 'no-mask',
          disabled: true,
          header: 'Activos',
          width: 100,
        },
        {
          type: 'input-text',
          name: 'inactiveEmployees',
          draggable: null,
          inputType: 'no-mask',
          disabled: true,
          header: 'Inactivos',
          width: 120,
        },
        {
          type: 'input-text',
          name: 'totalEmployees',
          draggable: null,
          inputType: 'no-mask',
          disabled: true,
          header: 'Total Colab.',
          width: 120,
        },
        {
          type: 'input-text',
          name: 'document',
          draggable: null,
          inputType: 'no-mask',
          disabled: true,
          header: 'Documento',
          width: 150,
        },
        {
          type: 'input-text',
          name: 'legal_representative',
          draggable: null,
          header: 'Representante Legal',
          disabled: true,
          inputType: 'no-mask',
          width: 250,
        },
        {
          type: 'input-text',
          name: 'email',
          draggable: null,
          header: 'E-mail',
          disabled: true,
          inputType: 'email',
          width: 150,
        },
        // {
        //   type: 'input-text',
        //   name: 'created_at',
        //   draggable: null,
        //   header: 'Data de creación',
        //   disabled: true,
        //   inputType: 'no-mask',
        //   width: 150,
        // },
      ],
    },
    {
      type: 'subheader',
      header: 'Informaciones para Facturación',
      name: 'teste-5',
      items: [
        {
          type: 'input-text',
          name: 'entrance_date',
          draggable: null,
          header: 'Data de Inicio PuntoOK',
          inputType: 'datetime',
          icon: <CalendarIcon />,
          width: 250,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(`data.${props.index}.entrance_date`)

            const validDate = parseFormattedDate(data.value as string)

            if (!validDate || data.original === data.value) return

            methods.setValue(
              `data.${props.index}.entrance_date.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              entrance_date: validDate.toISOString(),
            })

            methods.setValue(`data.${props.index}.entrance_date.status`, '')
          },
        },
        {
          type: 'input-text',
          name: 'cancelation_date',
          draggable: null,
          header: 'Data de Cancelamento PuntoOK',
          inputType: 'datetime',
          icon: <CalendarIcon />,
          width: 250,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(
              `data.${props.index}.cancelation_date`,
            )

            const validDate = parseFormattedDate(data.value as string)

            if (!validDate || data.original === data.value) return

            methods.setValue(
              `data.${props.index}.cancelation_date.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              cancelation_date: validDate.toISOString(),
            })

            methods.setValue(`data.${props.index}.cancelation_date.status`, '')
          },
        },
      ],
    },
    {
      type: 'subheader',
      header: 'Informaciones de Soporte',
      name: 'teste-2',
      items: [
        {
          type: 'input-text',
          name: 'test_period_start',
          draggable: null,
          inputType: 'datetime',
          icon: <CalendarIcon />,
          header: 'Inicio Prueba',
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(
              `data.${props.index}.test_period_start`,
            )

            const validDate = parseFormattedDate(data.value as string)

            if (!validDate || data.original === data.value) return

            methods.setValue(
              `data.${props.index}.test_period_start.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              test_period_start: validDate.toISOString(),
            })

            methods.setValue(`data.${props.index}.test_period_start.status`, '')
          },
          width: 180,
        },
        {
          type: 'input-text',
          name: 'test_period_end',
          draggable: null,
          inputType: 'datetime',
          header: 'Final Prueba',
          icon: <CalendarIcon />,
          width: 180,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(
              `data.${props.index}.test_period_end`,
            )

            const validDate = parseFormattedDate(data.value as string)

            if (!validDate || data.original === data.value) return

            methods.setValue(
              `data.${props.index}.test_period_end.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              test_period_end: validDate.toISOString(),
            })

            methods.setValue(`data.${props.index}.test_period_end.status`, '')
          },
        },
        {
          type: 'input-text',
          name: 'payroll_import_date',
          draggable: null,
          inputType: 'datetime',
          icon: <CalendarIcon />,
          header: 'Fecha Import. Plan.',
          width: 180,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(
              `data.${props.index}.payroll_import_date`,
            )

            const validDate = parseFormattedDate(data.value as string)

            if (!validDate || data.original === data.value) return

            methods.setValue(
              `data.${props.index}.payroll_import_date.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              payroll_import_date: validDate.toISOString(),
            })

            methods.setValue(
              `data.${props.index}.payroll_import_date.status`,
              '',
            )
          },
        },
        {
          type: 'input-text',
          name: 'app_setup_date',
          draggable: null,
          inputType: 'datetime',
          icon: <CalendarIcon />,
          header: 'Fecha App y Soporte',
          width: 180,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(`data.${props.index}.app_setup_date`)

            const validDate = parseFormattedDate(data.value as string)

            if (!validDate || data.original === data.value) return

            methods.setValue(
              `data.${props.index}.app_setup_date.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              app_setup_date: validDate.toISOString(),
            })

            methods.setValue(`data.${props.index}.app_setup_date.status`, '')
          },
        },
        {
          type: 'input-text',
          name: 'face_register_date',
          draggable: null,
          inputType: 'datetime',
          icon: <CalendarIcon />,
          header: 'Registro fotos',
          width: 180,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(
              `data.${props.index}.face_register_date`,
            )

            const validDate = parseFormattedDate(data.value as string)

            if (!validDate || data.original === data.value) return

            methods.setValue(
              `data.${props.index}.face_register_date.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              face_register_date: validDate.toISOString(),
            })

            methods.setValue(
              `data.${props.index}.face_register_date.status`,
              '',
            )
          },
        },
        {
          type: 'input-text',
          name: 'punch_register_date',
          draggable: null,
          inputType: 'datetime',
          icon: <CalendarIcon />,
          header: 'Marcación',
          width: 180,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(
              `data.${props.index}.punch_register_date`,
            )

            const validDate = parseFormattedDate(data.value as string)

            if (!validDate || data.original === data.value) return

            methods.setValue(
              `data.${props.index}.punch_register_date.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              punch_register_date: validDate.toISOString(),
            })

            methods.setValue(
              `data.${props.index}.punch_register_date.status`,
              '',
            )
          },
        },
      ],
    },
    {
      type: 'subheader',
      header: 'Informaciones de Contacto',
      name: 'teste-3',
      items: [
        {
          type: 'input-text',
          name: 'contact_email',
          draggable: null,
          inputType: 'no-mask',
          header: 'Email de Contacto',
          width: 220,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(`data.${props.index}.contact_email`)

            if (data.original === data.value) return

            methods.setValue(
              `data.${props.index}.contact_email.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              email: data.value as string,
            })

            methods.setValue(`data.${props.index}.contact_email.status`, '')
          },
        },
        {
          type: 'input-text',
          name: 'contact_name',
          draggable: null,
          inputType: 'no-mask',
          header: 'Nombre de Contacto',
          width: 220,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(`data.${props.index}.contact_name`)

            if (data.original === data.value) return

            methods.setValue(
              `data.${props.index}.contact_name.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              contact_name: data.value as string,
            })

            methods.setValue(`data.${props.index}.contact_name.status`, '')
          },
        },
        {
          type: 'input-text',
          name: 'contact_phone',
          draggable: null,
          inputType: 'no-mask',
          header: 'Teléfono de Contacto',
          width: 200,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(`data.${props.index}.contact_phone`)

            if (data.original === data.value) return

            methods.setValue(
              `data.${props.index}.contact_phone.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              phone: data.value as string,
            })

            methods.setValue(`data.${props.index}.contact_phone.status`, '')
          },
        },
      ],
    },
    {
      type: 'subheader',
      header: 'Informaciones de Pagos (Fixas)',
      name: 'teste-4',
      items: [
        {
          type: 'input-text',
          name: 'payment_month_day',
          draggable: null,
          inputType: 'only-numbers',
          icon: <CalendarIcon />,
          header: 'F. de Pago',
          width: 130,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(
              `data.${props.index}.payment_month_day`,
            )

            if (data.original === data.value || Number.isNaN(data.value)) return

            methods.setValue(
              `data.${props.index}.payment_month_day.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              payment_month_day: Number(data.value) as number,
            })

            methods.setValue(`data.${props.index}.payment_month_day.status`, '')
          },
        },
        {
          type: 'input-text',
          name: 'default_external_product_code',
          draggable: null,
          inputType: 'no-mask',
          header: 'Cod. de Produto',
          width: 180,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(
              `data.${props.index}.default_external_product_code`,
            )

            if (data.original === data.value) return

            methods.setValue(
              `data.${props.index}.default_external_product_code.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              default_external_product_code: data.value as string,
            })

            methods.setValue(
              `data.${props.index}.default_external_product_code.status`,
              '',
            )
          },
        },
        {
          type: 'input-text',
          name: 'default_month_invoice_value',
          draggable: null,
          inputType: 'money-guarani',
          header: 'Valor Padrón Factura',
          width: 180,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(
              `data.${props.index}.default_month_invoice_value`,
            )

            if (data.original === data.value || Number.isNaN(data.value)) return

            methods.setValue(
              `data.${props.index}.default_month_invoice_value.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              default_month_invoice_value: Number(data.value),
            })

            methods.setValue(
              `data.${props.index}.default_month_invoice_value.status`,
              '',
            )
          },
        },
        {
          type: 'input-text',
          name: 'default_month_invoice_quantity',
          draggable: null,
          inputType: 'no-mask',
          header: 'Qtd. en Factura',
          width: 140,
          handleSubmitValue: async (props: SubmitValueProps) => {
            const company = methods.getValues('data')[props.index]
            const data = methods.getValues(
              `data.${props.index}.default_month_invoice_quantity`,
            )

            if (data.original === data.value || Number.isNaN(data.value)) return

            methods.setValue(
              `data.${props.index}.default_month_invoice_quantity.status`,
              'loading',
            )

            await handleUpdateBusinessInfo({
              company_id: company.id,
              default_month_invoice_quantity: Number(data.value),
            })

            methods.setValue(
              `data.${props.index}.default_month_invoice_quantity.status`,
              '',
            )
          },
        },
      ],
    },
    {
      type: 'subheader',
      header: 'Informaciones del Sistema',
      name: 'teste-6',
      items: [
        {
          type: 'input-text',
          name: 'weeksPunches',
          draggable: null,
          array: true,
          disabled: true,
          length: weeksLength,
          headers: weeksHeader,
          inputType: 'only-numbers',
          header: 'Marcaciones Semanales',
          width: 60,
        },
        {
          type: 'input-text',
          name: 'numberOfPayrolls',
          draggable: null,
          inputType: 'only-numbers',
          header: 'Planillas',
          disabled: true,
          width: 80,
        },
        {
          type: 'input-text',
          name: 'totalPunches',
          disabled: true,
          draggable: null,
          inputType: 'formatted-number',
          header: 'T. Marcaciones',
          width: 120,
        },
        {
          type: 'input-text',
          name: 'totalMovements',
          disabled: true,
          draggable: null,
          inputType: 'no-mask',
          header: 'T. Movimientos',
          width: 120,
        },
        {
          type: 'input-text',
          name: 'totalVacations',
          disabled: true,
          draggable: null,
          inputType: 'no-mask',
          header: 'T. Vacaciones',
          width: 120,
        },
        {
          type: 'input-text',
          name: 'totalPayments',
          header: 'T. Pagos',
          width: 100,
          disabled: true,
          draggable: null,
          inputType: 'no-mask',
        },
      ],
    },
  ]

  return {
    data: columns,
  }
}
