import { Div, ModernTextInput } from '@/components'
import {
  ControlledDropdown,
  ControlledInput,
  ControlledSwitch,
} from '@/components/Forms'
import { useHandleCreateNotifications } from '@/libs/react-query/mutations/notifications/useHandleCreateNotification'
import { CheckBadgeIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@punto-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  INotificationManagementModuleProps,
  notificationsReferenceDatesOptionsByModule,
  notificationTimeBeforeReferenceDateOptions,
} from '../notifications'
import {
  NotificationTableCell,
  NotificationTableRow,
} from '../NotificationTable.styles'
import { NotificationForm, NotificationFormType } from './notification-form'

export const AddNotificationRow = ({
  module,
  closeAddNotification,
}: {
  module: INotificationManagementModuleProps['module']
  closeAddNotification: () => void
}) => {
  const {
    mutateAsync: handleCreateNotification,
    isLoading: isLoadingCreateNotification,
  } = useHandleCreateNotifications()

  const methods = useForm<NotificationFormType>({
    resolver: zodResolver(NotificationForm),
    reValidateMode: 'onSubmit',
  })

  const handleSubmit = methods.handleSubmit(async (data) => {
    await handleCreateNotification({
      name: data.name,
      notification_hour: data.notification_hour,
      notification_time_before_reference_date:
        data.notification_time_before_reference_date,
      reference_date: data.reference_date,
      is_active: !!data.is_active,
      module,
    })

    closeAddNotification()
  })

  const referenceDatesOptions =
    notificationsReferenceDatesOptionsByModule[module]

  return (
    <FormProvider {...methods}>
      <NotificationTableRow
        css={{
          background: 'transparent',

          '&:hover': {
            background: '$interface_light_down',
          },
        }}
      >
        <NotificationTableCell
          css={{
            paddingLeft: '64px',
          }}
        >
          <Div css={{ display: 'flex', alignItems: 'center' }}>
            <ControlledInput
              name="name"
              placeholder="Mi notificación"
              InputComponent={ModernTextInput}
            />
          </Div>
        </NotificationTableCell>
        <NotificationTableCell align="center" valign="middle">
          <Div
            css={{
              display: 'flex',
              justifyContent: 'center',
              maxWidth: 200,
            }}
          >
            <ControlledDropdown
              name="notification_time_before_reference_date"
              options={notificationTimeBeforeReferenceDateOptions}
              InputComponent={ModernTextInput}
            />
          </Div>
        </NotificationTableCell>
        <NotificationTableCell align="center" valign="middle">
          <Div css={{ maxWidth: 200 }}>
            <ControlledDropdown
              name="reference_date"
              InputComponent={ModernTextInput}
              options={referenceDatesOptions}
            />
          </Div>
        </NotificationTableCell>
        <NotificationTableCell align="center" valign="middle">
          <Div css={{ maxWidth: 120 }}>
            <ControlledInput
              name="notification_hour"
              inputType="hour"
              InputComponent={ModernTextInput}
              placeholder="12:00"
              css={{
                textAlign: 'center',
              }}
            />
          </Div>
        </NotificationTableCell>
        <NotificationTableCell align="center" valign="middle">
          <Div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ControlledSwitch name="is_active" />
          </Div>
        </NotificationTableCell>
        <NotificationTableCell align="right" valign="middle">
          <Div
            css={{
              paddingRight: '$4',
            }}
          >
            <Button
              variant="primary"
              size="sm"
              onClick={handleSubmit}
              isLoading={isLoadingCreateNotification}
            >
              <CheckBadgeIcon />
              Crear
            </Button>
          </Div>
        </NotificationTableCell>
      </NotificationTableRow>
    </FormProvider>
  )
}
